module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<img class="mainlogo" src="' +
((__t = (appLogo)) == null ? '' : __t) +
'">\n<div class="taskName">  \n  ' +
((__t = (taskName)) == null ? '' : __t) +
'\n</div>\n';
 if(canSimplifyInterface) { ;
__p += '\n    <span class="simplify-interface">\n        <i class="fa fa-angle-down"></i>\n        <i class="fa fa-angle-up"></i>\n    </span>\n';
 } ;
__p += '\n<!--<div class="taskOperation">\n<span class="saiCustom saiCustom-house_circle action" data-action="home" aria-hidden="true"></span>\n<span class="saiCustom saiCustom-cross_circle action" data-action="close" aria-hidden="true"></span>\n</div>-->';

}
return __p
}