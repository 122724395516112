module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="' +
__e(tileId ) +
'" order="' +
__e(config.get('order') ) +
'" class="' +
((__t = (config.get('type')+'Shortcut')) == null ? '' : __t) +
' desktopTile clickableTile" style="position: relative; top: 0px; left: 0px; width: ' +
__e(config.get('position').width ) +
'px; height: ' +
__e(config.get('position').height ) +
'px;">\n    ';
 var topCaption = config.get('topCaption'); if (topCaption) { ;
__p += '\n         <p class="gridTopCaption' +
__e( topCaption.position === '0'? ' leftTitle' : topCaption.position === '1'? ' centerTitle': ' rightTitle' ) +
'" style="text-align: ' +
__e( topCaption.position === '0'? 'left' : topCaption.position === '1'? 'center': 'right' ) +
'; color: #' +
__e( topCaption.color ) +
'; font-family: \'' +
__e( topCaption.fontName ) +
'\'; font-size: ' +
__e( topCaption.size * heightModifier ) +
'pt" > ' +
__e(topCaption.caption ) +
' </p>\n    ';
 } ;
__p += '\n    \n    ';
 var subCaption = config.get('subCaption'); if (subCaption) { ;
__p += '\n         <p style="text-align: ' +
__e( subCaption.position === '0'? 'left' : subCaption.position === '1'? 'center': 'right' ) +
'; color: #' +
__e( subCaption.color ) +
'; font-family: \'' +
__e( subCaption.fontName ) +
'\'; font-size: ' +
__e( subCaption.size * heightModifier ) +
'pt" > ' +
__e(subCaption.caption ) +
' </p>\n    ';
 } ;
__p += '\n    <div id="' +
__e(tileId ) +
'Content" class="gridLines"></div>\n</div>\n';

}
return __p
}