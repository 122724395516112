'use strict';

import FieldView from 'views/record/Field';
import Config from 'Config';

class LinkField extends FieldView {
    constructor (options) {
        super(options);

        if(this.fieldState.isReadOnly()) {
            this.fieldState.setEnabled(false);
        }

        let pipeIndex = this.fieldState.getLabel().indexOf('|');
        if( pipeIndex >= 0) {
            this.fieldState.setLabel(this.fieldState.getLabel().substring(pipeIndex + 1).trim());
        }
    }

    getFieldValue() {
        return '';
    }

    getlabelElement(){
        return this.getFieldElement();
    }

    getFieldElement(){
        return this.$el.find('a');
    }

    getFieldPositionModifier(loc, left){
        return {
            width: loc.fieldWidth,
            height: loc.fieldHeight,
            'margin-left': left === '0px'? 10 : 0,
            'margin-top' : ( loc.height - loc.fieldHeight ) / 2
        };
    }

    render() {
        super.render();
        this.$el.find('span').css('color', Config.appTheme);
    }

    onDOMUpdated () {
        super.onDOMUpdated();
        let me = this;
        this.getFieldElement().on('click', function() {
            me.trigger('linkclicked', me.fieldConfig);
        });
    }
}

export default LinkField;
