'use strict';

import { TaskRequest } from './TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

/**
 * This class allows to request the content of a remote notification
 */
class GetNotificationRequest extends TaskRequest{
    private remoteArguments: {[key:string] : string};

    constructor(domain:DomainContext, task, id){
        super('notification/'+id,'POST', domain, task);
        this.remoteArguments = {};
    }

    setNotificationArgument(paramId: string, paramValue: string){
        this.remoteArguments[paramId] = paramValue;
    }

    getSerializable(): {[prop:string]: any} {
        let content = super.getSerializable();
        content.request1.parameters = this.remoteArguments;
        return content;
    }
}

export { GetNotificationRequest };