'use strict';

import Backbone from 'backbone';

import Server from 'server/Server';
import { SAIView } from '../../../../Additions';

class PageIconPreview extends SAIView {

    constructor(options) {
        super(options);

        this.template = require('ejs-loader!templates/modalPopup/ModalPageIconPreview.ejs');
        this.tagName = 'div';
        this.id = '';
        this.className = '';
        this.events = {};

        Backbone.View.apply(this, arguments);
    }

    initialize(options) {
        this.options = options;

        this.checkMandatoryParameter(options, 'pageIcon', 'Reference to url to display');

        this.uniqId = parseInt(Math.random() * 10000);
    }

    /**
     * Initially, the form is hidden. The method showForm/hideForm on the
     * view will trigger the display
     */
    render() {
        var me = this;

        this.checkInitialized();

        this.$el = $(this.template({
            uniqId: this.uniqId,
            image: Server.getTokenedUrl(me.parameters.pageIcon)
        }));

        this.$el.on('shown.bs.modal', function () {
            me.initSelectors();
            me.initEvents();
            me.updateBodySize();
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#' + me.uniqId).remove();
        });

        this.$el.modal('show');
    }

    initSelectors() {
        this.modalContent = this.$el.find('.pageIconPreviewContent');

        this.modalHeader = this.modalContent.find('.pageIconPreviewHeader');

        this.modalBody = this.modalContent.find('.pageIconPreviewBody');
        this.previewPanel = this.modalContent.find('.previewPanel');
    }

    initEvents() {
        var me = this;
        $(window).resize(function () {
            me.updateBodySize();
        });
    }

    updateBodySize() {
        var mainHeight = this.modalContent.height();
        var headHeight = this.modalHeader.outerHeight();
        var finalBodyHeight = mainHeight - headHeight - 10;
        this.modalBody.css('height', finalBodyHeight + 'px');
        this.previewPanel.css('height', finalBodyHeight + 'px');
    }
}

export default PageIconPreview;
