module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="innerPage">\n    <div class="pageHeader" style="background: ' +
((__t = (dominantColor)) == null ? '' : __t) +
'; color: ' +
((__t = (headerTextColor)) == null ? '' : __t) +
'">\n        <div class="pageIcon"></div>\n        <div class="pageTitle">' +
((__t = (title)) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="recordContainer"></div>\n</div>    \n';

}
return __p
}