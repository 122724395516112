module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div data-role="' +
((__t = (role)) == null ? '' : __t) +
'" class="btn ' +
((__t = (bootstrapClass)) == null ? '' : __t) +
' ' +
((__t = (btnClass)) == null ? '' : __t) +
' ' +
((__t = (enableOnLoad)) == null ? '' : __t) +
'" ' +
((__t = (dismiss)) == null ? '' : __t) +
' ' +
((__t = (disabled)) == null ? '' : __t) +
' tabindex="0">\n    <i></i>\n    <div class="btn-text">' +
((__t = (btnText)) == null ? '' : __t) +
'</div> \n</div>';

}
return __p
}