'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { BasePanel } from './BasePanel';
import { DomainContext } from '../DomainContext';
import { Field } from './Field';
import { Level } from './Level';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import { Couple } from './Couple';

class Group extends ConfigurationElement{
    private parentPanel: BasePanel;
    private parentLevel: Level;

    private fields: { [fieldId:string]: Field };
    private fieldsArr: Array<Field>;
    private children: Array<Level | Field>;
    protected config: { id: string, label?: string, fields: any, position: any, [propId:string]: string | number | boolean };

    public static ICON_LOCATION_RIGHT:string = 'Right';
    public static CHILD_TYPE_FIELD:string = 'field';
    public static CHILD_TYPE_COUPLE_FIELD:string = 'couple';
    public static CHILD_TYPE_CHECKBOX_FIELD:string = 'checkbox';
    private static ASYNC_PROP_LABEL:Symbol = Symbol();

    constructor(config: {id : string, children: any, position: any}, parentPanel: BasePanel, parentLevel: Level, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [ Group.ASYNC_PROP_LABEL ];
        this.parentPanel = parentPanel;
        this.parentPanel.registerGroup(this);
        this.parentLevel = parentLevel;
        this.fields = {};
        this.fieldsArr = [];
        this.children = [];
        for(let key in config.children) {
            let childConf = config.children[key];
            let cType = Group.getObjectType(childConf.objectType);
            if(cType === BasePanel.CHILD_TYPE_LEVEL) {
                let newChild = new Level(childConf, parentPanel, this.parentLevel, this, context);
                this.children.push(newChild);
            } else if(cType === Group.CHILD_TYPE_FIELD 
                || cType === Group.CHILD_TYPE_CHECKBOX_FIELD) {
                let newChild = new Field(childConf, this.parentPanel.getParentTask(), this, this.parentLevel, context);
                this.children.push(newChild);
            } else if(cType === Group.CHILD_TYPE_COUPLE_FIELD) {
                let newChild = new Couple(childConf, this.parentPanel.getParentTask(), this, this.parentLevel, context);
                this.children = this.children.concat(newChild.getFields());
            } else {
                console.warn('Invalid child type in group : ' + cType);
            }
        }
    }

    public getPanel(): BasePanel {
        return this.parentPanel;
    }

    public getField(fieldId: string): Field {
        return this.fields[fieldId];
    }

    public getFields(): Array<Field> {
        return this.fieldsArr;
    }

    public hasField(fieldId: string): boolean {
        return this.fields[fieldId] !== undefined;
    }

    public getI18NFields() {
        let mappedFields = {};
        for(let field in this.fields) {
            let curField = this.fields[field];
            mappedFields[this.parentPanel.getFieldI18N(curField.getId())] = curField;
        }
        return mappedFields;
    }

    public addField(newField: Field): void {
        this.fields[newField.getId()] = newField;
        this.fieldsArr.push(newField);
    }

    public registerField(newChild: Field): void {
        this.fieldsArr.push(newChild);
        this.fields[newChild.getId()] = newChild;
    }

    public getPosition(format: string) {
        let currentPosition = Object.assign({}, this.config.position['*']);
        if(this.config.position[format]) {
            Object.assign(currentPosition, this.config.position[format]);
        }
        return currentPosition;
    }

    public getExistenceConditions(): string {
        return this.getValueOrDefault('existence');
    }

    public getIconLocation(): string {
        return this.getValueOrDefault('iconLocation', Group.ICON_LOCATION_RIGHT);
    }

    public initializeI18N(): Promise<Array<string>> {
        let allPromises = [];
        let me = this;

        if(this.hasAsyncRequirements()) {
            //We need to fetch the resources
            let i18NID = this.parentPanel.getI18NID() + '.Group' + this.getId();
            let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'task', i18NID, User.getLocale());
            i18NTextPromise.then((i18NText) => {
                if(i18NText !== undefined) {
                    me.setLabel(i18NText);
                }
                me.removeFromAsyncRequirement(Group.ASYNC_PROP_LABEL);
            });
            allPromises.push(i18NTextPromise);
        }
        //All fields must be initialized as well
        for(let fId in this.fields) {
            let fieldProm = this.fields[fId].resolveAsyncProperties();
            if(fieldProm !== null) {
                allPromises.push(fieldProm);
            }
        }
        allPromises.push(this.initializeHelp(this.getHelpId()));

        return allPromises.length > 0 ? Promise.all(allPromises) : null;
    }

    public static setReadOnly(descriptor: any) {
        let setFieldReadOnly = function(descriptor) {
            for(let key in descriptor.children) {
                let childConf = descriptor.children[key];
                let cType = Group.getObjectType(childConf.objectType);
                if(cType === BasePanel.CHILD_TYPE_LEVEL || cType === Group.CHILD_TYPE_COUPLE_FIELD) {
                    setFieldReadOnly(childConf);
                } else if(cType === Group.CHILD_TYPE_FIELD 
                    || cType === Group.CHILD_TYPE_CHECKBOX_FIELD) {
                    Field.setReadOnly(childConf);
                }
            }
        };

        setFieldReadOnly(descriptor);
    }

    public getHelpId(): string {
        return this.parentPanel.getHelpId() + '.' + this.getId();
    }
}

export { Group };