'use strict';

import { DomainContext } from '../DomainContext';
import StringUtils from 'utils/StringUtils';
import { Domain } from 'domain';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';

class ConfigurationElement {
    protected context: DomainContext;
    protected config: { id: string, label?: string };
    private internalId: string;
    protected remainingAsyncProps: Array<Symbol>;

    constructor(config: { id: string, label?: string }, _context: DomainContext){
        this.config = config;
        this.context = _context;
        this.internalId = StringUtils.guid();
    }

    // Should be overridden
    protected initialize() : Promise<any> {
        return new Promise<any>((accept) => {
            accept(undefined);
        });
    }

    protected removeFromAsyncRequirement(prop: Symbol): void {
        var index = this.remainingAsyncProps.indexOf(prop);
        if (index > -1) {
            this.remainingAsyncProps.splice(index, 1);
        }
    }

    protected hasAsyncRequirements(): boolean {
        return this.remainingAsyncProps.length > 0;
    }

    public getInternal(): string {
        return this.internalId;
    }

    public getId(): string {
        return this.config.id;
    }

    public getContext(): DomainContext {
        return this.context;
    }

    public getLabel(): string {
        return this.config.label !== undefined ? this.config.label : this.getId();
    }

    public setLabel(newValue: string): void {
        this.config.label = newValue;
    }

    protected getValueOrDefault(id: string, defaultValue?: string) : string {
        return this.config[id] !== undefined ? this.config[id] : defaultValue;
    }
    protected getNumberValueOrDefault(id: string, defaultValue?: number) : number {
        return this.config[id] !== undefined ? this.config[id] : defaultValue;
    }
    protected getBooleanValueOrDefault(id: string, defaultValue?: boolean) : boolean {
        return this.config[id] !== undefined ? this.config[id] === 'true' || this.config[id] : defaultValue;
    }

    protected static getObjectType(type: string) {
        return type.replace('Screen', '')
            .replace('Object', '')
            .replace('CoupleField', 'Couple')
            .replace('SingleField', 'Field')
            .replace('CheckboxField', 'Checkbox')
            .toLowerCase();
    }

    public getClonedConfig(): {id:string, [configProp: string]: any} {
        return JSON.parse(JSON.stringify(this.config));
    }

    public getConfig(): {id:string, [configProp: string] : any} {
        return this.config;
    }

    public setContext(context: DomainContext) {
        this.context = context;
    }

    public setDatefieldId(id: string) {
        this.config.id = id;
    }

    public initializeHelp(id:string): Promise<String> {
        return new Promise((accept, reject) => {
            CClientConfiguration.getHelp(this.context, id, User.getLocale())
                .then((help) => {
                    this.config['help'] = help;
                    accept(help);
                }).catch(reject);
        });
    }

    public getHelpId(): string {
        return undefined;
    }
}

export { ConfigurationElement };