import NumberUtils from 'utils/NumberUtils';

import Backbone from 'backbone';
import { SAIView } from '../../Additions';

class ModalView extends SAIView {
    initialize(options){
        this.options = options;
        this.uniqId = NumberUtils.getUniqueNumber();
    }

    render(){
        this.$el = $(require('ejs-loader!templates/modal/ModalView.ejs')({
            canDismiss: this.options.canDismiss || true,
            uniqId: this.uniqId,
            extraClass: this.options.extraClass || ''
        }));
    }

    display () {
        var me = this;

        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            if(me.classIdentifier){
                $('#'+me.uniqId).addClass(me.classIdentifier);
            }
            var contentElement = $('#'+me.uniqId).find('.modal-content');
            me.renderInnerPanel(contentElement);
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#'+me.uniqId).remove();
        });

        this.$el.modal('show');
    }

    renderInnerPanel(element){
        //Nothing to do in base modal
    }
}

export default ModalView;
