'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class SubmitRecordRequest extends TaskRequest{
    
    constructor(domain:DomainContext, task){
        super('submitRecord','POST', domain, task);
    }

    setOperation(operation){
        this.operation = operation;
    }

    setPendingDocuments(documents){
        this.documents = documents;
    }

    setRecordContext(context){
        this.record = context;
    }

    getSerializable(){
        let content = super.getSerializable();
        content.request1.recordOperation = this.operation;
        return content;
    }
}

export default SubmitRecordRequest;