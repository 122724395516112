'use strict';

import FieldView from 'views/record/Field';
import { ComboElement, Combo } from '../../../parametrage/structures/Combo';
import Server from 'server/Server';
import ThemeManager from 'parametrage/ThemeManager';
import { ColorTheme } from '../../../parametrage/lookandfeel/ColorTheme';

class EnumIcon extends FieldView {
    supportedMimeType:Array<any>;
    selectedItems:Array<any>;

    constructor(options) {
        super(options);
        this.selectedItems = [];
    }

    getFieldValue() {
        return this.selectedItems;
    }

    protected canSelectMultiple(): boolean {
        return this.fieldConfig.getFormat() === 'List';
    }

    parseValue(value) {
        if(this.canSelectMultiple()) {
            return typeof value === 'object' ? value : value.split(',');
        } else {
            return value;
        }
    }

    setModelValue (value) {
        if((typeof value === 'object' && value.join(',').length === 0) || value.lenght === 0) {
            this.fieldState.setValue(null);
            this.selectedItems = [];
        } else {
            this.fieldState.setValue(typeof value === 'object' ? value.join(',') : value);
            this.selectedItems = value;
        }
    }

    protected getRenderingModel(): {[key:string]: any} {
        let data = super.getRenderingModel();
        let combo: Combo = this.fieldConfig.getLinkedCombo();
        if(!combo) {
            console.error('Missing linked combo on field ' + this.fieldConfig.getId() + ' in task ' + this.fieldConfig.getTask().getId());
        }
        data.selectValues = combo? combo.getElements() : [];
        data.multiple = this.canSelectMultiple();
        //We check if the field is mandatory. If he's then we discard the possibility
        //of not having a value
        let fieldImportance = this.fieldState.getImportance();
        let datafieldImportance = this.fieldState.getLinkedDataField().getImportance();
        let valueMandatory = (this.fieldState.isEnabled() || this.fieldState.getValue() != '') && this.fieldState.isVisible(this.displayMode)
            && (fieldImportance !== undefined ? fieldImportance === '1' : datafieldImportance === '1');
        data.allowEmptyValue = !valueMandatory && this.fieldConfig.getFormat() !== 'List';
        return data;
    }

    render():any {
        super.render();
        let me = this;
        this.supportedMimeType = ['image/png','image/gif','image/jpeg','image/bmp'];
        let combo = me.getConfig().getLinkedCombo();
        let allPromises = [];
        let firstSelectedItemIndex = null;
        let idx = 0;
        allPromises.push(ThemeManager.getUserTheme());
        this.$el.find('.enumIcon').each(function() {
            // add the click binding
            $(this).on('click', me.onClickRequest.bind(me));
            let comboElement = combo.getElement(String($(this).data('id')));
            let icon = comboElement !== null ? comboElement.getAttribute('icon') : null;
            if(icon !== undefined && icon !== null) {
                // load the icon
                var holder = $($(this).find('.icon'));
                var imgScr = Server.getTokenedUrl('configuration/' + me.domainContext.getId() + '/image/highres,big/128,100,64,36/' + icon);
                var prom = new Promise((resolve, reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', imgScr);
                    xhr.onload = () => {
                        resolve([xhr.response, imgScr]);
                    };
                    xhr.send();
                })
                    .then(([response, currentImg]) => me.handleGetImg([response, currentImg, holder])
                    ).then((imgItem:string) => {
                        holder.append(imgItem);
                    }).catch((error) => {
                        console.error(error);
                    });
                allPromises.push(prom);
            } else {
                var holder = $($(this).find('.icon'));
                holder.text(comboElement !== null ? comboElement.getLabel().substring(0,1) : '');
            }
            // find the first select item so we can display it
            if($(this).hasClass('selected') && firstSelectedItemIndex === null) {
                firstSelectedItemIndex = idx;
            }
            idx++;
        });

        Promise.all(allPromises)
            .then(([theme, img]) => {
                this.updateImgSize(firstSelectedItemIndex, theme.getColors());
            });
    }

    loadScrollIcons(holder) : Promise<any> {
        let me = this;
        let icon = 'sai-open-menu';
        var imgScr = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/' + icon);
        var prom = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', imgScr);
            xhr.onload = () => {
                resolve([xhr.response, imgScr]);
            };
            xhr.send();
        })
            .then(([response, currentImg]) => me.handleGetImg([response, currentImg, holder])
            ).then((imgItem:string) => {
                holder.append(imgItem);
            }).catch((error) => {
                console.error(error);
            });
        return prom;
    }

    onClickRequest(src) {
        if(!this.fieldState.isEnabled()) { return; }
        let target = $(src.currentTarget);
        if(target.data('id') === undefined) {
            // the unselect item has been clicked
            this.$el.find('.enumIcon').each(function() {
                $(this).removeClass('selected');
            });
            target.addClass('selected');
            this.selectedItems = [];
        } else {
            let clickedId = String(target.data('id'));
            let itemIndex = this.selectedItems.indexOf(clickedId);
            if(itemIndex != -1) {
                // unselect
                this.selectedItems.splice(itemIndex,1);
                target.removeClass('selected');
            } else {
                // select
                if(this.canSelectMultiple()) {
                    this.selectedItems.push(clickedId);
                    target.addClass('selected');
                } else {
                    this.$el.find('.enumIcon').each(function() {
                        $(this).removeClass('selected');
                    });
                    target.addClass('selected');
                    this.selectedItems = [clickedId];
                }
            }
        }
        this.handleOpacity();
        this.performChange(false,null,false);
    }

    handleGetImg([response, defaultImg, holder]) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (response.indexOf('<svg') >= 0) {
                try {
                    var randNum = Math.floor(Math.random() * 100000);
                    response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                    response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                    var xmlSvg = $($.parseXML(response));
                    var size = '36px';
                    xmlSvg.find('svg').attr('width', size).attr('height',size);
                    resolve(xmlSvg.find('svg'));
                } catch(err) {
                    let imgElement = $('<img src="'+defaultImg+'"/>');
                    resolve(imgElement);
                }
            } else {
                let imgElement = $('<img src="'+defaultImg+'"/>');
                resolve(imgElement);
            }
        });
    }

    // Compute the size according to the component size
    updateImgSize(firstSelectedItemIndex, colorTheme:Array<any>) {
        if(this.$el.is(':visible')) {
            let colorThemeArray = Object.values(colorTheme);
            let component = $(this.$el);
            let componentHeight = component.innerHeight();
            let labelSize = component.find('label').outerHeight();
            let subitemLabelSize = component.find('.label').outerHeight();
            let availableSize = componentHeight - labelSize;
            let iconSize = availableSize - subitemLabelSize;
            let textIconCounter = 0;
            component.find('.enumIcon').each(function() {
                $(this).height(availableSize + 'px');
                $(this).width(availableSize + 'px');
                $(this).find('.icon').each(function() {
                    if($(this).children('img').length === 1) {
                        // the children is an image
                        let img = $($(this).children('img')[0]);
                        img.height(iconSize + 'px');
                        img.width(iconSize + 'px');
                    } else if($(this).children('svg').length === 1) {
                        // the children is an svg
                        let img = $($(this).children('svg')[0]);
                        img.attr('width', iconSize + 'px');
                        img.attr('height', iconSize + 'px');
                    } else {
                        $(this).css('font-size',iconSize-10 + 'px');
                        $(this).css('line-height',iconSize + 'px');
                        // If there is no icon and this is not the unselect button
                        if(!$(this).hasClass('fa-ban')) {
                            let newColor = colorThemeArray[textIconCounter % colorThemeArray.length];
                            $(this).css('color', newColor);
                            textIconCounter++;
                        }
                    }
                });
            });
            let availableWidth = component.innerWidth();
            let iconsNeededSize = component.find('.enumIcon').length * iconSize;
            if(iconsNeededSize > availableWidth) {
                component.find('.extra').each(function() {
                    $(this).css('display','inline-block');
                });
                component.find('.enumIconList').each(function() {
                    $(this).width(availableWidth - 5 + 'px');
                });
            } else {
                component.find('.enumIconList').each(function() {
                    $(this).width(availableWidth + 'px');
                });
            }

            if(firstSelectedItemIndex !== null) {
                component.find('.enumIconList')[0].scrollLeft = firstSelectedItemIndex * iconSize;
            }
            this.handleOpacity();
        }
    }

    // Add or remove opacity
    handleOpacity() {
        let me = this;
        this.$el.find('.enumIcon').each(function() {
            if($(this).data('id') === undefined) {
                if(me.selectedItems.length === 0) {
                    $(this).addClass('selected');
                } else {
                    $(this).removeClass('selected');
                }
            }
        });
    }
}

export default EnumIcon;
