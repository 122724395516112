'use strict';

import FieldOperationRequest from './FieldOperationRequest';

class FieldChangeRequest extends FieldOperationRequest{
    lastValue : any;
    lastNonEmptyValue : any;
    initialValue : any;

    constructor(domain, task){
        super('fieldChange',domain, task);
    }

    setLastValue(value){
        this.lastValue = value || '';
    }

    setLastNonEmptyValue(value){
        this.lastNonEmptyValue = value || '';
    }

    setInitialValue(value){
        this.initialValue = value || '';
    }

    getSerializable(){
        var request : any = super.getSerializable();
        request.request1.lastValue = this.lastValue;
        request.request1.lastNonEmptyValue = this.lastNonEmptyValue;
        request.request1.initialValue = this.initialValue;

        return request;
    }

}

export default FieldChangeRequest;