module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <select class="form-control changeableField selectpicker" data-id="' +
__e( fieldState.getId() ) +
' " data-style="btn-inverse" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
'>\n        <option value=""></option>\n        <option value="' +
__e( fieldState.getValue() ) +
'" selected>' +
__e( fieldState.getValue() ) +
'</option>\n    </select>\n</div>\n\n';

}
return __p
}