'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class PopupMenuRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('popupmenus', domain, _id);
    }
}

export { PopupMenuRequest };