import { Header } from 'models/listing/Header';

import moment from 'moment';
import Backbone from 'backbone';

class Formatter extends Backbone.Model {
    initialize(options){
        this.set('options', options);
    }

    /**
     * Returns the final formatted headers based on the
     * given grid headers
     */
    buildHeaders(headers){
        console.warn('Default formatter implementation does nothing');
        this.set('headers', new Header(headers));
        return headers;
    }

    getHeaders(){
        return this.get('headers');
    }

    /**
     * formats a given set of source rows into formatted rows
     * default implementation returns the same set
     */
    formatRows(rows){
        console.warn('Default formatter implementation does nothing');
        return rows;
    }

    parseFormatterColumn (colDef, gridHeaders) {
        if (colDef.type === undefined || colDef.type !== 'column') {
            console.error('Cannot parse column from ' + JSON.stringify(colDef));
            return null;
        }

        var newColDef = {};

        if (colDef.source && colDef.source !== null) {
            //This is a reference to multiple column (at least one). We thus have to create a header based
            //on the merge of base column and this new colDef
            let sources = colDef.source.split(',');
            let gridRef;
            if(sources.length > 0){
                gridRef = gridHeaders[sources[0]]; //we use the first one to fetch the attributes
            }
            if (gridRef === undefined) {
                console.error('Unable to find grid column with id ' + colDef.source + ' in mapping ');
                return null;
            } else {
                for (var attrname in gridRef) {
                    newColDef[attrname] = gridRef[attrname];
                }
            }
        }

        //overriding properties with formatter properties
        for (var attr in colDef) {
            if(attr === 'fid'){
                newColDef['dataIndex'] = colDef[attr];
            }else{
                newColDef[attr] = colDef[attr];
            }
        }

        return newColDef;
    }

    /**
     * Specifies if the formatter will modify the rows locally or if it has been applied on server
     */
    isLocal(){
        return false;
    }

    /**
     * Computes the human value of a given value based on the given header format
     */
    getHumanValue (someValue, currentHeader, keepNull) {
        let value = someValue + '';
        let currentFormat = currentHeader.getFormat();
        if (currentFormat === 'Number' || currentFormat === 'Integer') {

            if ((value === 0 || value === '0') && !keepNull) {
                return '';
            } else {
                var cleanedValue = value.replace(/'/g, '');
                var final = parseFloat(cleanedValue);
                if( final === 0 ){
                    return '';
                }else{
                    return final.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
                }
            }
        } else if(currentFormat === 'Date'){
            if(value === ''){
                return value;
            }else{
                return moment(value, 'YYYY-MM-DD HH:mm:ss').format('L');
            }
        } else if(currentFormat === 'DateTime'){
            if(value === ''){
                return value;
            }else{
                return moment(value, 'YYYY-MM-DD HH:mm:ss').format('L HH:mm');
            }
        } else if(currentFormat === 'Enum'){
            return currentHeader.getEnum().getLabel(value);
        } else {
            return value;
        }
    }

    /**
     * Returns the value/human value of an empty cell based
     * on a given format
     */
    getNullValueFromFormat (format) {
        if (format === 'Integer') {
            return {
                value: '0',
                humanValue: ''
            };
        } else if (format === 'Number') {
            return {
                value: '0.0',
                humanValue: ''
            };
        } else {
            return {
                value: '0',
                humanValue: ''
            };
        }
    }

    getRuptureColumns(){
        return [];
    }
}

export default Formatter;
