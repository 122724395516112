module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p +=
((__t = ( templateRenderer.computeTemplate(record) )) == null ? '' : __t) +
'\n';
 if(displayClose === true) { ;
__p += '\n    <span class="glyphicon glyphicon-remove closeTaskBtn" aria-hidden="true"></span>\n';
 } ;
__p += '\n\n\n';

}
return __p
}