'use strict';

import FieldView from 'views/record/Field';
import Context from '../../../models/context/Context';

class FilterField extends FieldView {
    private datafieldValues: {[dfId: string]: string};

    parseValue (value){
        this.datafieldValues = {};
        let parsedVal = super.parseValue(value);
        if(parsedVal !== '') {
            let allDfs = parsedVal.split('|');
            for(let entry in allDfs){
                let dfValues = allDfs[entry].split(':');
                if(dfValues.length !== 2) {
                    console.error('Invalid filter format ' + dfValues);
                    continue;
                }
                let datafield:string = dfValues[0].trim();
                if(datafield.startsWith('$')) { datafield = datafield.substring(1); }
                let value: string = dfValues[1].trim();
                if(value.startsWith('\'')){ datafield = value.substring(1, value.length - 1); }
                this.datafieldValues[datafield] = value;
            }
        }
        return parsedVal;
    }

    public getDataFieldValues() {
        return this.datafieldValues;
    }

    public getFilterContext(): Context {
        let filterContext = new Context({contextId: 'filter'});
        let fields = [];
        for(let key in this.datafieldValues) {
            fields.push({
                datafieldId: key,
                value: this.datafieldValues[key]
            });
        }
        filterContext.addFields(fields);
        return filterContext;
    }


    public hasInnerValues(): boolean {
        return true;
    }

    public getInnerValues(): { [dfId: string]: string} {
        return this.datafieldValues;
    }
}

export default FilterField;