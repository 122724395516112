'use strict';

import App from 'App';
import User from 'User';
import Server from 'server/Server';
import DomainManager from '../DomainManager';
import LoadingMask from 'views/loading/LoadingMask';

import { LoginRequest } from '../server/protocol/request/auth/LoginRequest';

import ThemeManager from '../parametrage/ThemeManager';

import * as moment from 'moment';
import * as Backbone from 'backbone';
let $ = require('jquery');
import { DomainContext } from '../parametrage/DomainContext';
import { SAIView } from '../Additions';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import Config from 'Config';
import AlertManager from '../utils/notification/AlertManager';
import { PasswordUpdateRequest } from '../server/protocol/request/auth/PasswordUpdateRequest';
import ServerUtils from '../utils/ServerUtils';

class Login extends SAIView {

    mainFrame : JQuery<HTMLElement>;
    background : JQuery<HTMLElement>;
    invalidLabel : JQuery<HTMLElement>;
    currentOrientation : any;
    loginPath : boolean;

    constructor(options){
        super(options);
        this.tagName = 'div';
        this.template = require('ejs-loader!templates/Login');
        this.id = '';
        this.className = 'loginPanel';
        Backbone.View.apply(this, arguments);
    }

    getViewType():string {
        return 'login';
    }

    events() : Backbone.EventsHash {
        return {
        //'click #loginBtn' : 'onClick',
        'resize' : 'onWindowResize'
        }
    }

    initialize(options) {
        App.checkAppVersion(true);
    }

    render () : any {
        this.$el.html(this.template({
            applogo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_WHITE),
            window: window
        }));

        this.background = this.$el.find('.siteBackgroundImage');

        this.mainFrame = this.$el.find('.loginFormCont');

        this.mainFrame.bind('webkitAnimationEnd', function () {
            this.style.webkitAnimationName = '';
        });
        this.mainFrame.bind('animationEnd', function () {
            this.style.animationName = '';
        });

        this.invalidLabel = this.$el.find('.invaliduserpass');

        //VBH Fixme
        /*if(!this.listeningToResize){
            this.listeningToResize = true;
            $(window).on('resize',this.onWindowResize.bind(this));
        }*/

        this.onWindowResize();

        $('#loginBtn').click(this.onClick.bind(this));
    }

    /**
     * There are two images that are displayed in the login background. One is
     * optimized for portrait, the other one for landscape orientation. Based on
     * the screen resolution, this handler will change the url to the other 
     * orientation background if it's not already the right one.
     */
    onWindowResize(){
        let w = $(window).width();
        let h = $(window).height();

        var curOr = w > h ? 'landscape' : 'portrait';
        if(this.currentOrientation !== curOr){
            this.currentOrientation = curOr;
            //orientation change, we reload the background image
            this.background.css('background-image', 'url('+Server.getTokenedUrl('app/login/webbackground_'+this.currentOrientation+'.jpg')+')');
        }
    }

    /**
     * This function is called when the user clicks on the login button. It'll
     * call the server and handle the success/failure result.
     * 
     * @param {*} ev The click event 
     */
    onClick (ev) {
        let newPassword = $('#login-update-password').val();
        if(newPassword !== undefined && newPassword !== '') {
            this.executeUpdate();
        } else {
            this.invalidLabel.hide();
            let id = $('#login-mail').val().trim();
            let pwd = $('#login-password').val();
            if(id === null || id.length === 0 || pwd === null || pwd.length === 0) {
                this.onLoginError({
                    code : 'INVALID_INPUT',
                    body : 'Le nom d\'utilisateur et le mot de passe ne doivent pas être vides.'
                });
                return;
            }
            let loginMessage = new LoginRequest(id, $('#login-password').val(), null);
            let loadingReqId;
            LoadingMask.requestLoading('Login en cours')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return Server.performRequest(loginMessage);
                })
                .then(this.onLoginSuccess.bind(this, id))
                .catch(this.onLoginError.bind(this))
                .finally(() => { LoadingMask.hide(loadingReqId); });
        }
    }

    tokenConnect() {
        let loginMessage = new LoginRequest(null,null, JSON.parse(sessionStorage.saiUserToken));
        let loadingReqId;
        LoadingMask.requestLoading('Login en cours')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(loginMessage);
            })
            .then(this.onLoginSuccess.bind(this, sessionStorage.saiUserId))
            .catch(this.onLoginError.bind(this))
            .finally(() => { LoadingMask.hide(loadingReqId); });
    }

    /**
     * This function is called upon credential authentication success. It'll
     * preload a lot of structure before moving on to the application desktop
     * or the requested url
     * 
     * @param {*} user The currently authenticating user
     * @param {*} response The server response that contains properties and 
     * session information for the user
     */
    onLoginSuccess (user, response) {
        User.setId(user);
        this.initializeSession(user, response);
        sessionStorage.saiUserToken = JSON.stringify(response.token);
        sessionStorage.saiUserId = user;
        return new Promise((success,error) => {
             Promise.all([ThemeManager.getUserTheme()])
            .then(() => {
                if (this.loginPath) {
                    App.router.navigate('#' + this.loginPath, {trigger: true});
                } else {
                    App.router.navigate('#desktop', {trigger: true});
                }
                AlertManager.reset();
                success(undefined);
            })
            .catch((reason) => {
                this.onLoginError({message : 'Error while loading application, ' + reason + '. If it persists contact your administrator'});
                error();
            });
        });
    }

    /**
     * Initializes all the internal structures (User, Server, etc..) that will 
     * allow the user to use the application, based on the server successful reply
     * 
     * @param {*} user The currently authenticating user
     * @param {*} response The server response that contains properties and
     */
    initializeSession(user, response) {
        //Session token that will allow to make authenticated calls
        Server.setToken(response.token);
        //User id
        User.setId(user);
        User.setAvailableTasks(response.availableTasks);
        //User working domain
        User.setAvailableDomains(response.availableDomains);
        let baseDomain = response.currentDomain;
        if(User.getCurrentDomain() === undefined) {
            User.setCurrentDomain(baseDomain);
        } else {
            baseDomain = User.getCurrentDomain();
        }
        
        //User config properties (behaviors, menus, etc..)
        User.setProperties(response.properties, baseDomain);
        //User session language
        User.setLocale(response.locale);
        moment.locale(User.getLocale());
        //User configuration package context
        DomainManager.setContext(baseDomain, new DomainContext(baseDomain, response.currentConfiguration));
        //Cleaning local storage from old parametrage if any
        this.cleanupStorage(response.currentConfiguration);

        App.getAvailableExercices(DomainManager.getContext(baseDomain))
            .then((exercices) => {
                for(let i in exercices as any) {
                    let exercice = exercices[i];
                    if(exercice.active) {
                        DomainManager.getContext(baseDomain).setExercice(exercice);
                        break;
                    }
                }
            });
    }

    /**
     * Cleanup the local storage to remove old configuration entries
     * @param configId the name of the package
     */
    cleanupStorage(configId: string) {
        if (typeof (Storage) !== undefined) {
            for (var i = 0; i < localStorage.length; i++) {
                let item = localStorage.key(i);
                if ((item.indexOf(configId) <= 0 && item.indexOf('configuration') === 0) || item.indexOf(Config.serverUrl) == 0) {
                    localStorage.removeItem(item);
                }
            }
        }
    }

    /**
     * This function visually display an error on the login panel. It's called when
     * something wrong happens with the application login
     * @param { message: string } response The response to display on the login
     * as login error 
     */
    onLoginError (response) {
        if(response !== undefined) {
            this.mainFrame.css('webkitAnimationName', 'tada');
            this.mainFrame.css('animationName', 'tada');
            this.invalidLabel.show();
            this.invalidLabel.text(response.body);

            if(response.code === 'SYSTEM_PASSWORD_EXPIRED') {
                $('.update-password').show();
                $('#loginBtn').text('Mettre à jour');
            }
        }
    }

    executeUpdate() {
        $('.invaliduserpass').hide();
        let username = this.$el.find('#login-mail').val() as string;
        username = username.trim();
        let currentPassword = this.$el.find('#login-password').val() as string;
        let newPassword = this.$el.find('#login-update-password').val() as string;
        let newPasswordConfirmation = this.$el.find('#login-update-password-confirmation').val() as string;

        if(newPassword === newPasswordConfirmation && newPassword !== '') {
            let request = new PasswordUpdateRequest(username, currentPassword, newPassword);

            let loadingReqId: string;
            LoadingMask.requestLoading('Mise à jour du mot de passe')
            .then((reqId) => {
                loadingReqId = reqId;
                Server.performRequest(request)
                .then(() => {
                    // The password update was successful, perform a login with the new password
                    let loginMessage = new LoginRequest(username, newPassword, null);
                    let loadingReqId;
                    LoadingMask.requestLoading('Login en cours')
                        .then((reqId) => {
                            loadingReqId = reqId;
                            return Server.performRequest(loginMessage);
                        })
                        .then(this.onLoginSuccess.bind(this, username))
                        .catch(this.onLoginError.bind(this))
                        .finally(() => { LoadingMask.hide(loadingReqId); });
                })
                .catch(this.onLoginError.bind(this))
            })
            .catch((error) => {
                App.displayErrorMessage(error);
            })
            .finally(() => {
                LoadingMask.hide(loadingReqId);
            });
        } else {
            if(newPassword === '') {
                $('.invaliduserpass').text('Le mot de passe ne doit pas être vide');
            } else {
                $('.invaliduserpass').text('Le mot de passe et la confirmation ne correspondent pas');
            }
            $('.invaliduserpass').show();
        }
    }
}

export default Login;
