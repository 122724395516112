module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<hr class="pendingHr">\n<img class="fileImage" src="' +
__e( imgSrc ) +
'"/>\n';
 if(mode === 'edit') {;
__p += '\n<label class="photoInputFormLabel newPhotoLabelPosition" for="title">Titre</label>\n<input class="photoInputFormInput formInput displayDoc" type="text" name="title" value="' +
__e( path) +
'" disabled>\n';
} else if (mode === 'loading') { ;
__p += '\n   <img class="fileImage" src="images/gif-load.gif"/> \n';
} else if (mode === 'progress') { ;
__p += '\n    <progress class="upProgress" value="0" max="100"></progress>\n';
} ;
__p += '\n';

}
return __p
}