'use strict';

import MobileMessage from 'server/protocol/MobileMessage';
import { DomainContext } from '../../../parametrage/DomainContext';

class MobileRequest extends MobileMessage{
    protected controller: string;
    protected service: string;
    protected method: string;
    protected domainContext: DomainContext;
    protected sessionVars: any;

    constructor(controller, service, method, domainContext:DomainContext){
        super();

        this.controller = controller;
        this.service = service;
        this.method = method;
        this.domainContext = domainContext;
    }

    public getStatus(): number{
        return 100;
    }

    public getController(): string{
        return this.controller;
    }

    public getService(): string{
        return this.service;
    }

    public getMethod(): string{
        return this.method;
    }

    public getDomain(): DomainContext{
        return this.domainContext;
    }

    public getSerializable(): any{
        return {};
    }

    public getName(): string{
        return 'MobileRequest';
    }

    public getUrl(): string{
        let url = this.controller;
        if(this.domainContext !== undefined) {
            url += '/' + this.domainContext.getDomain();
        }
        url += '/' + this.service;

        return url;
    }

    public setSessionVars(vars): any{
        this.sessionVars = vars;
    }

    public getSessionVars(): any{
        if(this.sessionVars === undefined){
            this.sessionVars = {};
        }

        if(this.sessionVars['CGE_EXERCICE'] === undefined && this.domainContext !== undefined && this.domainContext.getExercice() !== undefined) {
            this.sessionVars['CGE_EXERCICE'] = this.domainContext.getExercice().id;
        }

        return this.sessionVars;
    }

    public setSessionVar(varName, varValue){
        this.getSessionVars()[varName] = varValue;
    }

    public hasSubRequests(): boolean {
        return false;
    }

    public getSubRequests(): Array<MobileRequest> {
        return [];
    }

    public getTaskId(): string {
        return '';
    }
}

export default MobileRequest;