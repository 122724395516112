'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class PrepareNewEntryRequest extends TaskRequest{
    constructor(domain:DomainContext, task){
        super('prepareNewEntry','POST', domain, task);
    }

    setRecordContext(context) {
        this.record = context;
    }

    getSerializable() {
        return {
            request1: {
                record: this.record ? this.record.toJSON() : undefined
            }
        };
    }
}

export default PrepareNewEntryRequest;