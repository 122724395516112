'use strict';

import FieldView from 'views/record/Field';

import 'select2';
import { ComboElement, Combo } from '../../../parametrage/structures/Combo';

class EnumField extends FieldView {

    onDOMUpdated () {
        super.onDOMUpdated();

        var selectEl:any = this.$el.find('.selectpicker');
        selectEl.select2(this.getSelectOptions());

        selectEl.on('select2:opening', this.checkForSelectBlocked.bind(this, selectEl));

        var displayMode = this.displayMode;
        if(displayMode === 'tablet'){
            this.$el.find('.select2-container').css({
                width: '100%'
            });
        }else{
            if(this.locationProperties){
                var left = this.locationProperties.labelLeft;

                if (this.locationProperties.labelVisible === false) {
                    left = this.locationProperties.fieldLeft;
                }

                if (!left) {
                    left = '0';
                }
                this.$el.find('.select2-container').css(this.getFieldPositionModifier(this.locationProperties, left));
            }
        }
    }

    getSelectOptions (){
        return {
            closeOnSelect: this.getCloseOnSelect()
        };
    }

    getCloseOnSelect (){
        return true;
    }

    getSelectedOption (selectedValue): ComboElement {
        var selectedValues:Array<ComboElement> = this.fieldConfig.getLinkedCombo().getElements();
        for(var key in selectedValues) {
            let currentEl = selectedValues[key];
            if(currentEl.getId() === selectedValue) {
                return currentEl;
            }
        }
        return null;
    }

    protected getRenderingModel(): {[key:string]: any} {
        let data = super.getRenderingModel();
        let combo: Combo = this.fieldConfig.getLinkedCombo();
        if(!combo) {
            console.error('Missing linked combo on field ' + this.fieldConfig.getId() + ' in task ' + this.fieldConfig.getTask().getId());
        }
        data.selectValues = combo? combo.getElements() : [];
        data.multiple = this.canSelectMultiple();
        return data;
    }

    protected canSelectMultiple(): boolean {
        return false;
    }

    setModelValue (value) {
        super.setModelValue(value);
        var option: ComboElement = this.getSelectedOption(value);
    }

    public focus() {
        let selectEl:any = this.$el.find('.selectpicker');
        selectEl.focus();
    }
}

export default EnumField;
