'use strict';

import { BasePanel } from './BasePanel';

class SubPanel extends BasePanel {
    public getFieldI18N(fieldId: string) {
        return this.getParentTask().getId() + '.SubPanel' + this.getId() + '.' + fieldId;
    }

    public getI18NID(): string {
        return this.parentTask.getId() + '.SubPanel' + this.getId()
    }
}

export { SubPanel }; 