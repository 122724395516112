'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';

class TaskListingRequest extends TaskRequest{
    srcTask: string;
    template: string;

    constructor(domain, task, listTask){
        super('getTaskListing','POST', domain, listTask); //Warning! on purpose swap between task and listTask

        this.setSrcTask(task);
    }

    setSrcTask(newTask){
        this.srcTask = newTask;
    }

    setTemplate(newTemplate){
        this.template = newTemplate;
    }

    setRecordContext(context){
        this.record = context;
    }

    getSerializable(){
        return {
            request1: {
                record: this.record? this.record.toJSON(): undefined,
                task: this.srcTask,
                template: this.template
            }
        };
    }
}

export { TaskListingRequest };