'use strict';

import { Group } from './Group';
import { Task } from './Task';
import { DomainContext } from '../DomainContext';
import { Level } from './Level';
import { Field } from './Field';
import { Note } from '../../models/Note';

enum FieldValidityLevel {
    VALID, INVALID
}

class FieldInstance extends Field {
    private validity: FieldValidityLevel;
    private notes: Array<any>;

    constructor(config: {id : string, [propId: string]: any}, parentTask: Task, parentGroup: Group, parentLevel: Level, context: DomainContext) {
        super(config, parentTask, parentGroup, parentLevel, context);
        this.setInitialValue(this.getValue());
        this.setValidityLevel(FieldValidityLevel.VALID);
        this.notes = [];
    }

    public setValue(value: any){
        //Update of the value
        let currentValue = this.getValue();
        if(currentValue === undefined){
            //Might be the first time
            this.setInitialValue(value);
        }

        if(currentValue !== undefined && currentValue !== ''){
            //Last non empty
            this.setLastNonEmptyValue(value);
        }

        //Last value
        this.setLastValue(currentValue);
        //New current value
        this.config.value = value;
    }

    public setVisible(visibility: boolean) {
        for(let posKey in this.config.position) {
            this.config.position[posKey].visible = visibility;
        }
    }

    public setEnabled(enabled: boolean) {
        this.config.enabled = enabled ? 'true' : 'false';
    }

    public setLabel(label: string) {
        this.config.label = label;
    }

    public getLastValue() {
        return this.getValueOrDefault('lastValue');
    }
    private setLastValue(value: any) {
        this.config.lastValue = Array.isArray(value) ? value.join(',') : value;
    }

    public getLastNonEmptyValue() {
        return this.getValueOrDefault('lastNonEmptyValue');
    }
    private setLastNonEmptyValue(value: any) {
        this.config.lastNonEmptyValue = Array.isArray(value) ? value.join(',') : value;
    }

    public getInitialValue() {
        return this.getValueOrDefault('initialValue');
    }
    private setInitialValue(value: any) {
        this.config.initialValue = Array.isArray(value) ? value.join(',') : value;
    }

    static fromField(field: Field) {
        let newInstance = new FieldInstance(field.getClonedConfig(), null, null, null, field.getContext());
        newInstance.copyLinksFrom(field);
        return newInstance;
    }

    public getNotes(): Array<Note> {
        return this.notes || [];
    }

    public setNotes(files:Array<Note>) {
        this.notes = files;
    }

    public setReadOnly(readOnly: string): void {
        this.config.readOnly = readOnly;
    }

    public setHumanValue(hValue: string): void {
        this.config.humanValue = hValue;
    }

    public getHumanValue(): string {
        return this.config.humanValue;
    }

    public getValidityLevel(): FieldValidityLevel {
        return this.validity;
    }

    public setValidityLevel(level:FieldValidityLevel) : void {
        this.validity = level;
    }

    public isUserValid(): boolean {
        return ! ( this.isEnabled() && this.getValidityLevel() === FieldValidityLevel.INVALID);
    }
}

export { FieldInstance, FieldValidityLevel };