'use strict';

import * as Backbone from 'backbone';
let $ = require('jquery');

import App from 'App';
import Server from 'server/Server';

import { LoginRequest } from 'server/protocol/request/auth/LoginRequest';
import { SAIView } from '../Additions';
import LoadingMask from './loading/LoadingMask';
import StringUtils from 'utils/StringUtils';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import Router from '../routes/Router';

class ModalLogin extends SAIView {
    invalidLabel : JQuery<HTMLElement>;
    user : JQuery<HTMLElement>;
    password : JQuery<HTMLElement>;

    constructor(options?){
        super(options);

        this.tagName = 'div';
        this.template = require('ejs-loader!templates/modalPopup/ModalLogin.ejs');
        this.id = '';
        this.className = 'modalLoginPanel';

        Backbone.View.apply(this, arguments);
    }

    initialize () {
        App.checkAppVersion(false);
        this.uniqId = StringUtils.guid();
    }

    render () : any {
        this.$el = $(this.template({
            applogo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_WHITE),
            uniqId: this.uniqId
        }));
    }

    onClick (ev) {
        this.invalidLabel.hide();
        Server.setToken(undefined);
        let loginMessage = new LoginRequest(this.user.val(), this.password.val(), null);
        Server.performRequest(loginMessage)
            .then(this.onLoginSuccess.bind(this, this.user.val()))
            .catch(this.onLoginError.bind(this));
    }

    onLoginSuccess (user, response) {
        Server.setToken(response.token);
        $('#' + this.uniqId).modal('hide');
        if(user !== sessionStorage.saiUserId) {
            sessionStorage.saiUserId = user;
            App.setModalLoginClosed();
            App.reloadHome();
        } else {
            let loadingReqId;
            LoadingMask.requestLoading('Chargement')
                .then((reqId) => {
                    loadingReqId = reqId;
                    LoadingMask.restackPending();
                    return App.recallRequests();
                })
                .then(() => {
                    LoadingMask.hide(loadingReqId);
                });
        }
    }

    onLoginError (response) {
        let message = response.responseJSON.message;
        let toAnimate = this.$el.find('.loginPreviewDialog');
        toAnimate.css('webkitAnimationName', 'tada');
        toAnimate.css('animationName', 'tada');
        this.invalidLabel.show();
        this.invalidLabel.text(message.body);
    }

    display () {
        var me = this;

        LoadingMask.hide(null, true);

        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            me.invalidLabel = me.$el.find('.invaliduserpass');
            me.user = me.$el.find('.login-mail');
            me.password = me.$el.find('.login-password');
            me.$el.find('.loginBtn').click(me.onClick.bind(me));
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#' + me.uniqId).remove();
        });

        this.$el.modal({
            'show' :true,
            keyboard: false,
            backdrop: 'static'
        });
    }
}

export default ModalLogin;
