'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';

/**
 * This class allows to browse the content of a combo entry. A combo entry
 * is a map of attributes key/value pairs that maps to the original xml
 * parametrage. Helper functions have been added to quickly retreive common
 * attributes.
 */
class ComboElement {
    private attributes: { [key:string]:string };

    constructor(_attributes: {[key:string]:string}) {
        this.attributes = _attributes;
    }

    /**
     * @returns the attribute 'id' of the combo entry
     */
    public getId(): string {
        return this.attributes['id'];
    }

    /**
     * @returns the attribute 'label' of the combo entry
     */
    public getLabel(): string {
        return this.attributes['label'];
    }

    /**
     * This function retrieves any attribute value that might
     * have been defined in the combo entry. It'll return undefined if not set
     * @param attributeName The string identifier of the attribute to fetch
     * @returns The value of the attribute
     */
    public getAttribute(attributeName: string): string {
        // Force the 1st char to lower case
        attributeName = attributeName.charAt(0).toLowerCase() + attributeName.slice(1);
        return this.attributes[attributeName];
    }

    /**
     * This function returns the list of all attributes keys that are available
     * @returns An Array of string
     */
    public getAttributeKeys(): Array<string> {
        return Object.keys(this.attributes);
    }

    /**
     * This function returns a map of all attribute, as given in the config
     * @returns An Array of string
     */
    public getAttributes(): {[key: string] : string} {
        return this.attributes;
    }
}

/**
 * This class represents a combo from the parametrage. A combo is supposed to be
 * uniquely idenfied by its name and language. It contains a list of entries.
 */
class Combo extends ConfigurationElement{

    private elements : Array<ComboElement>;

    constructor(config: { id: string, label: string, elements : Array<{[key: string]: string}> }, context: DomainContext ) {
        super(config, context);
        this.elements = [];
        for(let key in config.elements) {
            this.elements.push(new ComboElement(config.elements[key]));
        }
    }

    /**
     * @returns The full list of all ComboElement that are stored within the combo
     */
    public getElements() : Array<ComboElement>{
        return this.elements;
    }

    /**
     * @param id The unique identifier of a ComboElement within the combo
     * @returns The ComboElement uniquely identified by the given id. Null otherwise
     */
    public getElement(id: string) : ComboElement {
        for(var key in this.elements){
            let currentEl = this.elements[key];
            if(currentEl.getId() === id) {
                return currentEl;
            }
        }

        return null;
    }
}

export { Combo, ComboElement };