module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recordOperationModalLabel" aria-hidden="true">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <img class="popupImg" src="images/logo-navbar.png"/>\n                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n                <h4 class="modal-title recordFormTitle" id="photoDeleteModalLabel">' +
__e( operationTitle ) +
'</h4>\n                <div class="bubblingG loadingSpinner"><span class="bubblingG_1"></span><span class="bubblingG_2"></span><span class="bubblingG_3"></span></div>\n            </div>\n            <div class="modal-body">\n                <div class="recordView"></div>\n            </div>\n            <div class="modal-footer">\n                <button type="button" class="btn btn-success recordFormAcceptBtn" data-dismiss="modal" disabled>' +
__e( okBtnText ) +
'</button>\n                <button type="button" class="btn btn-default recordFormCancelBtn" data-dismiss="modal">' +
__e( cancelBtnText ) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}