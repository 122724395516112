'use strict';

import User from 'User';
let $ = require('jquery');
import FieldView from 'views/record/Field';
import 'eonasdan-bootstrap-datetimepicker';
import Config from 'Config';

let moment = require('moment');
import DeviceUtils from '../../../utils/DeviceUtils';
import ScriptUtils from 'utils/ScriptUtils';

class DateField extends FieldView {
    protected visualDateFormat:string;
    protected disableOnClick: boolean;
    protected dominantColor: any;

    constructor (options) {
        super(options);

        this.visualDateFormat = 'DD/MM/YYYY';
        if(DeviceUtils.isMobile()) {
            this.fieldState.setReadOnly('true');
        }
        this.disableOnClick = options.readOnly && options.readOnly !== 'false';
        this.dominantColor = options.dominantColor || Config.appTheme;
    }

    protected evalDefaultValue(defaultValue) {
        return ScriptUtils.handleDateTransformation(defaultValue, this.getDefaultFormat());
    }

    protected getDefaultFormat() {
        return 'YYYY-MM-DD 00:00:00';
    }

    render() {
        super.render();
        this.onDOMUpdated();
    }

    setModelValue (value) {
        // Warning, value is a string. According to momentjs docs, we must
        // not parse a string directly as the result is inconsistent between
        // browsers, locale and timezones.
        // We want to parse the exact format to avoid such problems.
        let fieldValue, pickerDate;
        if(value !== '' && value.indexOf('$') === -1) {
            let m = moment(value, 'YYYY-MM-DD HH:mm:ss');
            fieldValue = m.format('YYYY-MM-DD HH:mm:ss');
            pickerDate = m;
        } else {
            fieldValue = value;
        }

        this.fieldState.setValue(fieldValue);
        if(this.rendered && pickerDate) {
            let dpickerEl = this.$el.find('.input-group');
            dpickerEl.data('DateTimePicker').date(pickerDate);
        }
    }

    onDOMUpdated () {
        super.onDOMUpdated();

        if (this.rendered) {
            let me = this;
            let dpickerEl = this.$el.find('.input-group') as any;
            let inDateStr = this.fieldState.getValue();
            let inDate = moment.utc(inDateStr || moment(), 'YYYY-MM-DD HH:mm:ss');

            dpickerEl.datetimepicker(this.getPickerOptions());
            dpickerEl.on('dp.hide', function (e:any) {
                me.$el.find('.form-control').val(e.date.format(me.visualDateFormat));
                me.$el.find('.form-control').change();
            });
            dpickerEl.on('dp.show', function (e) {
                me.$el.find('.day.active').css('background-color', me.dominantColor);
                me.$el.find('a').css('color', me.dominantColor);
                me.fixCalendarPosition(me.$el, me.$el.find('.bootstrap-datetimepicker-widget'));
            });

            if(!this.disableOnClick) {
                me.$el.find('input').unbind('click');
                me.$el.find('input').click(this.onInputClicked.bind(this, me.$el.find('.input-group-addon')));
            } else {
                me.$el.find('.input-group-addon').off();
            }

            if(inDateStr) {
                dpickerEl.data('DateTimePicker').date(inDate);
            }

            me.setDominantColor(me.dominantColor);
        }
    }

    onInputClicked (addon, event) {
        addon.click();
    }

    // Change the position to fixed in order to have the calendar on top of the modal
    fixCalendarPosition(field, calendar) {
        // If the position is already fixed, don't do it again, this would screw everything !
        if(calendar.css('position') !== 'fixed') {
            let isOnTop = calendar.css('top').indexOf('-') >= 0;
            let topPosition = 0;
            if(isOnTop) {
                topPosition = field.offset().top - calendar.height() - 10;
            } else {
                topPosition = field.offset().top + field.height();
            }

            let isOffscreen = field.width() + field.offset().left + 20 > $(window).width();
            calendar.css('top',topPosition + 'px');
            calendar.css('bottom', 'auto');
            calendar.css('left', 'auto');
            calendar.css('right', isOffscreen ? 20 : 'auto');
            calendar.css('position', 'fixed');
        }
    }

    getPickerOptions () {
        let options = {
            locale: User.getLocale().toLowerCase(),
            format: this.visualDateFormat
        };

        options['ignoreReadonly'] = true;

        return options;
    }

    getFieldElement () {
        return this.$el.find('.date');
    }

    setDominantColor(color){
        this.dominantColor = color;
        this.$el.find('.input-group-addon').children('.glyphicon').css('color', this.dominantColor);

        $('head').find('#datetimepicker-dynamic-style').remove();
        $('head').append('<style type="text/css">.bootstrap-datetimepicker-widget table td.today:before { border-bottom-color: '+color+' ;}</style>');
    }

    parseValue(value) {
        super.parseValue(value);
        if(value) {
            let humanDate = /^(\d\d)\/(\d\d)\/(\d\d\d\d)$/g;
            let completeDate = /(\d\d)-(\d\d)-(\d\d\d\d) \d\d:\d\d(:\d\d)*/g;
            if(value.match(humanDate)){
                let match = humanDate.exec(value);
                value = match[3] + '-' + match[2] + '-' + match[1] + ' 00:00:00';
            } else if(value.match(completeDate)) {
                value = value.substring(0, 10) + ' 00:00:00';
            }
        }
        return value;
    }
}

export default DateField;
