module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="floating-dialog" id="' +
((__t = (id)) == null ? '' : __t) +
'">\n    <div class="floating-dialog-title"><span class="floating-dialog-title-text">' +
((__t = (title)) == null ? '' : __t) +
'</span>\n    ';
 if(canClose) {
        ;
__p += '<span class="floating-dialog-close"></span>';

    } ;
__p += '\n    ';
 if(canMinimize) {
        ;
__p += '<span class="floating-dialog-minimize"><i class="fa fa-angle-down"></i></span>';

    } ;
__p += '\n    </div>\n    <div class="floating-dialog-content"></div>\n    <div class="floating-dialog-overlay"></div>\n</div>';

}
return __p
}