module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <input type="hidden" class="form-control changeableField" data-id="' +
__e( fieldState.getId() ) +
' " ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' >\n</div>';

}
return __p
}