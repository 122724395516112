'use strict';

import CClientConfiguration from 'parametrage/CClientConfiguration';

import List from 'views/listing/List';
import HeaderRenderer from 'views/listing/renderer/header/HeaderRenderer';
import GridRenderer from 'views/listing/renderer/grid/GridRenderer';
import DeviceUtils from 'utils/DeviceUtils';

import SearchBar from 'views/filter/SearchBar';
import { SAIView } from '../../../../Additions';
import { PopupMenu } from '../../../../parametrage/structures/PopupMenu';
import { TaskView } from '../../Task';
import { DomainContext } from '../../../../parametrage/DomainContext';
import { Filter } from '../../../../parametrage/structures/Filter';
import { Table } from '../../../../parametrage/structures/Table';
import { GridView } from '../../../../parametrage/structures/GridView';

let $ = require('jquery');

/**
 * This object represents a task navigator. It allows to browse the available
 * entities and select one.
 *
 * @type @exp;Backbone@pro;View@call;extend
 */
class NavigatorView extends SAIView {
    //Options on construction
    public options: { [propId: string]: any };

    //Rendering parameters
    private lineStyle: {[cssProp: string]: any};
    private lineExpanse: boolean;
    private presentation: string;

    //Call parameters
    private domainContext: DomainContext;

    //Result parameters
    private multiSelect: { keyColumn: string, selectedItemsClass: string, selectedKeys: any};

    //Configs
    private popupMenu: PopupMenu;
    private filterConfig: Filter
    private tableConfig: Table;
    private gridConfig: GridView;
    private iconConfig: any;

    //Components and results
    private task: TaskView;
    private searchBar: SearchBar;
    private dataView: List;
    private currentRecords: Array<any>;

    //Rendering elements
    private dataElement: JQuery<HTMLElement>;
    private filterElement: JQuery<HTMLElement>;


    /**
     * Initialisation of the Navigator. This function will fix missing
     * parameters to default values and display errors if such case happens.
     *
     * @param {object} options Object containing all the requested parameters.
     * This object is stored in the navigator and thus is accessible from
     * outside if necessary. Here are the expected parameters
     *
     * taskId : the task to use to refresh item (MANDATORY)
     * gridId : the grid to use to refresh item (MANDATORY)
     * filterId : the filter to use when refreshing item
     * template : the template to use to display items (MANDATORY)
     */
    initialize(options) {
        this.options = options;
        this.template = require('ejs-loader!templates/Navigator.ejs');

        this.lineStyle = options.lineStyle;
        this.lineExpanse = options.lineExpanse;

        /*
         *  We can either use the default grid presentation or use custom presentation from server
         */
        this.presentation = options.presentation || 'grid';

        if(DeviceUtils.isMobile() || DeviceUtils.isMediumDevice() || DeviceUtils.isSmallDevice()){
            this.presentation = 'header';
        }
        ////////////////////////////////////////////////////////////////////
        //Multiselection features                                        ///
        ////////////////////////////////////////////////////////////////////
        /*
         * allows multi selection in the navigator. Selected keys are stored in the selectedKeys array
         * This requires to provide the column that is responsible of the key storage
         *
         * multiSelect structure is the following ;
         * {
         *
         *    selectedItemsClass : Selection class of all selected items
         *    selectedKeys : Array of selected keys
         *    keyColumn : In multi selection mode, we need to know which column of data is representing the key
         *
         *  }
         */
        this.multiSelect = options.multiSelect;

        if (this.multiSelect) {
            //Checking for non missing data in the multiSelect structure
            this.checkMandatoryParameter(this.multiSelect, 'keyColumn', 'In multi selection mode, we need to know which column of data is representing the key');
            this.multiSelect.selectedItemsClass = this.multiSelect.selectedItemsClass || 'selectedItem';
            this.multiSelect.selectedKeys = this.multiSelect.selectedKeys || [];
        }
        ////////////////////////////////////////////////////////////////////

        this.domainContext = options.domainContext;
        this.task = options.task;
        this.iconConfig = options.iconConfig;
        this.gridConfig = options.gridConfig;
    }

    refreshData() {
        this.dataView.getListing().resetData();
        this.dataView.getListing().fetchNextPage();
    }

    public getCurrentRecords(): Array<any> {
        return this.currentRecords || [];
    }

    public setFilters(filters: any) {
        this.options.filters = filters;
    }

    /**
     * Renders the base navigator template defined as class parameter
     * without items. Items will be rendered when received
     */
    render(renderingOptions?) : any{
        this.checkInitialized();

        this.$el.html(this.template());
        this.$el.addClass('navigator');
        /*
         * Base navigator class is navigator, but this can be changed with navClass
         */
        if(this.options.navClass){
            this.$el.addClass(this.options.navClass);
        }


        this.dataElement = this.$el.find('.data-container-content');
        this.$el.addClass(renderingOptions.dataContainerType);
        this.filterElement = this.$el.children('.filter-container');

        var me = this;

        let renderer;
        if(this.presentation === 'header'){
            this.options.gridId = this.options.headerId ? this.options.headerId : this.options.gridId;
            renderer = new HeaderRenderer({
                lineStyle: this.lineStyle,
                lineExpanse: this.lineExpanse,
                domainContext: this.domainContext,
                iconConfig: this.iconConfig,
                gridConfig: this.gridConfig
            });
        }else{
            renderer = new GridRenderer({
                lineStyle: this.lineStyle,
                lineExpanse: this.lineExpanse,
                domainContext: this.domainContext,
                iconConfig: this.iconConfig,
                gridConfig: this.gridConfig
            });
        }

        this.dataView = new List({
            el: this.dataElement,
            taskId: this.options.taskId,
            gridId: this.options.gridId,
            formatterId: this.options.formatterId,
            filterId: this.options.filterId,
            start: 0,
            end: 20,
            renderer: renderer,
            domainContext: this.domainContext
        });

        this.dataView.render();

        this.listenTo(this.dataView, 'itemClicked', this.itemSelected.bind(this));

        this.listenTo(this.dataView, 'actionClicked', function(row, id, item){
            me.trigger('itemActionSelected', id, row, item);
        });

        if(this.dataView){
            this.listenTo(this.dataView.getListing(), 'receivedVars', function(vars){
                me.trigger('receivedVars', vars);
            });
            this.listenTo(this.dataView.getListing(), 'rowsAdded', function(items){
                me.currentRecords = items;
            });
        }

        if(renderingOptions.displayFilter !== false){
            let filterEnabled = false;
            // --------- HARDCODED FOR PMI ----------
            if(this.options.taskId === 'CRM40'){
                filterEnabled = true;
            }
            //---------------------------------------

            this.searchBar = new SearchBar({
                el: this.filterElement,
                taskId: this.options.taskId,
                filterId: this.options.filterId,
                filters: this.options.filters,
                enabled: filterEnabled,
                domainContext: this.domainContext
            });
            this.searchBar.render();

            this.listenTo(this.searchBar, 'searchChanged', function(newValue, customized){
                var filter = me.searchBar.getFilter();
                me.dataView.getListing().setFilterData(filter.values, filter.text);
                if(customized){
                    me.dataView.getListing().setSessionVar('CUSTOMIZED_FILTER', true);
                }else{
                    me.dataView.getListing().setSessionVar('CUSTOMIZED_FILTER', undefined);
                }
                me.refreshData();
            });

            var filter = this.searchBar.getFilter();
            this.dataView.getListing().setFilterData(filter.values, filter.text);

            let height = this.searchBar.$el.offset().top - this.dataElement.offset().top - 25;
            this.dataElement.height(height + 'px');
        }
    }

    private fetchConfig(): Promise<[Filter, GridView, PopupMenu, Table]> {
        return new Promise((accept, reject) => {
            if(this.popupMenu !== undefined 
                && this.filterConfig !== undefined 
                && this.gridConfig !== undefined 
                && (this.task && this.tableConfig || !this.task)) {
                accept([this.filterConfig, this.gridConfig, this.popupMenu, this.tableConfig]);
            } else {
                Promise.all([
                    CClientConfiguration.getFilter(this.options.filterId, this.domainContext),
                    CClientConfiguration.getGridView(this.options.gridId, this.domainContext),
                    CClientConfiguration.getPopupMenu('grid', this.options.gridId, this.domainContext),
                    this.task ? CClientConfiguration.getTable(this.task.getConfig().getNavigatorTableID(), this.domainContext) : undefined
                ])
                    .then(accept)
                    .catch(reject);
            }
        });
    }

    onDOMUpdated(): Promise<void>{
        if(this.$el.is(':visible')) {
            return this.resizeNavigator()
                .then(() => { return this.fetchConfig(); })
                .then(([filterConfig, gridConfig, popupMenu, table]) => {
                    this.filterConfig = filterConfig;
                    this.gridConfig = gridConfig;
                    this.popupMenu =popupMenu;
                    this.tableConfig = table;
                    return this.searchBar.initializeFilter(gridConfig, table, filterConfig, this.task.getConfig());
                })
                .then(() =>{
                    //The filter has been initialized, we check if we're allowed to
                    //make the navigator immediate call
                    if(!this.task.getConfig().canNavigatorSkipFirstLoad() || DeviceUtils.isMobile()) {
                        this.dataView.setPopupMenu(this.popupMenu);
                        this.dataView.getListing().setFilterData(this.searchBar.getFilter().values);
                        this.dataView.getListing().fetchNextPage();
                    }
                });
        } else {
            return null;
        }
    }

    private resizeNavigator(): Promise<void> {
        return new Promise((accept) =>  {
            let height = 0;
            if(this.searchBar && this.searchBar.$el.offset().top > this.dataElement.offset().top) {
                // Search bar at bottom
                height = this.searchBar.$el.offset().top - this.dataElement.offset().top - 25;
            } else {
                // Search bar on top
                height = this.$el.innerHeight() - this.dataElement.offset().top;
            }
            this.dataElement.height(height + 'px');
            accept();
        });
    }

    itemSelected(row, id, item){
        this.trigger('itemSelected', id, row, item);
    }

    updateRow(response){
        for(var key in response.items){
            var itemId = parseInt(response.items[key].columns['CLIENT_INTERNAL_ROW_ID'].value);
            this.dataView.getListing().updateRow(itemId, response.items[key]);
        }
    }
}

export default NavigatorView;
