module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pubFicheGroupInner">\n    <div class="pubFicheGroupData">\n        <div class="pubFicheGroupTitle">' +
((__t = (config.label)) == null ? '' : __t) +
'</div>\n        ';
 for(var key in config.fields) { 
             var currentField = config.fields[key];;
__p += '\n             ' +
((__t = ( displayField(currentField) )) == null ? '' : __t) +
'\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
}