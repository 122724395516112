module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <input type="number" step="0.01" class="form-control changeableField" data-id="' +
__e( fieldState.getId() ) +
' " value="' +
__e( fieldState.getValue() !== ''? parseFloat(fieldState.getValue()).toFixed(2) : '0.00' ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' >\n    <i class="fa fa-cloud-download note-download"></i>\n</div>\n';

}
return __p
}