'use strict';

import * as Backbone from 'backbone';
import PendingDocument from './PendingDocument';

class Note extends Backbone.Model {
    private noteId : any;
    private files: NoteFile[];
    private pendingDocuments : PendingDocument[];
    private author: string;
    private creationDate: string;
    private modificationDate: string;
    private noteText: string;
    private version: number;

    constructor(description: any) {
        super();
        this.noteId = description.noteId;
        this.author = description.author;
        this.creationDate = description.creationDate;
        this.noteText = description.noteText;
        this.version = description.version;
        this.files = [];
        this.pendingDocuments = [];
        for(let i in description.files) {
            let file = new NoteFile(description.files[i]);
            this.files.push(file);
        }
    }

    toJSON(param : any) {
        return {
            'noteId': this.noteId,
            'author': this.author,
            'creationDate': this.creationDate,
            'modificationDate': this.modificationDate,
            'noteText': this.noteText,
            'files': this.pendingDocuments
        };
    }

    public getFiles() {
        return this.files;
    }

    public addPendingDocument(pendingDocument: PendingDocument) {
        this.pendingDocuments.push(pendingDocument);
    }

    public getLastFileIndex(): number {
        return this.files.length - 1;
    }

    public addFile(file: NoteFile):void {
        this.files.push(file);
    }

    public getNoteId(): string {
        return this.noteId;
    }

    public getFile(fileIndex: number): NoteFile {
        if(fileIndex > this.files.length - 1) {
            return null;
        } else {
            return this.files[fileIndex];
        }
    }

    public getNoteText(): string {
        return this.noteText;
    }

    public deleteFile(fileIndex) {
        this.files.splice(fileIndex,1);
    }

    public static buildNoteArray(options:any): Array<Note> {
        let notes = [];

        for(let i in options) {
            notes.push(new Note(options[i]));
        }

        return notes;
    }
}

class NoteFile {
    private full: string;
    private name: string;
    private thumbnail: string;
    private type: string;

    constructor(fileDescription:any) {
        this.full = fileDescription.full;
        this.name = fileDescription.name;
        this.thumbnail = fileDescription.thumbnail;
        this.type = fileDescription.type;
    }

    public getFull(): string {
        return this.full;
    }

    public getName(): string {
        return this.name;
    }

    public getThumbnail(): string {
        return this.thumbnail;
    }

    public getType(): string {
        return this.type;
    }
}

export {Note, NoteFile}