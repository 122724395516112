'use strict';

import MobileRequest from '../MobileRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class ConfigurationRequest extends MobileRequest {
    private id: string;

    constructor(service: string, domain: DomainContext, _id? : string){
        super('configuration', service, 'GET', domain);
        this.id = _id;
    }

    public getUrl(){
        let url = this.controller;
        if(this.domainContext !== undefined) {
            url += '/' + this.domainContext.getId();
        }
        url += '/' + this.service;
        if(this.id !== undefined) {
            url += '/' + this.id;
        }
        return url;
    }

    protected getId() {
        return this.id;
    }
}

export { ConfigurationRequest };