module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <button type="button" class="fieldButton btn btn-default" aria-label="Left Align" data-id="' +
__e( fieldState.getId() ) +
' " ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' >' +
__e( fieldState.getLabel() ) +
'<i class="fa fa-cloud-download" style="margin-left: 10px; font-size: 15px;"></i></button>\n</div>';

}
return __p
}