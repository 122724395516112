module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal fade" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" tabindex="-1" role="dialog" tabindex="-1" aria-labelledby="pageIconPreview" aria-hidden="true">\n    <div class="modal-dialog fullModalDialog pageIconPreviewDialog">\n        <div class="modal-content fullModalContent pageIconPreviewContent">\n            <div class="modal-header pageIconPreviewHeader"> \n                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n                <div class="modal-title">Preview</div>\n            </div>\n            <div class="modal-body pageIconPreviewBody">\n                <div class="previewPanel" style="background-image: url(\'' +
((__t = (image)) == null ? '' : __t) +
'\')">\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}