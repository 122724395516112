'use strict';

import FieldView from 'views/record/Field';

class ButtonField extends FieldView {

    onDOMUpdated () {
        super.onDOMUpdated();

        if(this.fieldConfig.canCallOnClick()){
            this.$el.find('.fieldButton').click(this.onFieldClick.bind(this));
        }
    }

    onSizeUpdated (newSize){
        super.onSizeUpdated(newSize);
    }
}

export default ButtonField;
