module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(options.facebook) { ;
__p += '\n<div class="socialGroup facebook-share">\n    ';
 if(options.displayCounters) { ;
__p += '\n        <div class="sideCounter">\n            <div class="count facebook-count"></div>\n            <i class="fa fa-caret-left smallArrow"></i>\n        </div>    \n    ';
 } ;
__p += '\n    <button class="fb"><i class="fa fa-facebook"></i> facebook</button>\n</div>\n';
 } ;
__p += '\n\n';
 if(options.gPlus) { ;
__p += '\n<div class="socialGroup gplus-share">\n    ';
 if(options.displayCounters) { ;
__p += '\n        <div class="sideCounter">\n            <div class="count gplus-count"></div>\n            <i class="fa fa-caret-left smallArrow"></i>\n        </div>    \n    ';
 } ;
__p += '\n    <button class="gp"><i class="fa fa-google-plus"></i> google-plus</button>\n</div>\n';
 } ;
__p += '\n\n';
 if(options.linkedin) { ;
__p += '\n<div class="socialGroup linkedin-share">\n    ';
 if(options.displayCounters) { ;
__p += '\n        <div class="sideCounter">\n            <div class="count linkedin-count"></div>\n            <i class="fa fa-caret-left smallArrow"></i>\n        </div>    \n    ';
 } ;
__p += '\n    <button class="linkedin"><i class="fa fa-linkedin"></i> linkedin</button>\n</div>\n';
 } ;
__p += '\n\n';
 if(options.twitter) { ;
__p += '\n<div class="socialGroup twitter-share">\n    ';
 if(options.displayCounters) { ;
__p += '\n        <div class="sideCounter">\n            <div class="count twitter-count"></div>\n            <i class="fa fa-caret-left smallArrow"></i>\n        </div>    \n    ';
 } ;
__p += '\n    <button class="twitter"><i class="fa fa-twitter"></i> twitter</button>\n</div>\n';
 } ;
__p += '\n\n';
 if(options.pinterest) { ;
__p += '\n<div class="socialGroup pinterest-share">\n    ';
 if(options.displayCounters) { ;
__p += '\n        <div class="sideCounter">\n            <div class="count pinterest-count"></div>\n            <i class="fa fa-caret-left smallArrow"></i>\n        </div>    \n    ';
 } ;
__p += '\n    <button class="pinterest"><i class="fa fa-pinterest"></i> pinterest</button>\n</div>\n';
 } ;


}
return __p
}