class FieldUnit {
    private static _instance: FieldUnit;
    tablet: { col: number, height: number, fieldHeight: number };
    desktop: { col: number, height: number, fieldHeight: number };
    phone: { col: number, height: number, fieldHeight: number };

    //Singleton definition
    private constructor() {
        this.tablet = {
            col: 10,
            height: 56,
            fieldHeight: 32
        };
        this.desktop = {
            col: 10,
            fieldHeight: 30,
            height: 36
        };
        this.phone = {
            col: 10,
            height: 56,
            fieldHeight: 32
        };
    }

    public static get Instance(): FieldUnit {
        return this._instance || (this._instance = new this());
    }
}

const singleInstance = FieldUnit.Instance;

export default singleInstance;