module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="innerContainer">\n<p class="offreTitle">Vous êtes ...</p>\n';
 if(offres) { ;
__p += '\n    ';
 var size = 12/nbOffres; ;
__p += '\n    <div class="row">\n    ';
 for (var key in offres) { ;
__p += '\n        ';
 var current = offres[key] ;
__p += '\n        <div class="offre col-md-' +
((__t = (size)) == null ? '' : __t) +
' unselected">\n            <div class="offreInner">\n                <div class="offreImage" style="background-image: url(images/home/' +
((__t = (current.image)) == null ? '' : __t) +
');"></div>\n                <p class="offreTitle">' +
((__t = (current.name)) == null ? '' : __t) +
'</p>\n                ';
 for (var argKey in current.arguments) { ;
__p += '\n                    <p class="offreArgument"> ' +
((__t = (current.arguments[argKey])) == null ? '' : __t) +
'</p>\n                ';
 } ;
__p += '\n            </div>\n            <button data-index="' +
((__t = (key)) == null ? '' : __t) +
'" type="button" class="btn btn-default fullWidthOfferButton">Voir cette formule</button>\n        </div>\n    ';
 } ;
__p += '\n    </div>\n    ';
 for (var key in offres) { ;
__p += '\n        ';
 var current = offres[key] ;
__p += '\n        <div class="row offreDetail">\n            <div class="row col-md-12 offreDetailInner">\n                ';
 for(var detailKey in current.details) { ;
__p += '\n                    ';
 var currentDetail = current.details[detailKey]; ;
__p += '\n                    <div class="col-md-6 definition">\n                        <div>\n                            <i class="fps home-font-style fps-' +
((__t = (currentDetail.icon)) == null ? '' : __t) +
'"></i> \n                        </div>\n                        <div class="iconDefmargin featurette-description">\n                            ' +
((__t = (currentDetail.text)) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n            <div class="aboBtns">\n                ';
 for( var keyP in current.price) { ;
__p += '\n                    ';
 var currentP = current.price[keyP]; ;
__p += '\n                    <div class="offreBtn">\n                        <button style="color:' +
((__t = (currentP.textColor)) == null ? '' : __t) +
';" type="button" class="btn ' +
((__t = (currentP.colorCls)) == null ? '' : __t) +
'"><b>' +
((__t = (currentP.name)) == null ? '' : __t) +
'</b> ' +
((__t = (currentP.button)) == null ? '' : __t) +
'</button>\n                        <p class="offrePrice">';
 if(currentP.monthly) { ;
__p +=
((__t = (currentP.monthly)) == null ? '' : __t) +
'.- ';
 } ;
__p += '<i>' +
((__t = (currentP.condition)) == null ? '' : __t) +
'</i></p>\n                        <p class="offreFeatures">' +
((__t = (currentP.features)) == null ? '' : __t) +
'</p>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    ';
 } ;
__p += '\n';
 } ;
__p += '\n</div>';

}
return __p
}