module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="navLineAction" data-index="' +
((__t = (id)) == null ? '' : __t) +
'" data-actionindex="' +
((__t = (actionId)) == null ? '' : __t) +
'"><img src="' +
((__t = (server.getTokenedUrl(icon))) == null ? '' : __t) +
'"/></div>';

}
return __p
}