import { DataField } from '../parametrage/structures/DataField';
import { Combo } from '../parametrage/structures/Combo';
import User from 'User';
let moment = require( 'moment');

class StringUtils {
    static resolveTextProperties(label, contextToUse){
        if(typeof label !== 'string') {
            return label;
        }
        let context = contextToUse;
        if(context === undefined){
            context = {};
        }

        if(!label){
            return label;
        }

        var regex = /(\$(\{?(.+?)(#.+?)*\}?)(?: |$|\.(?:$| )))/g
        var matches = [];
        var curedMatches = [];

        var splittedLabel = label.split('$');
        splittedLabel.forEach(sub => {
            var subLabel = '$'+sub; // put the $ back
            var match = regex.exec(subLabel);
            while (match !== null) {
                matches.push(match[2]);
                curedMatches.push(match[0].indexOf('{') >= 0 ? match[3] : '$'+match[3]);
                match = regex.exec(subLabel);
            }
        });
        var toReplace = label;
        for(var strKey in matches){
            var toMatch = '\$'+matches[strKey];
            if(toMatch.indexOf('.') < 0) {
                if(context === undefined || context[curedMatches[strKey]] === undefined){
                    toReplace = toReplace.replace(toMatch, '');
                }else{
                    toReplace = toReplace.replace(toMatch, context[curedMatches[strKey]]);
                }
            } else {
                var splited = curedMatches[strKey].split('.');
                var root = splited[0];
                var subProp = splited[1];
                if(context === undefined || (context[curedMatches[strKey]] === undefined && (context[root] === undefined || context[root][subProp] === undefined))){
                    toReplace = toReplace.replace(toMatch, '');
                }else{

                    toReplace = toReplace.replace(toMatch, context[curedMatches[strKey]] === undefined ? context[root][subProp] : context[curedMatches[strKey]]);
                }
            }
        }
        return toReplace;
    }

    static underscoreToCamelCase(label){
        //show_select_grid will match _s and _g
        var regex = /(_.)/g;
        var matches = [];
        var match = regex.exec(label);
        while (match !== null) {
            matches.push(match[1]);
            match = regex.exec(label);
        }
        var toReplace = label;
        for(var strKey in matches){
            //replacing _s by S
            toReplace = toReplace.replace(matches[strKey], matches[strKey].substring(1,2).toUpperCase());
        }
        //show_select_grid becomes showSelectGrid
        return toReplace;
    }

    static basename(someString) {
        if(someString === undefined) {
            return someString;
        } else {
            var base = someString.substring(someString.lastIndexOf('/') + 1);
            if(base.lastIndexOf('.') != -1) {
                base = base.substring(0, base.lastIndexOf('.'));
            }
            return base;
        }
    }

    public static guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4();
    }

    static formatValue(value: string, datafield: DataField, combo?: Combo, alreadyFormatted?: boolean) {
        if(value === undefined || value === '' || datafield === undefined) {
            return value;
        }

        let format = datafield.getFormat();
        let formattedValue;
        let nbDecimal = datafield.getDecimalsNumber() !== undefined ? datafield.getDecimalsNumber() : 2;
        let parsedValue;
        switch(format) {
            case 'Integer':
                formattedValue = alreadyFormatted ? value : parseInt(value).toString();
                break;
            case 'Financial':
                formattedValue = alreadyFormatted ? value : parseFloat(value).toFixed(nbDecimal).toString();
                break;
            case 'Float':
                formattedValue = alreadyFormatted ? value : parseFloat(value).toFixed(nbDecimal).toString();
                break;
            case 'Year':
                formattedValue = alreadyFormatted ? value : moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                break;
            case 'Date':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('L') : value;
                break;
            case 'DateTime':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('L HH:mm') : value;
                break;
            case 'Time':
                parsedValue = moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                formattedValue = parsedValue !== undefined && parsedValue.isValid() ? parsedValue.format('HH:mm') : value;
                break;
            case 'Boolean':
                formattedValue = value;
                break;
            case 'Enum':
            case 'Select':
                formattedValue = this.getEnumDisplay(value, datafield, combo);
                break;
            case 'Percentage':
                let fValue = parseFloat(value) * 100;
                formattedValue = fValue.toFixed(2) + '%'
                break;
            default:
                formattedValue = value;
        }
        return this.getMultilangTextValue(User.getLocale(), formattedValue);
    }

    public static getMultilangTextValue(lang: string, multilangText: string) {
        const re = /([A-Z]{2})=\{(.+?)\}/g;
        const defaultLanguage = 'FR';
        const targetLanguage = lang;
        let defaultLanguageMessage = undefined;
        let targetLanguageMessage = undefined;
        let match;
        while(match = re.exec(multilangText)) {
            if(match[1] === defaultLanguage) {
                defaultLanguageMessage = match[2];
            }
            if(match[1] === targetLanguage) {
                targetLanguageMessage = match[2];
            }
        }
        if(targetLanguageMessage) { return targetLanguageMessage; }
        if(defaultLanguageMessage) { return defaultLanguageMessage; }
        return multilangText;
    }

    private static getEnumDisplay(enumKey: string, datafield: DataField, combo: Combo): string {
        if(!combo) {
            console.warn('Combo linked to datafield '+ datafield.getId() + ' not found, using key "' + enumKey + '" instead');
            return enumKey;
        }
        let datafieldConfig = datafield.getConfig;
        let displayCode = datafieldConfig['displayCode'];
        if(displayCode === 'CodeOnly') {
            return enumKey;
        } else if(displayCode === 'TextOnly') {
            return combo.getElement(enumKey) ? combo.getElement(enumKey).getLabel() : enumKey;
        } else {
            // Default: code + text
            let result = enumKey;
            if(combo.getElement(enumKey)) {
                result += ' - ' + combo.getElement(enumKey).getLabel();
            }
            return result
        }
    }
}

export default StringUtils;