'use strict';

import * as Backbone from 'backbone';

import Context from 'models/context/Context';

class ContextsCollection extends Backbone.Collection<Context> {
    addContext(context){
        var me = this;
        this.each(function(model){
            if(model.getContextId() === context.getContextId()){
                me.remove(model);
            }
        });
        super.add(context);
    }

    hasContext(contextId){
        var hasIt = false;
        this.each(function(model){
            if(model.getContextId() === contextId){
                hasIt = true;
            }
        });
        return hasIt;
    }

    getContext(contextId){
        var theModel;
        this.each(function(model){
            if(model.getContextId() === contextId){
                theModel = model;
            }
        });
        return theModel;
    }

    getOrCreate(contextId, resetConfig){
        if(!this.hasContext(contextId)){
            var newContext = new Context({ contextId: contextId});
            this.addContext(newContext);
            return newContext;
        }else{
            var context = this.getContext(contextId);
            if(resetConfig !== undefined){
                if(typeof resetConfig === 'boolean' && resetConfig){
                    context.reset({
                        parameters: true,
                        screen: true
                    });
                }else if(typeof resetConfig === 'object'){
                    context.reset(resetConfig);
                }
            }
            return context;
        }
    }

    removeContext(contextId){
        var me = this;
        this.each(function(model){
            if(model.getContextId() === contextId){
                me.remove(model);
            }
        });
    }

    getPartial(contextsList){
        var partial = new ContextsCollection();
        for(var key in contextsList){
            partial.addContext(this.getContext(contextsList[key]));
        }
        return partial;
    }

    toJSONString(){
        var final = {};
        this.each(function(context){
            final[context.getContextId() || 'undefinedContext'] = context.toJSON();
        });

        return JSON.stringify(final);
    }
}

export default ContextsCollection;
