import PageIconPreview from 'views/tasks/navigators/pages/PageIconPreview';
import _ from 'lodash';
import Server from 'server/Server';
import { SAIView } from '../../../../Additions';
import RuptureRow from '../../../../models/listing/RuptureRow';
import Config from 'Config';
import { Task } from '../../../../parametrage/structures/Task';
import { PopupMenu } from '../../../../parametrage/structures/PopupMenu';

class HeaderListItem extends SAIView {
    initialize(options) {
        this.row = options.row || {};
        this.actionClass = options.actionClass || '.action';
        this.itemClass = options.itemClass;
        this.itemId = options.itemId;
        this.template = require('ejs-loader!templates/listing/HeaderListItem.ejs');
        this.cache = {};
        this.lineStyle = options.lineStyle;
        this.allowExpand = options.allowExpand !== undefined? options.allowExpand: false;
        this.domainContext = options.domainContext;
        this.columnMap = [];
        this.buildHeaderMap();
        this.popupMenu = options.popupMenu;
        this.iconConfig = options.iconConfig;
        this.gridConfig = options.gridConfig;
    }

    render() {
        var visibleAcs = this.getVisibleActions();
        let isRupture = this.row instanceof RuptureRow;
        this.$el.html(this.template({
            icon: this.getIcon(),
            overlay: this.getOverlays(),
            columns: this.row.getColumnsValues(),
            displayData: this.displayData.bind(this),
            actions: visibleAcs,
            domainContext: this.domainContext,
            isRupture: isRupture,
            server: Server
        }));

        if(isRupture) {
            this.$el.addClass('rupture-row');
            this.$el.css('background-color', Config.appTheme);
        } else {
            this.$el.addClass('data-row');
        }

        this.actions = this.$el.children('.actions');
        this.$el.addClass('header-item');
        if(this.itemClass) { this.$el.addClass(this.itemClass); }
        this.$el.data('id', this.itemId);
        this.actions.children('.action').on('click',this.onClick.bind(this, this.row, 'action'));
        this.$el.on('click',this.onClick.bind(this, this.row, 'item'));
        this.$el.children('.text').on('click',this.onClick.bind(this, this.row, 'item'));
        this.$el.children('.icon').on('click',this.onClick.bind(this, this.row, 'icon'));
        this.$el.children('.graph').on('click', this.onClick.bind(this, this.row, 'graph'));

        if (visibleAcs.length === 0 || isRupture) {
            this.actions.hide();
        } else {
            this.actions.css('width', (visibleAcs.length * this.actions.children('.action').width()) + 20);
        }

        var currentIcon = this.$el.find('.mainIcon.coverIcon');
        currentIcon.one('load', this.onIconLoaded.bind(this, this.row, this.itemId));
        currentIcon.attr('src', Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,record/128,100,64,36,32/' + this.getIcon()));

        if(this.row.getImagePreview() !== undefined){
            //injecting photo dynamicaly
            var newPhoto = $('<div class="mainIcon coverImage"></div>').insertAfter(currentIcon);
            newPhoto.on('click', this.displayIcon.bind(this, this.row.getImage()));
            let tryImg = $('<img />').hide().insertAfter(newPhoto);
            tryImg.one('load', this.backToVisible.bind(this, newPhoto,currentIcon,tryImg));
            tryImg.one('error', this.hideFailPhoto.bind(this, newPhoto, tryImg));
            tryImg.prop('src', Server.getTokenedUrl(this.row.getImagePreview()));
        }

        if(this.getIcon() === undefined && this.row.getImagePreview() === undefined) {
            this.$el.find('.icon').hide();
        }

        var separateSpans = this.$el.find('.header-value').find('span');
        separateSpans.each(function(item){
            if($(this).parent().position().top !== 0 && $(this).parent().position().left === 0){
                $(this).remove();
            }
        });

        let textChild = this.$el.children('.text');
        let textWidth = this.$el.innerWidth() - this.$el.children('.icon').outerWidth();
        if(visibleAcs.length === 0){
            textChild.css('padding-right', 10);
            // Remove the left and right padding
            textWidth -= 15;
        }else{
            textChild.css('padding-right', 'unset');
            // Remove the left and right padding
            textWidth -= (visibleAcs.length * this.actions.children('.action').width()) + 20 + 5;
        }
        textChild.css('width', textWidth +'px');

        if(this.lineStyle){
            textChild.css(this.lineStyle);
        }

        if(this.allowExpand){
            let expansionEl = this.$el.find('.expansion');
            let offsetChild = textChild.offset().top + textChild.height();
            var me = this;

            let bannerHeight = textChild.find('.banner').outerHeight();
            let headerElements = textChild.find('.banner .header-element');
            let spanOnMultipleLines = false;
            let startTop = undefined;
            headerElements.each((index, el) => {
                let currElTop = $(el).position().top;
                let currElHeight = $(el).height();
                if(currElHeight > bannerHeight) {
                    spanOnMultipleLines = true;
                }
                if(startTop === undefined) {
                    startTop = currElTop;
                } else if(currElTop > startTop) {
                    spanOnMultipleLines = true;
                }
            });
            if(headerElements.length === 0) {
                textChild.addClass('no-title');
            }
            if(spanOnMultipleLines) {
                textChild.find('.banner').css('overflow', 'hidden');
                me.displayExpansion();
            } else {
                let extraBannerHeight = 0;
                if(!startTop) {
                    extraBannerHeight = bannerHeight;
                }
                textChild.find('.header-label, .header-value').each(function(){
                    if(($(this).offset().top + $(this).height() - extraBannerHeight) > offsetChild + 5  || me.forceExpand){
                        me.displayExpansion();
                        return false;
                    }
                });
            }
        }

        let bannerComponent = this.$el.find('.banner');
        let hasBanner = false;
        if(bannerComponent.text().trim() === '') {
            bannerComponent.hide();
        } else {
            hasBanner = true;
            bannerComponent.show();
        }

        let titleComponent = this.$el.find('.title');
        let hasTitle = false;
        if (titleComponent.text().trim() === '') {
            titleComponent.hide();
        } else {
            hasTitle = true;
            titleComponent.show();
        }

        if((hasTitle && !hasBanner) || (!hasTitle && hasBanner)) {
            this.$el.addClass('minimal-item');
        }
    }

    onDOMUpdated() {
        let textChild = this.$el.children('.text');
        if(this.allowExpand){
            let offsetChild = textChild.offset().top + textChild.height();
            var me = this;

            let bannerHeight = textChild.find('.banner').outerHeight();
            let headerElements = textChild.find('.banner .header-element');
            let spanOnMultipleLines = false;
            let startTop = undefined;
            headerElements.each((index, el) => {
                let currElTop = $(el).position().top;
                let currElHeight = $(el).height();
                if(currElHeight > bannerHeight) {
                    spanOnMultipleLines = true;
                }
                if(startTop === undefined) {
                    startTop = currElTop;
                } else if(currElTop > startTop) {
                    spanOnMultipleLines = true;
                }
            });
            if(headerElements.length === 0) {
                textChild.addClass('no-title');
            }
            if(spanOnMultipleLines) {
                textChild.find('.banner').css('overflow', 'hidden');
                me.displayExpansion();
            } else {
                let extraBannerHeight = 0;
                if(!startTop) {
                    extraBannerHeight = bannerHeight;
                }
                textChild.find('.header-label, .header-value').each(function(){
                    if(($(this).offset().top + $(this).height() - extraBannerHeight) > offsetChild + 5  || me.forceExpand){
                        me.displayExpansion();
                        return false;
                    }
                });
            }
        }
    }

    displayExpansion() {
        let expansionEl = this.$el.find('.expansion');
        expansionEl.show();
        expansionEl.off('click');
        expansionEl.on('click', this.onExpansionClick.bind(this));
        if(this.gridConfig) {
            let conf = this.gridConfig.getConfig();
            if(conf.mobileBannerState === 'opened') {
                this.$el.addClass('expanded');
            }
        }
    }

    onExpansionClick(evt){
        this.$el.toggleClass('expanded');
        evt.stopPropagation();
    }

    displayIcon(icon){
        if(icon){
            var newPreview = new PageIconPreview({
                pageIcon: icon
            });
            newPreview.render();
        }
    }

    getVisibleActions(){
        if(!this.popupMenu) {
            return [];
        } else {
            return this.popupMenu.getVisibleActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_MULTIPLE, PopupMenu.SCOPE_SINGLE], this.row.getColumnsValues(), this.columnMap);
        }
    }

    onIconLoaded(row, id){
        this.trigger('iconLoaded', row, id, this);
    }

    displayData(isBanner) {
        var htmlData = '';
        var headers = this.row.getHeaders().getColumns();
        var columns = this.row.getColumnsValues();
        var firstDone = false;
        for (var key in headers) {
            var currentHeader = headers[key];
            if (currentHeader.isBanner() === isBanner && ! currentHeader.isHidden()) {
                var data = columns[currentHeader.getId()];
                if (data && data.value) {
                    if (firstDone) {
                        htmlData += '<span class="header-colSeparator">' + currentHeader.getColumnSeparator() + '</span>';
                    }
                    var extraSpace = '';
                    htmlData += '<span class="header-element">';
                    if (!currentHeader.labelIsHidden()) {
                        htmlData += '<span class="header-label">' + currentHeader.getLabel() + currentHeader.getLabelSeparator() + '</span>';
                    }else if(firstDone){
                        extraSpace = '<span>&nbsp;</span>';
                    }

                    let finalValue = ( data.humanValue && data.humanValue !== '' ) ? data.humanValue : data.value;
                    if(finalValue.includes('\n')){
                        this.forceExpand = true;
                    }
                    finalValue = finalValue.replace(/[\n]/g, '<span class="virtual-return"></span>');
                    htmlData += '<span class="header-value">' + extraSpace + finalValue + '</span></span>';

                    firstDone = true;
                }
            }
        }
        return htmlData;
    }

    onClick(row, action, evt) {
        evt.stopPropagation();
        var id;
        if(action === 'action'){
            id = $(evt.delegateTarget).data('id');
        }else{
            id = $(evt.delegateTarget).parent().data('id');
        }

        if(row instanceof RuptureRow && action !== 'action') {
            return;
        }
        this.select();
        this.trigger(action + 'Clicked', row, id, this);
    }

    backToVisible(photo, icon,imgContainer){
        this.trigger('specificImageLoaded', imgContainer.prop('src'));
        photo.css('opacity', 1);
        photo.css({
            'background-image': 'url(\''+imgContainer.prop('src')+'\')',
            'background-size': 'cover'
        });
        icon.hide();
        imgContainer.remove();
    }

    hideFailPhoto(photo, imgContainer){
        photo.hide();
        imgContainer.remove();
    }

    setRow(newRow){
        this.row = newRow;
    }

    select(){
        this.$el.toggleClass('header-item-selected');
    }

    buildHeaderMap() {
        var columns = this.row.getHeaders().getColumns();
        for (var columnIndex in columns) {
            var column = columns[columnIndex];
            this.columnMap[column.getDatafieldId()] = column.getId();
        }
    }

    getIcon() {
        let gridValue = this.getIconType('icon','grid');
        if(gridValue !== undefined) {
            return gridValue;
        } else {
            return this.getIconType('icon','table');
        }
    }

    getIconType(iconType, source) {
        if(this.iconConfig[source] && this.iconConfig[source][iconType]) {
            let datafield =  this.iconConfig[source][iconType].datafield;
            let values = this.iconConfig[source][iconType].values;

            if(datafield === undefined && values !== undefined) {
                // No datafield, take the default value
                return values['default'];
            }
            let fieldId = this.row.getHeaders().getDatafieldsToColumnIds()[datafield];
            if(fieldId !== undefined) {
                let value = this.row.getColumnsValues()[fieldId].value
                let result = values[value];
                if(result !== undefined) {
                    return result;
                } else {
                    return values['default'];
                }
            } else {
                return values['default'];
            }
        }
    }

    getOverlays() {
        let result = {};
        let botLeft = this.getIconType('overlayBottomLeft','grid');
        if(botLeft === undefined) {
            botLeft = this.getIconType('overlayBottomLeft','table');
        }
        if(botLeft !== undefined) {
            result.thumbBotLeft = botLeft;
        }

        let botRight = this.getIconType('overlayBottomRight','grid');
        if(botRight === undefined) {
            botRight = this.getIconType('overlayBottomRight','table');
        }
        if(botRight !== undefined) {
            result.thumbBotRight = botRight;
        }

        let topLeft = this.getIconType('overlayTopLeft','grid');
        if(topLeft === undefined) {
            topLeft = this.getIconType('overlayTopLeft','table');
        }
        if(topLeft !== undefined) {
            result.thumbTopLeft = topLeft;
        }

        let topRight = this.getIconType('overlayTopRight','grid');
        if(topRight === undefined) {
            topRight = this.getIconType('overlayTopRight','table');
        }
        if(topRight !== undefined) {
            result.thumbTopRight = topRight;
        }

        return result;
    }
}

export default HeaderListItem;
