'use strict';

import * as Backbone from 'backbone';
import User from 'User';

class PrintFiles extends Backbone.Model{
    printId: string;
    files: any[];

    constructor(printId:string, files:any[]) {
        super();
        this.printId = printId;
        this.files = files;
    }

    toNoteDownload() : any {
        let noteDownload=[];
        let me = this;
        if(this.files) {
            this.files.forEach(function(item) {
                noteDownload.push({
                    type: item.format,
                    name: item.humanName,
                    full: 'file/'+User.getCurrentDomain()+'/result?request1={"printId":"'+me.printId+'","filename":"'+item.name+'","humanName":"'+item.humanName+'"}'
                });
            });
        } else {
            console.warn('No file result');
        }
        return [{files: noteDownload}];
    }
}

export default PrintFiles;