'use strict';

import { AuthenticationRequest } from './AuthenticationRequest';

class LoginRequest extends AuthenticationRequest{
    private password : any;
    private username : string;
    private token : string;

    constructor(user, pass, token){
        super('login', 'POST');
        this.setUserName(user);
        this.setPassword(pass);
        this.token = token;
    }

    setUserName(username){
        this.username = username;
    }

    setPassword(pass){
        this.password = pass;
    }

    getSerializable(){
        return {
            'auth-login' : this.username,
            'auth-pass' : this.password
        };
    }

    getName(){
        return 'LoginRequest';
    }

    getUrl() {
        if (!this.token) {
            return super.getUrl();
        } else {
            let url = this.token;
            url += '/' + this.controller;
            url += '/' + this.service;

            return url;
        }
    }
}

export { LoginRequest };