'use strict';

import { DomainContext } from './DomainContext';
import { ColorTheme } from './lookandfeel/ColorTheme';
import { ColorThemeRequest } from '../server/protocol/request/configuration/ColorThemeRequest';

import CClientConfiguration from 'parametrage/CClientConfiguration';

import User from 'User';
import Server from 'server/Server';
import DomainManager from 'DomainManager';

/**
 * Singleton helper that allows to fetch theme colors for the components. Based
 * on the given domain, it can fetch a theme from the server and use it to define
 * component colors 
 */
class ThemeManager {
    private themes: { [domainKey:string]: { [themeId:string]:Promise<ColorTheme> } };
    private static _instance: ThemeManager;   
    
    private constructor() {
        this.themes = {};
    }

    public static get Instance(): ThemeManager {
        return this._instance || (this._instance = new this());
    }

    /**
     * @returns The html color of the requested component in the given context 
     * @param componentName The component we want to color
     * @param context The domain context that allows to retrieve the proper 
     * color based on the component domain
     */
    public getColorForComponent(themeId: string, componentName: string, context: DomainContext) : Promise<string> {
        return new Promise((resolve, reject) => {
            this.getTheme(themeId, context)
                .then((theme: ColorTheme) => {
                    resolve(theme.getProperty(componentName));
                })
                .catch(reject);
        });
    }

    /**
     * @returns The ColorTheme that has the given id in the given domain context
     * @param id The unique id of the domain
     * @param context The domain context in which we want to get the theme
     */
    public getTheme(id: string, context: DomainContext) : Promise<ColorTheme> {
        return CClientConfiguration.getCachedConfiguration.bind(this)('themes', id, context, ColorThemeRequest, ColorTheme);
    }

    /**
     * @returns The List of available themes
     * @param context The domain context in which we want to get the list
     */
    public getThemeList(context: DomainContext) : Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            let newRequest:ColorThemeRequest = new ColorThemeRequest(context);
            Server.performRequest(newRequest)
            .then((result:any) => {
                resolve(result);
            })
            .catch(reject);
        });
    }

    /**
     * @returns The first theme of the available theme list
     * @param context The domain in which we get the theme
     */
    private getFirstAvailableTheme(context: DomainContext) : Promise<ColorTheme> {
        return new Promise<ColorTheme>((resolve, reject) => {
            this.getThemeList(context)
            .then((themeList: Array<string>) => {
                //We got the theme list
                if(themeList.length > 0) {
                    //We try to fetch the first theme
                    this.getTheme(themeList[0], context)
                        .then(resolve) //We got it
                        .catch(() => { //We didn't manage to get it
                            reject('THEME_RETRIEVAL_FAILURE');    
                        })    
                } else {
                    //There is no theme in the list
                    reject('THEME_NO_THEMES');
                }
            })
            .catch(reject); //We can't get the theme list. Total failure
        });
    }

    /**
     * @returns The user theme in the given domain. If the user has no theme in
     * this domain, then it'll try to fetch the first available one.
     * @param curDomain The domain in which the system will fetch the theme
     */
    public getUserTheme(domain?: string): Promise<ColorTheme> {
        let curDomain: DomainContext;

        if(domain === undefined) {
            curDomain = DomainManager.getCurrentContext(User.getCurrentDomain());
        } else {
            curDomain = DomainManager.getContext(domain);
        }

        return new Promise((resolve, reject) => {
            let themeId = User.getColorTheme();
            if(themeId !== undefined && themeId !== '') {
                //Fetching requested Theme from the server
                this.getTheme(themeId, curDomain)
                    .then(resolve) //Theme is available
                    .catch((reason) => {
                        //Theme isn't available, switching to first one
                        console.warn('The requested theme ' + themeId + ' is not available, switching to first available');
                        this.getFirstAvailableTheme(curDomain)
                            .then(resolve)
                            .catch(reject);
                    });
            } else {
                //The user doesn't have any theme, switching to first one
                this.getFirstAvailableTheme(curDomain)
                    .then(resolve)
                    .catch(reject);
            }
        })
    }
}

const singleInstance = ThemeManager.Instance;

export default singleInstance;