module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="coupleSelection">\n    <div class="responsiveActions" style="display: none;">\n        <div class="title">' +
((__t = (fieldLabel)) == null ? '' : __t) +
'</div>\n        <div class="selectedAction">X</div>\n        <div class="selectionAction" style="display: none;">Y</div>\n    </div>\n    <div class="navigatorContainer"></div>\n    <div class="selectedElements">\n        <div class="title">Selection</div>\n        <div class="selection"></div>\n        <div class="orderArrows">\n            <div class="upArrow"></div>\n            <div class="downArrow"></div>\n        </div>\n    </div>\n    <div class="searchInput input-group">\n        <input class="search-bar" type="text" class="form-control" placeholder="Recherche">\n        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>\n    </div>\n    <button type="button" class="btn-default">Ok</button>\n</div>';

}
return __p
}