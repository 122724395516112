module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-md-2">\n        <div class="uploadStatus">\n            ';
 if(status === 'uploading') {;
__p += ' \n            <img src="/images/file_load.png" alt="Upload" />\n            ';
} else if (status === 'pending') { ;
__p += '\n            <img src="/images/file_cloak-file.png" alt="Waiting for validation" />\n            ';
} else if (status === 'cloud') { ;
__p += '\n            <img src="/images/file_accept.png" alt="Online" />\n            ';
} ;
__p += '\n        </div>\n\n    </div>\n    <div class="col-md-10">\n        ';
 if(mode === 'edit') {;
__p += '\n        <div class="row">\n            <label class="recordDocAddFormLabel" for="title">Titre</label>\n        </div>\n        <div class="row">\n            ';
 var liop = path.lastIndexOf('.') ;
__p += '\n            ';
 if ( liop > 0 ) { ;
__p += '\n                <input class="documentAddTitle" type="text" name="title" value="' +
__e( path.substring(0, liop) ) +
'" ';
 if (status !== 'pending') { ;
__p += ' disabled ';
 } ;
__p += '>\n            ';
 } else { ;
__p += '\n                <input class="documentAddTitle" type="text" name="title" value="' +
__e( path ) +
'" ';
 if (status !== 'pending') { ;
__p += ' disabled ';
 } ;
__p += ' >\n            ';
 } ;
__p += '\n            ';
 if (status === 'cloud') { ;
__p += '\n                <div class="documentDownloadIcon">\n                    <img src="/images/file_download.png" alt="Download" />\n                </div>\n            ';
 } ;
__p += '\n        </div>\n        ';
 if (status === 'pending') { ;
__p += '\n        <div class="row">\n            <label class="documentPendingWarning">en attente de validation</label>\n        </div>\n        ';
 } ;
__p += '\n        ';
} else if (mode === 'progress') { ;
__p += '\n        <div class="row">\n            <progress class="documentAddProgress upProgress" value="0" max="100"></progress>\n        </div>\n        ';
} ;
__p += '\n    </div>\n</div>';

}
return __p
}