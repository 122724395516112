'use strict';

class MobileMessage{
    getStatus(): number{ return -1; }

    public isPrettyPrinting(response: any) : boolean {
        if(!response){
            return this.forcePretty();
        }else{
            return response.isPrettyPrint();
        }
    }

    serializeNull() : boolean{
        return false;
    }

    forcePretty() : boolean{
        return false;
    }
}

export default MobileMessage;