'use strict';

import { SAIView } from '../../Additions';

class FloatingDialogView extends SAIView {
    private title: string;
    private uniqueId : string;
    private canClose: boolean = false;
    private canMinimize: boolean = false;
    private titleElement: JQuery<HTMLElement>;
	private x_start: number = 0;
    private y_start : number= 0;
    private position_x: number;
    private position_y: number;
    private selected: JQuery<HTMLElement>;
    private dialog;
    private overlay;

    constructor(options) {
        super(options);
        this.options = options;
        this.title = options.title;
        this.template = require('ejs-loader!templates/FloatingDialog.ejs');
        this.uniqueId = 'floating-dialog-'+new Date().getTime();
        if(options.canClose !== undefined) {
            this.canClose = options.canClose;
        }
        if(options.canMinimize !== undefined) {
            this.canMinimize = options.canMinimize;
        }
        if(options.position !== undefined) {
            this.position_x = options.position.x;
            this.position_y = options.position.y;
        }
    }

    render() : any {
        this.$el.html(this.template({
            title: this.title,
            id: this.uniqueId,
            canClose: this.canClose,
            canMinimize: this.canMinimize
        }));
        this.dialog = this.$el.find('.floating-dialog');
        this.dialog.css('left',this.position_x);
        this.dialog.css('top', this.position_y);
        this.titleElement = this.$el.find('.floating-dialog-title');
        this.overlay = this.$el.find('.floating-dialog-overlay');
        this.titleElement.mousedown(this.handleMouseDown.bind(this));
        this.overlay.mouseup(this.handleMouseUp.bind(this));
        this.overlay.mousemove(this.handleDrag.bind(this));
    }

    emptyContent() : void {
        this.$el.find('.floating-dialog-content').children().remove();
    }

    addContent(element: JQuery<HTMLElement>) : void {
        this.$el.find('.floating-dialog-content').append(element);
    }

    getContent() : JQuery<HTMLElement> {
        return this.$el.find('.floating-dialog-content');
    }

    handleMouseDown(event) {
        if($(event.target).hasClass('fa') || $(event.target).hasClass('floating-dialog-minimize')) {
            this.onLegendMinimizeClick(event);
            return false;
        }
        this.selected = this.dialog; // Store the object of the element which needs to be moved
		this.x_start = event.pageX;
        this.y_start = event.pageY;
        this.overlay.show();
        return false;
    }

    handleDrag(event) : void {
        let x_pos = event.pageX;
		let y_pos = event.pageY;
        if (this.selected !== null && this.selected !== undefined) {
            let currentLeft = this.selected.offset().left;
            let currentTop = this.selected.offset().top;
            let left = currentLeft + x_pos - this.x_start;
            let top = currentTop + y_pos - this.y_start;
            if(left > 60 && (left + this.selected.outerWidth()) < (this.overlay.outerWidth() - 60)) {
                this.x_start = event.pageX;
            } else {
                if(left < 60) {
                    left = 60;
                } else {
                    left = this.overlay.outerWidth() - 60 - this.selected.outerWidth();
                }
            }
            this.selected.css('left', left);

            if(top > 0 && (top + this.selected.outerHeight()) < (this.overlay.outerHeight())) {
                this.y_start = event.pageY;
            } else {
                if(top < 0) {
                    top = 0;
                } else {
                    top = this.overlay.outerHeight() - this.selected.outerHeight();
                }
            }
            this.selected.css('top', top);
        }
    }

    handleMouseUp() : void {
        this.overlay.hide();
        this.selected = null;
    }

    show(): void {
        this.$el.show();
    }

    hide() : void {
        this.$el.hide();
    }

    private onLegendMinimizeClick(evt) {
        let arrowState = $(evt.target).hasClass('fa')? $(evt.target) : $(evt.target).find('.fa');
        if(arrowState.hasClass('fa-angle-down')) {
            arrowState.addClass('fa-angle-right');
            arrowState.removeClass('fa-angle-down');
            $(evt.target).parents('.floating-dialog').find('.floating-dialog-content').hide();
        } else {
            arrowState.addClass('fa-angle-down');
            arrowState.removeClass('fa-angle-right');
            $(evt.target).parents('.floating-dialog').find('.floating-dialog-content').show();
        }
    }
} export default FloatingDialogView