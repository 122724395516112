'use strict';

import App from 'App';
import Server from 'server/Server';
let $ = require('jquery');

import { TaskView, TaskWindowType } from 'views/tasks/Task';
import NavigatorView from 'views/tasks/navigators/simple/Navigator';
import RecordView from 'views/record/Record';
import RecordModel from 'models/Record';
import RecordPopupView from 'views/record/RecordPopup';
import HistoryViewer from 'views/tasks/HistoryViewer';
import TabBarView from 'views/tasks/TabBar';
import DeviceUtils from 'utils/DeviceUtils';
import TabBarMenu from 'views/tasks/TabBarMenu';
import LoadingMask from 'views/loading/LoadingMask';

import Contexts from 'collections/Contexts';
import Context from 'models/context/Context';

import GetGridDataRequest from 'server/protocol/request/task/GetGridData';
import SubmitRecordRequest from 'server/protocol/request/task/SubmitRecord';
import ModalConfirmationDialog from '../modal/ModalConfirmationDialog';

const moment = require('moment');
import User from 'User';
import * as _ from 'lodash';
import { SAIEventCalendar, SAIEventManager, SAIEvent, SAITimePeriod, SAIEventInterfaceDisplayMode } from '@sai/historyviewer';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { DataField } from '../../parametrage/structures/DataField';
import { TaskPopupView } from '../record/TaskPopup';
import GetTaskRequest from '../../server/protocol/request/task/GetTask';
import TaskFactory from 'factory/TaskFactory';
import { Task } from '../../parametrage/structures/Task';
import NotificationManager from '../../utils/notification/NotificationManager';
import DialogUtils from '../../utils/DialogUtils';
import FloatingDialogView from '../modal/FloatingDialog';
import { Header } from '../../models/listing/Header';
import ColorUtils from '../../utils/ColorUtils';


class CalendarTask extends TaskView {
    private templateRecordOp: any;
    private templateSideOp: any;
    private eventsPalette: Array<string>;
    private eventsPaletteMapping: Map<string, number>;
    private modifRecord: RecordView;
    private data: Array<any>;
    private caltabBar: TabBarView;
    private caltabBarEl: JQuery<HTMLElement>;
    private viewModesMenu: TabBarMenu;
    private d3Calendar: SAIEventCalendar;
    private calendarEl: any;
    private calendarOptionsEl: JQuery<HTMLElement>;
    private filteredEvents: Array<SAIEvent>;
    private fetchedEvents: Array<SAIEvent>;
    private eventsMenu: TabBarMenu;
    private calendarWidth: number;
    private d3EventManager: SAIEventManager;
    private calendarCallBack: Function;
    private modifyPopup: TaskPopupView;
    private createPopup: TaskPopupView;
    private headersMapping: any;
    private paletteUsageCounter: number;
    private historyConfig: any;
    private fetchedHeaders: Header;

    constructor(options) {
        super(options);
        this.initCalendar(options);
    }

    initInnerTaskProperties() {
        this.taskType = 'Calendar';
        this.className = 'calendarTask';
    }

    initCalendar(options) {
        this.templateRecordOp = require('ejs-loader!templates/calendar/RecordOperationForm.ejs');
        this.templateSideOp = require('ejs-loader!templates/calendar/SideOptions.ejs');
        this.eventsPalette = ['#1CADE4', '#2683C6', '#27CED7', '#42BA97', '#3E8853', '#36550E', '#62A39F', '#6EAC1C', '#B26B03'];
        this.eventsPaletteMapping = new Map();

        CClientConfiguration.getGridView(options.taskConfig.getNavigatorGridView(), this.domainContext)
            .then((gridConfig) => {
                this.navigator = new NavigatorView({
                    taskId: this.getConfig().getId(),
                    task: this,
                    gridId: this.getConfig().getNavigatorGridView(),
                    filterId: this.getConfig().getNavigatorFilter(),
                    domainContext: this.domainContext,
                    gridConfig: gridConfig
                });

                var mode = 'tablet';
                if (DeviceUtils.isSmallDevice()) {
                    mode = 'phone';
                }

                this.modifRecord = new RecordView({
                    displayMode: mode,
                    domainContext: this.domainContext
                });

                this.data = [];
            })
            .catch((error) => {
                App.displayErrorMessage(error);
            });
    }

    renderCalendarTabBar(): void {
        if (DeviceUtils.isFullScreenPageNav()) {
            if (!this.caltabBar) {
                this.caltabBarEl = this.getCalendarTabBarElement();

                var tabs = this.getCalendarTabBarTabs();

                this.caltabBar = new TabBarView({
                    el: this.caltabBarEl,
                    prefix: 'cal',
                    tabs: tabs,
                    domainContext: this.domainContext
                });
                this.caltabBar.render();
                this.listenTo(this.caltabBar, 'tabPressed', this.onCalTabPressed.bind(this));
            }
            this.caltabBarEl.show();
        } else {
            if (this.caltabBar) {
                this.caltabBarEl.hide();
            }
        }
    }

    getCalendarTabBarElement(): JQuery<HTMLElement> {
        return this.$el.find('.calendarTabBar');
    }

    getCalendarTabBarTabs(): Array<any> {
        var tabs = [
            {
                id: 'home',
                name: 'Home',
                icon: 'home',
                position: 'center',
                enabled: true,
                type: 'icon'
            }
        ];

        if (App.hasCallStack()) {
            tabs.push({
                id: 'back',
                name: 'Retour',
                icon: 'arrow-left',
                position: 'center',
                enabled: true,
                type: 'icon'
            });
        }

        if (DeviceUtils.isSmallDevice()) {
            tabs.push();
        } else {
            tabs.push();
        }
        return tabs;
    }

    onCalTabPressed(tab): void {
        if (tab === 'home') {
            App.backToDesktop();
        } else if (tab === 'new') {
            this.onNewEventRequest(moment());
        } else if (tab === 'back') {
            App.popTask();
        }
    }

    onViewModeSelected(mode): void {
        this.stopListening(this.viewModesMenu);
        if (mode === 'monthAgenda') {
            this.setupMonthAgenda();
            this.d3Calendar.setVisualMode('monthAgenda');
            this.d3Calendar.render();
        } else if (mode === 'agenda') {
            //this.setupAgenda();
        } else {
            this.setupFullCalendar();
            this.d3Calendar.setVisualMode(mode);
            this.d3Calendar.render();
        }
    }

    getViewModesTabs(): Array<{ id: string, name: string }> {
        return [
            {
                id: 'year',
                name: 'Année'
            }, {
                id: 'month',
                name: 'Mois'
            }, {
                id: 'monthAgenda',
                name: 'Mois avec agenda'
            }, {
                id: 'week',
                name: 'Semaine'
            }, {
                id: 'day',
                name: 'Jour'
            }, {
                id: 'agenda',
                name: 'Agenda'
            }
        ];
    }

    /**
     * Displays the calendar form on the screen
     */
    render() {
        super.render();

        this.renderCalendarTabBar();
        this.calendarEl = this.$el.find('.calendar');
        this.calendarEl.on('monthChanged', this.onCalendarMonthChanged.bind(this));
        this.calendarEl.on('yearChanged', this.onCalendarYearChanged.bind(this));
        this.displayOptions();
        this.calendarOptionsEl.find('input[type="radio"]').change(this.onCalendarModeChanged.bind(this));
        return this;
    }

    onCalendarMonthSelected(month, year): void {
        var newDate = moment().month(month).year(year);
        this.displayMonthInBar(newDate);
    }

    onCalendarDaySelected(date, month, year): void {
        var newDate = moment().month(month).year(year).date(date);
        //We need to filter events such that we only get those of the selected day
        var start = moment(newDate).startOf('day');
        var end = moment(newDate).endOf('day');

        this.filteredEvents = [];
        for (var key in this.fetchedEvents) {
            var eventStartDate = moment(this.fetchedEvents[key].start);
            var eventEndDate = moment(this.fetchedEvents[key].end);
            if ((eventStartDate.isBefore(end) || eventStartDate.isSame(end)) && (eventEndDate.isAfter(start) || eventEndDate.isSame(start))) {
                this.filteredEvents.push(this.fetchedEvents[key]);
            }
        }

        if (this.filteredEvents.length > 0) {
            this.eventsMenu = new TabBarMenu({
                template: '<div class="selectEventMenu col-md-12" style="border-left: 5px solid ${values.color}" data-index="${itemId}"><div class="time"><table><tr><td>${moment(values.start, \'YYYY-MM-DD HH:mm:ss\').format("HH:mm")}</td></tr><tr><td> - </td></tr><tr><td>${moment(values.end, \'YYYY-MM-DD HH:mm:ss\').format("HH:mm")}</td></tr></table></div><table class="title"><tr><td>${values.title}</td></tr></table></div>',
                entries: this.filteredEvents,
                title: newDate.format('LL'),
                extraClass: 'dayEventsList'
            });
            this.listenTo(this.eventsMenu, 'entrySelected', this.onEventsMenuSelected.bind(this));
            this.eventsMenu.display();
        }

    }

    onEventsMenuSelected(index, entry): void {
        var key = _.findKey(this.filteredEvents, { id: index });
        var eventObject = this.filteredEvents[key];
        this.onEventClicked(eventObject);
    }


    onCalendarMonthChanged(evt, month, year): void {
        var newDate = moment().month(month).year(year);
        this.displayMonthInBar(newDate);
    }

    onCalendarYearChanged(evt, year): void {
        var newDate = moment().year(year);
        this.displayYearInBar(newDate);
    }

    onCalendarModeChanged(evt): void {
        var val = $(evt.delegateTarget).val();
        if (val === 'hist') {
            this.displayHistory();
        } else {
            this.displayCalendar();
        }
    }

    displayOptions(): void {
        this.calendarOptionsEl = this.$el.find('.sideOptions');
        this.calendarOptionsEl.append(this.templateSideOp());
        this.calendarOptionsEl.children('.openTriangle').click(this.openOptions.bind(this));
        this.calendarOptionsEl.children('.closeTriangle').click(this.closeOptions.bind(this));
    }

    openOptions(): void {
        this.calendarOptionsEl.removeClass('closedOptions');
        this.calendarOptionsEl.addClass('openedOptions');
        this.calendarOptionsEl.animate({
            right: 0
        }, 250, function () {
        });
        this.calendarEl.animate({
            width: this.calendarWidth - 150
        }, 250, function () {
        });
    }

    closeOptions(): void {
        this.calendarOptionsEl.addClass('closedOptions');
        this.calendarOptionsEl.removeClass('openedOptions');

        this.calendarOptionsEl.animate({
            right: -260
        }, 250, function () {
        });
        this.calendarEl.animate({
            width: this.calendarWidth
        }, 250, function () {
        });
    }

    displayCalendar(): void {
        var me = this;

        let calDisplay = this.calendarEl.children('.calendarDisplay');
        let menubarHeight = DeviceUtils.isFullScreenPageNav() ? this.caltabBarEl.height() : 0;
        //if (DeviceUtils.isFullScreenPageNav()) {
        let currentDomain = this.getConfig().getContext().getDomain();
        if (me.getConfig().getId() === 'GTPRP') {
            // hardcoded for creches :'-()
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPTYE.LABEL', 'GTPEVT.LINKDEPARTEMENT', 'GTPEVT.LINKFUNCTION'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPEVT.LINKTYPE',
                description: []
            };
        } else if (currentDomain === 'ABA') {
            // hardcoded for aba
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPEVT.LABEL'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPEVT.LINKTYPE',
                description: []
            };
        } else if (['87D', 'CAS', 'ERM', 'FDC', 'HEB', 'SPS', 'UAT'].includes(currentDomain)) {
            // hardcoded for fdc
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPTYE.LABEL'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPEVT.LINKTYPE',
                description: this.getFDCTitle()
            };
        } else if (me.getConfig().getId() === 'GTP45' || me.getConfig().getId() === 'GTPAM') {
            // hardcoded for creches :'-()
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPEVT.LINKDEPARTEMENT'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPTYE.LABEL',
                description: ['GTPTYE.LABEL', 'GTPEVT.LINKDEPARTEMENT', 'GTPEVT.LINKFUNCTION', 'GTPEVT.COMMENTARY']
            };
        } else if (me.getConfig().getId() === 'GTP40' && currentDomain === 'CPH') {
            //also for creches
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPTYE.LABEL'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPTYE.LABEL',
                description: ['GTPTYE.LABEL']
            };
        } else if (me.getConfig().getId() === 'GTP40') {
            //also for creches
            this.historyConfig = {
                id: 'GTPEVT.NORECO_PK',
                start: 'GTPEVT.EVENTSTART',
                end: 'GTPEVT.EVENTEND',
                title: ['GTPTYE.LABEL', 'GTPEVT.LINKDEPARTEMENT'],
                colorKey: 'GTPEVT.LINKTYPE',
                colorLegend: 'GTPTYE.LABEL',
                description: []
            };
        }
        //Creating a new Instance of the calendar. The default view will
        //be the one specified as currentVisualMode in the view parameters
        this.calendarEl.children('.calendarBanner').hide();
        this.d3EventManager = new SAIEventManager({
            fetchEvent: function (period, callback) {
                me.calendarCallBack = callback;
                me.refreshCurrentViewEvents(period, callback);
            },
            lazyRetrieval: false
        });

        var floatingDialog = $('<div></div>');
        this.$el.find('.taskBody').append(floatingDialog);
        var calendarLegend = new FloatingDialogView({
            el: floatingDialog,
            title: 'Légende', //TODO i18n
            canClose: false,
            canMinimize: false,
            position: { x: calDisplay.width() - 200, y: calDisplay.offset().top + 50 }
        });
        calendarLegend.render();
        this.d3Calendar = new SAIEventCalendar({
            rootElement: calDisplay,
            width: this.calendarEl.width(),
            height: $(window).height() - menubarHeight,
            locale: User.getLocale().toLowerCase(),
            manager: this.d3EventManager,
            displayMode: SAIEventInterfaceDisplayMode.MONTHES,
            availableModes: [SAIEventInterfaceDisplayMode.WEEKS, SAIEventInterfaceDisplayMode.MONTHES],
            listeners: {
                'newEvent': this.onNewEventRequest.bind(this),
                'monthChanged': this.onCalendarMonthSelected.bind(this),
                'eventSelected': this.onEventClicked.bind(this)
            },
            legendDialog: calendarLegend,
            instanceId: this.getTaskInstanceId(),
            showTopMenu: true,
            showModeSwitch: true
            /*,
            visualModes: ['day', 'week', 'month', 'monthAgenda', 'agenda', 'year'],
            timeStartCriteria: this.model.get('startField'),
            timeEndCriteria: this.model.get('endField'),
            currentVisualMode: 'month',
            eventSize: DeviceUtils.isSmallDevice() ? 10 : 25,
            dayNumberAlignment: 'top-right',
            headerAlignment: 'end',
            headerFormat: DeviceUtils.isFullScreenPageNav()? 'ddd' : 'dddd',
            headerHeight: 30,
            displayNewMarker: DeviceUtils.isFullScreenPageNav()? true: false,*/
        });

        this.displayMonthInBar(moment());
        /*} else {
            this.calendarEl.fullCalendar({
                lang: User.getLocale().toLowerCase(),
                header: {
                    right: 'prev month agendaWeek agendaDay next'
                },
                height: this.calendarEl.height(),
                timezone: 'UTC',
                defaultDate: moment(),
                firstDay: 1,
                editable: true,
                eventLimit: true, // allow "more" link when too many events
                views: {
                    month: {
                        titleFormat: '[Agenda -] MMMM YYYY',
                        columnFormat: 'dddd'
                    }
                },
                events: function (start, end, timezone, callback) {
                    me.refreshCurrentViewEvents(new SAITimePeriod(start, end), callback);
                },
                eventClick: me.onEventClicked.bind(me),
                dayClick: me.onDayClicked.bind(me),
                eventDrop: me.onEventDropResize.bind(me),
                eventResize: me.onEventDropResize.bind(me)
            });
        }*/
    }

    getFDCTitle(): Array<string> {
        let titleElements = [];
        let initProps = this.getConfig().getInitialPropsOverride();
        if (initProps !== undefined) {
            if (initProps === undefined || initProps === null || initProps['filterId'] !== undefined) {
                titleElements.push('ADRDOSDOSSIER.DLABEL');
            } else if (initProps['filter'] !== undefined) {
                if (initProps['filter']['GTPEVT.STATECOL'] != undefined) {
                    titleElements.push('ADRDOSDOSSIER.DLABEL');
                } else if (initProps['filter']['GTPEVT.STATECLIENT'] != undefined) {
                    titleElements.push('ADRCOLDOSSIER.DLABEL');
                }
            }
        }
        return titleElements;
    }

    displayHistory() {
        this.calendarEl.fullCalendar('destroy');
        var newHisto = new HistoryViewer({
            el: this.calendarEl,
            taskId: this.getConfig().getId(),
            gridId: this.getConfig().getNavigatorGridView(),
            filterId: this.getConfig().getNavigatorFilter()
        });
        newHisto.fetchData();
    }

    updateCalendarTitle(text) {
        if (DeviceUtils.isFullScreenPageNav()) {
            this.caltabBar.updateTabText('viewMode', text);
        } else {
            this.calendarEl.children('.calendarBanner').children('.title').text('Agenda - ' + text);
        }
    }

    displayMonthInBar(date) {
        var text = '';
        if (DeviceUtils.isFullScreenPageNav()) {
            text = date.format('MMM YY');
            text = text.substring(0, 1).toUpperCase() + text.substring(1);
        } else {
            text = date.format('MMMM YYYY');
            text = text.substring(0, 1).toUpperCase() + text.substring(1);
        }
        var index = text.indexOf('.');
        if (index > 0) {
            text = text.slice(0, index) + text.slice(index + 1);
        }
        this.updateCalendarTitle(text);
    }

    displayYearInBar(date) {
        var text = date.format('YYYY');
        this.updateCalendarTitle(text);
    }

    /**
     * Called when the calendar is displayed in the DOM.
     */
    onDOMUpdated() {
        super.onDOMUpdated();
        this.displayCalendar();
    }

    /**
     * Called when the user clicks on an event
     * @param {object} eventObject The original event object of fullcalendar
     * @param {object} jsEvent The javascript event associated with the click
     * @param {object} view The view associated with the event
     * @returns {Boolean} returns false to prevent follow url behavior of browser (cf fc doc)
     */
    onEventClicked(eventObject) {
        var me = this;
        var mode = 'tablet';
        if (DeviceUtils.isSmallDevice()) {
            mode = 'phone';
        }

        CClientConfiguration.getGridView(this.getConfig().getNavigatorGridView(), this.domainContext)
            .then((gridConfig) => {
                let keys = gridConfig.getDatafieldKeyColumns();

                let recordKey = this.buildRecordKey(eventObject.data, keys);

                this.buildTask(this.getConfig().getId(), TaskWindowType.FullSimplified, {})
                    .then((task) => {
                        let newPopup = this.modifyPopup = new TaskPopupView({
                            operationTitle: 'Modification',
                            taskView: task,
                            displayMode: DeviceUtils.getDisplayModeFromSize(),
                            domainContext: this.domainContext,
                            operationState: 'modify'
                        });
                        newPopup.on('modalWindowAction', this.handleModalWindowAction.bind(this));
                        newPopup.display();
                        task.makeMainRecordRetreivalCall(recordKey);
                    });


                /*
                var newPopup = this.modifyPopup = new RecordPopupView({
                    domainContext: this.domainContext,
                    displayMode: mode,
                    mode: 'getRecord',
                    operationTitle: 'Modification',
                    recordModelKeys: recordKey,
                    taskId: this.getConfig().getId(),
                    handleDocuments: true,
                    buttons: [
                        {
                            role: 'ok',
                            bootstrapClass: 'btn-success',
                            btnClass: 'recordFormAcceptBtn',
                            enableOnLoad: true,
                            disabled: false,
                            btnText: 'valider',
                            operation: me.onModifyPopupButtonClicked.bind(me, eventObject, 'ok'),
                            faIcon: 'fa-check-circle'
                        },
                        {
                            role: 'cancel',
                            bootstrapClass: 'btn-default',
                            btnClass: 'recordFormCancelBtn',
                            enableOnLoad: false,
                            disabled: false,
                            btnText: 'annuler',
                            operation: me.onModifyPopupButtonClicked.bind(me, eventObject, 'cancel'),
                            faIcon: 'fa-ban'
                        },
                        {
                            role: 'delete',
                            bootstrapClass: 'btn-default',
                            btnClass: 'recordFormDeleteBtn',
                            enableOnLoad: false,
                            disabled: false,
                            btnText: 'Supprimer',
                            operation: me.onModifyPopupButtonClicked.bind(me, eventObject, 'delete'),
                            faIcon: 'fa-trash'
                        }
                    ]
                });
                newPopup.display();*/
            });

        //returning false prevents wrong behavior of the browser in case of specified url
        //see http://fullcalendar.io/docs/mouse/eventClick/ for details
        return false;
    }

    handleModalWindowAction(result) {
        if (result !== NotificationManager.USER_CANCELLED) {
            this.refreshCurrentViewEvents(new SAITimePeriod(this.d3Calendar.getStart(), this.d3Calendar.getEnd()), this.calendarCallBack);
        }
    }

    /**
     * Called when the user clicks on an event
     * @param {object} date The date of the clicked day
     * @returns {Boolean} returns false to prevent follow url behavior of browser (cf fc doc)
     */
    onNewEventRequest(startDate, endDate?) {
        var me = this;
        var mode = 'tablet';
        if (DeviceUtils.isSmallDevice()) {
            mode = 'phone';
        }

        CClientConfiguration.getGridView(this.getConfig().getNavigatorGridView(), this.domainContext)
            .then((gridConfig) => {
                this.buildTask(this.getConfig().getId(), TaskWindowType.FullSimplified, {})
                    .then((task) => {
                        let newPopup = this.createPopup = new TaskPopupView({
                            operationTitle: 'Création',
                            taskView: task,
                            displayMode: DeviceUtils.getDisplayModeFromSize(),
                            domainContext: this.domainContext,
                            operationState: 'create'
                        })

                        newPopup.on('modalWindowAction', this.handleModalWindowAction.bind(this));
                        newPopup.display()
                            .then(() => {
                                task.createNewEntry(this.getEventDefaultValues(this, { start: startDate, end: endDate }));
                            });
                    });
            });

        /*new Promise((resolve, failure) => {
            var newPopup = this.createPopup = new RecordPopupView({
                displayMode: mode,
                mode: 'prepareNewEntry',
                operationTitle: 'Création',
                recordModelKeys: [],
                taskId: this.getConfig().getId(),
                handleDocuments: true,
                buttons: [
                    {
                        role: 'ok',
                        bootstrapClass: 'btn-success',
                        btnClass: 'recordFormAcceptBtn',
                        enableOnLoad: true,
                        disabled: false,
                        btnText: 'Créer',
                        operation: me.onCreationPopupButtonClicked.bind(me, date, 'ok'),
                        faIcon: 'fa-check-circle'
                    },
                    {
                        role: 'cancel',
                        bootstrapClass: 'btn-default',
                        btnClass: 'recordFormCancelBtn',
                        enableOnLoad: false,
                        disabled: false,
                        btnText: 'Annuler',
                        operation: me.onCreationPopupButtonClicked.bind(me, date, 'cancel'),
                        faIcon: 'fa-ban'
                    }
                ],
                domainContext: me.domainContext
            });
            //newPopup.onRecordLoaded = me.onRecordLoaded.bind(me, date);
            resolve(newPopup);
        }).then((popup:any) => { popup.display();})
            .catch(App.displayError);*/

        //returning false prevents wrong behavior of the browser in case of specified url
        //see http://fullcalendar.io/docs/mouse/eventClick/ for details
        return false;
    }

    getEventDefaultValues(task: TaskView, values: any): Array<any> {
        let initialValues = [];
        let intitialFilter: any = task.getConfig().getInitialPropsOverride() || {};
        if (intitialFilter.filter !== undefined) {
            for (let key in intitialFilter.filter) {
                let field = task.getConfig().getFieldFromDataField(key);
                if (field !== undefined) {
                    initialValues.push({
                        name: field.getId(),
                        datafieldId: key,
                        value: intitialFilter.filter[key].value
                    });
                }
            }
        }
        if (values['start'] !== undefined) {
            let startValue = values['start'];
            let startDataField = this.historyConfig['start'];
            let field = task.getConfig().getFieldFromDataField(startDataField);
            initialValues.push({
                name: field.getId(),
                datafieldId: startDataField,
                value: startValue.format('YYYY-MM-DD HH:mm:ss')
            });
        }
        if (values['end'] !== undefined) {
            let startValue = values['end'];
            let startDataField = this.historyConfig['end'];
            let field = task.getConfig().getFieldFromDataField(startDataField);
            initialValues.push({
                name: field.getId(),
                datafieldId: startDataField,
                value: startValue.format('YYYY-MM-DD HH:mm:ss')
            })
        }

        return initialValues;
    }

    /**
     * Called when the user drags and drops an event or resize it within the calendar
     * @param {object} event. The event in the calendar. Contains the new dates
     * @param {long} the delta in time
     * @param {function} revertFunc. If called, the event is moved back to its previous time
     */
    onEventDropResize(event, delta, revertFunc) {
        var startValue = event.start.format('YYYY-MM-DD HH:mm:ss');
        var endValue = event.end.format('YYYY-MM-DD HH:mm:ss');
        event.srcData.columns[this.historyConfig.start.value] = startValue;
        event.srcData.columns[this.historyConfig.end.value] = endValue;
        var values = new RecordModel(event.srcData.columns).outputAllValues();
        var allContexts = new Contexts();
        var recordContext = new Context();
        recordContext.setContextId('record');
        recordContext.addFields(values);
        allContexts.addContext(recordContext);
    }

    onDropSuccess(response) {
        //event dropped successfully
    }

    onRecordLoaded(date, recordView) {
        var startDateStr = date.format();
        var endDateStr = date.add(1, 'hour').format();
    }

    onModifyPopupButtonClicked(eventObject, action, evt) {
        var recordValues;
        if (action === 'ok') {
            //record validation. We get the values and send the modify on server
            //fixme
            recordValues = this.modifyPopup.getRecordView('1').getFieldsValues();
            this.createFromPopup(recordValues, 'modify');
            // return false to prevent the modal close
            return false;
        }

        if (action === 'delete') {
            //record validation. We get the values and send the modify on server
            //fixme
            recordValues = this.modifyPopup.getRecordView('1').getFieldsValues();
            DialogUtils.displayDialog({
                title: '',
                message: 'Voulez-vous vraiment supprimer cet évènement ?',
                cancelText: 'Non',
                confirmText: 'Supprimer'
            }, this.onDeleteConfirmation.bind(this, recordValues));
            // return false to prevent the modal close
            return false;
        }
    }

    onDeleteConfirmation(recordValues, action) {
        if (action === 'cancel') { return; }
        this.createFromPopup(recordValues, 'delete');
    }

    /*onCreationPopupButtonClicked(date, action, evt) {
        if (action === 'ok') {
            //record validation. We get the values and send the modify on server
            //fixme
            var recordValues = this.createPopup.getRecordView('1').getFieldsValues();
            this.createFromPopup(recordValues, 'new');
            // return false to prevent the modal close
            return false;
        }
    }*/

    onDayClicked() {
        return false;
    }

    createFromPopup(values, operation) {
        let submitRequest = new SubmitRecordRequest(this.domainContext, this.getConfig().getId());
        submitRequest.setOperation(operation);

        var recordContext = new Context();
        recordContext.setContextId('record');
        recordContext.addFields(values);
        submitRequest.setRecordContext(recordContext);

        var description;
        if (operation === 'delete') {
            description = 'Suppression en cours';
        } else if (operation === 'new') {
            description = 'Création en cours';
        } else if (operation === 'modify') {
            description = 'Modification en cours';
        } else {
            description = 'Opération en cours';
        }

        let loadingReqId;
        LoadingMask.requestLoading(description)
            .then(function (reqId) {
                loadingReqId = reqId;
                return Server.performRequest(submitRequest)
            })
            .then(this.onModifySuccess.bind(this))
            .catch(App.displayErrorMessage)
            .then(function () { LoadingMask.hide(loadingReqId); });
    }

    onModifySuccess() {
        this.d3EventManager.resetExistingEvents();
        this.d3Calendar.resetInterface();
        if (this.modifyPopup !== undefined) { this.modifyPopup.$el.modal('hide'); }
        if (this.createPopup !== undefined) { this.createPopup.$el.modal('hide'); }
    }

    /**
     * This function calls the server with the currently displayed moment of time
     *
     * @param {long} start The first day of the range
     * @param {long} end The last day of the range
     */
    refreshCurrentViewEvents(period, callback): void {
        //Retreiving visible interval to get the events
        let dataRequest = new GetGridDataRequest(this.domainContext, this.getConfig().getId(), this.getConfig().getNavigatorGridView());
        // No limit on the number of events to retrieve
        dataRequest.setRange(0, -1);
        dataRequest.setFilterId(this.getConfig().getNavigatorFilter());

        var filterContext = new Context();
        filterContext.setContextId('filter');
        let finalFilterFields = [];

        let initialPropsOverride = this.getConfig().getInitialPropsOverride();
        if (initialPropsOverride) {
            let filterElements = initialPropsOverride['filter'];
            for (let key in filterElements) {
                if (filterElements[key].value) {
                    finalFilterFields.push({
                        datafieldId: filterElements[key].datafieldId,
                        key: filterElements[key].datafieldId,
                        value: filterElements[key].value
                    });
                }
            }
        }

        filterContext.addFields(finalFilterFields);
        filterContext.addFields([
            {
                datafieldId: this.historyConfig.start,
                key: this.historyConfig.start,
                value: '<= ' + period.getEnd().format('YYYY-MM-DD HH:mm:ss')
            }, {
                datafieldId: this.historyConfig.end,
                key: this.historyConfig.end,
                value: '>= ' + period.getStart().format('YYYY-MM-DD HH:mm:ss')
            }
        ]);
        dataRequest.setFilterContext(filterContext);

        let loadingReqId;
        LoadingMask.requestLoading('Chargement en cours')
            .then(function (reqId) {
                loadingReqId = reqId;
                return Server.performRequest(dataRequest)
            })
            .then(this.onRecordReceived.bind(this, period, callback))
            .catch(App.displayErrorMessage)
            .then(function () { LoadingMask.hide(loadingReqId); });
    }

    /**
     * Upon receival of records, the calendar must display them. This function
     * thus builds Event Object and give them to fullcalendar for rendering
     *
     * @param {server response} response
     */
    onRecordReceived(period, callback, response): void {
        this.computeHeadersMapping(response);

        // Get the datafield used for the legend
        this.fetchedHeaders = new Header(response.headers);
        let promisesList = []
        let colorLegendDatafield = this.getDatafieldForTableColumn(this.historyConfig.colorLegend);
        if (colorLegendDatafield) {
            promisesList.push(CClientConfiguration.getDataField(colorLegendDatafield, this.domainContext, this.getConfig()));
        }

        for (let i in this.historyConfig.description) {
            let descriptionElement = this.historyConfig.description[i];
            let elemDataField = this.getDatafieldForTableColumn(descriptionElement);
            if (elemDataField) {
                promisesList.push(CClientConfiguration.getDataField(elemDataField, this.domainContext, this.getConfig()));
            }
        }
        Promise.all(promisesList)
            .then(() => {
                this.fetchedEvents = [];
                for (var index in response.items) {
                    var item = response.items[index];
                    var newEvent = this.buildNewEvent(item);
                    //We don't want sticky events as we want to permanently refresh all events when changing period
                    this.fetchedEvents.push(newEvent);
                }

                if (this.calendarCallBack) {
                    callback(period, this.fetchedEvents);
                } else {
                    this.calendarEl.fullCalendar('renderEvents', this.fetchedEvents);
                }
            })
    }

    /**
     * Retreives which columns is mapped to specific fullCalendar entries
     * @param {object} response The server response to the getGridData call
     */
    computeHeadersMapping(response) {
        if (!this.headersMapping) {
            this.headersMapping = {};
            for (var key in response.headers) {
                var currentCol = response.headers[key];
                this.headersMapping[currentCol.datafieldId] = currentCol.dataIndex;
            }
        }
    }

    /**
     * Simple function that builds an event based on the global mapping of columns
     * @param {object} item Object that contains all values of an event record
     * @returns Event Object structure for fullCalendar
     */
    buildNewEvent(item) {
        let newEvent = new SAIEvent(item.columns[this.headersMapping[this.historyConfig.id]].value,
            moment(item.columns[this.headersMapping[this.historyConfig.start]].value),
            moment(item.columns[this.headersMapping[this.historyConfig.end]].value),
            item);
        let title = this.buildTitle(item);
        newEvent.setTitle(title);
        let description = this.buildStringFromConfig(item, this.historyConfig.description, '');
        newEvent.setDescription(description !== '' ? description : undefined);
        newEvent.setPopupTitle(description);

        if ((item.columns[this.headersMapping[this.historyConfig.colorLegend]].comboAttributes !== undefined
            && item.columns[this.headersMapping[this.historyConfig.colorLegend]].comboAttributes.color !== undefined)
            || item.columns[this.headersMapping[this.historyConfig.colorLegend]].value.indexOf('VAC') === 0) {
            if (item.columns[this.headersMapping[this.historyConfig.colorLegend]].value === 'VACREF') {
                newEvent.setColor('#d70a53');
            } else if (item.columns[this.headersMapping[this.historyConfig.colorLegend]].value === 'VAC') {
                newEvent.setColor('#ffcc00');
            } else if (item.columns[this.headersMapping[this.historyConfig.colorLegend]].value === 'VACPOS') {
                newEvent.setColor('#ffb347');
            }
        } else {
            if (this.headersMapping[this.historyConfig.colorLegend]) {
                let colorLegend = item.columns[this.headersMapping[this.historyConfig.colorLegend]].value;
                newEvent.setColorLegend(colorLegend);
                var value = item.columns[this.headersMapping[this.historyConfig.colorKey]].value;
                newEvent.setColor(this.getEventColor(value));
            } else {
                console.warn('no color switch mapping defined for this calendar');
            }
        }

        return newEvent;
    }

    getDatafieldForTableColumn(column) {
        let mappedColumn = this.fetchedHeaders.getColumnsMap()[column];
        if (mappedColumn) {
            return mappedColumn.getDatafieldId();
        }
        return undefined;
    }

    getFieldHumanValue(item, columnId, comboMap) {
        let value = item.columns[columnId].value;

        let datafield = this.getDatafieldForTableColumn(columnId);
        let enumElem = comboMap && comboMap[datafield] ? comboMap[datafield].getElement(value) : undefined;
        if (enumElem) {
            value = enumElem.getLabel();
        }

        return value;
    }

    buildTitle(item) {
        let result = '';
        for (let i in this.historyConfig.title) {
            let value = item.columns[this.headersMapping[this.historyConfig.title[i]]].value;
            if (value) {
                if (result.length > 0) {
                    result += ', ';
                }
                result += value;
            }
        }
        if (result.length === 0) {
            result = 'Sans titre';
        }
        return result;
    }

    buildStringFromConfig(item, configItem: Array<any>, defaultValue: string): string {
        let result = '';
        for (let i in configItem) {
            let value = item.columns[this.headersMapping[configItem[i]]].value;
            if (value) {
                if (result.length > 0) {
                    result += ', ';
                }
                result += value;
            }
        }
        if (result.length === 0) {
            result = defaultValue;
        }
        return result;
    }

    /**
     * Computes the event color based on the value given. This value is usually
     * an enum item. The color can be hardcoded for some tasks or computed based
     * on the hash of the value.
     *
     * @param {string} switchValue
     * @returns the color to set to the event
     */
    getEventColor(switchValue) {
        let taskId = this.getConfig().getId();
        if (taskId === 'GTP40' || taskId === 'GTPAM') {
            //[HARDCODED PALETTE FOR CRECHES]
            let palette = {
                'P.REELLE': '#e6e6e6',
                'P.C_': '#ff8c00',
                'P.PRESTA': '#008c00',
                'P.': '#008cd3',
                'A.C_': '#ff0000',
                'A.': '#351759'
            }

            let colorValue = palette[switchValue];
            if (colorValue !== undefined) {
                return colorValue;
            } else {
                for (let key in palette) {
                    if (switchValue.startsWith(key)) {
                        return palette[key];
                    }
                }
            }
        }

        // Get the color of the event based on the hash of the switc value
        return this.getColorFromString(switchValue);
    }

    handleTaskHeaderAction(action, actionArguments): void {
        if (action === 'close') {
            App.popTask();
        }
    }

    setupFullCalendar() {

    }

    setupMonthAgenda() {

    }

    buildRecordKey(recordData, keyDatafields: Array<DataField>) {
        let keys = [];
        for (let i in keyDatafields) {
            let df = keyDatafields[i];
            let colId = this.headersMapping[df.getId()];
            let value = recordData.columns[colId].value;
            keys.push({
                datafieldId: df.getId(),
                key: colId,
                value: value
            });
        }
        return keys;
    }

    buildTask(taskName: string, windowType: TaskWindowType, presentation: { [propId: number]: boolean }): Promise<TaskView> {
        let newRequest = new GetTaskRequest(this.domainContext, taskName);
        let loadingReqId;
        return new Promise((accept, reject) => {
            LoadingMask.requestLoading('Chargement de la tâche')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return Promise.all([Server.performRequest(newRequest), CClientConfiguration.getTask(taskName, this.domainContext)]);
                })
                .then(([result, task]) => {
                    accept(TaskFactory.createTask(task, result, this.domainContext, { windowType: windowType, presentation: presentation },
                        this.initializeTaskPresentation));
                })
                .catch((error) => {
                    App.displayErrorMessage(error);
                    reject(error);
                })
                .then(() => { LoadingMask.hide(loadingReqId); });
        });
    }

    initializeTaskPresentation(config: Task) {
        config.setInputInterface('Simple');
    }

    getColorFromString(label: string): string {
        var hash = this.hashCode(label);
        var tmpColor = ((hash) >>> 0).toString(16).slice(-6);
        var hexColor = '#' + tmpColor[4] + tmpColor[5] + tmpColor[2] + tmpColor[3] + tmpColor[0] + tmpColor[1];
        var rgb = ColorUtils.hexToRgb(hexColor);
        var hsl = ColorUtils.rgbToHsl(rgb.r, rgb.g, rgb.b);
        hsl.l = hsl.l * 0.65;
        var modRgb = ColorUtils.hslToRgb(hsl);
        return ColorUtils.rgbToHex(modRgb.r, modRgb.g, modRgb.b);
    }

    // Get the hashCode of a string
    hashCode(inputString: string): number {
        var hash = 0;
        if (inputString.length === 0) { return hash; }
        for (var i = 0; i < inputString.length; i++) {
            var chr = inputString.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }
}

export { CalendarTask };
