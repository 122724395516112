module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <div class="input-group date" data-id="' +
__e( fieldState.getId() ) +
' " >\n        <input type="text" class="form-control changeableField" value="' +
__e( moment(fieldState.getValue(), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' readonly><span class="input-group-addon"><span class="glyphicon-calendar glyphicon"></span></span>\n    </div>\n</div>\n';

}
return __p
}