'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';

class FieldOperationRequest extends TaskRequest{
    private fieldId : any;

    constructor(service, domain, task){
        super(service,'POST', domain, task);
    }

    setRecordContext(context){
        this.record = context;
    }

    setPageContext(context){
        this.page = context;
    }

    setFieldId(newId){
        this.fieldId = newId;
    }

    getSerializable(): {[prop:string]: any} {
        let content = super.getSerializable();
        Object.assign(content.request1, {
            fieldId: this.fieldId
        });
        return content;
    }
}

export default FieldOperationRequest;