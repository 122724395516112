'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class FilterRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('filter', domain, _id);
    }
}

export { FilterRequest };