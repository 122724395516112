'use strict';

import * as Backbone from 'backbone';

class PendingDocument extends Backbone.Model {
    documentId : any;
    operation : any;
    pendingId : any;

    constructor(options) {
        super(options);
        this.documentId = options.documentId;
        this.operation = options.operation;
        this.pendingId = options.pendingId;
    }

    toJSON(param : any) {
        return {'documentId': this.documentId, 'operation': this.operation, 'pendingId': this.pendingId};
    }
}

export default PendingDocument