'use strict';

import RecordView from 'views/record/Record';
import DeviceUtils from 'utils/DeviceUtils';

import Server from 'server/Server';
import { SAIView } from '../../Additions';

class PageView extends SAIView {

    initialize (options) {
        this.options = options;
        this.taskConfig = options.taskConfig;
        this.tableConfig = options.tableConfig;
        this.page = options.page;
        this.domainContext = options.domainContext;
        this.template = DeviceUtils.isFullScreenPageNav() ? require('ejs-loader!templates/Page_mobile.ejs') : require('ejs-loader!templates/Page.ejs');
        this.newTemplate = DeviceUtils.isFullScreenPageNav() ? require('ejs-loader!templates/NewPage_mobile.ejs'): require('ejs-loader!templates/NewPage.ejs');

        this.supportedMimeType = ['image/png','image/gif','image/jpeg','image/bmp'];
    }

    render () {
        let domiCol = undefined;
        let headerTextColor = undefined;

        let currentTitle = this.page.getTitle();
        if(!currentTitle) {
            currentTitle = this.page.getDataPage().getLabel();
        }
        if(currentTitle && currentTitle.indexOf('(') > -1){
            currentTitle = currentTitle.substring(0, currentTitle.indexOf('('));
        }

        var tplToUse = this.tplMode === 'newEntry'? this.newTemplate: this.template;

        if(this.tplMode === 'newEntry'){
            currentTitle = 'Nouveau : '+currentTitle;
        }

        this.$el.html(tplToUse({
            title: currentTitle,
            icon: this.tableConfig.getTheme(this.page.getDataPage().getThemeID()).getIcon(),
            optionsIcon: 'images/options.png',
            validationIcon: 'images/save.png',
            dominantColor: domiCol ? domiCol : 'none',
            headerTextColor: headerTextColor ? headerTextColor : '#333',
            domainContext: this.domainContext
        }));

        this.newPageValidationBtn = this.$el.find('.newPageValidation');
        this.newPageValidationBtn.click(this.onValidationClick.bind(this));

        this.pageValidationBtn = this.$el.find('.pageValidation');
        this.pageValidationBtn.click(this.onValidationClick.bind(this));

        this.pageHeader = this.$el.find('.pageHeader');
        var recordHolder = this.$el.find('.recordContainer');
        let recordRendering = $('<div></div>').addClass('recordContainer');
        let pageId = this.page.getId();
        let configPage = this.taskConfig.getPage(pageId);
        if(!this.recordView){
            var displayMode;

            if (DeviceUtils.isMediumDevice() || DeviceUtils.isSmallDevice() || DeviceUtils.hasMediumSize(recordHolder)){
                displayMode = 'tablet';
            }else{
                displayMode = 'desktop';
            }
            this.recordView = new RecordView({
                displayMode:displayMode,
                el: recordRendering,
                groups: configPage.getGroups(),
                dominantColor: domiCol,
                contextType: 'page',
                domainContext: this.domainContext,
                taskConfig: this.taskConfig
            });
        }else{
            this.recordView.setGroups(configPage.getGroups());
            this.recordView.setElement(recordRendering);
            this.recordView.setDominantColor(domiCol);
        }
        this.recordView.setValuesAndPositions(this.page.getGroups());
        this.recordView.render();
        recordHolder.replaceWith(recordRendering);
        this.recordHolder = recordRendering
        var me = this;
        var holder = $(this.$el.find('.pageIcon'));
        var defaultImgSrc = Server.getTokenedUrl('configuration/' + me.domainContext.getId() + '/image/highres,big/128,100,64,36/' + this.tableConfig.getTheme(this.page.getDataPage().getThemeID()).getIcon());
        var prom = new Promise((resolve, reject) => {
            var mobileIconeSrc = defaultImgSrc + '-mobile';
            var xhr = new XMLHttpRequest();
            xhr.open('GET', mobileIconeSrc);
            xhr.onload = () => {
                resolve([xhr.response, mobileIconeSrc, defaultImgSrc]);
            };
            xhr.send();
        })
            .then(([response, mobileIconeSrc, currentImg]) => me.handleGetImg([response, mobileIconeSrc, currentImg, holder])
            ).then((imgSrc) => {
                var imgDiv = $('<img class="pageHeader-style" src="'+imgSrc+'"/>');
                holder.prepend(imgDiv);
            }).catch((error) => {
                console.error(error);
            });
    }

    dispatchUpdateEvent(evt) {
        dispatchEvent(evt);
    }

    onValidationClick (evt){
        if(!this.operating){
            this.operating = true;
            this.trigger('validationClicked');
            this.operating = false;
        }
    }

    setRecordView (groups){
        this.recordView.setGroups(groups);
        this.render();
    }

    deleteRecordViewContent (){
        this.recordView.setGroups([]);
        this.recordView.render();
    }

    setPage (selectedPage){
        this.page = selectedPage;
    }

    getPage() {
        return this.page;
    }

    setKeys(keys) {
        this.pageKeys = keys;
        if(this.recordView) {
            this.recordView.setKeys(keys);
        }
    }

    getKeys() {
        return this.pageKeys;
    }

    setMode (mode){
        this.tplMode = mode;
    }

    resize (mode, optheight){
        if(this.recordView){
            this.recordView.resize(mode, optheight);

            this.recordView.expandGroups();
        }
    }

    handleGetImg([response, mobileIconeSrc, defaultImg, holder]) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (response.indexOf('<svg') >= 0) {
                try {
                    var randNum = Math.floor(Math.random() * 100000);
                    response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                    response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                    var xmlSvg = $($.parseXML(response));
                    var size = '36px';
                    xmlSvg.find('svg').attr('class', 'pageHeader-style').attr('width', size).attr('height',size);
                    holder.prepend(xmlSvg.find('svg'));
                } catch(err) {
                    resolve(defaultImg);
                }
            } else if(me.supportedMimeType.includes(response.type)){
                resolve(mobileIconeSrc);
            } else {
                resolve(defaultImg);
            }
        });
    }
}

export default PageView;
