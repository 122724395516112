module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ficheSection ' +
((__t = (classToAdd)) == null ? '' : __t) +
'">\n    ';
 if( title !== '' ) { ;
__p += '\n        <div class="ficheSectionTitle">' +
((__t = (title)) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n    <div class="innerElsContainer">\n    </div>\n</div>';

}
return __p
}