module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal fade" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" role="dialog" tabindex="-1" aria-labelledby="loginPreview" aria-hidden="true" data-backdrop="static" data-keyboard="false" >\n    <div class="modal-dialog loginPreviewDialog">\n        <div class="modal-content fullModalContent pageIconPreviewContent">\n            <div class="modal-body loginPreviewBody">\n                <form action method="post" target="postHiddenIFrame" class="animated loginFormCont">\n                    <img class="loginLogo" src="' +
((__t = (applogo)) == null ? '' : __t) +
'">\n                    <div class="form-group">\n                        <input type="text" name="login_key" value="" class="login-mail form-control" tabindex="1" placeholder="Nom d\'utilisateur ou e-mail">\n                    </div>\n                    <div class="form-group">\n                        <input type="password" class="login-password form-control" name="login_password" tabindex="2" placeholder="Mot de passe">\n                    </div>\n                    <div class="invaliduserpass" style="display: none;">Utilisateur ou mot de passe invalide</div>\n                    <div class="form-group">\n                        <button type="submit" name="login_signin" value="Login" class="loginBtn form-control btn-block btn-primary" tabindex="3">Login</button>\n                    </div>\n                    <div class="login-progress" style="line-height: 72px;"></div>\n                </form>\n                <iframe name="postHiddenIFrame" class="postHiddenIFrame"></iframe>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}