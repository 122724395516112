'use strict';

let $ = require('jquery');
import { SAIView } from  'Additions';
import App from 'App';
import Server from 'server/Server';
import { DomainContext } from '../parametrage/DomainContext';
import {PasswordUpdateRequest} from '../server/protocol/request/auth/PasswordUpdateRequest';
import User from 'User';
import ColorUtils from 'utils/ColorUtils';
import ServerUtils from '../utils/ServerUtils';
import ToastFactory from 'utils/ToastFactory';
import { LoginRequest } from '../server/protocol/request/auth/LoginRequest';

class PasswordUpdateView extends SAIView {
    domainContext : DomainContext;

    constructor(options?) {
        super(options);
        this.domainContext = options.domainContext;
        this.template = require('ejs-loader!templates/PasswordUpdate.ejs');
    }

    render() : any {
        this.$el = $(this.template({
            currentPasswordLabel: 'Mot de passe actuel',
            newPasswordLabel: 'Nouveau mot de passe',
            confirmPasswordLabel : 'Confirmation',
            currentDomain: User.getCurrentDomain(),
            buttonsCancelLabel: 'Annuler',
            buttonsValidateLabel: 'Valider',
            title: 'Changement de mot de passe',
            operationColor: '#F6A115',
            colorUtils: ColorUtils
        }));
        

        this.$el.find('.btn[data-id="CANCEL"]').on('click',App.hidePasswordUpdate.bind(App));
        this.$el.find('.btn[data-id="VALIDATE"]').on('click',this.executeUpdate.bind(this));

        this.$el.appendTo(this.options.el);
    }

    hide() :void {
        this.$el.removeClass('show');
        this.$el.hide();
    }

    executeUpdate() {
        $('.error-message').hide();
        let currentPassword = this.$el.find('input[data-id="CURRENT_PASSWORD"]').val() as string;
        let newPassword = this.$el.find('input[data-id="NEW_PASSWORD"]').val() as string;
        let newPasswordConfirmation = this.$el.find('input[data-id="CONFIRM_PASSWORD"]').val() as string;

        if(newPassword === newPasswordConfirmation && newPassword !== '') {
            let request = new PasswordUpdateRequest(sessionStorage.saiUserId, currentPassword, newPassword);
            ServerUtils.loadRequest('Mise à jour du mot de passe', request, () => {
                this.updateLoginToken(sessionStorage.saiUserId, newPassword);
                ToastFactory.displayText('Mot de passe mis à jour', 2000);
                setTimeout(this.hide.bind(this),2000);
            });
        } else {
            if(newPassword === '') {
                $('.error-message').text('Le mot de passe ne doit pas être vide');
            } else {
                $('.error-message').text('Le mot de passe et la confirmation ne correspondent pas');
            }
            $('.error-message').show();
        }
    }

    private updateLoginToken(userId:string, password:string):void {
        // remove the current token 
        Server.setToken(null);
        let loginMessage = new LoginRequest(userId, password, null);
        Server.performRequest(loginMessage)
            .then((response) => {
                sessionStorage.saiUserToken = JSON.stringify(response.token);
            })
            .catch((error) => {
                App.displayError(error);
            });
    }
}

export default PasswordUpdateView