module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="record-header-icon">\n    <div class="record-header-thumbContainer">\n        ';
 for(var key in overlay) { ;
__p += '\n        <div class="thumbImage ' +
((__t = (key)) == null ? '' : __t) +
'" style="background-image : url(\'' +
((__t = (server.getTokenedUrl('configuration/' + domainContext.getId() + '/image/highres,big,record/128,100,64,36,32/' + overlay[key]))) == null ? '' : __t) +
'\')"></div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n<div class="record-header-texts">\n    <div class="record-header-title"></div>\n    <div class="record-header-text"></div>\n</div>\n';

}
return __p
}