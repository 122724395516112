
class Renderer{
    constructor(options){
        this.initialize(options || {});
    }

    initialize(options){
        this.lineStyle = options.lineStyle;
        this.lineExpanse = options.lineExpanse;
    }

    /**
     * Renders the base skeleton of the list
     */
    renderCore(){
        console.warn('[renderer] Default rendering does nothing');
    }

    /**
     * Renders each item of the list and returns the list of jquery
     * elements representing the items.
     */
    renderItems(rows, loadMoreCallback, clickHandler){
        console.warn('[renderer] Default rendering does nothing');
        return [];
    }

    /**
     * Adds something to allow to load the next packet of data
     */
    addLoadMore(loadMoreCallback){
        console.warn('[renderer] Default rendering does nothing');
    }

    /**
     * Remove the component that allows to load more data
     */
    removeLoadMore(){
        console.warn('[renderer] Default rendering does nothing');
    }

    /**
     * Sets the root tag of the rendering
     */
    setElement(element){
        this.element = element;
    }

    setHeaders(headers){
        this.headers = headers;
    }

    setFormatter(formatter){
        this.formatter = formatter;
    }

    resetData(){
        console.warn('[renderer] Default rendering does nothing');
    }

    setPopupMenu(popupMenu) {
        this.popupMenu = popupMenu;
    }
}

export default Renderer;