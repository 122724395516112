'use strict';

import FieldView from '../Field';
import App from 'App';
import Config from 'Config';
import DeviceUtils from 'utils/DeviceUtils';
import I18NUtils from 'utils/i18n/I18NUtils';
import User from 'User';
const path = require('path');

export default class SainetFile extends FieldView {
    protected uploadedFiles: Array<any> = [];
    private state: string;
    private progress: number;
    protected dominantColor: any;
    private allowedFileType: any;
    
    constructor(options) {
        super(options);
        this.on('fileLoaded', this.onFileLoaded.bind(this));
        this.dominantColor = options.dominantColor || Config.appTheme;
        this.parseFileType();
    }

    render() : any {
        super.render();
        this.$el.find('input').off('change.fileupload');
        this.$el.find('input').on('change.fileupload', this.autoUploadFiles.bind(this));

        this.$el.css('height', 'unset');
        this.$el.css('margin-bottom', '10px');
        this.$el.find('.uploaded-file-item').on('click', 'i', this.deleteFile.bind(this));
    }

    protected getRenderingModel(): {[key:string]: any} {
        let data = super.getRenderingModel();
        data.uploadLabel = 'Charger un fichier';
        data.allowedFileType = this.allowedFileType && this.allowedFileType.length > 0 ? this.allowedFileType.join(',') : 'image/*,.pdf,.doc,.docx';
        return data;
    }

    autoUploadFiles() {
        this.$el.find('.field-loading').show();
        this.uploadFiles();
    }

    deleteFile() {
        this.uploadedFiles = [];
        this.setModelValue(undefined);
        this.$el.find('input').val('');
        this.$el.find('.uploaded-file').empty();
        this.$el.find('.uploaded-file').hide();
        this.$el.find('.upload-button-label').show();
    }

    uploadFiles(): Promise<void> {
        let me = this;
        let files = me.$el.find('input').prop('files');
        var file = files[0];
        var uploadFileName = file.name;
        var uploadFileType = file.type;
        if(!this.isValidFileType(uploadFileType, uploadFileName)) {
            var error = I18NUtils.getMessage('INVALID_UPLOAD_FILE_FORMAT', User.getLocale(), { 'format': this.allowedFileType.join(', ')});
            this.$el.find('input').val('');
            App.displayErrorMessage(error);
            return;
        }
        if(uploadFileType.match(/image\//)) {
            var reader = new FileReader();
            reader.onloadend = function (readerEvent: any) {
                var image = new Image();
                image.onload = function (imageEvent) {
                    // Resize the image
                    var canvas = document.createElement('canvas'),
                        maxSize = 1024,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    var dataUrl = canvas.toDataURL();
                    var resizedImage = me.dataURLToBlob(dataUrl);
                    me.trigger('fileLoaded', resizedImage, uploadFileName);
                }
                image.src = readerEvent.target.result;
            }
            reader.readAsDataURL(file);
        } else {
            // Check the size
            var fileSizeMo = file.size/1024/1024;
            if(fileSizeMo > 5) {
                // File size > 5 Mo
                var error = I18NUtils.getMessage('INVALID_UPLOAD_FILE_SIZE', User.getLocale(), { 'size' : '5Mo'});
                this.$el.find('input').val('');
                App.displayErrorMessage(error);
                return;
            }
            this.putFile(file);
        }
    }

    onFileLoaded (blob, filename) {
        var newFile = new File([blob],filename);
        this.putFile(newFile);
    }

    putFile(file: File) {
        App.putFile(file, uploadResult => this.onFileAdded(uploadResult, file.name), this.onFileProgress.bind(this), this.domainContext);
    }

    onFileAdded (resp, filename) {
        this.state = 'uploaded';
        this.uploadedFiles.push(resp);
        this.$el.trigger('SAIFieldUpdate');
        this.displayUploadedFile(filename);
        this.setModelValue(resp.id);
    }

    onFileProgress (evt) {
        if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total * 100;
            this.progress = percentComplete;
        }
    }

    displayUploadedFile(filename) {
        var fileContainer = this.$el.find('.uploaded-file');
        fileContainer.append('<span class="uploaded-file-item" id="'+this.uploadedFiles[0].id+'">'
        +'<i class="fa fa-times" aria-hidden="true"></i><span class="filename">'+filename+'</span>'
        +'</span>');
        var item = this.$el.find('.uploaded-file');
        var uploadButton = this.$el.find('label.upload-button-label');
        item.css('margin-left', uploadButton.css('margin-left'));
        item.css('width', uploadButton.css('width'));
        item.css('height', uploadButton.css('height'));
        item.css('margin-top', DeviceUtils.isMobile() ? '22px': uploadButton.css('margin-top'));
        item.css('background-color', this.dominantColor);
        item.find('.filename').css('width', Number.parseInt(uploadButton.css('width')) - 20);
        item.find('i.fa').on('click', this.deleteFile.bind(this));
        fileContainer.show();
        this.$el.find('.upload-button-label').hide();
    }

    getPendingDocuments () {
        return this.uploadedFiles;
    }

    dataURLToBlob(dataUrl) {
        var BASE64_MARKER = ';base64,';
        var parts;
        var contentType;
        var raw;
        if (dataUrl.indexOf(BASE64_MARKER) == -1) {
            parts = dataUrl.split(',');
            contentType = parts[0].split(':')[1];
            raw = parts[1];
            return new Blob([raw], {type: contentType});
        }

        parts = dataUrl.split(BASE64_MARKER);
        contentType = parts[0].split(':')[1];
        raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    }

    renderPosition() {
        super.renderPosition();
        var inputEl = this.$el.find('input');
        var uploadButton = this.$el.find('label.upload-button-label');
        uploadButton.css('background-color', this.dominantColor);
        if(DeviceUtils.isMobile()) {
            uploadButton.css('display', 'block');
        } else {
            uploadButton.css('margin-left', inputEl.css('margin-left'));
            uploadButton.css('width', inputEl.css('width'));
            uploadButton.css('height', inputEl.css('height'));
            uploadButton.css('margin-top', inputEl.css('margin-top'));
        }
    }


    parseFileType() {
        // The file format need to be converted from the VCL format to html format
        // 'Text files (*.txt)|*.TXT' need to be converted to '.TXT'
        var rawType = this.getConfig().getLinkedDataField().getFileType();
        var regex = /\|(.+?(\..+?))(?:$| |,|\|)/g;
        var matches = [];
        var match = regex.exec(rawType);
        while (match !== null) {
            matches.push.apply(matches, match[2].toLocaleLowerCase().split(';*'));
            match = match = regex.exec(rawType);
        }
        this.allowedFileType = matches;
    }

    isValidFileType(filetype, filename):boolean {
        if(this.allowedFileType.includes('.*')) {
            return true;
        }
        switch(filetype) {
            case 'image/bmp':
                return this.allowedFileType.includes('.bmp');
            case 'text/css':
                return this.allowedFileType.includes('.css');
            case 'text/csv':
                return this.allowedFileType.includes('.csv');
            case 'application/msword':
                return this.allowedFileType.includes('.doc');
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return this.allowedFileType.includes('.docx');
            case 'image/gif':
                return this.allowedFileType.includes('.gif');
            case 'text/html':
                return this.allowedFileType.includes('.html') || this.allowedFileType.includes('.htm');
            case 'image/jpeg':
                return this.allowedFileType.includes('.jpg') || this.allowedFileType.includes('.jpeg');
            case 'image/png':
                return this.allowedFileType.includes('.png');
            case 'application/pdf':
                return this.allowedFileType.includes('.pdf');
            case 'image/svg+xml':
                return this.allowedFileType.includes('.svg');
            case 'application/zip':
                return this.allowedFileType.includes('.zip');
            case 'text/plain':
                return this.allowedFileType.includes('.txt');
            default:
                var ext = path.extname(filename).toLocaleLowerCase();
                return this.allowedFileType.includes(ext);
        }
    }
}
