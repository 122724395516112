module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tab-container">\n    <div class="leftTabs">\n        ';
 for(var key in leftTabs) { ;
__p += '\n            ';
 if(!leftTabs[key][size+'Grouped']){ ;
__p += '\n                ' +
((__t = ( btnTpl(leftTabs[key]) )) == null ? '' : __t) +
' \n            ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n    <div class="centerTabs">\n        ';
 for(var key in centerTabs) { ;
__p += '\n            ';
 if(!centerTabs[key][size+'Grouped']){ ;
__p += '\n                ' +
((__t = ( btnTpl(centerTabs[key]) )) == null ? '' : __t) +
' \n            ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n    <div class="rightTabs">\n        ';
 for(var key in rightTabs) { ;
__p += '\n            ';
 if(!rightTabs[key][size+'Grouped']){ ;
__p += '\n                ' +
((__t = ( btnTpl(rightTabs[key]) )) == null ? '' : __t) +
' \n            ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
}