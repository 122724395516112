'use strict';

import { SimpleTask } from 'views/tasks/Simple';
import { TaskPresentation, TaskWindowType } from './Task';
import App from 'App';

class DoubleTask extends SimpleTask {
    public initInnerTaskProperties(): void{
        this.taskType = 'Double';
        this.className = 'doubleTask';
    }

    public handleTaskHeaderAction (action): void {
        if (action === 'close') {
            App.popTask();
        }
    }

    public initPresentation(): number {
        return super.initPresentation() | TaskPresentation.ShowSubNavigator | TaskPresentation.ShowSubRecordHeader;
    }

    public getRemovedPresentationFromType(type): number {
        let flags = super.getRemovedPresentationFromType(type);
        switch(type) {
            case TaskWindowType.Simplified:
                return flags | TaskPresentation.ShowSubNavigator;
            case TaskWindowType.FullSimplified:
            case TaskWindowType.MicroPopup:
                return flags | TaskPresentation.ShowSubNavigator | TaskPresentation.ShowSubRecordHeader;
        }
        return 0;
    }
}

export { DoubleTask };
