'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import Context from 'models/context/Context';
import { DomainContext } from '../../../../parametrage/DomainContext';

class SubmitPageRequest extends TaskRequest{

    constructor(domain:DomainContext, task){
        super('submitPage','POST', domain, task);
    }

    setOperation(operation){
        this.operation = operation;
    }

    setPendingFileList(documents){
        this.documents = documents;
    }

    getSerializable(){
        return {
            request1: {
                record: this.record? this.record.toJSON(): undefined,
                page: this.page? this.page.toJSON(): undefined,
                recordOperation: this.operation,
                pendingFileList: this.documents,
                message: this.message ? this.message : undefined
            }
        };
    }
}

export default SubmitPageRequest;