module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="task-panel">\n    ';
 if( window.App.getConfig().displayLoginBar ) { ;
__p += '\n        <div class="navbar-wrapper">\n            <div class="container">\n                <nav class="navbar navbar-inverse navbar-static-top" role="navigation">\n                    <div class="container">\n                        <div class="navbar-header">\n                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#loginNavbar" aria-controls="navbar">\n                                <span class="sr-only">Toggle navigation</span>\n                                <span class="icon-bar"></span>\n                                <span class="icon-bar"></span>\n                                <span class="icon-bar"></span>\n                            </button>\n                            ';
 if ( this.window.App.getConfig().displayHomePage ) { ;
__p += '\n                            <img class="mainlogo" src="images/logo-navbar.png">\n                            ';
 } else { ;
__p += '\n                            <img class="mainlogo" src="' +
((__t = (applogo)) == null ? '' : __t) +
'">\n                            ';
 } ;
__p += '\n                        </div>\n                        <div id="loginNavbar" class="navbar-collapse collapse">\n                            <ul class="nav navbar-nav">\n                                <li><a href="#home">HOME</a></li>\n                                <li><a href="#offre">OFFRE</a></li>\n                                <li><a href="#contact">CONTACT</a></li>\n                                <li class="active"><a>LOGIN</a></li>\n                                <li><a href="#offre">ESSAYER GRATUITEMENT</a></li>\n                            </ul>\n                        </div>\n                    </div>\n                </nav>\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    <div class="siteBackgroundImage" style="background-size: cover;"></div>\n    <form action method="post" target="postHiddenIFrame" class="animated loginFormCont">\n        <img class="loginLogo" src="' +
((__t = (applogo)) == null ? '' : __t) +
'">\n        <div class="form-group">\n            <input type="text" name="login_key" value="" id="login-mail" class="form-control" tabindex="1" placeholder="Nom d\'utilisateur ou e-mail">\n        </div>\n        <div class="form-group">\n            <input type="password" id="login-password" class="form-control" name="login_password" tabindex="2" placeholder="Mot de passe">\n        </div>\n        <div class="invaliduserpass">Utilisateur ou mot de passe invalide</div>\n        <div class="form-group update-password">\n            <input type="password" id="login-update-password" class="form-control" name="login_update_password" tabindex="2" placeholder="Nouveau mot de passe">\n        </div>\n        <div class="form-group update-password">\n            <input type="password" id="login-update-password-confirmation" class="form-control" name="login_update_password_confirmation" tabindex="2" placeholder="Confirmation du nouveau mot de passe">\n        </div>\n        <div class="form-group">\n            <button type="submit" name="login_signin" value="Login" class="form-control btn-block btn-primary" tabindex="3" id="loginBtn">Login</button>\n        </div>\n        <div class="login-progress" style="line-height: 72px;"></div>\n    </form>\n    <iframe name="postHiddenIFrame" class="postHiddenIFrame"></iframe>\n</div>\n';

}
return __p
}