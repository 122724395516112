/* global me */
import ModalView from 'views/modal/ModalView';
import List from 'views/listing/List';
import SearchBar from 'views/filter/SearchBar';
import HeaderRenderer from 'views/listing/renderer/header/HeaderRenderer';

import Logger from 'utils/Logger';

class HeaderListPopup extends ModalView {
    initialize(options){
        super.initialize(options);
        this.checkMandatoryParameter(options, 'taskId', 'Task to call for data');
        this.checkMandatoryParameter(options, 'gridId', 'Grid to retreive from server');
        this.checkMandatoryParameter(options, 'filterId', 'Filter to apply');
        this.parameters.foreignKey = options.foreignKey;
        this.parameters.title = options.title;
        this.classIdentifier = 'headerListPopup';
        this.domainContext = options.domainContext;
        this.iconConfig = options.iconConfig;
        this.sessionVars = options.sessionVars;
        this.headerList = new List({
            taskId: this.parameters.taskId,
            gridId: this.parameters.gridId,
            filterId: this.parameters.filterId,
            renderer: new HeaderRenderer({
                domainContext: options.domainContext,
                iconConfig: this.iconConfig,
                itemClass: options.itemClass
            }),
            domainContext: options.domainContext,
            sessionVars: this.sessionVars
        });
        this.listenTo(this.headerList,'list.onPrepareContext', this.onContextRequested.bind(this));
    }

    onContextRequested(context) {
        this.trigger('headerListPopup.onPrepareContext', context);
    }

    getHeaderList(){
        return this.headerList;
    }

    renderInnerPanel(element){
        var me = this;
        if(this.parameters.title !== undefined){
            var header = $('<div class="modal-header"></div>').appendTo(element);
            $('<div class="titleText">'+this.parameters.title+'</div>').appendTo(header);
        }
        var body = $('<div class="modal-body"></div>').appendTo(element);
        // ------ HARDCODED FOR PMI ----
        if(this.parameters.taskId === 'CRM40' && this.parameters.gridId === 'CRM06_ENFANTS'){
            body.css('max-height', 537);
        }
        //------------------------------
        var footer = $('<div class="modal-footer"></div>').appendTo(element);

        this.searchBar = new SearchBar({
            el: footer,
            taskId: this.options.taskId,
            filterId: this.options.filterId,
            filters: this.options.filters,
            enabled: true,
            domainContext: this.domainContext,
            hideCriterias: true
        });
        this.searchBar.render();

        this.listenTo(this.searchBar, 'searchChanged', function(newValue, customized){
            var filter = me.searchBar.getFilter();
            me.headerList.getListing().resetData(true);
            me.headerList.getListing().setFilterData(filter.values, filter.text);
            if(customized){
                me.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', true);
            }else{
                me.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', undefined);
            }
            this.headerList.getListing().fetchNextPage();
        });

        //Creating the cancel Button. There is no ok button as a selection in the list will immediately trigger the dismiss
        var cancelBtn = $('<button type="button" role="cancel" class="btn btn-modal-cancel">Annuler</button>').appendTo(footer);
        cancelBtn.one('click', this.onCancelClick.bind(this));

        //Creating the listing that will be displayed
        this.listenToOnce(this.headerList, 'itemClicked', this.onItemClicked.bind(this));

        this.headerList.setElement(body);
        this.headerList.render();

        this.headerList.getListing().fetchNextPage();
    }

    onCancelClick(){
        //This will allow the caller to know that the user pressed cancel on purpose
        this.$el.modal('hide');
        this.trigger('userCancelled');
    }

    onItemClicked(row, id, item){
        this.$el.modal('hide');
        var allValues = row.getColumnsValues();
        var selection = '';
        if(this.parameters.foreignKey !== undefined){
            //Specific selection of a column
            if(allValues[this.parameters.foreignKey]){
                selection = allValues[this.parameters.foreignKey].value;
            }else{
                Logger.error('HeaderListPopup', 'itemClicked', 'The selection column has been set in the parametrage but didn\'t appear in the grid columns. Contact your administrator');
                this.$el.modal('hide');
                this.trigger('error');
            }
        }else{
            for(var key in allValues){
                if(allValues[key].key){
                    selection = allValues[key].value;
                }
            }
        }
        this.trigger('selection', selection, row);
    }
}

export default HeaderListPopup;