'use strict';

import * as _ from 'lodash';
let $ = require('jquery');
import Server from 'server/Server';
import MessagePopup from 'views/message/MessagePopup';
import I18NUtils from 'utils/i18n/I18NUtils';
import User from 'User';
import ModalLogin from 'views/ModalLogin';
import Router from './routes/Router';
import Config from 'Config';
import DeviceUtils from 'utils/DeviceUtils';
import { Task } from './parametrage/structures/Task';
import { TaskView } from './views/tasks/Task';
import ModalConfirmationDialog from './views/modal/ModalConfirmationDialog';
import MenuView from './views/Menu';
import DomainManager from 'DomainManager';
import TaskMenuView from 'views/tasks/TaskMenu';
import DomainSelectorView from './views/DomainSelector';
import PasswordUpdateView from './views/PasswordUpdate';
import GetGridDataRequest from './server/protocol/request/task/GetGridData';
import LoadingMask from 'views/loading/LoadingMask';
import { UserInfosRequest } from './server/protocol/request/auth/UserInfosRequest';
import { DomainContext } from './parametrage/DomainContext';
import ColorUtils from 'utils/ColorUtils';

class App {
    private static _instance: App;
    eventUtils : any;
    views: { [key:string] : any }
    tasksStack: Array<any>;
    isMobile : boolean;
    private lastVersionCheck : number = new Date().getTime();

    //These definitions shouldn't be there as these are components that should
    //be defined separately somewhere else
    modalLoginOpened: boolean;
    maskIt: boolean;
    currentWidth: number;
    currentHeight: number;
    messageView: any;
    tasksHeader: any;
    deviceUtils: any;
    taskMenu: any;
    //modalLogin: any;
    config : any;
    router : Router;
    menu : MenuView;
    domainSelector : DomainSelectorView;
    passwordUpdate : PasswordUpdateView;

    private constructor() {
        this.views = {
            current: undefined
        };
        this.maskIt = false;
        this.modalLoginOpened = false;
        this.tasksStack = [];
        this.router = new Router();
        this.config = Config;
    }

    public static get Instance(): App {
        return this._instance || (this._instance = new this());
    }

    public showView(view: any) {
        if (this.views.applicationDesktop) {
            this.views.applicationDesktop.$el.hide();
        }

        if (this.views.current !== undefined) {
            this.views.current.$el.hide();
        }

        this.views.current = view;

        let viewType: string = view.getViewType();
        $(document).find('.'+viewType+'Container').show();

        if(viewType === 'login') {
            $(document).find('#taskMenuBar').hide();
        }
        if(viewType === 'desktop' && !DeviceUtils.isMobile()) {
            if(!this.taskMenu) {
                this.taskMenu = new TaskMenuView({
                    el: '#taskMenuBar',
                    domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
                });
                this.taskMenu.render();
            }
            $(document).find('#taskMenuBar').show();
            this.taskMenu.setSelectedEntry('');
        }

        this.views.current.$el.show();
        if (this.views.current.onShow) {
            this.views.current.onShow();
        }
    }

    public stackNewTask(newTask: any, result: any) {
        if (newTask) {
            this.views.taskContainer.show();
            this.tasksStack.push(newTask);
            //Rendering and adding the task into the DOM
            var newHolder = $('<div>').appendTo($('#mainTaskContainer'));
            newTask.setElement(newHolder);
            newTask.render();
            //We display the task as it's now at the top of the screen
            if (this.tasksHeader && !this.tasksHeader.$el.is(':visible')) {
                this.tasksHeader.$el.show();
            }
            this.showView(newTask);

            if(result.screen) {
                newTask.applyInitialScreen(result.screen, true);
            }

            if (newTask.onDOMUpdated) {
                newTask.onDOMUpdated();
            }

        } else {
            console.warn('empty task cannot be added to the system');
        }
    }

    public onWindowResize(evt: any) {
        var newWidth = $(window).width();
        if(this.currentWidth !== newWidth){
            this.currentWidth = newWidth;
            var mode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';

            for (var key in this.tasksStack) {
                var element = this.tasksStack[key].$el;
                if (element.length > 0 && this.tasksStack[key].resizeTask) {
                    this.tasksStack[key].resizeTask(mode);
                }
            }

            if (this.taskMenu) {
                if (DeviceUtils.isFullScreenPageNav()) {
                    this.taskMenu.$el.hide();
                } else {
                    this.taskMenu.$el.show();
                }
            }
        }
    }

    public popTask() {
        if (this.tasksStack.length === 0) {
            console.warn('stack is already empty. Wrong behavior');
            this.displayError('wrong stack behavior. Contact administrator');
        }
        var toClose = this.views.current;
        if (toClose.onClose) {
            toClose.onClose();
        }
        toClose.$el.remove();

        this.taskMenu.removeTask(toClose);

        _.remove(this.tasksStack, function (task) {
            return task.getTaskInstanceId() === toClose.getTaskInstanceId();
        });

        if (this.tasksStack.length === 0) {
            this.backToDesktop();
        } else {
            let currentTask: TaskView = this.tasksStack[this.tasksStack.length - 1];
            this.taskMenu.setSelectedEntry(currentTask.getTaskInstanceId());
            this.showView(this.tasksStack[this.tasksStack.length - 1]);
        }
    }

    public backToDesktop() {
        if (DeviceUtils.isFullScreenPageNav()) {
            while(this.tasksStack.length > 0) {
                var task:any = this.tasksStack.pop();
                task.$el.remove();
            }
        }
        this.checkAppVersion(false);
        this.router.navigate('#desktop', {trigger: false});
        this.router.displayDesktop();
    }

    public displayMenu():void {
        if(this.menu == undefined) {
            this.menu = new MenuView({
                el: '#menu',
                domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
            });
        }
        this.menu.show();
    }

    public logout() {
        if(this.menu !== undefined) {
            this.menu.hide();
        }
        sessionStorage.clear();
        window.location.href = '/';
    }

    public showDomainSelector() {
        if(this.menu !== undefined) {
            this.menu.hide();
        }
        if(this.domainSelector === undefined) {
            this.domainSelector = new DomainSelectorView({
                el: '.mainContainer',
                domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
            });
        }
        this.domainSelector.render();
    }

    public hideDomainSelector() {
        this.domainSelector.hide();
    }

    public changeDomain() {
        this.domainSelector.hide();
        if(this.domainSelector.getSelectedDomain() !== User.getCurrentDomain()) {
            User.setCurrentDomain(this.domainSelector.getSelectedDomain());
            if(this.views.applicationDesktop !== undefined) {
                this.views.applicationDesktop.delete();
                this.views.applicationDesktop = undefined;
            }
            this.getDomainProperties(User.getCurrentDomain(), this.domainSelector.getSelectedExercice())
                .then(() => {
                    this.backToDesktop();
                });
        } else {
            DomainManager.getCurrentContext(User.getCurrentDomain()).setExercice(this.domainSelector.getSelectedExercice());
        }
    }

    getDomainProperties(domain:string, currentExercice: any) {
        let dataRequest = new UserInfosRequest(domain);
        
        return new Promise((accept, reject) => {
            let loadingReqId;
            LoadingMask.requestLoading('Chargement des propriétés')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(dataRequest);
            })
            .then((response) => {
                User.setProperties(response.properties,domain);
                DomainManager.setContext(domain, new DomainContext(domain, response.currentConfiguration));

                this.getAvailableExercices(DomainManager.getCurrentContext(domain))
                    .then((exercices) => {
                        for(let i in exercices as any) {
                            let exercice = exercices[i];
                            if(exercice.id === currentExercice.id) {
                                DomainManager.getContext(domain).setExercice(exercice);
                                break;
                            }
                        }
                        accept(undefined);
                    });
            })
            .catch(reject)
            .then(() => {
                LoadingMask.hide(loadingReqId);
            })
        });
    }

    public showPasswordUpdate() {
        if(this.menu !== undefined) {
            this.menu.hide();
        }
        if(this.passwordUpdate === undefined) {
            this.passwordUpdate = new PasswordUpdateView({
                el: '.mainContainer',
                domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
            });
        }
        this.passwordUpdate.render();
    }

    public hidePasswordUpdate() {
        this.passwordUpdate.hide();
    }

    public reloadHome() {
        window.location.href = '/';
    }

    public downloadDocument(url: string) {
        var urlPair = url.split('?').map(item => item.trim());
        var urlApi = urlPair[0];
        var data = urlPair[1];

        //split params into form inputs
        var inputs = '';
        if (data) {
            $.each(data.split('&'), function () {
                var pair = this.split('=').map(item => item.trim());
                inputs += '<input type="hidden" name="' + pair[0] + '" value="' + pair[1].replace(/"/g, '&quot;') + '" />';
            });
        }

        $('<form target="_blank" action="' + urlApi + '" method="get">' + inputs + '</form>').appendTo('body').submit().remove();
        //$.fileDownload(url);
    }

    /**
     * If the server returns a 500 error, we end here. The 500 is a special
     * error that will probably happen all the time until the server is fixed,
     * thus there is not much to do about it. In some cases, 500 error can
     * have reduced impact when the default behaviors (ex : background
     * images) offers a basic feature to the user (that will not be user specific)
     *
     * @returns {undefined}
     */
    public handleInternalError(error: any) {
        console.warn(error.responseJSON);
        this.displayError(error.responseJSON.message);
    }

    public requestModalLogin(message: any, resolve:any, reject:any) {
        if (message.service === 'login' && window.location.hash === '#login') {
            // we are already trying to login
            this.views.applicationLogin.onLoginError({body : 'La session a expiré, veuillez vous reconnecter'});
            reject();
        } else {
            Server.addPendingRequest({'message': message, 'resolve': resolve, 'reject': reject});
            if (!this.modalLoginOpened) {
                this.modalLoginOpened = true;
                var LoginView = new ModalLogin();
                LoginView.display();
            }
        }
    }

    public setModalLoginClosed() {
        this.modalLoginOpened = false;
    }

    public recallRequests() {
        this.modalLoginOpened = false;
        return Server.recallRequests();
    }

    public displayError(error: any) {
        let message;
        if(error instanceof Error) {
            message = JSON.parse(error.message);
        } else if(error.message === undefined) {
            message = I18NUtils.getMessage('MISSING_MESSAGE', User.getLocale(), {});
        } else {
            message = error.message;
        }
        this.displayErrorMessage(message);
    }

    public displayErrorMessage(message: any) : Promise<string> {
        if(message === ModalConfirmationDialog.SYSTEM_CANCEL_MESSAGE) {
            return new Promise((accept) => {accept(undefined);});
        }
        if(message === undefined) {
            message = {
                title: 'Erreur',
                body: 'Une erreur est survenue'
            };
        } else if(message.message) {
            //browser error instead of standard error
            //conversion into standard message
            message = {
                title: message.message,
                body: message.stack
            };
        }
        console.warn((message.title? message.title + '! ' : '') + ( message.body? message.body + '\n': '') + (message.expert? message.expert : ''));
        var newView = new MessagePopup({
            message: message,
            type: 'warning',
            allowDismiss: true
        });
        return newView.display();
    }

    public displayCustomMessage(message: any, type: string) : Promise<string> {
         var newView = new MessagePopup({
            message: message,
            type: type,
            allowDismiss: true
        });
        return newView.display();
    }

    public getConfig() {
        return this.config;
    }
    /**
     * Simply uploads a file on the server. The result will contain a unique id that identifies the file for later use
     */
    public putFile(file, callback, progressHandlingFunction, domainContext:DomainContext) {
        var formData = new FormData();
        formData.append('file', file);
        formData.append('device', this['config'].deviceType);
        var request = $.ajax({
            url: Server.getTokenedUrl('file/' + domainContext.getDomain() + '/file'),
            type: 'POST',
            xhr: function () {  // Custom XMLHttpRequest
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // Check if upload property exists
                    myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // For handling the progress of the upload
                }
                return myXhr;
            },
            statusCode: {
                500: this.handleInternalError,
                502: this.handleInternalError,
                401: this.requestModalLogin
            },
            //Ajax events
            success: function(result){
                callback(result);
            },

            // Form data
            data: formData,
            //Options to tell jQuery not to process data or worry about content-type.
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json'
        });

        return request;
    }

    public goBack(callback: Function) {
        var backButtons = $('.tab-button[data-id="back"]:visible')[0];
        if(backButtons !== undefined) {
            backButtons.click();
        } else {
            backButtons = $('.tab-button[data-id="home"]:visible')[0];
            if(backButtons !== undefined) {
                backButtons.click();
            } else {
                this.router.displayDesktop();
            }
        }
    }

    public checkAppVersion(force:boolean) {
        if(force || new Date().getTime() - this.lastVersionCheck > 600000) {
            this.getAppVersion()
            .then((clientVersion) => {
                const currentCliVersion = localStorage.getItem('sai-version');
                if (currentCliVersion === undefined || currentCliVersion === null) {
                    localStorage.setItem('sai-version',clientVersion);
                } else if (currentCliVersion !== clientVersion) {
                    console.log('version has changed');
                    localStorage.clear();
                    location.reload();
                }
            });
        }
    }

    public getAppVersion(): Promise<any> {
        return new Promise((accept, reject) => {
            let d = new Date();
            this.lastVersionCheck = d.getTime();
            let url : string = '/index.html?t=' + d.getTime();
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.onload = () => {
                let regexp = /src=\"app-(.+)\.js\"/;
                accept(xhr.response.match(regexp)[1]);
            };
            xhr.onerror = () => {
                reject(xhr.response);
            };
            xhr.send();
        });
    }

    getSvgIcon(iconPath : string, iconSize : {h: number, w: number}) {
        let me = this;
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', iconPath);
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.send();
        }).then((response) => me.handleGetImg([response,iconPath, iconSize]));
    }

    handleGetImg([response, imgSrc, iconSize]) {
        return new Promise((resolve, reject) => {
            let jsonResult = undefined;
            try {
                jsonResult = JSON.parse(response);
            } catch(e) {
                // Nothing to do
            }
            if (jsonResult !== undefined && jsonResult.status === 404) {
                reject(new Error(JSON.parse(response).message.body));
            } else if (response.indexOf('<svg') >= 0) {
                var randNum = Math.floor(Math.random() * 100000);
                response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                var xmlSvg = $($.parseXML(response));
                xmlSvg.find('svg').attr('width', iconSize.h+'px').attr('height',iconSize.w+'px');
                resolve(xmlSvg.find('svg'));
            } else {
                let imgElement = $('<img src="'+imgSrc+'" />');
                resolve(imgElement);
            }
        });
    }

    private actionMode: string;
    public setActionMode(mode: string): void {
        this.actionMode = mode;
    }

    public getActionMode(): string {
        return this.actionMode;
    }

    public getAvailableExercices(domain: DomainContext) {
        //Building the server request
        let dataRequest = new GetGridDataRequest(domain,'UNI84', 'UNI84_ACTIF');
        return new Promise((resolve, reject) => {
            let loadingReqId:string;
            LoadingMask.requestLoading('Chargement des données')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(dataRequest);
            })
            .then((response) => {
                let availableExercices = {};
                for(let id in response.items) {
                    let item = response.items[id];
                    availableExercices[item.columns['EXERCICE'].value] = {
                        id: item.columns['EXERCICE'].value,
                        label: item.columns['LIBELLE'].value,
                        start: item.columns['DATE_DEBUT'].value,
                        end: item.columns['DATE_FIN'].value,
                        boucled: item.columns['BOUCLED'].value === '1',
                        active: item.columns['ACTUEL'].value === '1'
                    }
                }
                resolve(availableExercices);
            })
            .catch(reject)
            .then(() => {
                LoadingMask.hide(loadingReqId);
            });
        });
    }

    public hasCallStack():boolean {
        return this.tasksStack.length > 1;
    }
}

const singleInstance = window['App'] = App.Instance;

export default singleInstance;
