'use strict';

import { DataField } from './DataField';
import { ConfigurationElement } from './ConfigurationElement';
import { DataPage } from './DataPage';
import { Theme } from './Theme';
import { DomainContext } from '../DomainContext';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import * as _ from 'lodash';
import StringUtils from 'utils/StringUtils';

class Table extends ConfigurationElement {

    private datafields: { [dfId: string] : DataField }; //table specific datafields
    private datafieldsArr: Array<DataField>; //ordered array of table datafields
    private dfLookupCache: { [dfId: string] : DataField };
    private datapages: { [dpId: string] : DataPage }; //datapages of the table
    private datapagesArr: Array<DataPage>; //ordered array of datapages
    private themes: { [thId: string] : Theme }; //themes of the table
    private themesArr: Array<Theme>; //ordered array of the themes
    private static ASYNC_PROP_LABEL:Symbol = Symbol();

    constructor(config: { id: string, themes: any, datapages: any, datafields: any}, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [ Table.ASYNC_PROP_LABEL ];
        //Direct datafields definition
        this.dfLookupCache = {};
        this.datafields = {};
        this.datafieldsArr = [];
        for(let key in config.datafields) {
            let newDf = new DataField(config.datafields[key], null, this, null, context);
            this.datafields[newDf.getId()] = newDf;
            this.datafieldsArr.push(newDf);
        }
        //datapages definitions
        this.datapages = {};
        this.datapagesArr = [];
        for(let key in config.datapages) {
            let newDp = new DataPage(config.datapages[key], this, context);
            this.datapages[newDp.getId()] = newDp;
            this.datapagesArr.push(newDp);
        }

        //themes definition
        this.themes = {};
        this.themesArr = [];
        for(let key in config.themes) {
            let newTh = new Theme(config.themes[key], this, context);
            this.themes[newTh.getId()] = newTh;
            this.themesArr.push(newTh);
        }
        if(!this.isGlobal()) {
            //Themes are sorted by their 'order' property
            this.themesArr = _.sortBy(this.themesArr, (entry) => { return entry.getOrder(); })

            //Check on title datafield to make sure we've at least one
            let allIsTitle = _.filter(this.datafields, (item) => {
                return item.isTitle();
            });
            if(allIsTitle.length === 0) {
                console.warn('The table ' + this.getId() + ' has no isTitle datafield');
            }
        }
    }

    public isGlobal(): boolean {
        return this.getId() === undefined;
    }

    /**
     * Returns the configuration ordered list of themes
     */
    public getThemes(): Array<Theme> {
        return this.themesArr;
    }

    /**
     * Returns the Theme associated with the given string id
     * @param themeId The unique id of the theme within the table
     */
    public getTheme(thId: string): Theme {
        return this.themes[thId];
    }

    /**
     * Looks for the given id datafield in the table
     * @param dfId The id of the datafield to retrieve
     * @param searchInDatapages If the search has to include datapages
     */
    public getDataField(dfId: string, searchInDatapages?: boolean) : DataField {
        //Looking in the table datafields
        if(this.datafields[dfId] !== undefined) {
            return this.datafields[dfId];
        } else if(this.dfLookupCache[dfId] !== undefined) {
            return this.dfLookupCache[dfId];
        } else if(this.datafields[dfId.replace('DOSSIER.', '.')] !== undefined) {
            return this.datafields[dfId.replace('DOSSIER.', '.')];
        } else if(this.dfLookupCache[dfId.replace('DOSSIER.', '.')] !== undefined) {
            return this.dfLookupCache[dfId.replace('DOSSIER.', '.')]
        } else if(searchInDatapages) {
            //Not found in table, might be in the datapages
            let dfParts = dfId.split('.').map(item => item.trim());
            if(dfParts.length === 3) {
                //datapage datafield
                let dpage = this.datapages[dfParts[1]];
                if( dpage !== undefined) {
                    return dpage.getDataField(dfId);
                }
            } else {
                //The given datafield might be taskonly we look
                //it up in the whole datapage set
                for(let dpId in this.datapages) {
                    let foundDf = this.datapages[dpId].getDataField(dfId);
                    if(foundDf !== undefined) { 
                        this.dfLookupCache[dfId] = foundDf;
                        return foundDf;
                    }
                }
            }
        }

        return undefined;
    }

    /**
     * Returns an array of all datafields of the table. It might include
     * datapage datafields if requested
     * @param includeDatapages If true, the array will include datapages datafields
     */
    public getDataFields(includeDatapages: boolean): Array<DataField> {
        let allDfs = this.datafieldsArr;
        if(includeDatapages) {
            for(let dpId in this.datapages) {
                allDfs = allDfs.concat(this.datapages[dpId].getDataFields());
            }
        }
        return allDfs;
    }

    /**
     * Returns the specific datapage identified by the given id
     * @param datapageId The id of the datapage to fetch
     */
    public getDataPage(dpId: string): DataPage {
        return this.datapages[dpId];
    }

    /**
     * Returns the configuration ordered array of datapages within this table
     */
    public getDataPages() : Array<DataPage> {
        return this.datapagesArr;
    }

    public initializeI18N(): Promise<Array<string>> {
        let allPromises = [];
        let me = this;

        if(!this.isGlobal() && this.hasAsyncRequirements()) {
            //We need to fetch the resources to label the table
            let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'table', this.getId(), User.getLocale());
            i18NTextPromise.then((i18NText) => {
                if(i18NText !== undefined) {
                    me.setLabel(i18NText);
                }
                me.removeFromAsyncRequirement(Table.ASYNC_PROP_LABEL);
            });
            allPromises.push(i18NTextPromise);
        }
        //All datafield must be initialized as well
        for(let dfId in this.datafields) {
            let dfProm = this.datafields[dfId].initializeI18N();
            if(dfProm !== null) { allPromises.push(dfProm); }
        }
        //As well as all datapages
        for(let dpId in this.datapages) {
            let dpProm = this.datapages[dpId].initializeI18N();
            if(dpProm != null) { allPromises.push(); }
        }
        //As well as all themes
        for(let thId in this.themes) {
            let thProm = this.themes[thId].initializeI18N();
            if(thProm !== null) { allPromises.push() ;}
        }

        return Promise.all(allPromises);
    }

    public getType(): string {
        return this.getValueOrDefault('type');
    }

    public getIcon(): string {
        return StringUtils.basename(this.getValueOrDefault('icon'));
    }

    public getEditTask(): string {
        return this.getValueOrDefault('editTask');
    }

    public getViewTask(): string {
        return this.getValueOrDefault('viewTask');
    }

    public getListTask(): string {
        return this.getValueOrDefault('listTask');
    }

    public isDossier(): boolean {
        return this.getBooleanValueOrDefault('isDossier');
    }

    public getKeyDatafields(): Array<any> {
        const keys = [];
        for(let i in this.datafieldsArr) {
            const df = this.datafieldsArr[i];
            if(df.getConfig().isKey) {
                keys.push(df);
            }
        }
        return keys;
    }
}

export { Table }