'use strict';

let $ = require('jquery');

import App from 'App';
import User from 'User';
import Config from 'Config';
import Server from 'server/Server';

import RecordView from 'views/record/Record';
import RecordModel from 'models/Record';
import Context from 'models/context/Context';
import RecordPopupDocumentsView from 'views/record/RecordPopupDocuments';

import GetRecordRequest from '../../server/protocol/request/task/GetRecord';
import PrepareNewEntryRequest from 'server/protocol/request/task/PrepareNewEntry';
import { SAIView } from '../../Additions';
import { DomainContext } from '../../parametrage/DomainContext';
import StringUtils from 'utils/StringUtils';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { Task } from '../../parametrage/structures/Task';
import ColorUtils from 'utils/ColorUtils';

class RecordPopupView extends SAIView {
    private operationTitle : string;
    private buttons : any;
    private gridId : string;
    private recordView : RecordView;
    private documentHandler : any;
    private documentHandlerGlyph : any;
    private documentAddGlyph : any;
    private recordViewEl : any;
    private recordSubViewEl : any;
    private buttonsHolder : any;
    private fileUp : any;
    private docsPan : any;
    private modalBody : any;
    private modalContent : any;
    private modalDialog : any;
    private modalFooter : any;
    private domainContext: DomainContext;

    initialize(options) {
        this.options = options;

        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.btnTemplate = require('ejs-loader!templates/modalPopup/ModalPopupButton.ejs');

        this.checkMandatoryParameter(options, 'mode', 'Server call will depend of this mode');

        this.checkMandatoryParameter(options, 'operationTitle', 'The i18n title of the popup');
        this.checkMandatoryParameter(options, 'displayMode', 'How to display the record within the popup');
        this.checkMandatoryParameter(options, 'buttons', 'Array of buttons properties');
        if(options.mode === 'getRecord') {
            this.checkMandatoryParameter(options, 'recordModelKeys', 'Array of keys for the form to load');
        }
        this.checkMandatoryParameter(options, 'taskId', 'id of the task');
        this.checkMandatoryParameter(options, 'handleDocuments', 'The popup allows the user to manage documents');
        this.checkMandatoryParameter(options, 'domainContext', 'The execution context of the component');

        this.operationTitle = this.options['operationTitle'];
        this.buttons = this.options['buttons'];
        for (var i = 0; i < this.buttons.length; i++) {
            this.checkMandatoryParameter(this.buttons[i], 'bootstrapClass', 'bootrap class to apply to the button');
            this.checkMandatoryParameter(this.buttons[i], 'btnClass', 'context class to apply to the button');
            this.checkMandatoryParameter(this.buttons[i], 'enableOnLoad', 'true/false. When the record is loaded disabled buttons will be enabled');
            this.checkMandatoryParameter(this.buttons[i], 'disabled', 'true/false. Initial state of the button');
            this.checkMandatoryParameter(this.buttons[i], 'btnText', 'true/false. Button is initially disabled');
            this.checkMandatoryParameter(this.buttons[i], 'operation', 'function to call when clicked');
            this.checkMandatoryParameter(this.buttons[i], 'role', 'button role (unique within the view). Ex: ok, cancel, etc..');
        }

        this.gridId = this.options['gridId'];
        this.domainContext = options.domainContext;

        var recordViewOptions = {
            dominantColor: Config.appTheme,
            displayMode: this.options.displayMode,
            taskId: this.options.taskId,
            domainContext: this.domainContext
        }
        this.recordView = new RecordView(options);

        this.uniqId = StringUtils.guid();
    }

    render() : any {
        this.checkInitialized();

        this.$el = $(this.template({
            operationTitle: this.options.operationTitle,
            operationColor: Config.appTheme,
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            uniqId: this.uniqId,
            allowDocs: this.parameters['handleDocuments'],
            hideSpinner: false,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: '',
            allowDismiss: false,
            colorUtils: ColorUtils
        }));

        this.documentHandler = this.$el.find('.documentHandlerIcon');
        this.documentHandlerGlyph = this.$el.find('.documentPanelSwitch');
        this.documentAddGlyph = this.$el.find('.documentHandlerFileAdd').find('img');

        this.recordViewEl = this.$el.find('.innerView');
        this.recordSubViewEl = this.$el.find('.subView');

        this.buttonsHolder = this.$el.find('.modal-footer');

        for (var i = 0; i < this.options.buttons.length; i++) {
            var current = this.options.buttons[i];
            var toAdd = $(this.btnTemplate({
                bootstrapClass: current.bootstrapClass,
                btnClass: current.btnClass,
                enableOnLoad: current.enableOnLoad === true ? 'enableOnLoad' : '',
                disabled: current.disabled === true ? 'disabled' : '',
                btnText: current.btnText,
                role: current.role,
                dismiss: current.dismissOnClick === false ? '' : 'data-dismiss="modal"'
            }));
            if (current.faIcon !== undefined) {
                toAdd.find('i').addClass('fa '+current.faIcon);
            }

            toAdd.appendTo(this.buttonsHolder);
            toAdd.on('click', current.operation);
            toAdd.on('keyup', this.handleButtonKeyup.bind(this));
        }

        this.fileUp = this.$el.find('.recordDocAddFormFile');

        this.fileUp.change(this.onNewFileChange.bind(this));
    }

    onNewFileChange(evt){
        if (this.docsPan !== undefined) {
            this.docsPan.addNewFile(this.fileUp[0]);
        }
    }

    display(callback?) {
        var me = this;

        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            //registering user btn clicks here

            let taskContext;
            let request;

            if(me.options.mode === 'getRecord'){
                request = new GetRecordRequest(me.domainContext, me.options.taskId);
                taskContext = new Context({contextId: 'key'});
                request.setKeyContext(taskContext);
                if(me.options.gridId){
                    request.setGridId(me.options.gridId);
                }
            }else if(me.options.mode === 'prepareNewEntry'){
                request = new PrepareNewEntryRequest(me.domainContext, me.options.taskId);
                taskContext = new Context({contextId: 'record'});
                request.setRecordContext(taskContext);
            }else{
                console.error('missing request object for call');
            }

            taskContext.addFields(me.options['recordModelKeys']);
            request.setGridId(me.gridId);

            Server.performRequest(request)
                .then(me.displayRecord.bind(me))
                .catch(me.displayErrorMessage.bind(me));
        });
        this.$el.on('hidden.bs.modal', function () {
            //calling given callback
            $('#' + me.uniqId).remove();
            if (callback) {
                callback();
            }
        });
        this.$el.modal({
            'show' : true,
            keyboard: false,
            backdrop: 'static'
        });

    }

    onDocsBtnClicked(evt) {
        this.toggleDocumentsPanel();
    }

    toggleDocumentsPanel() {
        var me = this;

        this.docsPan.$el.height(this.modalBody.height());
        this.docsPan.$el.width(this.recordView.$el.width());

        var left;
        var opacity;
        var docOpacity;
        var classToAdd;
        if (this.docsPan.status === 'visible') {
            left = this.$el.find('.modal-dialog').width();
            opacity = 1;
            this.docsPan.status = 'hidden';
            if (this.docsPan.documents.length > 0) {
                classToAdd = 'fa fa-file-text';
            } else {
                classToAdd = 'fa fa-file-o';
            }
            docOpacity = 0;
        } else {
            left = 15;
            opacity = 0;
            this.docsPan.status = 'visible';
            classToAdd = 'fa fa-pencil';
            docOpacity = 1;
        }
        /*
         * This animation change the operation glyph between document and edition
         * using a fade effect
         */
        this.documentHandlerGlyph.animate({
            opacity: 0
        }, {
            queue: false,
            duration: 250,
            complete: function () {
                me.documentHandlerGlyph.removeClass();
                me.documentHandlerGlyph.addClass(classToAdd);
                me.documentHandlerGlyph.animate(
                    {
                        opacity: 1
                    }, {
                        queue: false,
                        duration: 250
                    }
                );
            }
        });


        this.documentAddGlyph.animate(
            {
                opacity: docOpacity
            }, {
                queue: false,
                duration: 500
            }
        );
        /*
         * This animation translate the document panel in and out of
         * the window base on which panel has to be displayed
         */
        this.docsPan.$el.animate(
            {
                left: left
            }, {
                queue: false,
                duration: 500
            }
        );
        /*
         * This animation fades out the record panel when the document
         * panel has to be shown
         */
        this.recordViewEl.animate(
            {
                opacity: opacity
            }, {
                queue: false,
                duration: 500
            }
        );

    }

    displayRecord(response) {
        let task = new Task(response.task, this.domainContext);
        this.modalDialog = this.$el.children('.modal-dialog');
        this.modalContent = this.modalDialog.children('.modal-content');
        this.modalBody = this.modalContent.children('.modal-body');
        this.modalFooter = this.modalContent.children('.modal-footer');

        this.recordView.setElement(this.recordViewEl);
        this.recordView.model = new RecordModel(response.recordKeys);
        this.recordView.setGroups(task.getPanels()[0].getGroups());
        this.recordView.setValuesAndPositions(task.getPanels()[0].getGroups(), true);
        this.$el.find('.loadingSpinner').hide();
        this.recordView.render();
        var widthDimensions = this.recordView.getGroupsBoundaries();
        var finalWidth = widthDimensions['minLeft'] + widthDimensions['maxLeft'] + 15;
        this.$el.find('.modal-dialog').prop('style', 'width:' + finalWidth + 'px;');
        this.$el.find('.modal-dialog').addClass('record-popup');
        this.$el.find('.enableOnLoad').prop('disabled', false);
        //VBH : Fixme
        /*if (this.onRecordLoaded) {
            this.onRecordLoaded(this.recordView);
        }*/

        if(this.parameters['handleDocuments']){
            this.documentHandler.show();
            this.documentHandlerGlyph.click(this.onDocsBtnClicked.bind(this));

            if (this.docsPan === undefined) {
                this.docsPan = new RecordPopupDocumentsView({
                    el: this.recordSubViewEl,
                    taskId: this.parameters['taskId'],
                    recordKeys: this.options.mode !== 'new' ? this.parameters['recordModelKeys'] : undefined,
                    mode: this.parameters['mode'],
                    gridId: this.options.gridId || '',
                    glyphHolder: this.documentHandlerGlyph
                });
                this.docsPan.render();
                this.docsPan.onDOMUpdated();
                this.docsPan.$el.css('left', this.$el.find('.modal-dialog').width());
                this.docsPan.$el.height(this.modalBody.height());
                this.docsPan.$el.width(this.recordView.$el.width());
            }
        }

        this.modalBody.height($(window).height() - this.modalFooter.outerHeight() - this.modalBody.offset().top - 111);

        if(this.recordView.groups !== undefined && this.recordView.groups.length > 0){
            var lastEl = this.recordView.groups[this.recordView.groups.length-1].$el;
            var groupStart = parseInt(lastEl.css('top').replace(/[^-\d\.]/g, ''));
            var maxHeight = groupStart + lastEl.height();
            this.recordView.$el.height(maxHeight);
            if(this.recordView.$el.height() < this.modalBody.height()){
                this.modalBody.height(this.recordView.$el.height());
            }
        }

        /*this.modalBody.mCustomScrollbar({
            theme:'sai-scroll-theme',
            scrollInertia: 200,
            autoHideScrollbar: true
        });*/

        this.modalBody.children('.mCustomScrollBox').children('.mCSB_scrollTools').find('.mCSB_dragger_bar').css('background-color', Config.appTheme);
    }

    displayErrorMessage(message: any) {
        this.$el.modal('hide');
        App.displayErrorMessage(message);
    }

    getRecordView(): RecordView {
        return this.recordView;
    }

    private handleButtonKeyup(e: JQueryKeyEventObject) {
        // space or enter
        if(e.which === 13 || e.which === 32) {
            $(e.target).click();
        }
    }
}

export default RecordPopupView;
