'use strict';

import App from 'App';
import Server from 'server/Server';

import InputTaskView from 'views/tasks/InputTask';
import RecordView from 'views/record/Record';
import DeviceUtils from 'utils/DeviceUtils';
import TabBarView from 'views/tasks/TabBar';
import ToastFactory from 'utils/ToastFactory';
import User from 'User';
import Context from 'models/context/Context';
import SubmitRecordRequest from 'server/protocol/request/task/SubmitRecord';
import NoteDownload from 'views/album/documents/NoteDownload';
import MenuItemView from './MenuItem';
import TabContainerView from '../record/TabContainer';
import { Task } from '../../parametrage/structures/Task';
import GetGridDataRequest from '../../server/protocol/request/task/GetGridData';
import { Header } from '../../models/listing/Header';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { TaskWindowType, TaskPresentation } from './Task';
import { Field } from '../../parametrage/structures/Field';
import LoadingMask from 'views/loading/LoadingMask';
import I18NUtils from 'utils/i18n/I18NUtils';
import {Note, NoteFile} from 'models/Note';
import ModalConfirmationDialog from '../modal/ModalConfirmationDialog';
import DialogUtils from '../../utils/DialogUtils';
import ServerUtils from '../../utils/ServerUtils';

class SimpleTask extends InputTaskView {
    constructor(options) {
        super(options);
        this.displayHeader = (this.presentation & TaskPresentation.ShowRecordHeader) === TaskPresentation.ShowRecordHeader;
        this.tabContainer = new TabContainerView({
            panels: options.taskConfig.getPanels(),
            taskId: options.taskConfig.getId(),
            dominantColor: '#161616',
            displayMode: DeviceUtils.getDisplayModeFromSize(),
            domainContext: options.domainContext,
            enabled: false,
            headerId: options.taskConfig.getHeaderID(),
            headerDisplayMode: options.taskConfig.getHeaderDisplayMode(),
            headerIcon: options.taskConfig.getLinkedTable().getIcon(),
            headerMode: options.taskConfig.getHeaderDisplayMode(),
            taskConfig: options.taskConfig,
            displayHeader: this.displayHeader
        });
        let recordsPanels = this.tabContainer.getRecordViews();
        let me = this;
        for(let key in recordsPanels) {
            let curRecord = recordsPanels[key];
            this.stopListening(curRecord, 'record.onPrepareKeyContext');
            this.stopListening(curRecord, 'record.taskContext');
            this.listenTo(curRecord, 'record.onPrepareKeyContext', this.onKeyRequested.bind(this));
            this.listenTo(curRecord, 'record.taskContext', function(context) {
                context.task = me;
            });
        }
    }

    getRecordViews() {
        return this.tabContainer.getRecordViews();
    }

    getRecordView(panelId) {
        return this.tabContainer.getRecordView(panelId);
    }

    onKeyRequested(keyContext) {
        keyContext.setContextId('key');
        keyContext.addFields(this.recordKey);
    }

    render() {
        super.render();
        if(this.isInline()) {
            this.recordEntry = this.$el.find('.recordContainer');
            this.recordEntry.addClass('inlineRecord');
            this.tabContainer.setElement(this.recordEntry);
            this.tabContainer.render();
            this.recordEntry.show();
            //Setup of the main record container size
            let taskBody = this.$el.find('.taskBody');
            let availableSpace = taskBody.innerWidth();
            let navigatorWidth = Math.floor(availableSpace*0.3) * (this.presentation & TaskPresentation.ShowNavigator);
            this.navigator.$el.width(navigatorWidth);
            let leftStart = this.navigator.$el.width() + (15 * (this.presentation & TaskPresentation.ShowNavigator));
            let recordHeight = '100%';
            if(this.isModal()) {
                let recordsMaxHeight = 0;
                let recordsMaxWidth = 0;
                for(let recIdx in this.getRecordViews()) {
                    let record = this.getRecordView(recIdx);
                    recordsMaxHeight = Math.max(recordsMaxHeight, record.getMaxHeight());
                    recordsMaxWidth = Math.max(recordsMaxWidth, record.getMaxWidth());
                }
                recordHeight = Math.min(recordsMaxHeight, $(window).height() - 200);
                this.$el.parents('.modal-body').height(recordHeight);
                this.$el.parents('.modal-content').width(recordsMaxWidth + 35);
                this.$el.parents('.modal-dialog').width(recordsMaxWidth + 35);
            } else {
                if((this.presentation & TaskPresentation.ShowNavigator) === TaskPresentation.ShowNavigator) {
                    recordHeight = this.navigator.$el.height() - 50;
                }
            }
            this.recordEntry.css({
                left: leftStart,
                top: taskBody.css('padding-top'),
                //width: availableSpace - leftStart - 15,
                height: recordHeight
            });
            //Setup of the inner record panel size
            let height = this.recordEntry.height();
            if(Object.keys(this.getRecordViews()).length > 1) {
                height -= this.recordEntry.find('.tab-container-tabs').height();
            }
            if((this.presentation & TaskPresentation.ShowRecordHeader) === TaskPresentation.ShowRecordHeader || this.modalFlag) {
                height -= (this.recordEntry.find('.record-header').outerHeight() + 10);
            }
            this.tabContainer.$el.find('.tab-container-records').height(height);
        } else {
            this.recordEntry = this.$el.find('.recordContainer');
            this.tabContainer.setElement(this.recordEntry);
            this.tabContainer.render();
        }
    }

    initInnerTaskProperties(){
        this.taskType = 'Simple';
        this.className = 'simpleTask';
    }

    switchToRecord() {

        //We simulate the view switch by hiding the navigator
        this.navigator.$el.hide();
        if (this.navigatorTabBar) {
            this.navigatorTabBar.$el.hide();
        }
        this.recordEntry.show();
        this.coloredTitle.show();
        var recordTopOff = this.tabContainer.$el.offset().top;
        var tabTopOff = $(window).height();

        if (DeviceUtils.isFullScreenPageNav()) {
            this.recordTabBar.$el.show();
            tabTopOff = this.recordTabBar.$el.offset().top;
            this.recordEntry.css({
                height: (tabTopOff - recordTopOff) + 70 + 'px'
            });
        } else {
            this.recordEntry.css({
                height: (tabTopOff - recordTopOff) + 'px'
            });
        }

        this.getAlbumElement().hide();

        this.taskHeader.setupContextAction(this.getRecordContextActions());
        
    }

    handleIconLoaded(row, id, item){
        var imgsrc = row.getIcon();
        var img = item.$el.find('.mainIcon.coverIcon');
        var color = window.App.colorThief.getColor(img[0], imgsrc);
        this.coloredTitle.css('background-color', color);
        if(this.tabContainer){
            this.tabContainer.setDominantColor(color);
        }
    }

    buildOrSetRecordBar () {
        if (DeviceUtils.isFullScreenPageNav()) {
            if (!this.recordTabBar) {
                this.recordTabBarEl = this.getRecordBarElement();
                var tabs = this.getRecordBarTabs();
                this.recordTabBar = new TabBarView({
                    el: this.recordTabBarEl,
                    prefix: 'record',
                    tabs: tabs,
                    dockLocation: 'bottom',
                    domainContext: this.domainContext
                });
                this.recordTabBar.render();
                this.stopListening(this.recordTabBar, 'tabPressed');
                this.listenTo(this.recordTabBar, 'tabPressed', this.onRecordBarTabPressed.bind(this));
            }

            this.recordTabBar.$el.show();
        } else {
            if (this.recordTabBar) {
                this.recordTabBar.$el.hide();
            }
        }
    }

    backToNavigator () {
        this.coloredTitle.hide();
        if (this.recordBanner) {
            this.recordBannerEl.hide();
        }
        this.recordEntry.hide();
        this.recordTabBarEl.hide();
        this.currentRecord = undefined;
        this.recordKey = undefined;

        this.navigator.$el.show();
        this.navigatorTabBar.$el.show();
    }

    onRecordBarTabPressed (tabId) {
        if (tabId === 'home') {
            window.App.backToDesktop();
        } else if (tabId === 'back') {
            this.backToNavigator();
        } else if (tabId === 'report') {
            this.displayLocalTemplates();
        } else if (tabId === 'save') {
            this.onValidationClick();
        } else if (tabId === 'notes') {
            this.onNotesClick();
        } else if (tabId === 'delete') {
            this.onDeleteClick();
        }
    }

    onDeleteClick() {
        DialogUtils.displayDialog({ message: 'Cette entrée sera supprimée sans possibilité de revenir en arrière.'}, (userChoice) => {
            if (userChoice === 'confirm') {
                this.performRecordOperation(this.buildRecordRequest('delete'), 'Suppression en cours', 'Entrée supprimée!');
            }
        });
    }

    onValidationClick() {
        let invalidPanel = this.getInvalidPanel();
        if (invalidPanel !== null) {
            let recordView = this.tabContainer.getRecordView(invalidPanel.getId());
            recordView.moveToField(recordView.getInvalidField());
            this.displayErrorMessage(I18NUtils.getMessage('RECORD_INVALID_FIELD_VALUES', User.getLocale(), {}));
        } else {
            var submitMode = this.mode === 'modify' ? 'modify' : 'new';
            this.performRecordOperation(this.buildRecordRequest(submitMode), 'Validation en cours', 'Entrée sauvegardée!');
        }
    }

    performRecordOperation(request, loadingText, successText) {
        ServerUtils.loadRequest(loadingText, request, () => {
            ToastFactory.displayText(successText, 2000);
            this.backToNavigator();
            this.navigator.refreshData();
        });
    }

    getInvalidPanel() {
        let panels = this.tabContainer.getPanels();
        let invalidPanel = undefined;
        for (let panelIdx in panels) {
            let panel = panels[panelIdx];
            let recordView = this.tabContainer.getRecordView(panel.getId());
            let validRecord = recordView.validate();
            if (!validRecord) {
                return invalidPanel;
            }
        }
        return null;
    }

    buildRecordRequest(operation) {
        let submitRequest = new SubmitRecordRequest(this.domainContext, this.taskConfig.getId());
        submitRequest.setOperation(operation);
        var recordContext = new Context({ contextId: 'record' });
        let panelList = this.tabContainer.getPanels();
        for (let panel in panelList) {
            let currentPanel = panelList[panel];
            recordContext.addPanelFields(this.tabContainer.getRecordView(currentPanel.getId()).getFieldsValues(), currentPanel.getId());
        }
        submitRequest.setRecordContext(recordContext);
        var keyContext = new Context({
            contextId: 'key'
        });
        if(operation !== 'new') {
            keyContext.addFields(this.getKeys());
        } else {
            keyContext.addFields(this.taskConfig.getKeysForNewEntry());
        }
        submitRequest.setKeyContext(keyContext);
        return submitRequest;
    }

    buildAndDisplayRecordView (result) {
        if(!this.isInline()) {
            super.buildAndDisplayRecordView();

            this.buildOrSetRecordBar();
            var displayMode = 'desktop';

            if (DeviceUtils.isMediumDevice() || DeviceUtils.isSmallDevice()){
                displayMode = 'tablet';
            }
            if (!this.tabContainer) {
                this.tabContainer = new RecordView({
                    el: this.recordEntry,
                    groups: [],
                    taskId: this.taskConfig.getId(),
                    dominantColor: '#161616',
                    displayMode: displayMode,
                    domainContext: this.domainContext
                });
            } else {
                this.tabContainer.setDisplayMode(displayMode);
            }
            this.tabContainer.setEnabled(true);
            this.currentDisplayedTask = new Task(result.task, this.domainContext);
            let recordViews = this.tabContainer.getRecordViews();
            for(let index in recordViews) {
                recordViews[index].setValuesAndPositions(this.currentDisplayedTask.getPanel(index).getGroups(),true);
            }
            this.tabContainer.render();
            this.switchToRecord();
            this.addDownloadNoteItem(result);
        } else {
            this.currentDisplayedTask = new Task(result.task, this.domainContext);
            this.tabContainer.setEnabled(true);
            let recordViews = this.tabContainer.getRecordViews();
            for(let index in recordViews) {
                recordViews[index].setValuesAndPositions(this.currentDisplayedTask.getPanel(index).getGroups(),true);
            }

            let headerId = this.taskConfig.getHeaderID();
            let dataGridRequest = this.taskConfig.getNavigatorGridView();
            let dataHandler = this.onNavigatorGridReceived.bind(this);
            if(headerId !== undefined) {
                // Get grid data on headerId instead of navigator
                dataGridRequest = headerId;
                dataHandler = this.onGridReceived.bind(this);
            }
            //Performing request for the current record
            if((this.presentation & TaskPresentation.ShowRecordHeader) === TaskPresentation.ShowRecordHeader) {
                let dataRequest = new GetGridDataRequest(this.domainContext,this.taskConfig.getId(), dataGridRequest);
                let filterContext = new Context();
                this.onKeyRequested(filterContext);
                dataRequest.setFilterContext(filterContext);
                Server.performRequest(dataRequest)
                    .then(dataHandler)
                    .catch(App.displayErrorMessage);
            }
        }
    }

    updateTaskViewWithRecord() {
        this.tabContainer.setEnabled(true);

        if((this.presentation & TaskPresentation.ShowRecordHeader) === TaskPresentation.ShowRecordHeader) {
            let headerId = this.taskConfig.getHeaderID();
            if(headerId !== undefined) {
                // Get grid data on headerId
                let dataRequest = new GetGridDataRequest(this.domainContext,this.taskConfig.getId(), headerId);
                let filterContext = new Context();
                filterContext.setContextId('key');
                filterContext.addFields(this.recordKey);
                dataRequest.setFilterContext(filterContext);
                Server.performRequest(dataRequest)
                    .then(this.onGridReceived.bind(this))
                    .catch(App.displayErrorMessage);
            } else {
                // Get grid data on navigator grid
                let dataRequest = new GetGridDataRequest(this.domainContext,this.taskConfig.getId(), this.taskConfig.getNavigatorGridView());
                let filterContext = new Context();
                filterContext.setContextId('key');
                filterContext.addFields(this.recordKey);
                dataRequest.setFilterContext(filterContext);
                Server.performRequest(dataRequest)
                    .then(this.onNavigatorGridReceived.bind(this))
                    .catch(App.displayErrorMessage);
            }
        }
    }

    onNavigatorGridReceived(result) {
        let me = this;
        let titleDataFields = [];
        let gridHeader = new Header(result.headers);
        let columns = gridHeader.getColumns();
        let allPromises = [];
        for(let i in columns) {
            let col = columns[i];
            let dataFieldId = col.getDatafieldId();
            allPromises.push(CClientConfiguration.getDataField(dataFieldId,me.domainContext,me.taskConfig));
        }

        if(allPromises.length > 0) {
            Promise.all(allPromises)
                .then((dataFields) => {
                    for( let i in dataFields) {
                        let dataField = dataFields[i];
                        titleDataFields.push(dataField);
                    }
                    me.tabContainer.updateHeader(result.items[0], gridHeader,titleDataFields);
                });
        }
    }

    onGridReceived(result) {
        //TODO
    }

    resizeTask (mode) {
        super.resizeTask(mode);
        if (this.tabContainer && this.recordEntry && this.recordEntry.is(':visible')) {
            var result = {
                groups: this.tabContainer.rawGroups
            };
            //this.buildAndDisplayRecordView(result);
        } else {
            this.resizeMainNav(mode);
        }
    }

    resizeMainNav (mode) {
        this.renderNavigatorTabBar();
        var heightToSet = 0;
        if (mode === 'tablet' || mode === 'phone') {
            //we've a nav bar
            heightToSet = this.navigatorTabBarEl.offset().top - this.navigator.dataElement.offset().top - 5;
        } else {
            heightToSet = $(window).height() - this.navigator.dataElement.offset().top - 5;
        }

        this.navigator.dataElement.css({
            height: heightToSet
        });
    }

    getRecordBarElement () {
        return this.$el.find('.recordTabBar');
    }

    getRecordBarTabs () {
        var tabs = [
            {
                id: 'home',
                name: 'Home',
                icon: 'home',
                enabled: true
            }, {
                id: 'back',
                name: 'Retour',
                icon: 'arrow-left',
                enabled: true
            }, {
                id: 'save',
                name: 'Valider',
                icon: 'floppy-o',
                enabled: this.canModify
            }, {
                id: 'delete',
                name: 'Effacer',
                icon: 'trash',
                enabled: this.canDelete
            }
        ];

        if (this.mode === 'modify' && this.getTplsOfType('single').length > 0) {
            tabs.push({
                id: 'report',
                name: 'Rapports',
                icon: 'file',
                enabled: true
            });
        }

        return tabs;
    }

    handleTaskHeaderAction (action, actionArguments) {
        if (action === 'close') {
            if (this.recordEntry.is(':visible') && !this.navigator.$el.is(':visible')) {
                this.switchToMainNavigator();
            } else {
                window.App.popTask();
            }
        } else if(action === 'notes') {
            var nd = new NoteDownload(this.getNotes());
            nd.display();
        } else{
            super.handleTaskHeaderAction(action, actionArguments);
        }
    }

    switchToAlbum(){
        this.recordBanner.$el.hide();
        this.recordEntry.hide();
        if (DeviceUtils.isFullScreenPageNav()) {
            this.recordTabBar.$el.hide();
        }
        super.switchToAlbum();
    }

    switchToMainNavigator () {
        this.coloredTitle.hide();
        window.App.taskMenu.requestTaskSpecificIconUpdate(this.taskConfig.getId(), null);
        if(this.recordBanner) { this.recordBanner.$el.hide(); }
        this.recordEntry.hide();
        if (DeviceUtils.isFullScreenPageNav()) {
            this.recordTabBar.$el.hide();
        }
        this.navigator.$el.show();
        if (this.navigatorTabBar) {
            this.navigatorTabBar.$el.show();
        }
    }

    addDownloadNoteItem(item) {
        var notes = item.task.notes;
        if (DeviceUtils.isFullScreenPageNav()) {
            if (notes !== undefined && notes.length) {
                this.setNotes(Note.buildNoteArray(notes));
                
                this.recordTabBar.addIfAbsent({
                    id: 'notes',
                    name: 'Notes',
                    icon: 'cloud-download',
                    enabled: true
                });
            } else {
                this.recordTabBar.removeItem('notes');
            }
            this.recordTabBar.render();
        } else {
            if (notes !== undefined && notes.length) {
                this.setNotes(Note.buildNoteArray(notes));
                let downloadIcon = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-note-download');
                let downloadItem = new MenuItemView({
                    label: 'Télécharger les notes',
                    iconPath: downloadIcon,
                    actionType: 'notes',
                    id : 'DOWNLOAD'
                });
                downloadItem.addTextOverlay(notes.length);
                this.desktopActionsMenu.addMenuItem(downloadItem);
            }
        }
    }

    onNotesClick() {
        var nd = new NoteDownload(this.getNotes());
        nd.display();
    }

    isInline() {
        return (this.taskType === 'Simple' && (!DeviceUtils.isFullScreenPageNav() || this.isModal()));
    }

    buildOrSetRecordBanner(record) {
        if(!this.isInline() && this.displayHeader) {
            super.buildOrSetRecordBanner(record);
        }
    }

    updateScreenContent(notificationScreen) {
        let fieldStack = [];
        let recordViews  = this.tabContainer.getRecordViews();
        for(let screenEntry in notificationScreen){
            let someField = new Field(notificationScreen[screenEntry], null, null, null, this.domainContext);
            for(let key in recordViews) {
                let foundField = recordViews[key].getField(someField.getId());
                if(foundField && (foundField.getConfig().getDatafieldId() === someField.getDatafieldId() || (
                    foundField.getConfig().isCouple() && foundField.getConfig().getLinkedCouple().getConfig().keyVar === someField.getDatafieldId()
                ))) {
                    fieldStack.push([recordViews[key], someField]);
                    break;
                }
            }
        }
        let requestId;
        LoadingMask.requestLoading()
            .then((reqId) => {
                this.setRecordLoading(true);
                requestId = reqId;
                return this.applyChange(fieldStack)
            })
            .then(() => {
                for(let key in recordViews) {
                    let groups = recordViews[key].getGroups();
                    for(let i in groups) {
                        groups[i].moveFieldsToPositions();
                    }
                }
            })
            .catch((error) => {
                LoadingMask.hide(requestId);
                App.displayErrorMessage(error);
            })
            .then(() => {
                this.setRecordLoading(false);
                LoadingMask.hide(requestId);
            });
    }

    setRecordLoading(isLoading) {
        let recordViews = this.tabContainer.getRecordViews();
        for (let key in recordViews) {
            recordViews[key].$el.toggleClass('loading', isLoading);
        }
    }

    applyChange(fieldStack) {
        let me = this;
        if(fieldStack.length > 0) {
            return new Promise((accept, reject) => {
                let record = fieldStack[0][0];
                let field = fieldStack[0][1];
                record.setField(field, false, true);
                record.getField(field.getId()).performChange(true, undefined, true)
                    .then(() => {
                        fieldStack.shift();
                        return me.applyChange(fieldStack);
                    })
                    .then(accept)
                    .catch(reject);
            });
        } else {
            return new Promise((accept) => {
                accept();
            })
        }
    }

    displayPanel(panelId) {
        this.tabContainer.selectTab(panelId);
    }

    setDominantColor(color) {
        let recordsPanels = this.getRecordViews();
        for (let key in recordsPanels) {
            recordsPanels[key].setDominantColor(color);
        }
    }

    getDatafieldPanelId(datafieldId) {
        let recordsPanels = this.getRecordViews();
        for(let key in recordsPanels) {
            let curRecord = recordsPanels[key];
            if(curRecord.getFieldByDatafieldId(datafieldId)) {
                return key;
            }
        }

        return undefined;
    }
}

export { SimpleTask };
