module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <div class="checkbox">\n      <input class="changeableField" type="checkbox" ' +
__e( fieldState.getValue() === '1' ? 'checked':'') +
' ' +
__e( fieldState.isReadOnly() || !fieldState.isEnabled() ? 'disabled' : '') +
' >\n      <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>\n      <label>\n          ' +
__e( fieldState.getLabel() ) +
'\n    </label>\n    <i class="fa fa-cloud-download note-download"></i>\n  </div>\n</div>';

}
return __p
}