class Logger {
    static error(className, functionName, message){
        Logger.checkConsoleFunction('error');
        console.error('Error in ' + className + '@' + functionName + ': '+message);
    }

    static warn(className, functionName, message):void{
        Logger.checkConsoleFunction('warn');
        console.warn('Warning in ' + className + '@' + functionName + ': '+message);
    }

    static log(className, functionName, message){
        Logger.checkConsoleFunction('log');
        console.log('Debug in ' + className + '@' + functionName + ': '+message);
    }

    static checkConsoleFunction(fName){
        if(!console || console[fName] === undefined){
            throw new Error('Cannot output ' + fName + ' to console');
        }
    }
}

export default Logger;