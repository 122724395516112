'use strict';

import { DomainContext } from '../DomainContext';

class Key {
    private datafields: { [dfId:string] : string };
    private context: DomainContext;

    constructor(config : { datafields: { [dfId:string] : string} }, context: DomainContext) {
        this.datafields = config.datafields;
        this.context = context;
    }

    public getDatafield(dfId: string) : string {
        return this.datafields[dfId];
    }

    public getDatafields() : { [dfId:string] : string} {
        return this.datafields;
    }

    public hasDatafield(dfId: string): boolean {
        return this.datafields[dfId] !== undefined;
    }

    public getContext(): DomainContext {
        return this.context;
    }
}

export { Key }; 