'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class WallpaperRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _themeId: string, _id : string){
        super('theme/' + _themeId + '/wallpaper', domain, _id);
    }
}

export { WallpaperRequest };