'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class GetTaskRequest extends TaskRequest{
    constructor(domain:DomainContext, task){
        super('getTask','GET', domain, task);

    }
}

export default GetTaskRequest;