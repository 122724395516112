'use strict';

import { DomainContext } from '../DomainContext';
import { Key } from './Key';
import { BasePanel } from './BasePanel';
import { ConfigurationElement } from './ConfigurationElement';
import { Group } from './Group';
import { Field } from './Field';
import { Couple } from './Couple';

class Level extends ConfigurationElement {
    private key : Key;
    private parentLevel: Level;
    private parentPanel: BasePanel;
    private parentGroup: Group;
    private children: Array<Group | Level | Field>;

    public static CHILD_TYPE_KEY:string = 'key';

    constructor(config: { id: string, key: any, children:Array<any> }, parentPanel: BasePanel, parentLevel: Level, parentGroup: Group, context: DomainContext, ) {
        super(config, context);
        this.parentLevel = parentLevel;
        this.parentPanel = parentPanel;
        this.parentGroup = parentGroup;

        this.children = [];
        for(let key in config.children) {
            let childConf = config.children[key];
            let cType = Level.getObjectType(childConf.objectType);
            if(cType === BasePanel.CHILD_TYPE_LEVEL) {
                let newChild = new Level(childConf, parentPanel, this, this.parentGroup, context);
                this.children.push(newChild);
            } else if(cType === BasePanel.CHILD_TYPE_GROUP) {
                let newChild = new Group(childConf, parentPanel, this, context);
                this.children.push(newChild);
            } else if(cType === Level.CHILD_TYPE_KEY) {
                this.key = new Key(childConf, context);
            } else if(cType === Group.CHILD_TYPE_FIELD
                || cType === Group.CHILD_TYPE_CHECKBOX_FIELD) {
                let newChild = new Field(childConf, this.parentPanel.getParentTask(), this.parentGroup, this, context);
                this.children.push(newChild);
            } else if(cType === Group.CHILD_TYPE_COUPLE_FIELD) {
                let newChild = new Couple(childConf, this.parentPanel.getParentTask(), this.parentGroup, this, context);
                this.children = this.children.concat(newChild.getFields());
            } else {
                console.warn('Invalid child type in level : ' + cType);
            }
        }
    }

    public getKey(): Key {
        return this.key;
    }

    public getParentLevel() : Level{
        return this.parentLevel;
    }

    public getParentPanel(): BasePanel {
        return this.parentPanel;
    }
}

export { Level }; 