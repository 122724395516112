module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="documentAlbumItem" data-id="' +
((__t = (index)) == null ? '' : __t) +
'">\n    <div class="documentAlbumCorner"><img src="images/options.png"/></div>\n    <div class="documentAlbumItemImage"></div>\n    <div class="documentAlbumItemText">' +
((__t = (name)) == null ? '' : __t) +
'</div>\n</div>    ';

}
return __p
}