'use strict';

import MobileRequest from '../MobileRequest';

class AuthenticationRequest extends MobileRequest{
    constructor(service, method){
        super('auth', service, method, undefined);
    }

    getName(){
        return 'AuthenticationRequest';
    }
}

export { AuthenticationRequest };