module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var rgbVersion = colorUtils.hexToRgb(operationColor);
var hslVersion = colorUtils.rgbToHsl(rgbVersion.r, rgbVersion.g, rgbVersion.b);
var increase = hslVersion.h < 150 ? -1 : 1;
hslVersion.h = Math.min(255, hslVersion.h + increase * hslVersion.h * 0.05);
var newRgb = colorUtils.hslToRgb(hslVersion);
var secondBase = colorUtils.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
var darkerSecond = colorUtils.shadeColor(secondBase, - 0.10);
var darkerFirst = colorUtils.shadeColor(operationColor, - 0.05);
var patternId = Math.random();
;
__p += '\n<div class="modal fade ' +
((__t = (extraClass)) == null ? '' : __t) +
'" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" role="dialog" aria-labelledby="recordOperationModalLabel" aria-hidden="true" data-toggle="modal" ';
 if(!allowDismiss) { ;
__p += ' data-backdrop="static" data-keyboard="false"';
 } ;
__p += '>\n    <div class="modal-dialog ' +
((__t = (extraClass)) == null ? '' : __t) +
'">\n        <div class="modal-content">\n            <div class="modal-header" style="background-color:' +
((__t = (operationColor)) == null ? '' : __t) +
'">\n                <div class="modal-title-header">\n                    <svg width="100%" height="100%">\n                        <pattern id="pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
'" x="0" y="0" width="180" height="15" patternUnits="userSpaceOnUse">\n                            <rect style="fill:' +
((__t = (secondBase)) == null ? '' : __t) +
';" width="90" height="15" x="0" y="0"></rect>\n                            <rect style="fill:' +
((__t = (operationColor)) == null ? '' : __t) +
';" width="90" height="15" x="90" y="0"></rect>\n                            <path style="fill:' +
((__t = (darkerSecond)) == null ? '' : __t) +
';" d="M 0,0 0,15 H 15 Z"></path>\n                            <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 90,0 90,15 H 105 Z"></path>\n                            <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 135,0 135,15 H 150 Z"></path>\n                        </pattern>\n                        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
')" />\n                    </svg>\n                </div>\n                ';
 if (!hideTitle) { ;
__p += '\n                <div class="modal-title recordFormTitle" style="background-color:' +
((__t = (operationColor)) == null ? '' : __t) +
'">' +
((__t = (operationTitle)) == null ? '' : __t) +
'</div>\n                ';
 } ;
__p += '\n                ';
 if(allowDocs) { ;
__p += '\n                <div class="documentHandlerIcon modal-title" style="background-color:' +
((__t = (operationColor)) == null ? '' : __t) +
'">\n                    <div class="documentHandlerFileAdd">\n                        <img src="images/file_add_mini.png" alt="Add"/>\n                        <input class="recordDocAddFormInput recordDocAddFormFile formInput" type="file" name="file" multiple>  \n                    </div>\n                    <i class="fa fa-file-o documentPanelSwitch"></i>\n                </div>\n                ';
 } ;
__p += '\n            </div>\n            <div class="modal-body">\n                <div class="innerView"></div>\n                <div class="subView"></div>\n            </div>\n            <div class="modal-footer">\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}