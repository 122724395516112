module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="filterElement filterElementText">\n    <div class="row">\n        <div class="col-md-4">\n            ';
 if(element.fEnabled === false) { ;
__p += '\n                <select class="form-control" disabled>\n                    <option selected>' +
((__t = (element.fLabel)) == null ? '' : __t) +
'</option>\n                </select>    \n            ';
 } else { ;
__p += '\n                <select class="form-control">\n                    <option selected>' +
((__t = (element.fLabel)) == null ? '' : __t) +
'</option>\n                </select>       \n            ';
 } ;
__p += '\n        </div>\n        <div class="col-md-4">\n            ';
 if(element.fEnabled === false) { ;
__p += '\n                <select class="form-control" disabled>\n                    <option value="' +
((__t = (element.finalStruct.type)) == null ? '' : __t) +
'" selected>' +
((__t = (element.finalStruct.type)) == null ? '' : __t) +
'</option>\n                </select>    \n            ';
 } else { ;
__p += '\n                <select class="form-control">\n                    <option value="' +
((__t = (element.finalStruct.type)) == null ? '' : __t) +
'" selected>' +
((__t = (element.finalStruct.type)) == null ? '' : __t) +
'</option>\n                </select>       \n            ';
 } ;
__p += '\n        </div>\n        <div class="col-md-4">\n            ';
 if(element.fEnabled === false) { ;
__p += '\n                <input class="form-control" value="' +
((__t = (element.finalStruct.value)) == null ? '' : __t) +
'" disabled/>    \n            ';
 } else { ;
__p += '\n                <input class="form-control" value="' +
((__t = (element.finalStruct.value)) == null ? '' : __t) +
'"/>          \n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>';

}
return __p
}