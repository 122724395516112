module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal fade" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" role="dialog" data-keyboard="' +
((__t = (canDismiss)) == null ? '' : __t) +
'" aria-hidden="true" data-backdrop="static">\n    <div class="modal-dialog ' +
((__t = (extraClass)) == null ? '' : __t) +
'">\n        <div class="modal-content">\n            \n        </div>\n    </div>\n</div>';

}
return __p
}