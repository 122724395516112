'use strict';

import { BasePanel } from './BasePanel';
import { Task } from './Task';
import { DomainContext } from '../DomainContext';
import { Group } from './Group';
import * as _ from 'lodash';
import { Field } from './Field';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import { DataField } from './DataField';

class Page extends BasePanel {
    constructor(config: {id : string, groups: any, datafields: any}, parentTask: Task, context: DomainContext) {
        super(config, parentTask, context);

        let mandatoryFields = ['FM_PAGE_CREATION_DATE', 'FM_PAGE_MODIFICATION_DATE', 'FM_PAGE_VERSION'];
        for(let grpId in this.groups) {
            let curGrp: Group = this.groups[grpId];
            let foundFields = [];
            for(let toLookId in mandatoryFields) {
                let toLook: string = mandatoryFields[toLookId];
                if(curGrp.hasField(toLook)) {
                    foundFields.push(toLook);
                }
            }
            mandatoryFields = _.difference(mandatoryFields, foundFields);
        }

        if(this.groupsArr.length > 0) {
            for(let remId in mandatoryFields) {
                this.createMissingField(mandatoryFields[remId]);
            }
        } else {
            console.warn('The page ' + this.getId() + ' has no group');
        }

        for(let key in config.datafields) {
            let newDf = new DataField(config.datafields[key], null, null, parentTask, context);
            newDf.setSourcePageId(config.id);
            this.parentTask.addDataField(newDf);
        }
    }

    private createMissingField(fieldId: string) {
        let newField: Field = new Field({
            id: fieldId,
            datafieldId: this.parentTask.getTableID().substring(0,6) + '.' + this.getId() + '.' + fieldId,
            position: {
                tablet: {
                  fieldColumn: 1, fieldWidth: 0, row: -99,
                  height: 1, visible: false, enabled: false,
                  labelVisible: false
                },
                phone: {
                  fieldColumn: 1, fieldWidth: 0, row: -99,
                  height: 1, visible: false, enabled: false,
                  labelVisible: false
                },
                desktop: {
                  fieldColumn: 1, fieldWidth: 0, row: -99,
                  height: 1, visible: false, enabled: false,
                  labelVisible: false
                }
              },
            autoAddedSystemField: true
        }, this.getParentTask(), this.groupsArr[0], undefined, this.getContext());
        this.groupsArr[0].addField(newField);
    }

    public getFieldI18N(fieldId: string) {
        return this.getParentTask().getId() + '.' + this.getId() + '.' + fieldId;
    }

    public getI18NID(): string {
        return this.parentTask.getId() + '.' + this.getId()
    }

    protected getPanelI18NPromise() : Promise<any>{
        let me = this;
        let i18NID = this.getI18NID();
        return new Promise((accept, reject) => {
            let requiredResources: Array<Promise<any>> = [
                CClientConfiguration.getDataPage(me.parentTask.getTableID(), me.getId(), me.getContext()),
                CClientConfiguration.getI18nText(me.getContext(), 'task', i18NID, User.getLocale())
            ]
            Promise.all(requiredResources)
                .then(([datapage, i18Ntext]) => {
                    me.setLabel(i18Ntext ? i18Ntext : datapage.getLabel());
                    accept(undefined);
                })
                .catch(reject);
        })
    }
}

export { Page };