'use strict';

class ColorUtils {
    private static _instance: ColorUtils;

    //Singleton definition
    private constructor() {}
    public static get Instance(): ColorUtils {
        return this._instance || (this._instance = new this());
    }

    /**
     * Transforms given number into its hexadecimal value
     * @param {number} c Number to transform
     * @returns {string} The hexadecimal string representing this number
     */
    componentToHex(c: number) : string{
        var hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }

    /**
     * Transforms rgb color parameters into an hexadecimal representation
     * @param {number} r Red color
     * @param {number} g Green color
     * @param {number} b Blue color$
     * @returns {color} The hex value of this color
     */
    rgbToHex(r: number, g: number, b: number): string {
        return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }

    hexToRgb(hex: string): {r: number, g: number, b: number} {
        return {
            r: parseInt(hex.substring(1, 3), 16),
            g: parseInt(hex.substring(3, 5), 16),
            b: parseInt(hex.substring(5, 7), 16)
        };
    }

    /**
     * Transforms rgb color parameters into an hsl representation Array
     * WARNING: s and l are given as % (i.e 75, 28, etc..)
     * @param {number} r Red color
     * @param {number} g Green color
     * @param {number} b Blue color$
     * @returns {color} The hsl value of this color
     */
    rgbToHsl(r: number, g: number, b: number) : {h: number, s: number, l: number }{
        // Make r, g, and b fractions of 1
        r /= 255;
        g /= 255;
        b /= 255;

        // Find greatest and smallest channel values
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;
        // Calculate hue
        // No difference
        if (delta == 0)
            h = 0;
        // Red is max
        else if (cmax == r)
            h = ((g - b) / delta) % 6;
        // Green is max
        else if (cmax == g)
            h = (b - r) / delta + 2;
        // Blue is max
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        // Make negative hues positive behind 360°
        if (h < 0)
            h += 360;

        // Calculate lightness
        l = (cmax + cmin) / 2;

        // Calculate saturation
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Multiply l and s by 100
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return {h: h, s:s, l:l};
    }

    /**
     * Convert a hsl color in an rgb equivalent
     * @param color The hsl color to convert
     */
    hslToRgb(color: { h: number, s: number, l: number }): { r: number, g: number, b: number } {
        // Must be fractions of 1
        color.s /= 100;
        color.l /= 100;

        let c = (1 - Math.abs(2 * color.l - 1)) * color.s,
            x = c * (1 - Math.abs((color.h / 60) % 2 - 1)),
            m = color.l - c / 2,
            r = 0,
            g = 0,
            b = 0;
        if (0 <= color.h && color.h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= color.h && color.h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= color.h && color.h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= color.h && color.h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= color.h && color.h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= color.h && color.h < 360) {
            r = c; g = 0; b = x;
        }
        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);

        return {r:r, g:g, b:b};
    }

    /**
     * Darken or lighten color
     * @param {color} color Hexadecimal color with # provided
     * @param {number} percent Value between -1 and 1. Negative will darken, positive will lighten
     * @returns {color} The hex value after transformation
     */
    shadeColor(color: string, percent: number) : string {
        var f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
        return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    }

    /**
     * Merges two colors
     * @param {color} c0 The first color to blend
     * @param {color} c1 The second color to blend
     * @param {number} p value between 0 and 1. 0 is 100% first color, 1 is 100% second color
     * @returns {String} The hex value after blending
     */
    blendColors(c0: string, c1: string, p: number) : string {
        var f = parseInt(c0.slice(1), 16), t = parseInt(c1.slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF, R2 = t >> 16, G2 = t >> 8 & 0x00FF, B2 = t & 0x0000FF;
        return '#' + (0x1000000 + (Math.round((R2 - R1) * p) + R1) * 0x10000 + (Math.round((G2 - G1) * p) + G1) * 0x100 + (Math.round((B2 - B1) * p) + B1)).toString(16).slice(1);
    }

    /**
     * Generates a random hex color
     * @returns {String} The color string ex: #3ef46a
     */
    public getRandomColor(stringSeed?: string): string {
        if(stringSeed === undefined || stringSeed === null) {
            var letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ.-_'.split('');
            stringSeed = '';
            for (var i = 0; i < 10; i++) {
                stringSeed += letters[Math.floor(Math.random() * letters.length)];
            }
        }
        let eventNumber = this.getStringHashValue(stringSeed);
        let hexValue = eventNumber.toString(16);
        let r = parseInt(hexValue.substr(0,2),16);
        let g = parseInt(hexValue.substr(0,2),16);
        let b = parseInt(hexValue.substr(0,2),16);

        let baseR = 25;
        let baseG = 70;
        let baseB = 175;

        let rH = Math.floor((r + baseR) / 2).toString(16);
        let gH = Math.floor((g + baseG) / 2).toString(16);
        let bH = Math.floor((b + baseB) / 2).toString(16);
        var color = '#' + (rH.length >1 ? rH : '0'+ rH) +
            (gH.length >1 ? gH : '0'+ gH) +
            (bH.length >1 ? bH : '0'+ bH);
        return color;
    }

    /**
     * Returns a hash of a given string
     * @param stringValue The pseudo unique hash value
     */
    private getStringHashValue(stringValue: string) {
        var seed = 131;
        var seed2 = 137;
        var hash = 0;
        // make hash more sensitive for short string like 'a', 'b', 'c'
        stringValue += 'x';
        // Note: Number.MAX_SAFE_INTEGER equals 9007199254740991
        var MAX_SAFE_INTEGER = parseInt((9007199254740991 / seed2) + '');
        for(var i = 0; i < stringValue.length; i++) {
            if(hash > MAX_SAFE_INTEGER) {
                hash = parseInt((hash / seed2) + '');
            }
            hash = hash * seed + stringValue.charCodeAt(i);
        }
        return hash%16581375;
    }

    private hashCode(str: string) : number { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 6) - hash);
        }
        return hash;
    }
    
    public intToRGB(str: string) : string {
        let i = this.hashCode(str);
        let c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return '#'+'00000'.substring(0, 6 - c.length) + c;
    }
}

const singleInstance = ColorUtils.Instance;

export default singleInstance;