module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var btnClass = ''; if(enabled) { btnClass = 'tab-button'; } else { btnClass =  'disabled-tab-button'; } ;
__p += '\n';
 if ( type ) { btnClass = btnClass + ' ' + 'tab-button-' + type; } ;
__p += '\n<div data-id="' +
((__t = (id)) == null ? '' : __t) +
'" class="' +
((__t = (btnClass)) == null ? '' : __t) +
'">\n    ';
 if(type === 'text') { ;
__p += '\n        <div class="tab-button-text-only tab-text">\n            ' +
((__t = (name)) == null ? '' : __t) +
'\n        </div>\n    ';
 } else if(type === 'icon') { ;
__p += '\n        <div class="tab-button-icon-only">\n            <i class="fa fa-' +
((__t = (icon)) == null ? '' : __t) +
'"></i>\n        </div>\n    ';
 } else if(type === 'sideText') { ;
__p += '\n        <div class="tab-button-icon-side">\n            <i class="fa fa-' +
((__t = (icon)) == null ? '' : __t) +
'"></i>\n        </div>\n        <div class="tab-button-text-side tab-text">\n            ' +
((__t = (name)) == null ? '' : __t) +
'\n        </div>\n    ';
 } else { ;
__p += '\n        <div class="tab-button-icon">\n            <i class="fa fa-' +
((__t = (icon)) == null ? '' : __t) +
'"></i>\n        </div>\n        <div class="tab-button-text tab-text">\n            ' +
((__t = (name)) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
}