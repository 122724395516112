'use strict';

import ModalConfirmationDialog from '../views/modal/ModalConfirmationDialog';

class DialogUtils {
    static displayDialog(dialogOptions: {[key:string] : string | Array<any>}, onChoose?: (choice:string) => any, onReject?: (reason:any) => any) {
        var confirmationDialog = new ModalConfirmationDialog(dialogOptions);
        let display = confirmationDialog.display();
        if(onChoose) {
            display = display.then(onChoose);
        }
        if(onReject) {
            display = display.catch(onReject);
        }
    }

}

export default DialogUtils;