'use strict';

import * as Backbone from 'backbone';


class PendingFileModel extends Backbone.Model{
    /*url: '',

    initialize: function() {
    },

    defaults: {
    },

    validate: function(attrs, options) {
    },

    parse: function(response, options)  {
        return response;
    }*/
}

export default PendingFileModel;
