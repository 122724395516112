'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class I18NRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('texts', domain, _id);
    }
}

export { I18NRequest };