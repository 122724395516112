'use strict';

import MobileRequest from 'server/protocol/request/MobileRequest';
import Context from 'models/context/Context';
import { DomainContext } from '../../../../parametrage/DomainContext';

interface MessageResponse {
    status: string,
    type: string,
    code: string,
    response: Array<{
        id: string,
        type: string,
        response: string
    }>
}

class TaskRequest extends MobileRequest{
    protected taskId : string;
    protected operation : string;
    protected documents : any;
    protected record : Context;
    protected page : Context;
    protected key: Context;
    protected message: MessageResponse;
    protected filter: Context;

    constructor(service, method, domainContext:DomainContext, task){
        super('task', service, method, domainContext);
        this.taskId = task;
    }

    public getTaskId(): string {
        return this.taskId;
    }

    getUrl(){
        let url = this.controller;
        url += '/' + this.domainContext.getDomain();
        url += '/' + this.taskId;
        url += '/' + this.service;

        return url;
    }

    setPageContext(context: Context){
        this.page = context;
    }

    setRecordContext(context: Context) {
        this.record = context;
    }

    setKeyContext(context: Context) {
        this.key = context;
    }

    setFilterContext(context: Context) {
        this.filter = context;
    }

    getSerializable(): {[prop:string]: any} {
        return {
            request1: {
                record: this.record? this.record.toJSON(): undefined,
                page: this.page? this.page.toJSON(): undefined,
                key: this.key? this.key.toJSON(): undefined,
                filter: this.filter? this.filter.toJSON(): undefined,
                message: this.message? this.message : undefined
            }
        }
    }

    setMessageContext(response: MessageResponse) {
        this.message = response;
    }
}

export { TaskRequest, MessageResponse };