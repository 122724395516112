'use strict';

import { AuthenticationRequest } from './AuthenticationRequest';

class PasswordUpdateRequest extends AuthenticationRequest{
    private userId : string;
    private oldPassword : string;
    private newPassword : string;

    constructor(userId:string, oldPassword:string, newPassword: string){
        super('updatepassword', 'GET');
        this.userId = userId;
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
    }

    getSerializable(){
        return {
            'auth-login': this.userId,
            'auth-pass' : this.oldPassword,
            request1: {
                userId: this.userId,
                oldPassword : this.oldPassword,
                newPassword : this.newPassword
            }
        };
    }

    getName(){
        return 'PasswordUpdateRequest';
    }
}

export { PasswordUpdateRequest as PasswordUpdateRequest };