'use strict';

let $ = require('jquery');
import App from 'App';
import Server from 'server/Server';

import DocumentsCollection from 'collections/Documents';
import DocumentModel from 'models/Document';
import PendingFileModel from 'models/PendingFile';
import UploadingFileView from 'views/album/UploadingFile';
import { SAIView } from '../../Additions';

class RecordPopupDocumentsView extends SAIView {
    private documents : DocumentsCollection;
    private upFilesViews : Array<any>;
    private upFilesIds : Array<any>;
    private documentsLoad : JQuery<HTMLElement>;
    private docsHolder : JQuery<HTMLElement>;
    private documentsMeta : any;
    private pendingEntry : any;

    constructor(options? : any) {
        super(options);
    }

    initialize (options : any) {
        this.options = options;
        this.template = require('ejs-loader!templates/RecordPopupDocuments.ejs');

        this.checkMandatoryParameter(options, 'mode', 'Document retreival condition depends of the mode');
        this.checkMandatoryParameter(options, 'taskId', 'Server call will depend of this parameter');
        this.checkMandatoryParameter(options, 'gridId', 'Server call will depend of this parameter');
        this.checkMandatoryParameter(options, 'glyphHolder', 'Icon holder must be defined to update the icon based on the number of documents');
        if (options.mode !== 'new') {
            this.checkMandatoryParameter(options, 'recordKeys', 'Server call will depend of this parameter');
        }
    }

    render () : any {
        this.checkInitialized();
        this.$el.html(this.template());

        this.documents = new DocumentsCollection();
        this.listenTo(this.documents, 'reset', this.displayDocumentList);
        //Will contain views corresponding to documents
        this.upFilesViews = [];
        this.upFilesIds = [];

        this.documentsLoad = this.$el.find('.centeredSpinner');
        this.docsHolder = this.$el.find('.docHolder');
        this.docsHolder.hide();
    }

    onDOMUpdated () {
        if (this.parameters['mode'] !== 'prepareNewEntry') {
            //VBH Fixme
            //App.getDocuments(this.parameters['taskId'], this.parameters['recordKeys'], this.parameters['gridId'], this.onDocumentsReceived.bind(this));
        }else{
            this.documentsLoad.remove();
            this.displayNewDocBtn();
        }
    }

    addNewFile (fileField) {
        this.docsHolder.scrollTop(0);
        for (var i = 0; i < fileField.files.length; i++) {
            var newFile = fileField.files[i];
            var newModel = new PendingFileModel({
                file: newFile,
                meta: $.extend(true, {}, this.documentsMeta)
            });
            var newView = this.renderNewDocument(newModel,'uploading','progress');
            //VBH Fixme
            //App.putFile(newFile, this.onFileAdded.bind(this, newView), this.onFileProgress.bind(this, newView));
        }
    }

    onTitleChange(evt){
        if(this.pendingEntry){
            this.pendingEntry.wantedName = $(evt.currentTarget).val();
        }
    }

    renderNewDocument(newModel, status, mode){
        var newDiv = $('<div class="docAddItem"></div>').prependTo(this.docsHolder);
        var newView = new UploadingFileView({
            template: 'RecordPopupDocumentsItem',
            model: newModel,
            el: newDiv,
            status: status,
            preview: false
        });
        newView.setMode(mode);
        newView.render();
        this.upFilesViews.push(newView);
        this.listenTo(newView, 'downloadIconClicked', this.onDocClicked.bind(this));
        return newView;
    }

    onDocClicked(model, evt){
        App.downloadDocument(Server.getTokenedUrl('document/'+model.get('file').docId+'/'+model.get('file').name));
    }

    onDocumentsReceived (response) {
        this.documentsLoad.remove();

        if (response.documents) {
            this.documents.reset(this.buildDocumentList(response.documents));
        }
        this.documentsMeta = response.fields;
        this.displayNewDocBtn();
        var classToAdd;
        if (this.documents.length > 0) {
            classToAdd = 'fa fa-file-text';
        } else {
            classToAdd = 'fa fa-file-o';
        }
        this.parameters['glyphHolder'].removeClass();
        this.parameters['glyphHolder'].addClass(classToAdd);


    }

    buildDocumentList (docs) {
        var docsModels = [];
        for (var key in docs) {
            var currentDoc = docs[key];
            var fileData = {};
            for(var entry in currentDoc){
                fileData[entry] = currentDoc[entry].value;
            }
            docsModels.push(new DocumentModel({
                file: fileData
            }));
        }
        return docsModels;
    }

    displayDocumentList () {
        for (var i = 0; i < this.documents.length; i++) {
            this.renderNewDocument(this.documents.at(i), 'cloud', 'edit');
        }
    }

    displayNewDocBtn () {
        this.docsHolder.show();
    }

    onFileAdded (view, response) {
        view.setMode('edit');
        view.setStatus('pending');
        view.render();
        view.$el.find('.documentAddTitle').change(this.onTitleChange.bind(view));
        var newPendingEntry = {
            pendingId : response.id,
            wantedName: view.$el.find('.documentAddTitle').val()
        };
        view.pendingEntry = newPendingEntry;
        this.upFilesIds.push(newPendingEntry);
    }

    onFileProgress (view, evt) {
        if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total * 100;
            view.setProgress(percentComplete);
        }
    }
}

export default RecordPopupDocumentsView;
