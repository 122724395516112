'use strict';

import LoadingMask from 'views/loading/LoadingMask';
import App from 'App';
import Server from 'server/Server';
import MobileRequest from 'server/protocol/request/MobileRequest';

class ServerUtils {
    /**
     * This function allows to display a loading screen with the given text,
     * perform the request and handle the result with the given success function
     * or display the error using the standard error output. It then closes the
     * loading screen
     * @param loadingText The text to display while loading
     * @param request The request to send
     * @param successHandler The function to call on successfull operation
     */
    static loadRequest(loadingText: string, request: MobileRequest, successHandler : (result:any) => void) {
        let loadingReqId: string;
        LoadingMask.requestLoading(loadingText)
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(request);
            })
            .then(successHandler)
            .catch((error) => {
                App.displayErrorMessage(error);
            })
            .then(() => { LoadingMask.hide(loadingReqId); });
    }

}

export default ServerUtils;