'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { Group } from './Group';
import { Task } from './Task';
import { DomainContext } from '../DomainContext';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import { Level } from './Level';
import { DataField } from './DataField';
import { Combo } from './Combo';
import { Couple } from './Couple';
import DeviceUtils from 'utils/DeviceUtils';

class Field extends ConfigurationElement {
    private parentTask: Task;
    private parentGroup: Group;
    private parentLevel: Level;
    private linkedDataField: DataField;
    private linkedCombo: Combo;
    private linkedCouple: Couple;

    protected config: { id: string, label?: string, [propId:string]: any };
    public static CONTENT_CONVERSION_NO_CONVERTION = '0';

    private static ASYNC_PROP_LABEL:Symbol = Symbol();
    private static ASYNC_PROP_COMBO:Symbol = Symbol();

    constructor(config: {id : string, [propId: string]: any}, parentTask: Task, parentGroup: Group, parentLevel: Level, context: DomainContext) {
        super(config, context);
        this.parentGroup = parentGroup;
        this.parentTask = parentTask;
        this.parentLevel = parentLevel;
        if(this.parentGroup) {
            this.parentGroup.registerField(this);
        }
        this.remainingAsyncProps = [Field.ASYNC_PROP_LABEL, Field.ASYNC_PROP_COMBO];
    }

    public getGroup(): Group {
        return this.parentGroup;
    }

    public getTask(): Task {
        return this.parentTask;
    }

    public getLevel(): Level {
        return this.parentLevel;
    }

    public getLinkedDataField(): DataField {
        return this.linkedDataField;
    }

    public setLinkedDataField(df: DataField): void {
        this.linkedDataField = df;
    }

    public getLinkedCombo(): Combo {
        return this.linkedCombo;
    }

    public setLinkedCombo(combo: Combo): void {
        this.linkedCombo = combo;
    }

    public setLinkedCouple(couple: Couple): void {
        this.linkedCouple = couple;
    }

    public getLinkedCouple(): Couple {
        return this.linkedCouple;
    }

    public isCouple(): boolean {
        return this.linkedCouple !== undefined;
    }

    public isAutoAddedSystemField(): boolean { return this.getBooleanValueOrDefault('autoAddedSystemField', false); }

    public getDatafieldId(): string { return this.getValueOrDefault('datafieldId'); }
    public getPresentation(): string { return this.getValueOrDefault('presentation', this.linkedDataField.getPresentation()); }
    public setPresentation(newPres:string ): void { this.config.presentation = newPres; }
    public resolveAsyncProperties(): Promise<any> {
        let me = this;
        if(!me.hasAsyncRequirements()) {
            return null;
        }
        return new Promise((accept, reject) => {
            let panel = me.parentGroup.getPanel();
            let i18NID = panel.getFieldI18N(this.getId());
            let dataFieldId = me.getDatafieldId();
            // Special handle for the couple label
            if(me.isCouple() && me.isCoupleKey()) {
                dataFieldId = me.getLinkedCouple().getLocalKey();
            }
            let requiredResources: Array<Promise<any>> = [
                CClientConfiguration.getDataField(dataFieldId, me.getContext(), me.parentTask),
                CClientConfiguration.getI18nText(me.getContext(), 'task', i18NID, User.getLocale()),
                CClientConfiguration.getI18nText(me.getContext(), 'table', dataFieldId, User.getLocale()),
                this.initializeHelp(this.getHelpId())
            ]
            Promise.all(requiredResources)
                .then(([datafield, i18Ntext, i18NtextGlobal]) => {
                    me.setLinkedDataField(datafield);
                    if(me.getPresentation === undefined) {
                        me.setPresentation(datafield.getPresentation())
                    }
                    let straightLabel = me.config.label;
                    me.setLabel(straightLabel ? straightLabel : (i18Ntext ? i18Ntext : i18NtextGlobal ? i18NtextGlobal : datafield.getLabel()));
                    me.removeFromAsyncRequirement(Field.ASYNC_PROP_LABEL);
                    //We can check if we still need some resources like combos, etc..
                    //to make the datafield work properly
                    let type = me.getObjectTypeFromFormatPresentation();
                    if(type === 'Enum' || type === 'ListEnum' || type === 'EnumIcon') {
                        let enumName = me.getLinkedDataField().getEnumName();
                        if(enumName.indexOf('DOSSIER.') === 6) { //[DOSSIER]
                            enumName = enumName.replace('DOSSIER.', '.');
                        }
                        let comboName = enumName;
                        if(enumName === 'ByDataFieldName') {
                            comboName = datafield.getId();
                            if(comboName.indexOf('DOSSIER.') === 6) { //[DOSSIER]
                                comboName = comboName.replace('DOSSIER.', '.');
                            }
                        } else if(enumName === 'ByTaskAndFieldName') {
                            comboName = me.getTask().getId() + '_' + me.getId();
                        }
                        CClientConfiguration.getCombo(comboName, User.getLocale(), me.getContext(), me.getTask().getId())
                            .then((combo) => {
                                me.setLinkedCombo(combo);
                                accept(undefined);
                                me.removeFromAsyncRequirement(Field.ASYNC_PROP_COMBO);
                            })
                            .catch(reject);
                    } else {
                        accept(undefined);
                        me.removeFromAsyncRequirement(Field.ASYNC_PROP_COMBO);
                    }
                })
                .catch(reject);
        })
    }

    public copyLinksFrom(field: Field) {
        this.setLinkedDataField(field.getLinkedDataField());
        this.setLinkedCombo(field.getLinkedCombo());
    }

    public getPosition(format: string) {
        let currentPosition = Object.assign({}, this.config.position['*']);
        if(this.config.position[format]) {
            Object.assign(currentPosition, this.config.position[format]);
        }
        //Filling missing properties that are optionnal in the configuration
        if(currentPosition.enabled === undefined) {
            currentPosition.enabled = true;
        }
        if(currentPosition.readOnly === undefined) {
            currentPosition.readOnly = 'false';
        }
        if(currentPosition.visible === undefined) {
            currentPosition.visible = true;
        }
        if(currentPosition.height === undefined) {
            currentPosition.height = 1;
        }
        return currentPosition;
    }

    public getPositionProperty(property: string, format: string, defaultValue: string | boolean | number): string | boolean | number {
        if(this.config.position[format] && this.config.position[format][property] !== undefined) {
            return this.config.position[format][property];
        } else if(this.config.position['*'] && this.config.position['*'][property] !== undefined){
            return this.config.position['*'][property]
        } else {
            return defaultValue;
        }
    }

    public getImagePadding(): { x: number, y: number } {
        return {
            x: this.getNumberValueOrDefault('decX',0),
            y: this.getNumberValueOrDefault('decY',0),
        }
    }

    public canHaveNoReactionOnChange(): boolean { return this.getBooleanValueOrDefault('noReactionOnChange', false); }
    public canModifyColorOnChange(): boolean { return this.getBooleanValueOrDefault('modifyColorOnChange', true); }
    public canBeIgnoredOnCopy(): boolean { return this.getBooleanValueOrDefault('copyIgnore', false); }
    public canModifyEnum(): boolean { return this.getBooleanValueOrDefault('canModifyEnum', false); }
    public isReadOnly(): boolean { return this.config.readOnly === 'true'; }
    public isEnabled(): boolean { return this.config.enabled !== 'false'; }
    public isLabelVisible(format: string): boolean { return this.getPositionProperty('labelVisible', format, true) as boolean; }
    public isVisible(format: string): boolean { 
        if(this.getPositionProperty('row', format, 1) < 0 ){
            return false;
        }
        return this.getPositionProperty('visible', format, true) as boolean; 
    }

    public getImportance(): string { return this.getValueOrDefault('importance'); }
    public getPopupMenuID(): string { return this.getValueOrDefault('popupMenuId'); }
    public getPlaceHolder(): string { return this.getValueOrDefault('placeHolder'); }

    public getContentConversion(): string { return this.getValueOrDefault('contentConversion', Field.CONTENT_CONVERSION_NO_CONVERTION); }
    public canShowInnerIcon(): boolean { return this.getBooleanValueOrDefault('showInnerIcon', false); }
    public canCalendarAutoPopup(): boolean { return this.getBooleanValueOrDefault('calendarAutoPopup', false); }
    public canAutoGeneratedContent(): boolean { return this.getBooleanValueOrDefault('autoGeneratedContent', false); }
    public getExistenceConditions(): string { return this.getValueOrDefault('existence'); }
    public getSilo(): string { return this.getValueOrDefault('silo'); }
    public getDefaultValue(): string { return this.getValueOrDefault('defaultValue'); }
    public getPermanentValue(): string { return this.getValueOrDefault('permanentValue'); }
    public getKeepInSessionVar(): string { return this.getValueOrDefault('keepInSessionVar'); }
    public getDisplayColumnId(): string { return this.getValueOrDefault('displayColumn'); }
    public getGridView(): string { return this.getValueOrDefault('gridView'); }

    public canCallOnValidate(): boolean { return this.getBooleanValueOrDefault('callOnValidate', false); }
    public canCallOnClick(): boolean { return this.getBooleanValueOrDefault('callOnClick', false); }
    public canSendAllPanels(): boolean { return this.getBooleanValueOrDefault('sendAllPanels', false); }
    public getScriptOnClick(): string { return this.getValueOrDefault('scriptOnClick'); }
    public getScriptOnEnter(): string { return this.getValueOrDefault('scriptOnEnter'); }
    public getScriptOnValidate(): string { return this.getValueOrDefault('scriptOnValidate'); }
    public getScriptOnNoteValidate(): string { return this.getValueOrDefault('scriptOnNoteValidate'); }

    //Couple specific entries
    public getCoupleGrid(): string { return this.linkedCouple.getGrid();}
    public getCoupleKey(): string { return this.linkedCouple.getForeignKey();}
    public getCoupleValue(): string { return this.linkedCouple.getLabel();}
    public getCoupleFilter(): string { return this.linkedCouple.getFilter();}
    public isCoupleKey(): boolean { return this.getCoupleKey() === this.getDatafieldId()};
    public isCoupleLabel(): boolean { return !this.isCoupleKey };

    public getValue() {
        let value = this.getValueOrDefault('value')
        return value !== undefined ? value : '';
    }

    public getFormat(): string {
        return this.getLinkedDataField().getFormat();
    }

    public getObjectTypeFromFormatPresentation(): string{
        if(this.isCouple()) {
            return 'Couple';
        }
        let format = this.getFormat();
        let presentation = this.getPresentation();

        let type = 'Text';
        if(format === 'ByForeignKey') {
            format = 'String';
        }
        if((format === 'String' && presentation === 'CheckBoxes') || format === 'Boolean') {
            type = 'Checkbox';
        } else if(format === 'String') {
            if(presentation === 'Link' || presentation === 'RadioButtons' ||
              presentation === 'Button' || presentation === 'Password' || presentation === 'Phone') {
                type = presentation;
            } else if(presentation === 'Email') {
                type = 'Email';
            } else {
                type = 'Text';
            }
        } else if(format === 'List') {
            if(this.getLinkedDataField().getTableName() !== undefined) {
                type = 'ListCouple';
            } else {
                type = 'ListEnum';
                if(presentation === 'Icon' && DeviceUtils.isMobile()) {
                    type = 'EnumIcon';
                }
            }
        } else if(format === 'Enum') {
            if(presentation === 'Icon' && DeviceUtils.isMobile()) {
                type = 'EnumIcon';
            } else {
                type = 'Enum';
            }
        } else {
            type = format;
        }
        return type;
    }

    public isRequired(format: string): boolean {
        let fieldImportance = this.getImportance();
        if(fieldImportance) {
            return fieldImportance === '1' && this.isVisible(format) && this.isEnabled();
        }
        if(this.linkedDataField) {
            let dFieldImportance = this.linkedDataField.getImportance();
            if(dFieldImportance) {
                return dFieldImportance === '1' && this.isVisible(format) && this.isEnabled();
            }
        }
        return false;
    }

    public static setReadOnly(descriptor: any) {
        descriptor.readOnly = 'true';
    }

    public getHelpId(): string {
        return this.parentTask.getId() + (this.parentTask.getReadableType() === 'Dossier' ? '.' : '.Panel') + this.parentGroup.getPanel().getId() + '.' + this.getId();
    }
}

export { Field };