module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="task-panel">\n    <div class="taskHeader"></div>\n    <div class="taskBody">\n        <div class="recordBanner" style="display:none;"></div>\n        <div class="coloredTitle" style="display:none;">\n            <div class="text">' +
((__t = (coloredTitleText)) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="navigatorContainer"></div>   \n        <div class="recordContainer" style="display:none;"></div>\n        <div class="navTabBar"></div>\n        <div class="recordTabBar" style="display: none;"></div>\n        <div class="ficheContainer"></div>\n        <div class="albumContainer"></div>\n    </div>\n</div>';

}
return __p
}