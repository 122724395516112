'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class GridActionRequest extends TaskRequest{
    constructor(domain, task, gridId, popupId, itemId, actionId, gridIndex){
        super('gridAction','POST', domain, task);
        this.setGridId(gridId);
        this.setPopupId(popupId);
        this.setItemId(itemId);
        this.setActionId(actionId);
        this.setGridIndex(gridIndex);
    }

    setGridContext(context){
        this.grid = context;
    }

    setRecordContext(context){
        this.record = context;
    }

    setGridId(id){
        this.gridId = id;
    }

    setPopupId(id){
        this.popupId = id;
    }

    setItemId(id){
        this.itemId = id;
    }

    setActionId(id){
        this.actionId = id;
    }

    setGridIndex(index){
        this.gridIndex = index;
    }

    getSerializable(){
        return {
            request1: {
                gridId: this.gridId,
                grid: this.grid? this.grid.toJSON(): undefined,
                record: this.record? this.record.toJSON(): undefined,
                popupId: this.popupId,
                itemId: this.itemId,
                actionId: this.actionId,
                gridIndex: this.gridIndex
            }
        };
    }
}

export default GridActionRequest;