import DocumentViewer from './DocumentViewer';

/**
 * This viewer displays a given pdf link as a modal.
 */
class IMGViewer extends DocumentViewer {
    protected renderLink(element: JQuery<HTMLElement>): void {
        this.renderPdf(element);
    }

    /**
     * Renders the related pdf in the sreen with the given element width.
     * @param element The modal main inner content element.
     */
    private renderPdf(element: JQuery<HTMLElement>): void {
        this.body.empty();
        var img = $('<img />').appendTo(this.body);
        img.attr('src', this.parameters.documentLink);
        img.css('width', '100%');
    }
}

export default IMGViewer;