module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="groupTitle" style="height: ' +
((__t = (titleHeight)) == null ? '' : __t) +
'px; line-height: ' +
((__t = (titleHeight)) == null ? '' : __t) +
'px; border-bottom-color:' +
((__t = (dominantColor)) == null ? '' : __t) +
';">\n    <div class="groupTitleText" style="color:' +
((__t = (dominantColor)) == null ? '' : __t) +
';">' +
__e( title ) +
'</div>\n</div>\n<div class="fieldsContainer"></div>\n';

}
return __p
}