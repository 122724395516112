'use strict';

//Parametrage
import { SAIDashboardView } from '@sai/dashboard';
import User from 'User';
import Server from 'server/Server';
import I18NUtils from 'utils/i18n/I18NUtils';
import StringUtils from 'utils/StringUtils';
import ScriptUtils from 'utils/ScriptUtils';
import ThemeManager from 'parametrage/ThemeManager';
import DomainManager from 'DomainManager';
import { ColorTheme } from 'parametrage/lookandfeel/ColorTheme';

//Request
import { ComboRequest } from '../server/protocol/request/configuration/ComboRequest';
import { DashboardRequest } from '../server/protocol/request/configuration/DashboardRequest';
import { FilterRequest } from '../server/protocol/request/configuration/FilterRequest';
import { GridViewRequest } from '../server/protocol/request/configuration/GridViewRequest';
import { PopupMenuRequest } from '../server/protocol/request/configuration/PopupMenuRequest';
import { TaskRequest } from '../server/protocol/request/configuration/TaskRequest';
import { GetNotificationRequest } from '../server/protocol/request/task/GetNotification';
//Globals
import { DomainContext } from './DomainContext';
import { Combo } from './structures/Combo';
import { Filter } from './structures/Filter';
import { GridView, Column } from './structures/GridView';
import { PopupMenu } from './structures/PopupMenu';
import { Task } from './structures/Task';
import { I18NRequest } from '../server/protocol/request/configuration/I18NRequest';
import { Table } from './structures/Table';
import { TableRequest } from '../server/protocol/request/configuration/TableRequest';
import { HelpRequest } from '../server/protocol/request/configuration/HelpRequest';
import { DataField } from './structures/DataField';
import { DataPage } from './structures/DataPage';
import { AbstractTask } from './structures/AbstractTask';
import { Page } from './structures/Page';
import MobileRequest from '../server/protocol/request/MobileRequest';
import Context from '../models/context/Context';
import { ConfigurationElement } from './structures/ConfigurationElement';

interface ConfigurationRequestEntity<T> {
    new (context: DomainContext, id: string): T;
}

interface ConfigurationEntity<T> {
    new (config: any, context: DomainContext): T;
}

type IconMappingValue = {
    datafield:Array<string>,
    values: {
        [value:string]:string
    }
}

type IconMapping = {
    [configType:string] : {
        identifyByNote?:boolean,
        icon?: IconMappingValue,
        overlayBottomRight?: IconMappingValue,
        overlayBottomLeft?: IconMappingValue,
        overlayTopLeft?: IconMappingValue,
        overlayTopRight?: IconMappingValue
    }
}

class CClientConfiguration {
    private static _instance: CClientConfiguration;

    //Parametrage cache
    private combos: { [contextId:string] : { [comboId:string]: Combo }}
    private grids: { [contextId:string] : { [gridId:string]: GridView }}
    private filters: { [contextId:string] : { [gridId:string]: Filter }}
    private tasks: { [contextId:string] : { [gridId:string]: Task }}
    private table: { [contextId:string] : { [tableId:string]: Table }}
    private dashboard: { [contextId:string] : { [gridId:string]: SAIDashboardView }}
    private popups: { [contextId: string] : { [popupId:string] : PopupMenu}}
    private i18nTablesAndTask: { [contextId: string] : { [categoryId:string] : { [propId: string]: string}}}
    private helps: { [contextId:string] : { [language:string] : { [field:string] : string}}}
    private iconMappingCache: {[mappingId:string] : IconMapping}
    public APP_LOGO:string = 'app/login/weblogo.png';
    public APP_LOGO_WHITE:string = 'app/login/weblogo_white.png';
    public APP_LOGO_BLACK:string = 'app/login/weblogo_black.png';

    //Singleton definition
    private constructor() {
    }
    public static get Instance(): CClientConfiguration {
        return this._instance || (this._instance = new this());
    }

    /**
     * Fetches a Promise<Task> with the given task id in the given domain
     * @param taskId The id of the task
     * @param context The domain context to use
     */
    public getTask(taskId: string, context: DomainContext): Promise<AbstractTask> {
        return this.getCachedConfiguration<AbstractTask, TaskRequest>('tasks', taskId, context, TaskRequest);
    }

    /**
     * Fetches a Promise<Task> with the given task id in the given domain
     * @param taskId The id of the task
     * @param context The domain context to use
     */
    public getPage(taskId: string, pageId:string, context: DomainContext): Promise<Page> {
        return new Promise<Page>((accept, reject) => {
            this.getTask(taskId, context)
                .then((task: Task) => {
                    accept(task.getPage(pageId));
                })
                .catch(reject);
        });
    }

    /**
     * Fetches a Promise<Filter> with given id in the given domain.
     * 
     * @param id The id of the filter
     * @param context The domain context to use
     */
    public getFilter(id: string, context: DomainContext) : Promise<Filter> {
        return this.getCachedConfiguration<Filter, FilterRequest>('filters', id, context, FilterRequest, Filter);
    }

    /**
     * Fetches a Promise<Combo> with given id and lang in the given domain.
     * 
     * @param id The id of the combo
     * @param lang The language of the combo 
     * @param context The domain context to use
     */
    public getCombo(id: string, lang: string, context: DomainContext, taskId: string) : Promise<Combo> {
        if(id.indexOf(' :: ') > 0) {
            //The id contains script, we've to evaluate before the fetch
            id = ScriptUtils.evalInContext(id, {
                'CURRENT_CHAIN_ID': taskId.substring(0, 3)
            });
        }
        return this.getCachedConfiguration<Combo, ComboRequest>('combos', lang + '/' + id, context, ComboRequest, Combo);
    }

    public getComboByDataField(datafield: DataField, lang: string, context: DomainContext, taskId: string) : Promise<Combo> {
        let enumName = datafield.getEnumName();
        if(!enumName) {
            throw 'The requested DataField is not of type enum ' + datafield.getId();
        } else if(enumName.indexOf(' :: ') > 0) {
            //The id contains script, we've to evaluate before the fetch
            enumName = ScriptUtils.evalInContext(enumName, {
                'CURRENT_CHAIN_ID': taskId.substring(0, 3)
            });
        } else if(enumName === 'ByDataFieldName') {
            enumName = datafield.getId();
        } else if(enumName.indexOf('DOSSIER.') > 0) {
            let regExp = /(.*)DOSSIER(\..*)/;
            let match = regExp.exec(enumName);
            if(match) {
                enumName = match[1]+match[2];
            }
        }
        return this.getCombo(enumName, lang, context, taskId);
    }

    /**
     * Fetches the given notification from the parametrage/on the server through
     * a call base on the type of notification and the associated parameters
     * @param type The type of notification which can be 'local' or 'remote'
     * @param id The identifier of the notification, required in both mode
     * @param remoteTask The task to call if it's a 'remote' notification
     * @param remoteArgs The arguments to give with the 'remote' notification call
     */
    public getRemoteNotification(id: string, remoteTask: string, remoteArgs: {[arg:string] : string}, keyContext?: Context) {
        return new Promise((resolve, reject) => {
            if(!id || id === null) {
                let rejectMessage = I18NUtils.getMessage('NOTIFICATION_REMOTE_MISSING_ID', User.getLocale(), {});
                reject(rejectMessage);
                return;
            }

            if(!remoteTask || remoteTask === null) {
                let rejectMessage = I18NUtils.getMessage('NOTIFICATION_REMOTE_MISSING_TASK', User.getLocale(), {});
                reject(rejectMessage);
                return;
            }

            remoteArgs = remoteArgs || {};
            let remoteRequest = new GetNotificationRequest(DomainManager.getCurrentContext(User.getCurrentDomain()), remoteTask, id);
            for(let argEntry in remoteArgs) {
                remoteRequest.setNotificationArgument(argEntry, remoteArgs[argEntry]);
            }

            if(keyContext) {
                remoteRequest.setKeyContext(keyContext);
            }

            //And send it. The result will be a local notification, we 
            //can thus resolve it like we do in the Local type case
            Server.performRequest(remoteRequest)
                .then((response: any) => {
                    resolve(response.notification);
                })
                .catch(reject);
        });
    }

    /**
     * Fetches a Promise<GridView> with given id in the given domain.
     * 
     * @param id The id of the gridview 
     * @param context The domain context to use
     */
    public getGridView(id: string, context: DomainContext) : Promise<GridView> {
        return this.getCachedConfiguration<GridView, GridViewRequest>('grids', id, context, GridViewRequest, GridView);
    }

    public getDashboard(id: string, lang:string, context: DomainContext) : Promise<SAIDashboardView> {
        return this.getCachedConfiguration<SAIDashboardView, DashboardRequest>('dashboards', id + '/' + lang, context, DashboardRequest, SAIDashboardView);
    }

    public getPopupMenu(categoryId: string, entryId: string, context: DomainContext): Promise<PopupMenu> {
        return this.getCachedConfiguration<PopupMenu, PopupMenuRequest>('popupmenus', categoryId + '/' + entryId, context, PopupMenuRequest, PopupMenu);
    }

    public getTable(id: string, context: DomainContext): Promise<Table> {
        return this.getCachedConfiguration<Table, TableRequest>('tables', id.substring(0,6), context, TableRequest, Table);
    }

    public getDataPage(tableId: string, id: string, context: DomainContext): Promise<DataPage> {
        return new Promise<DataPage>((accept, reject) => {
            this.getTable(tableId, context)
                .then((table) => {
                    accept(table.getDataPage(id));
                })
                .catch(reject);
        });
    }

    public getDataField(id: string, context: DomainContext, task?: Task) : Promise<DataField>{
        let me = this;
        return new Promise<DataField>((accept, reject) => {
            let foundInTask = task ? task.getDataField(id) : undefined;
            if(foundInTask) {
                accept(foundInTask);
            } else {
                //Check in global datafields
                this.getGlobalDataField(id, context)
                    .then((datafield: DataField) => {
                        if(datafield) {
                            accept(datafield);
                        } else {
                            //if not found we check in the associated table
                            this.getTable(id, context)
                            .then((table: Table) => {
                                if(table) {
                                    let foundDf = table.getDataField(id, table.isDossier());
                                    if(foundDf === undefined) {
                                        console.error('cannot find datafield ' + id + ' in table ' + table.getId());
                                    }
                                    accept(foundDf);
                                } else {
                                    console.warn('cannot find datafield ' + id + ', assuming task only');
                                    accept(new DataField({
                                        id: id,
                                        format: 'String',
                                        source: 'TaskOnly',
                                        isHeader: 'false',
                                        isTitle: 'false',
                                        isKey: 'false',
                                    }, null, null,null,context));
                                }
                            })
                            .catch(() => {
                                console.warn('table '+id+' does not exist, assuming task only');
                                accept(new DataField({
                                    id: id,
                                    format: 'String',
                                    source: 'TaskOnly',
                                    isHeader: 'false',
                                    isTitle: 'false',
                                    isKey: 'false',
                                }, null, null,null,context));
                            });
                        }
                    })
                    .catch(reject);
            }
        });
    }

    public getGridDataField(id: string, context: DomainContext, gridId: string) : Promise<DataField>{
        return new Promise<DataField>((accept, reject) => {
            this.getGridView(gridId,context)
                .then((grid:GridView) => {
                    let df = grid.getColumnByDataFieldId(id);
                    if(df !== null) {
                        accept(df.getDataField());
                    } else {
                        accept(undefined);
                    }
                }).catch(reject);
        });
    }

    public getGlobalDataField(id: string, context: DomainContext) : Promise<DataField>{
        return new Promise((accept,reject) => {
            this.getTable('GLOBAL', context)
                .then((table:Table) => {
                    accept(table.getDataField(id));
                })
                .catch(reject);
        });
    }
    /**
     * Returns the i18N value of the given id as set in the configuration resources
     * @param context The packaging context
     * @param type The type of I18N resource that we want (table, task, etc..)
     * @param i18NID The unique ID that the resource should have inside
     * @param language The language in which we want the resource
     */
    public getI18nText(context: DomainContext, type: string, i18NID: string, language: string, global?: boolean): Promise<string> {
        let me = this;
        global = global || false;
        if(me.i18nTablesAndTask  === undefined) {
            me.i18nTablesAndTask = {};
        }

        //It might be the first time we look for entries in the given domain
        let currentCache: { [category:string] : { [propId:string]:string }} = me.i18nTablesAndTask[context.getId()];
        if(currentCache === undefined) {
            me.i18nTablesAndTask[context.getId()] = {};
        }

        //It might not be the first time we look for this i18n file
        let pendingReq = type + '/' + language + '/' + (global? 'global' : i18NID.substring(0,3));
        if(me.i18nTablesAndTask[context.getId()][pendingReq] !== undefined) {
            return new Promise((accept) => {
                accept(me.i18nTablesAndTask[context.getId()][pendingReq][i18NID]);
            })
        } else {
            return new Promise<string>((found, failed) => {
                let newRequest: I18NRequest = new I18NRequest(context, pendingReq);
                Server.performRequest(newRequest)
                .then((result:any) => {
                    //As the performRequest promiss will be fetched multiple times
                    //In case of concurrent promises the cache entry might be
                    //initialized multiple times thus we only initialize it once
                    if(me.i18nTablesAndTask[context.getId()][pendingReq] === undefined) {
                        me.i18nTablesAndTask[context.getId()][pendingReq] = result.texts;
                    }

                    if(me.i18nTablesAndTask[context.getId()][pendingReq] === undefined) {
                        if(!global) {
                            // Try with global
                        this.getI18nText(context, type, i18NID, language, true)
                            .then((result:any) => {
                                found(result);
                            });
                        } else {
                            failed();
                        }
                    } else {
                        found(me.i18nTablesAndTask[context.getId()][pendingReq][i18NID]);
                    }
                })
                .catch(failed); //In case of server failure
            });
        }
    }

    public getHelp(context: DomainContext, id: string, language: string) : Promise<string> {
        let me = this;
        if(me.helps === undefined) {
            me.helps = {};
        }
        //It might be the first time we look for entries in the given domain
        let currentCache = me.helps[context.getId()];
        if(currentCache === undefined) {
            me.helps[context.getId()] = {};
        }

        let languageCache = me.helps[context.getId()][language];
        if(languageCache === undefined) {
            me.helps[context.getId()][language] = {};
        }
        
        let taskId = id.substring(0,5);
        if(me.helps[context.getId()][language][taskId] === undefined) {
            // The help for the task was never retrieved
            return new Promise<string>((found, failed) => {
                let request = new HelpRequest(context, language+'/'+id.substring(0,5));
                Server.performRequest(request)
                .then((result:any) => {
                    if(result.helps !== undefined) {
                        // populate the cache with the result
                        for(let key in Object.keys(result.helps)) {
                            let helpKey = Object.keys(result.helps)[key];
                            me.helps[context.getId()][language][helpKey] = result.helps[helpKey];
                        }
                    }
                    
                    found(me.helps[context.getId()][language][id]);
                })
                .catch(failed); //In case of server failure
            });
        } else {
            return new Promise((accept) => {
                accept(me.helps[context.getId()][language][id]);
            });
        }
    }

    /**
     * Retrieves entries from the configuration as Promise and populates the
     * cache if needed. This function is generic and can be used to fetch any
     * configuration element that is initialised with a config parameter.
     *
     * @param cacheName The identifier of the cache in the CClientConfiguration
     * @param id The id of the entry to fetch
     * @param context The domain context to use on the fetch. Each entry is cached
     * by id and by context
     * @param requestConstr The MobileRequest class object that will be used to
     * fetch the configuration on the server
     * @param entityConstr The class object that will be used to build the
     * final configuration entity
     */
    public getCachedConfiguration<T, U extends MobileRequest>(cacheName: string, id: string, context: DomainContext, requestConstr: ConfigurationRequestEntity<U>, entityConstr?: ConfigurationEntity<T>) : Promise<T> {
        let me = this;
        return new Promise<T>((resolve, reject) => {
            //initialisation of the cache if never used yet
            if(me[cacheName]  === undefined) {
                me[cacheName] = {};
            }

            //It might be the first time we look for entries in the given domain
            let currentCache: { [themeId:string]:Promise<T> } = me[cacheName][context.getId()];
            if(currentCache === undefined) {
                me[cacheName][context.getId()] = {};
            }

            //Looking for a specific entry in the domain
            if(me[cacheName][context.getId()][id] === undefined) {
                //If it hasn't been fetched yet, we build a promise that will
                //look up the entry in the server using the provided request
                me[cacheName][context.getId()][id] = new Promise<T>((found, failed) => {
                    let newRequest:U = new requestConstr(context, id);
                    Server.performRequest(newRequest)
                    .then((result) => {
                        //Resolving the server request result by building the entity
                        let newEntity;
                        if(entityConstr){
                            newEntity = new entityConstr(result, context);
                        } else if(newRequest['getEntity']){
                            newEntity = newRequest['getEntity'](result, context);
                        } else {
                            throw new Error('Invalid configuration. Unable to define which object must be instantiated from server response');
                        }
                        if(newRequest['postProcess']) {
                            newRequest['postProcess'](newEntity)
                                .then((entity) => {
                                    found(entity);
                                })
                                .catch(reject);
                        } else {
                            if(newEntity.initialize) {
                                newEntity.initialize()
                                .then(() => {
                                    found(newEntity);
                                });
                            } else {
                                found(newEntity);
                            }
                        }
                    })
                    .catch(failed); //In case of server failure
                });
            }

            //Resolving the entity from the cache
            me[cacheName][context.getId()][id]
            .then((entity : T) => {
                //The server result was previously successfully fetched
                //We can thus resolve with the inner built entity
                resolve(entity);
            })
            .catch(reject); //Or it had failed and we reject it
        });
    }

    public resolveTextProperties(label, contextToUse){
        if(contextToUse === undefined) {
            contextToUse = {};
        }

        if(User !== undefined){
            let userProperties = User.getProperties();
            //Setting all user properties in context
            for(let prop in userProperties){
                //but no override of existing properties
                let finalPropName = 'user_'+prop;
                if(contextToUse[finalPropName] === undefined){
                    contextToUse[finalPropName] = userProperties[prop].value;
                }
            }
        }

        return StringUtils.resolveTextProperties(label, contextToUse);
    }

    public getGlobalImage(imageName: string): string {
        return Server.getTokenedUrl(imageName);
    }

    public getWallPaper(domain?: string) : Promise<string> {
        let wallpaperId = User.getWallPaperId();
        return new Promise<string>((resolve, reject) => {
            ThemeManager.getUserTheme(domain)
            .then((theme: ColorTheme) => {
                if(wallpaperId === undefined || wallpaperId === '') {
                    let allWallPapers: Array<string> = theme.getWallPapers();
                    if(allWallPapers.length > 0) {
                        resolve(allWallPapers[0]);
                    } else {
                        reject(I18NUtils.getMessage('THEME_NO_WALLPAPER', User.getLocale(), {}));
                    }
                } else {
                    if(theme.hasWallPaper(wallpaperId)) {
                        resolve(wallpaperId);
                    } else {
                        reject(I18NUtils.getMessage('WALLPAPER_NOT_FOUND', User.getLocale(), {
                            wallpaperId : wallpaperId,
                            themeId: theme.getId()
                        }));
                    }
                }
            })
            .catch(() => {
                //Unable to fetch theme, there won't be any wallpaper
                reject(I18NUtils.getMessage('USER_NO_THEME', User.getLocale(), {}));
            });
        })
    }

    public getGridIconConfig(gridId:string, taskId:string, context:DomainContext): Promise<IconMapping> {
        let me = this;
        if(this.iconMappingCache === undefined) {
            this.iconMappingCache = {};
        }
        let mappingKey = 'GRID-'+gridId+'-'+taskId+'-'+context.getId();
        return new Promise((accept, reject) => {
            if(me.iconMappingCache[mappingKey] !== undefined) {
                accept(me.iconMappingCache[mappingKey]);
                return; 
            }
            let result: IconMapping = {
                grid: {
                    icon : undefined,
                    overlayBottomRight: undefined,
                    overlayBottomLeft: undefined,
                    overlayTopLeft: undefined,
                    overlayTopRight: undefined
                }
            };
            this.getGridView(gridId, context)
                .then((gridView) => {
                    me.getIconConfigForElement(gridView, result, 'grid', mappingKey, taskId, context)
                        .then((iconConfig) => {
                            accept(iconConfig);
                        });
                })
        });
    }

    public getTableIconConfig(tableId:string, taskId:string, context:DomainContext): Promise<IconMapping> {
        let me = this;
        if(this.iconMappingCache === undefined) {
            this.iconMappingCache = {};
        }
        let mappingKey = 'TABLE-'+tableId+'-'+taskId+'-'+context.getId();
        return new Promise((accept, reject) => {
            if(me.iconMappingCache[mappingKey] !== undefined) {
                accept(me.iconMappingCache[mappingKey]);
                return; 
            }
            let result: IconMapping = {
                table: {
                    icon : undefined,
                    overlayBottomRight: undefined,
                    overlayBottomLeft: undefined,
                    overlayTopLeft: undefined,
                    overlayTopRight: undefined
                }
            };
            this.getTable(tableId, context)
                .then((table) => {
                    me.getIconConfigForElement(table, result, 'table', mappingKey, taskId, context)
                        .then((iconConfig) => {
                            accept(iconConfig);
                        });
                })
        });
    }

    getIconConfigForElement(configElement:ConfigurationElement,result:IconMapping,type:string,mappingKey:string, taskId:string, context:DomainContext): Promise<IconMapping> {
        let me = this;
        let tableConfig = configElement.getConfig();
        let allPromise = [];
        return new Promise((accept) => {
            for(let attr in result[type]) {
                let iconValue = tableConfig[attr];
                if(iconValue !== undefined) {
                    let inlineRegExp = /([A-Z0-9._]+)\s:(\s.+=.+,*)$/
                    let comboRegexp = /(.+)\s*:\s*(.+)/
                    let inlineMatch = inlineRegExp.exec(iconValue);
                    let comboMatch = comboRegexp.exec(iconValue);
                    if(inlineMatch) {
                        allPromise.push(new Promise((accept) => {
                            let map = {};
                            map[attr] = {
                                datafield: inlineMatch[1],
                                values: {}
                            };
                            let group = inlineMatch[2];
                            let comaSplit = group.split(',');
                            for(let index in comaSplit){
                                let keyPair = comaSplit[index].split('=');
                                map[attr].values[keyPair[0].trim()] = keyPair[1].trim();
                            }
                            accept(map);
                        }));
                    } else if(comboMatch) {
                        let enumName = undefined;
                        if(type === 'grid') {
                            let column = (configElement as GridView).getColumnByDataFieldId(comboMatch[1].trim());
                            if(column !== undefined && column !== null) {
                                enumName = column.getAttribute('enumName');
                            }
                        }
                        if(enumName !== undefined) {
                            allPromise.push(new Promise((accept) => {
                            me.getCombo(enumName, User.getLocale(),context,taskId)
                                .then((combo) => {
                                    let map = {};
                                    map[attr] = {
                                        datafield: comboMatch[1].trim(),
                                        values: {}
                                    };
                                    let elements = combo.getElements();
                                    for(let elemId in elements) {
                                        let elem = elements[elemId];
                                        map[attr].values[elem.getId()] = elem.getAttribute(comboMatch[2].trim());
                                    }
                                    accept(map);
                                });
                            }));
                        } else {
                            allPromise.push(new Promise((accept) => {
                                me.getDataField(comboMatch[1].trim(),context,null)
                                    .then((dataField: DataField) => {
                                        me.getComboByDataField(dataField, User.getLocale(),context,taskId)
                                            .then((combo) => {
                                                let map = {};
                                                map[attr] = {
                                                    datafield: dataField.getId(),
                                                    values: {}
                                                };
                                                if(combo !== undefined) {
                                                    let elements = combo.getElements();
                                                    for(let elemId in elements) {
                                                        let elem = elements[elemId];
                                                        map[attr].values[elem.getId()] = elem.getAttribute(comboMatch[2].trim());
                                                    }
                                                } else {
                                                    // Fallback
                                                    map[attr].values['default'] = 'file';
                                                }
                                                accept(map);
                                            })
                                            .catch(() => {
                                                let map = {};
                                                map[attr] = {
                                                    datafield: dataField.getId(),
                                                    values: {}
                                                };
                                                // Fallback
                                                map[attr].values['default'] = 'file';
                                                accept(map);
                                            });
                                    });
                            }));
                        }
                    } else {
                        allPromise.push(new Promise((accept) => {
                            let map = {};
                            map[attr] = {
                                datafield: undefined,
                                values: {}
                            };
                            map[attr].values['default'] = iconValue;
                            accept(map);
                        }));
                    }
                } else if(attr === 'icon' && type === 'table') {
                    // Fallback
                    allPromise.push(new Promise((accept) => {
                        let map = {};
                        map[attr] = {
                            datafield: undefined,
                            values: {}
                        };
                        map[attr].values['default'] = 'file';
                        accept(map);
                    }));
                }
            }
            result[type].identifyByNote = configElement.getConfig().identifyByNote;
            Promise.all(allPromise)
                .then((results) => {
                    for(let i in results) {
                        let iconItem = results[i];
                        for(let val in iconItem) {
                            result[type][val] = iconItem[val];
                        }
                    }
                    me.iconMappingCache[mappingKey] = result;
                    accept(result);
                });
        });
    }

    getTableForGridView(gridId:string, context:DomainContext): Promise<Table> {
        return new Promise((accept) => {
            this.getGridView(gridId, context)
                .then((gridConfig) => {
                    let columns = gridConfig.getColumns();
                    for(let idx in columns) {
                        let col = columns[idx];
                        let datafield = undefined;
                        if(col.getAttribute('objectType') === 'GridColumn') {
                            datafield = col.getDataField();
                        } else if(col.getAttribute('objectType') === 'GridPage') {
                            let datapage = col.getDataField();
                            let subfields = datapage.getConfig().elements;
                            for(let elIdx in subfields) {
                                let subCol = new Column(subfields[elIdx], context);
                                if(subCol.getAttribute('objectType') === 'GridColumn') {
                                    datafield = subCol.getDataField();
                                    break;
                                }
                            }
                        }
                        if(datafield !== undefined) {
                            this.getDataField(datafield.getId(), context)
                                .then((datafieldConfig) => {
                                    if(datafieldConfig.getTable()) {
                                        this.getTable(datafieldConfig.getTable().getId(),context)
                                            .then((tableConfig) => {
                                                accept(tableConfig);
                                            });
                                    }
                                });
                        }
                    }
                });
        })
    }
}

const singleInstance = CClientConfiguration.Instance;

export default singleInstance;