'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import MobileMessage from '../../MobileMessage';

class GetStatusRequest extends TaskRequest{
    private time: number;
    private count: number;
    private pid: string;
    private internalMessage: MobileMessage;
    private resolve: any;
    private reject: any;

    constructor(initialMessage, initialResolve, initialReject, domain, task, time, count, pid){
        super('getStatus','GET', domain, task);
        this.time = time;
        this.count = count;
        this.pid = pid;
        this.internalMessage = initialMessage;
        this.resolve = initialResolve;
        this.reject = initialReject;
    }

    public getPid(): string {
        return this.pid;
    }

    public getCount(): number {
        return this.count;
    }

    public getTime(): number {
        return this.time;
    }

    getSerializable(){
        return {
            request1: {
                pid: this.pid
            }
        };
    }

    public getInitialMessage(): MobileMessage {
        return this.internalMessage;
    }

    public getInitialMessageResolve(): any {
        return this.resolve;
    }

    public getInitialMessageReject(): any {
        return this.reject;
    }
}

export default GetStatusRequest;