'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';
import { Task } from '../../../../parametrage/structures/Task';
import { AbstractTask } from '../../../../parametrage/structures/AbstractTask';

class TaskRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('task', domain, _id);
    }

    public postProcess(entity: AbstractTask) {
        let allPromise = [];
        allPromise.push(entity.initializeI18N());
        allPromise.push(entity.initializeHelp(this.getId()));
        return new Promise((accept, reject) => {
            Promise.all(allPromise).then(([i18n,helps]) => {
                accept(entity);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public preProcess(results: Array<any>) {
        results[0] = results[0].task;
    }

    public getEntity(config:any, context: DomainContext): any {
        //Here we can switch to build the proper type of task
        return new Task(config, context);
    }
}

export { TaskRequest };