'use strict';

const moment = require('moment');

class ScriptUtils {
    private static _instance: ScriptUtils;
    //Singleton definition
    private constructor() {}
    public static get Instance(): ScriptUtils {
        return this._instance || (this._instance = new this());
    }

    /**
     * The given expression is called within the given context passed as
     * argument using a closure
     * @param expression The expression to evaluate
     * @param context The surrounding context
     */
    public evalInContext(expression:string, context) {
        //Sometimes there are $ in front of the variable, sometimes not. We've
        //to add 'this.' in front of all variables
        for(let key in context) {
            let regex = new RegExp('\\' + key + '(\\.|\\}|\\s|$)');
            if(regex.test(expression) && expression.indexOf('this["'+key+'"]') === -1) {
                expression = expression.replace(new RegExp(this.escapeRegExp(key), 'g'), 'this["' + key + '"]');
            }
        }
        expression = expression.replace(new RegExp(' = ', 'g'), ' == ');
        expression = expression.replace(new RegExp(' OR ', 'g'), ' || ');
        expression = expression.replace(new RegExp(' AND ', 'g'), ' && ');
        expression = expression.replace(new RegExp(' :: ', 'g'), ' + ');
        return function() { 
            try {
                return eval(expression);
            } catch(err) {
                console.error('Unable to evaluate ' + expression, err);
            }
        }.call(context);
    }

    private escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    public handleDateTransformation(expression:string, dateFormat:string) {
        let regExp = /(.*)\$LOCAL_DATE\s+([\+\-])\s+(\d+)(.*)\(toDate\)/g;
        let match = regExp.exec(expression);
        if(match) {
            let targetDate = moment().add(eval(match[2]+match[3]),'d');
            return match[1]+targetDate.format('YYYY-MM-DD 00:00:00')+match[4];
        } else if(expression === '$LOCAL_DATE') {
            return moment().format(dateFormat);
        } else if(expression === '$LOCAL_DATETIME') {
            return moment().format('YYYY-MM-DD HH:mm:ss');
        } else {
            return expression;
        }
    }
}

const singleInstance = ScriptUtils.Instance;

export default singleInstance;