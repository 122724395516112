'use strict';

import * as Backbone from 'backbone';

var HeaderModel = Backbone.Model.extend({
    url: '',

    initialize: function() {
    },

    defaults: {
        taskName: 'No name'
    },

    validate: function(attrs, options) {
    },

    parse: function(response, options)  {
        return response;
    }
});

export default HeaderModel;
