module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal fade" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" role="dialog" tabindex="-1" aria-labelledby="confirmation" aria-hidden="true" data-backdrop="static" data-keyboard="false" >\n    <div class="modal-dialog confirmationDialog">\n        <div class="modal-content fullModalContent confirmationContent">\n            <div class="modal-body confirmationBody">\n                <div class="confirmationTitle">' +
((__t = (title)) == null ? '' : __t) +
'</div>\n                <div class="confirmationMessage">' +
((__t = (message)) == null ? '' : __t) +
'</div>\n                <div class="buttonHolder">\n                    ';
 for (var key in buttons) { ;
__p += '\n                    <div class="confirmation-button" data-id="' +
((__t = (buttons[key].id)) == null ? '' : __t) +
'">\n                        <i class="fa fa-' +
((__t = (buttons[key].icon)) == null ? '' : __t) +
'"></i>\n                        <div clas="btn-text">' +
((__t = (buttons[key].text)) == null ? '' : __t) +
'</div>\n                    </div>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}