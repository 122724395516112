import DocumentViewer from './DocumentViewer';
import App from 'App';
// Use the legacy version for older browser compatibility
// https://github.com/mozilla/pdf.js/issues/14729
const pdfjsLib = require('pdfjs-dist/legacy/build/pdf.js');
pdfjsLib.GlobalWorkerOptions.workerSrc = 'vendor/pdf/pdf.worker.min.js';

/**
 * This viewer displays a given pdf link as a modal.
 */
class PDFViewer extends DocumentViewer {
    private loadingTask: Promise<any>;

    private getLoadingTask() : Promise<any> {
        if(this.loadingTask == null) {
            this.loadingTask = pdfjsLib.getDocument(this.parameters.documentLink).promise;
            this.loadingTask.catch (function (reason) {
                console.error(reason);
                App.displayErrorMessage(new Error('Impossible de lire le document, ' + reason));
                this.$el.modal('hide');
            });
        }
        return this.loadingTask;
    }

    protected renderLink(element: JQuery<HTMLElement>): void {
        this.renderPdf(element);
    }

    /**
     * Renders the related pdf in the sreen with the given element width.
     * @param element The modal main inner content element.
     */
    private renderPdf(element: JQuery<HTMLElement>): void {
        this.body.empty();
        var me = this;
        this.getLoadingTask().then(function (pdfDocument) {
            me.renderPage(1, pdfDocument, element.width());
        })
    }

    /**
     * Renders a single page on a canvas, scaled to the given width.
     * @param idx The page number (1...n) of the pdf to render.
     * @param pdfDoc The pdf document from which we fetch data.
     * @param width The width of the scaled canvas.
     */
    private renderPage(idx: number, pdfDoc: any, width: number): void {
        var me = this;
        pdfDoc.getPage(idx).then(function (pdfPage) {
            // Display page on the existing canvas with 100% scale.
            var wrapper = $('<div class="pdf-wrapper"></div>').appendTo(me.body);
            wrapper.width(width);
            var scale = 1.0;
            // Use devicePixelRatio for sharper results on HiDPI display
            var outputScale = window.devicePixelRatio || 1;
            var baseView = pdfPage.getViewport({ scale: scale });
            let widthHeightRatio = baseView.width / baseView.height;
            wrapper.height(width / widthHeightRatio);
            var jqCanvas = $('<canvas class="pdf-canvas"></canvas>').appendTo(wrapper);
            var canvas = (<HTMLCanvasElement>jqCanvas[0]);
            canvas.width = Math.floor(baseView.width * outputScale);
            canvas.height = Math.floor(baseView.height * outputScale);
            canvas.style.width = Math.floor(baseView.width) + 'px';
            canvas.style.height =  Math.floor(baseView.height) + 'px';

            var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            var ctx = canvas.getContext('2d');
            pdfPage.render({
                canvasContext: ctx,
                transform: transform,
                viewport: baseView,
            });
            if (idx < pdfDoc.numPages) {
                me.renderPage(idx + 1, pdfDoc, width);
            }
        });
    }
}

export default PDFViewer;