module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <input type="text" class="form-control changeableField" data-id="' +
__e( fieldState.getId() ) +
' " value="' +
__e( fieldState.getValue() ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' >\n</div>\n';

}
return __p
}