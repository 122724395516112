'use strict';

import Server from 'server/Server';
import ColorUtils from 'utils/ColorUtils';
import { SAIView } from '../../../../Additions';
import { Table } from '../../../../parametrage/structures/Table';
import { Task } from '../../../../parametrage/structures/Task';
import { DomainContext } from '../../../../parametrage/DomainContext';
import { Theme } from '../../../../parametrage/structures/Theme';
import { Page } from '../../../../parametrage/structures/Page';
import { View, Model } from 'backbone';
import { PageInstance } from '../../../../parametrage/structures/PageInstance';
let $ = require('jquery');

class PageNavigatorView extends SAIView {
    //data given at construction and usage
    private pagesCollection: {[themeId:string]:{
        theme: Theme,
        state: string,
        pages: Array<Page>,
        pagesCount: {[pageId:string]: number},
        pagesMap: { [pageIdPageOcc: string]: Page}
    }};
    private pagesMap: {themeIdPageIdPageOcc : Page};
    private tableConfig: Table;
    private taskConfig: Task;
    private displayActionButtons: boolean;
    private displayAddPageButton: boolean;
    private domainContext: DomainContext;
    //inner variables
    private supportedMimeType: Array<string>;
    private currentlySelectedPage: { theme: string, page: Page };
    //DOM elements
    private currentlySelectedEl: JQuery<HTMLElement>;
    private acceptBtn: JQuery<HTMLElement>;
    private removeBtn: JQuery<HTMLElement>;
    private pageNavHeader: JQuery<HTMLElement>;
    private innerNav: JQuery<HTMLElement>;

    constructor(options) {
        super(options);
        this.template = require('ejs-loader!templates/PageNavigator.ejs');
        this.tableConfig = options.tableConfig;
        this.taskConfig = options.taskConfig;

        this.supportedMimeType = ['image/png','image/gif','image/jpeg','image/bmp'];

        //If we use this component in a modal popup we don't want it to display actions buttons
        this.displayActionButtons = options.displayActionsButtons !== undefined ? options.displayActionsButtons : true;
        this.domainContext = options.domainContext;
        this.displayAddPageButton = options.displayAddPage;
    }

    public selectFirstPage() {
        this.$el.find('.page')[0].click();
    }

    private onPageClick(evt) {
        if (this.currentlySelectedEl) {
            this.currentlySelectedEl.removeClass('selected');
        }
        let targetEl: any = $(evt.target);
        this.currentlySelectedEl = targetEl.closest('.page');
        this.currentlySelectedEl.addClass('selected');
        var theme = this.currentlySelectedEl.data('theme');
        var pageId = this.currentlySelectedEl.data('pageid');
        var pageOcc = this.currentlySelectedEl.data('pageocc');
        this.currentlySelectedPage = {
            theme: theme,
            page: this.pagesCollection[theme].pagesMap[pageId + '/' + pageOcc]
        };
        this.trigger('pageSelected', this.currentlySelectedPage);
    }

    private onThemeClick(evt) {
        var attributes = evt.currentTarget.attributes;
        var themeId = attributes.getNamedItem('data-theme').value;
        var currentTheme = this.pagesCollection[themeId];
        //Retreiving theme element. We close or open it based on its current state.
        var clickedThemeEl = $(evt.target).closest('.pageNavTheme');
        var clickedThemeElImage = clickedThemeEl.find('.theme .fa');
        //Closed/opened thumb is also updated based on the state
        if (currentTheme.state === 'opened') {
            currentTheme.state = 'closed';
            clickedThemeEl.addClass('closed');
            clickedThemeElImage.addClass('fa-angle-right');
            clickedThemeElImage.removeClass('fa-angle-down');
        } else {
            currentTheme.state = 'opened';
            clickedThemeEl.removeClass('closed');
            clickedThemeElImage.removeClass('fa-angle-right');
            clickedThemeElImage.addClass('fa-angle-down');
        }

    }

    private onPageGroupClick(evt) {
        var attributes = evt.currentTarget.attributes;
        var pageId = attributes.getNamedItem('data-group').value;
        //Retreiving theme element. We close or open it based on its current state.
        var clickedGroupEl = $(evt.target).closest('.pageGroup');
        var thumbElemEl = clickedGroupEl.find('.pageNavPageIcon i');
        //Closed/opened thumb is also updated based on the state
        if (thumbElemEl.hasClass('fa-angle-down')) {
            thumbElemEl.addClass('fa-angle-right');
            thumbElemEl.removeClass('fa-angle-down');
            this.$el.find('.page[data-pageid="'+pageId+'"]').hide();
        } else {
            thumbElemEl.addClass('fa-angle-down');
            thumbElemEl.removeClass('fa-angle-right');
            this.$el.find('.page[data-pageid="'+pageId+'"]').show();
        }
    }

    public setPagesCollection(dossierPages): Array<PageInstance> {
        this.pagesCollection = {};
        let builtPages = [];
        for(var key in dossierPages){
            let pageAccess = this.taskConfig.getPagesAccess();
            if(pageAccess[dossierPages[key].id]) {
                let newPage = new PageInstance(dossierPages[key], this.taskConfig, this.tableConfig, this.domainContext);
                builtPages.push(newPage);
                //The 18n init will be synchronous as we already did the async
                //part while loading the configuration
                newPage.initializeI18N();
                let themeId = this.tableConfig.getDataPage(newPage.getId()).getThemeID();
                if(!this.pagesCollection[themeId]) {
                    this.pagesCollection[themeId] = {
                        theme: this.tableConfig.getTheme(themeId),
                        state: 'opened',
                        pages: [],
                        pagesCount: {},
                        pagesMap: {}
                    };
                }
                let pageId = newPage.getId();
                let pageOcc = newPage.getOccurrence();
                this.pagesCollection[themeId].pagesMap[pageId + '/' + pageOcc] = newPage;
                if(this.pagesCollection[themeId].pagesCount[pageId] === undefined) {
                    this.pagesCollection[themeId].pagesCount[pageId] = 1;
                } else {
                    this.pagesCollection[themeId].pagesCount[pageId] += 1;
                }
                this.pagesCollection[themeId].pages.push(newPage);
            }
        }
        if(this.displayAddPageButton) {
            this.acceptBtn.show();
        }
        this.removeBtn.show();
        this.render();
        return builtPages;
    }

    getPagesCollection() {
        return this.pagesCollection;
    }

    render() {
        var me = this;
        this.$el.html(this.template({
            sourceTaskConfig: this.taskConfig,
            pagesByTheme: this.pagesCollection,
            themes: this.tableConfig.getThemes(),
            displayActions: this.displayActionButtons,
            domainContext: this.domainContext
        }));

        this.$el.find('.theme').on('click', this.onThemeClick.bind(this));
        this.$el.find('.page').on('click', this.onPageClick.bind(this));
        this.$el.find('.pageGroup').on('click', this.onPageGroupClick.bind(this));
        this.pageNavHeader = this.$el.find('.pageNavHeader');
        this.innerNav = this.$el.find('.innerPageNav');
        this.acceptBtn = this.$el.find('.pageNavAdd');
        if(this.displayAddPageButton) {
            this.acceptBtn.show();
            this.acceptBtn.click(this.onAddButtonClick.bind(this));
        } else {
            this.acceptBtn.hide();
        }
        this.removeBtn = this.$el.find('.pageNavRemove');
        this.removeBtn.click(this.onRemoveButtonClick.bind(this, this.currentlySelectedPage));


        var currentImgs = this.$el.find('.pageNavThemeIcon').each(function(i){
            var holder = $(this);
            let theme = holder.closest('.theme');
            let themeId = theme.data('theme');
            var defaultImgSrc = Server.getTokenedUrl('configuration/' + me.domainContext.getId() + '/image/highres,big/128,100,64,36/' + me.tableConfig.getTheme(themeId).getIcon());
            var prom = new Promise((resolve, reject) => {
                var mobileIconeSrc = defaultImgSrc + '-mobile';
                var xhr = new XMLHttpRequest();
                xhr.open('GET', mobileIconeSrc);
                xhr.onload = () => {
                    resolve([xhr.response, mobileIconeSrc, defaultImgSrc]);
                };
                xhr.send();
            })
                .then(([response, mobileIconeSrc, currentImg]) => me.handleGetImg([response, mobileIconeSrc, currentImg, holder])
                ).then((imgSrc) => {
                    var imgDiv = $('<img class="theme-style" src="'+imgSrc+'"/>');
                    holder.append(imgDiv);
                }).catch((error) => {
                    console.error(error);
                });
        });

        return this;
    }

    onDOMUpdated() {

    }

    reset() {
        this.setPagesCollection([]);
    }

    onAddButtonClick() {
        this.trigger('addPage');
    }

    onRemoveButtonClick(meta) {
        this.trigger('removePage', meta);
    }

    setHeaderDominantColor(pageMeta) {
        var result = ColorUtils.hexToRgb(pageMeta.color);
        var hsl = ColorUtils.rgbToHsl(result.r, result.g, result.b);
        if (hsl.l > 60) {
            //very light color
            pageMeta.headerTextColor = '#333';
        } else {
            pageMeta.headerTextColor = 'white';
        }
    }

    resize(mode) {
        if (mode === 'tablet') {
            this.innerNav.css({
                height: this.$el.height()
            });
        }
    }

    hideIcon(imgContainer){
        imgContainer.remove();
    }

    handleGetImg([response, mobileIconeSrc, defaultImg, holder]) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (response.indexOf('<svg') >= 0) {
                try {
                    var randNum = Math.floor(Math.random() * 100000);
                    response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                    response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                    var xmlSvg = $($.parseXML(response));
                    var size = '40px';
                    if($(window).width() < 768) {
                        size = '30px';
                    }
                    xmlSvg.find('svg').attr('class', 'theme-style').attr('width', size).attr('height',size);
                    holder.append(xmlSvg.find('svg'));
                } catch(err) {
                    resolve(defaultImg);
                }
            } else if(me.supportedMimeType.indexOf(response.type) >= 0){
                resolve(mobileIconeSrc);
            } else {
                resolve(defaultImg);
            }
        });
    }

    getPageInstance(datapageId:string, pageOccurrence:number): any {
        let mapKey = datapageId + '/' + pageOccurrence;
        let selectedPage = null;
        let selectedTheme = null;
        Object.keys(this.pagesCollection).forEach(theme => {
            let themeInstance = this.pagesCollection[theme];
            let pageInstance = themeInstance.pagesMap[mapKey];
            if(pageInstance) {
                selectedPage = pageInstance;
                selectedTheme = theme;
            }
        });
        return { page: selectedPage, theme: selectedTheme };
    }
}

export default PageNavigatorView;
