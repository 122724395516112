'use strict';

/**
 *
 * @param {string} taskId The task to access
 * @param {string} grid The grid to fill
 * @param {string} filter The filter id to apply on data
 * @param {object} filterValues The filter values to apply in the filter
 * @param {string} formatter The formatter id to apply on data
 * @param {number} start Start to fetch the item from this number
 * @param {number} end Stop fetching at this number
 * @param {string} customText One of the column data much match this text in each row
 * @param {Function} successFunc Which function to call with the response
 */

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class GetGridDataRequest extends TaskRequest{
    constructor(domain, task, gridId){
        super('getGridData','POST', domain, task);
        this.gridId = gridId;

        this.range = {
            start: 0,
            end: 20
        };

        this.filterId = 'DEFAULT_FILTER';

        this.filter = {
            customText : undefined,
            context: undefined
        };
    }

    getGridId(newId){
        this.gridId = newId;
    }

    setFilterId(newId){
        this.filterId = newId;
    }

    setFormatterId(newId){
        this.formatterId = newId;
    }

    setRange(start, end){
        this.range = {
            start: start,
            end: end
        };
    }

    setCustomFilterText(customText){
        this.filter.customText = customText;
    }

    setFilterContext(context){
        this.filter.context = context;
    }

    setSessionVars(vars){
        this.sessionVars = vars;
    }

    setIsMainGrid(isMainGrid) {
        this.isMainGrid = isMainGrid;
    }

    getSerializable(){
        return {
            request1: {
                gridId: this.gridId,
                filterId: this.filterId,
                formatterId: this.formatterId,
                start: this.range.start,
                end: this.range.end,
                customText : this.filter.customText,
                filter: this.filter.context? this.filter.context.toJSON(): undefined,
                record: this.record? this.record.toJSON(): undefined,
                page: this.page? this.page.toJSON(): undefined,
                mainGrid: this.isMainGrid
            }
        };
    }
}

export default GetGridDataRequest;