'use strict';

import App from 'App';
import Server from 'server/Server';
import Context from 'models/context/Context';
import User from 'User';

import { TaskListingRequest } from 'server/protocol/request/task/TaskListing';

import * as _ from 'lodash';

class TemplateManager {
    private static _instance: TemplateManager;

    //Singleton definition
    private constructor() {}
    public static get Instance(): TemplateManager {
        return this._instance || (this._instance = new this());
    }    

    public preBuildTemplates(model: any) {
        var tplAtt = model.get('templates');
        for(var key in tplAtt){
            tplAtt[key] = _.template(tplAtt[key]);
        }
    }

    public buildAndDownloadTemplate(currentTaskId, listTaskId, records, template){
        var recordContext = new Context({ contextId: 'record' });
        recordContext.addFields(records);
        let request = new TaskListingRequest(User.getCurrentDomain(), currentTaskId, listTaskId);
        request.setRecordContext(recordContext);
        request.setTemplate(template);
        Server.performRequest(request)
            .then(this.onTplPrinted)
            .catch(App.displayErrorMessage);
    }

    public onTplPrinted(result) {
        var printParam = {
            printId: result.printId,
            filename: result.files[0].name,
            humanName: result.files[0].humanName
        };

        var fileParam = 'request1='+JSON.stringify(printParam);
        App.downloadDocument(Server.getTokenedUrl('file/'+User.getCurrentDomain()+'/result?'+fileParam));
    }
}

const singleInstance = TemplateManager.Instance;

export default singleInstance;

