'use strict';

import * as Backbone from 'backbone';
import { SAIView } from '../../Additions';

class UploadingFileView extends SAIView {
    private imgSrc : string;

    constructor(options? : any){
        super(options);
    }

    /**
     * This views takes a File as argument during initialisation and displays
     * a thumbnail from it after reading it locally.
     *
     * @param {object} options
     */
    initialize(options) {
        this.options = options;

        this.checkMandatoryParameter(options, 'template', 'Document retreival condition depends of the mode');

        this.template = require('ejs-loader!templates/' + options.template + '.ejs');

        var reader = new FileReader();

        var me = this;
        /**
         * When the reader manages to read the image we preview it
         * by rendering it again
         */
        reader.onload = function (e : any) {
            me.imgSrc = e.target.result;
            me.render();
        };

        if (this.model.get('file')) {
            var extension = this.model.get('file').name.split('.').pop().toLowerCase();
            if ((extension === 'jpg' || extension === 'png' || extension === 'jpeg') && this.options.preview !== false) {
                reader.readAsDataURL(this.model.get('file'));
            } else {
                me.imgSrc = 'images/file-64.png';
            }
        }
        this.status = this.options.status;
        this.mode = 'edit';
    }

    render() : any {
        this.checkInitialized();
        this.$el.html(this.template({
            imgSrc: this.imgSrc,
            path: this.model.get('file').name,
            mode: this.mode,
            status: this.status
        }));

        this.$el.find('.documentDownloadIcon').click(this.onMainImageClick.bind(this));
    }

    onMainImageClick(evt) {
        this.trigger('downloadIconClicked', this.model, evt);
    }

    setMode(mode) {
        this.mode = mode;
        this.render();
    }

    setStatus(status) {
        this.status = status;
        this.render();
    }

    setProgress(value) {
        if (this.mode === 'progress') {
            this.$el.find('.upProgress').val(value);
        } else {
            console.error('can\'t set progress to non progress mode');
        }
    }
}

export default UploadingFileView;
