'use strict';

let $ = require('jquery');

import App from 'App';
import Config from 'Config';
import Context from 'models/context/Context';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import Server from 'server/Server';
import User from 'User';
import I18NUtils from 'utils/i18n/I18NUtils';
import StringUtils from 'utils/StringUtils';
import ColorUtils from 'utils/ColorUtils';
import { SAIView } from '../../Additions';
import { DomainContext } from '../../parametrage/DomainContext';
import SubmitRecordRequest from '../../server/protocol/request/task/SubmitRecord';
import NotificationManager from '../../utils/notification/NotificationManager';
import ModalConfirmationDialog from '../modal/ModalConfirmationDialog';
import { TaskView } from '../tasks/Task';
import RecordView from './Record';
import DialogUtils from '../../utils/DialogUtils';


enum TaskPopupButtons {
    ShowModify = 1,
    ShowCancel = 2,
    ShowDelete = 4,
    ShowCreate = 8
}

class TaskPopupView extends SAIView {
    private operationTitle : string;
    private buttons : any;
    private taskView : TaskView;
    private taskViewEl : JQuery<HTMLElement>;
    private buttonsHolder : any;
    private modalBody : any;
    private modalContent : any;
    private modalDialog : any;
    private modalFooter : any;
    private domainContext: DomainContext;
    private buttonsVisibility: number;
    private operationState: string;
    private modalMessageIsDisplayed: boolean;
    private operationColor: string;

    initialize(options) {
        this.options = options;
        this.modalMessageIsDisplayed = false;
        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.btnTemplate = require('ejs-loader!templates/modalPopup/ModalPopupButton.ejs');

        this.checkMandatoryParameter(options, 'operationTitle', 'The i18n title of the popup');
        this.checkMandatoryParameter(options, 'displayMode', 'How to display the record within the popup');
        this.checkMandatoryParameter(options, 'taskView', 'task to display');
        this.checkMandatoryParameter(options, 'domainContext', 'The execution context of the component');
        this.operationState = options.operationState;
        this.operationTitle = this.options['operationTitle'];
        this.operationColor = this.options['operationColor'] || '#F6A115';

        this.domainContext = options.domainContext;
        this.taskView = options.taskView;
        this.taskView.setModal(true);
        this.uniqId = StringUtils.guid();

        this.buttons = [
            {
                role: 'modifiy',
                btnText: 'Modifier', //todo i18n
                operation: this.operateOpenedTask.bind(this, false),
                visibilityFlag: TaskPopupButtons.ShowModify
            },
            {
                role: 'create',
                btnText: 'Ajouter', //todo i18n
                operation: this.operateOpenedTask.bind(this, true),
                visibilityFlag: TaskPopupButtons.ShowCreate
            },
            {
                role: 'cancel',
                btnText: 'Annuler',
                operation: this.cancelAction.bind(this),
                visibilityFlag: TaskPopupButtons.ShowCancel
            },
            {
                role: 'delete',
                btnText: 'Supprimer', //todo i18n
                operation: this.deleteOpenedTask.bind(this),
                visibilityFlag: TaskPopupButtons.ShowDelete
            }
        ];

        this.initButtonsVisibility();
    }

    initButtonsVisibility() : void {
        this.buttonsVisibility |= TaskPopupButtons.ShowCancel;
        if(this.operationState === 'modify') {
            if(this.taskView.canModify) {
                this.buttonsVisibility |= TaskPopupButtons.ShowModify;
            }
            if(this.taskView.canDelete) {
                this.buttonsVisibility |= TaskPopupButtons.ShowDelete;
            }
        } else if(this.operationState === 'create') {
            this.buttonsVisibility |= TaskPopupButtons.ShowCreate;
        }
    }

    render() : any {
        this.checkInitialized();

        this.$el = $(this.template({
            operationTitle: this.options.operationTitle,
            operationColor: this.operationColor,
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: false,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: '',
            allowDismiss: false,
            colorUtils: ColorUtils
        }));

        this.taskViewEl = this.$el.find('.innerView');

        this.buttonsHolder = this.$el.find('.modal-footer');

        for (var i = 0; i < this.buttons.length; i++) {
            var current = this.buttons[i];
            if((current.visibilityFlag & this.buttonsVisibility) === current.visibilityFlag) {
                var toAdd = $(this.btnTemplate({
                    bootstrapClass: 'btn-success',
                    btnClass: 'recordFormAcceptBtn',
                    enableOnLoad: 'enableOnLoad',
                    disabled: '',
                    btnText: current.btnText,
                    role: current.role,
                    dismiss: current.dismissOnClick === false ? '' : 'data-dismiss="modal"'
                }));
                if (current.faIcon !== undefined) {
                    toAdd.find('i').addClass('fa '+current.faIcon);
                }

                toAdd.appendTo(this.buttonsHolder);
                toAdd.on('click', current.operation);
                toAdd.on('keyup', this.handleButtonKeyup.bind(this));
            }
        }
    }

    display(callback?): Promise<void> {
        var me = this;
        return new Promise((accept) => {
            //rendering popup
            this.render();

            //displaying as modal
            this.$el.on('shown.bs.modal', function () {
                //registering user btn clicks here
                me.displayTask();
                accept();
            });
            this.$el.on('hidden.bs.modal', function () {
                if(!me.modalMessageIsDisplayed) {
                    //calling given callback
                    $('#' + me.uniqId).remove();
                    if (callback) {
                        callback();
                    }
                }
            });
            this.$el.modal({
                'show' : true,
                keyboard: false,
                backdrop: 'static'
            });
        });

    }

    displayTask() {
        this.modalDialog = this.$el.children('.modal-dialog');
        this.modalContent = this.modalDialog.children('.modal-content');
        this.modalBody = this.modalContent.children('.modal-body');
        this.modalFooter = this.modalContent.children('.modal-footer');

        this.taskView.setElement(this.taskViewEl);
        this.$el.find('.loadingSpinner').hide();
        this.taskView.render();
        this.taskView.onDOMUpdated();
        this.$el.find('.modal-dialog').addClass('record-popup');
        this.$el.find('.enableOnLoad').prop('disabled', false);
        
        this.modalBody.children('.mCustomScrollBox').children('.mCSB_scrollTools').find('.mCSB_dragger_bar').css('background-color', Config.appTheme);
    }

    displayErrorMessage(message: any) {
        this.modalMessageIsDisplayed = true;
        this.$el.modal('hide');
        App.displayErrorMessage(message)
            .then(() => {
                this.modalMessageIsDisplayed = false;
                this.display();
            });
    }

    getTaskView(): TaskView {
        return this.taskView;
    }

    operateOpenedTask(newEntry: boolean) {
        let submitRequest = new SubmitRecordRequest(this.domainContext, this.getTaskView().getConfig().getId());
        submitRequest.setOperation(newEntry ? 'new' : 'modify');

        var recordContext = new Context({ contextId: 'record'});
        let panelList = this.taskView.getConfig().getPanels();
        let invalidPanel = undefined;
        for(let panel in panelList) {
            let currentPanel = panelList[panel];
            let recordView: RecordView = this.taskView.getRecordView(currentPanel.getId());
            let validRecord = recordView.validate();
            if(!validRecord) {
                invalidPanel = currentPanel;
                break;
            }
            recordContext.addPanelFields(this.taskView.getRecordView(currentPanel.getId()).getFieldsValues(),currentPanel.getId());
        }
        if(invalidPanel) {
            let recordView = this.taskView.getRecordView(invalidPanel.getId());
            this.taskView.displayPanel(invalidPanel.getId());
            recordView.moveToField(recordView.getInvalidField());
            this.displayErrorMessage(I18NUtils.getMessage('RECORD_INVALID_FIELD_VALUES', User.getLocale(), {}));
        } else {
            submitRequest.setRecordContext(recordContext);
            var keyContext = new Context({
                contextId: 'key'
            });
            if(!newEntry) { keyContext.addFields(this.taskView.getKeys()); }
            submitRequest.setKeyContext(keyContext);

            Server.performRequest(submitRequest)
                .then((accept) => {
                    this.trigger('modalWindowAction',NotificationManager.SUCCESS);
                }).catch((error) => {
                    this.displayErrorMessage(error);
                });
        }
    }

    cancelAction() {
        this.trigger('modalWindowAction',NotificationManager.USER_CANCELLED);
    }

    deleteOpenedTask() {
        this.modalMessageIsDisplayed = true;
        DialogUtils.displayDialog({
            title:'',
            message:'Cette entrée sera supprimée sans possibilité de revenir en arrière.'
        }, this.onConfirmationDialogOkCancelConfirm.bind(this));
    }

    onConfirmationDialogOkCancelConfirm(userChoice: string) {
        this.modalMessageIsDisplayed = false;
        if(userChoice === 'confirm') {
            let submitRequest = new SubmitRecordRequest(this.domainContext, this.getTaskView().getConfig().getId());
            submitRequest.setOperation('delete');

            var recordContext = new Context({ contextId: 'record'});
            let panelList = this.taskView.getConfig().getPanels();
            for(let panel in panelList) {
                let currentPanel = panelList[panel];
                recordContext.addPanelFields(this.taskView.getRecordView(currentPanel.getId()).getFieldsValues(),currentPanel.getId());
            }
            submitRequest.setRecordContext(recordContext);
            var keyContext = new Context({
                contextId: 'key'
            });
            keyContext.addFields(this.taskView.getKeys());
            submitRequest.setKeyContext(keyContext);

            Server.performRequest(submitRequest)
                .then(() => {
                    this.trigger('modalWindowAction',NotificationManager.SUCCESS)
                }).catch((error) => {
                    this.displayErrorMessage(error);
                });
        } else {
            this.$el.modal('show');
        }
    }
    

    fillDeleteContext(item, context) {
        context['operation'] = 'delete';
        let keys = this.taskView.getKeys();
        for(let key in keys) {
            context[key] = keys[key];
        }
    }

    getTaskWidth():number {
        let recordsMaxWidth = 0;
        for(let recIdx in this.taskView.getRecordViews()) {
            let record = this.taskView.getRecordView(recIdx);
            recordsMaxWidth = Math.max(recordsMaxWidth, record.getMaxWidth());
        }
        return recordsMaxWidth;
    }

    private handleButtonKeyup(e: JQueryEventObject) {
        // space or enter
        if(e.which === 13 || e.which === 32) {
            $(e.target).click();
        }
    }
}

export { TaskPopupView };

