'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class PrepareNewPageRequest extends TaskRequest{
    constructor(domain:DomainContext, task){
        super('prepareNewPage','POST', domain, task);
    }

    setRecordContext(context){
        this.record = context;
    }

    setPageContext(context){
        this.page = context;
    }

    getSerializable(){
        return {
            request1: {
                record: this.record? this.record.toJSON(): undefined,
                page: this.page? this.page.toJSON(): undefined
            }
        };
    }
}

export default PrepareNewPageRequest;