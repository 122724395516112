'use strict';

const config = {
    serverUrl: '${PROTOCOL}://${HOST}/SNV4SRV-mobile-war',
    deviceType : 'web',
    appTheme: '#669934',
    webTitle: '${WEB_TITLE}',
    alerts: {
        intensiveRangeHours: [6,19],
        refreshRateMin: 60,
        intensiveRefreshRateMin: 10
    }
};

export default config;
