'use strict';

import { AuthenticationRequest } from './AuthenticationRequest';

class UserInfosRequest extends AuthenticationRequest{
    private domainName : string;

    constructor(domain:string){
        super('info', 'GET');
        this.domainName = domain;
    }

    getSerializable(){
        return {
            request1: {
                domain: this.domainName
            }
        };
    }

    getName(){
        return 'UserInfosRequest';
    }
}

export { UserInfosRequest };