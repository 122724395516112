'use strict';

import FieldView from 'views/record/Field';
import Inputmask from 'inputmask';

class TextField extends FieldView {
    onDOMUpdated() {
        let specialMask = this.fieldConfig.getLinkedDataField().getSpecialMask();
        if(specialMask !== undefined && specialMask.length > 0) {
            specialMask = specialMask.replace(/0/g, '9');
            let field = this.getFieldElement();
            if(field.length > 0) {
                var im = new Inputmask(specialMask);
                im.mask(field[0]);
            }
        }
    }
}

export default TextField;