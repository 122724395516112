'use strict';

import FieldOperationRequest from './FieldOperationRequest';

class FieldClickRequest extends FieldOperationRequest{
    constructor(domain, task){
        super('fieldClick',domain, task);
    }
}

export default FieldClickRequest;