'use strict';

/**
 * This class holds all the color configuration as well as specific resources
 * of a sainet theme.
 */
class ColorTheme {
    private id: string;
    private name: string;
    private properties: { [key:string]:string };
    private colors: { [key:string]:string };
    private wallpapers: Array<string>;

    constructor(_config: { id: string, name: string, properties: {[key:string]:string}, wallpapers: Array<string>, colors: {[key:string]:string} }) {
        this.properties = _config.properties || {};
        this.colors = _config.colors || {};
        this.wallpapers = _config.wallpapers || [];
        this.id = _config.id;
        this.name = _config.name;
    }

    initialize() : Promise<any> {
        return new Promise<any>((accept) => {
            accept(undefined);
        });
    }

    /**
     * @returns The user friendly name of the theme or the i18n 'No name' equivalent
     */
    public getName(): string { 
        return this.name;
    }

    public getId(): string {
        return this.id;
    }
    /**
     * @returns The value of the given mapped color from the theme mapping. If the
     * color is not in the mapping, it checks if the color is already a html one.
     * If so, it returns it, or it returns null
     */
    public getColor(id: string): string {
        if(this.colors[id] !== undefined) {

        } else if(/^#(?:[0-9a-f]{3}){1,2}$/i.test(id)) {
            return id;
        } else {
            return null;
        }
    }

    /**
     * @returns the existing mapping of colors
     */
    public getColors() : { [key:string]:string } {
        return this.colors;
    }
    
    /**
     * @returns The value of the given property
     */
    public getProperty(propertyId: string): string {
        return this.properties[propertyId];
    }

    /**
     * @returns The list of available wallpapers
     */
    public getWallPapers(): Array<string> {
        return this.wallpapers;
    }

    public hasWallPaper(id: string) : boolean {
        for(let i in this.wallpapers) {
            if(this.wallpapers[i] === id) {
                return true;
            }
        }
        return false;
    }
}

export { ColorTheme };