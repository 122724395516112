'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class ComboRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('combo', domain, _id);
    }
}

export { ComboRequest };