'use strict';

import App from 'App';

import NavigatorView from 'views/tasks/navigators/simple/Navigator';

import _ from 'lodash';
import Backbone from 'backbone';
import { SAIView } from '../../Additions';
import ColorUtils from 'utils/ColorUtils';

class TabBarMenu extends SAIView {

    initialize (options) {
        this.options = options;

        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.btnTemplate = require('ejs-loader!templates/modalPopup/ModalPopupButton.ejs');

        this.checkMandatoryParameter(options, 'entries', 'Entries to display');
        this.checkMandatoryParameter(options, 'template', 'Templates for item display');

        /*
         * This holds the list of the current loaded records in the navigator
         * If this list is reset, the navigator will re-render the new records
         */
        options.template = _.template(options.template);

        this.title = options.title;

        this.navigator = new NavigatorView({
            template: {
                navigator: options.template
            },
            itemsClass: options.itemsClass,
            taskId: '_',
            gridId: '_',
            navClass: 'tabbar-menu',
            gridConfig: undefined
        });

        this.uniqId = parseInt(Math.random() * 10000);
    }

    render () {
        this.checkInitialized();

        this.$el = $(this.template({
            operationTitle: this.title,
            logo: '',
            operationColor: App.getConfig().appTheme,
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: true,
            hideTitle: !(this.title && this.title !== ''),
            hideCloseButton: true,
            extraClass: this.options.extraClass || '',
            allowDismiss: true,
            colorUtils: ColorUtils
        }));

        this.$el.find('.modal-header, .modal-footer').css({
            padding: '5px'
        });

        this.navigatorViewEl = this.$el.find('.innerView');

        this.buttonsHolder = this.$el.find('.modal-footer');

        this.loadingSpinner = this.$el.find('.loadingSpinner');

        this.navigator.setElement(this.navigatorViewEl);
        this.navigator.render({
            displayTitle: false,
            displayFilter: false,
            displayHeader: false,
            displayFooter: false
        });

        this.navigator.onRecordReceived({
            items: this.parameters.entries
        });

        this.listenTo(this.navigator, 'itemSelected', this.onNavigatorItemSelected.bind(this));
    }

    onNavigatorItemSelected (itemId) {
        var currentGroupSelection = this.navigator.currentRecords.at(itemId);
        this.$el.modal('hide');
        this.trigger('entrySelected', currentGroupSelection.get('id'), currentGroupSelection);
    }

    display () {
        var me = this;

        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            me.loadingSpinner.hide();
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#' + me.uniqId).remove();
            if ($('body').children('.modal').hasClass('in')) {
                $('body').addClass('modal-open');
            }
        });
        this.$el.modal('show');

    }
}

export default TabBarMenu;
