module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n        <select class="form-control changeableField selectpicker" data-id="' +
__e( fieldState.getId() ) +
' " data-style="btn-inverse" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' ' +
__e( multiple? 'multiple="multiple"' : '') +
'>\n        <option value=""></option>\n    ';
 for(var i = 0; i < selectValues.length; i++) { ;
__p += '\n        ';
 if( selectValues[i].getId() === value) { ;
__p += '\n            <option value="' +
__e( selectValues[i].getId() ) +
'" selected>' +
__e( selectValues[i].getLabel() ) +
'</option>\n        ';
 } else { ;
__p += '\n            <option value="' +
__e( selectValues[i].getId() ) +
'">' +
__e( selectValues[i].getLabel() ) +
'</option>\n        ';
 } ;
__p += '    \n    ';
 } ;
__p += ' \n    </select>\n</div>';

}
return __p
}