'use strict';

import { TaskModel } from '../models/Task';

import { AlbumView } from 'views/album/photos/Album';

import { SimpleTask } from 'views/tasks/Simple';
import { DoubleTask } from 'views/tasks/Double';
import { DossierTask } from 'views/tasks/Dossier';
import { CalendarTask } from 'views/tasks/Calendar';
import { ListTask } from 'views/tasks/List';
import { ViewTask } from 'views/tasks/View';

import TemplateManager from 'utils/TemplateManager';

import App from 'App';
import { AbstractTask } from '../parametrage/structures/AbstractTask';
import { TaskWindowType, TaskPresentation, TaskView } from '../views/tasks/Task';
import { DomainContext } from '../parametrage/DomainContext';
import { access } from 'fs';
import { Task } from '../parametrage/structures/Task';

class TaskFactory {
    private static _instance: TaskFactory;
    private taskCounter: number;

    //Singleton definition
    private constructor() {
        this.taskCounter = 0;
    }

    public static get Instance(): TaskFactory {
        return this._instance || (this._instance = new this());
    }

    public createTask(taskConfig: AbstractTask, accesses, domainContext: DomainContext, extraParameters?:{[propId:string]: any}, initialModifier ?: (config:Task) => void): Promise<TaskView> {
        extraParameters = extraParameters || {};
        this.taskCounter++;
        let newConfig = taskConfig.clone();
        if(initialModifier) {
            initialModifier(newConfig as Task);
        }
        let type = newConfig.getType();
        var newId = type + 'Task' + this.taskCounter;
        newConfig.setAccesses(accesses.access);
        newConfig.setPageAccess(accesses.allowedPages);
        newConfig.setInitialPropsOverride(accesses.initialpropsOverride);
        
        //Prebuilding all templates so that we only build them once in
        //multiple instance of subviews
        var newTask;

        var parameters = {
            taskConfig: newConfig,
            el: '#mainTaskContainer',
            domainContext: domainContext
        };

        Object.assign(parameters,extraParameters);

        if (type === 'B') {
            let inputInterface = parameters.taskConfig.getConfig().inputInterface;
            if(inputInterface === 'History/Planning') {
                newTask = new CalendarTask(parameters);
            } else {
                newTask = new SimpleTask(parameters);
            }
        } else if (type === 'F') {
            newTask = new DoubleTask(parameters);
        } else if (type === 'R') {
            newTask = new DossierTask(parameters);
        } else if (type === 'V' || type === 'W') {
            newTask = new ViewTask(parameters);
        } else if (type === 'L' || type === 'M' || type === 'T' || type === 'U') {
            newTask = new ListTask(parameters);
        } else {
            newTask = undefined;
            console.error('no such task type ' + type);
        }
        if (newTask) {
            newTask.setTaskInstanceId(newId);
            //Facade getter to retreive attribute from model directly on task
            newTask.get = function (attribute) {
                return this.model.get(attribute);
            };

            newTask.getModel = function () {
                return this.model;
            };

            newTask.getId = function () {
                return this.id;
            };

            //Facade setter to directly set a model attribute on the task
            newTask.set = function (attribute, value) {
                return this.model.set(attribute, value);
            };

            //Facade functions to allow to modify the display the visibility of the task
            newTask.show = function () {
                this.$el.show();
            };

            newTask.hide = function () {
                this.$el.hide();
            };
        }

        return new Promise((accept, reject) => {
            newTask.initializeConfig().then(() => {
                accept(newTask);
            })
        });
    }

    getNextAvailableTaskId (type) {
        this.taskCounter++;
        return type + 'Task' + this.taskCounter;
    }
}

const singleInstance = TaskFactory.Instance;

export default singleInstance;
