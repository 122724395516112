'use strict';

import NumberView from 'views/record/subfields/Number';

class YearField extends NumberView {
    setModelValue(value:string) {
        super.setModelValue(value);
        const regexp = /^[0-9]{4}$/i;
        const valid = regexp.test(value);

        if(!valid || parseInt(value) < 1800) {
            this.$el.addClass('invalid');
        } else {
            this.$el.removeClass('invalid');
        }
    }
}

export default YearField;
