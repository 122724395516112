module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="photoDeleteModalLabel" aria-hidden="true">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n                <h4 class="modal-title photoInputFormTitle" id="photoDeleteModalLabel">' +
((__t = (title)) == null ? '' : __t) +
'</h4>\n            </div>\n            <div class="modal-body">\n                <p>' +
((__t = (operation)) == null ? '' : __t) +
'</p>\n            </div>\n            <div class="modal-footer">\n                <button type="button" class="btn btn-danger photoInputFormAcceptBtn" data-dismiss="modal">Supprimer</button>\n                <button type="button" class="btn btn-default photoInputFormCancelBtn" data-dismiss="modal">Annuler</button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}