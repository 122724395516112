'use strict';

import * as Backbone from 'backbone';
import { ViewOptions } from 'backbone';

declare module 'backbone' {
    interface View<TModel extends Model> {
        parameters : Array<any>;
        template : any;
        checkMandatoryParameter(options:object, param:any, usage:any) : void;
        getMainPanel() : JQuery<HTMLElement> ;
        onHide() : void;
        onShow() : void;
        onClose() : void;
        checkInitialized() : void;
        options : ViewOptions<TModel>;
        btnTemplate? : any;
        uniqId? : string;
        status? : string;
        mode? : string;
    }

    interface ViewOptions<TModel extends Model> {
        dominantColor? : string;
        displayMode? : string;
        taskId? : string;
        operationTitle? : string;
        buttons? : Array<any>;
        mode? : string;
        gridId? : string;
        recordKeys? : any;
        glyphHolder? : any;
        template? : string;
        status? : string;
        preview? : boolean;
        contextType? : string; 
        groups? : boolean;
        ficheId? : string;
        ficheKeys? : string;
        type? : string;
        message? : any;
        domainContext? :any;
        filters?: any;
        filterId?: any;
        enabled?: boolean;
        sessionVars?: any;
    }
}

/*Backbone.View.prototype.checkMandatoryParameter = function(options, param, usage){
    if (options[param] === undefined) {
        this.badInitialisation = true;
        console.error('Missing "' + param + '" : ' + usage);
        throw new Error('Missing "' + param + '" : ' + usage);
    } else {
        if (!this.parameters) {
            this.parameters = {};
        }

        this.parameters[param] = options[param];
    }
};

Backbone.View.prototype.getMainPanel = function(){
    var panel = this.$el.find('.task-panel');
    if (panel.length === 0) {
        console.error('In view');
        console.error('Missing task-panel class on main div. This class allows the system to switch views within a task stack');
    }
    return panel;
};


Backbone.View.prototype.checkInitialized = function () {
    if (this.badInitialisation === false) {
        console.error('cannot render the view as it has not been initialised properly');
        throw new Error('cannot render the view as it has not been initialised properly');
    }
};*/

export default Backbone;

export class SAIView extends Backbone.View<Backbone.Model>{
    parameters : any;
    badInitialisation : boolean;

    constructor(options) {
        super(options);
        this.options = options;
        //this.checkMandatoryParameter(options, 'domainContext', 'The context of configuration to use in almost every call');
    }

    checkMandatoryParameter(options, param, usage){
        if (options[param] === undefined) {
            this.badInitialisation = true;
            console.error('Missing "' + param + '" : ' + usage);
            throw new Error('Missing "' + param + '" : ' + usage);
        } else {
            if (!this.parameters) {
                this.parameters = {};
            }

            this.parameters[param] = options[param];
        }
    }

    getMainPanel() : JQuery<HTMLElement>{
        var panel = this.$el.find('.task-panel');
        if (panel.length === 0) {
            console.error('In view');
            console.error('Missing task-panel class on main div. This class allows the system to switch views within a task stack');
        }
        return panel;
    }

    checkInitialized() : void {
        if (this.badInitialisation === false) {
            console.error('cannot render the view as it has not been initialised properly');
            throw new Error('cannot render the view as it has not been initialised properly');
        }
    }

    getViewType() {
    }

    getRecordView(panelId: string) :any {
    }

    getRecordViews():any{
    }
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
        position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.lastIndexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(search, pos) {
      return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
}