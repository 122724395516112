'use strict';
import App from 'App';
import Server from 'server/Server';
import DomainManager from 'DomainManager';

import GetGridDataRequest from 'server/protocol/request/task/GetGridData';
import { SAIView } from '../../Additions';

class HistoryViewer extends SAIView {

    initialize(options) {
        this.options = options;

        this.template = require('ejs-loader!templates/HistoryViewer.ejs');
        this.colorMap = {};

        this.checkMandatoryParameter(options, 'taskId', 'The id of the task to retreive data from');
        this.checkMandatoryParameter(options, 'gridId', 'The grid that will be used for data');
        this.checkMandatoryParameter(options, 'filterId', 'The filter that might limit data retreival');
        this.data = [];
    }

    fetchData() {
        let dataRequest = new GetGridDataRequest(DomainManager.getCurrentContext(Server.getCurrentDomain()), this.parameters.taskId, this.parameters.gridId);
        dataRequest.setFilterId(this.parameters.filterId);
        dataRequest.setRange(0,-1);

        Server.performRequest(dataRequest)
            .then(this.onHistoryDataReceived.bind(this))
            .catch(App.displayErrorMessage);
    }

    onHistoryDataReceived(result) {
        this.data = result.items;
        this.render();
    }

    render() {
        this.checkInitialized();
        this.$el.html(this.template());
        this.historyEl = this.$el.children('.history');
        this.historyEl.css({
            height: this.$el.height()
        });
        this.historyTitleEl = this.$el.children('.historyTitle');
        this.history = new SAITimeHandler({
            rootElement: this.historyEl[0],
            width: this.historyEl.width(),
            height: this.historyEl.height()
        }).History(this.data, {
            viewModes: ['Classic'],
            timeCriteria: '7',
            timeEndCriteria: '8',
            dataGrouping: ['10'],
            colorCriteria: '10',
            currentVisualMode: 'Classic',
            colorSet: this.getEventColor.bind(this),
            titleCriteria: '11',
            onEventClick: this.onEventClicked.bind(this)
        });

        this.history.render();
    }

    getEventColor(entry) {
        if (this.colorMap[entry]) {
            return this.colorMap[entry];
        }

        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        this.colorMap[entry] = color;

        return color;
    }

    onEventClicked(data) {
    }
}

export default HistoryViewer;
