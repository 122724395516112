module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal fade" id="' +
((__t = (uniqId)) == null ? '' : __t) +
'" tabindex="-1" role="dialog" tabindex="-1" aria-labelledby="photoPreview" aria-hidden="true">\n    <div class="modal-dialog fullModalDialog photoPreviewDialog">\n        <div class="modal-content fullModalContent photoPreviewContent">\n            <div class="modal-header photoPreviewHeader"> \n                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n                <div class="modal-title">Preview</div>\n            </div>\n            <div class="modal-body photoPreviewBody">\n                <table class="previewPanel">\n                    <tr>\n                        <td class="prevPanel">\n                            <i class="fa fa-angle-left"></i>\n                        </td>\n                        <td class="photoPanel">\n                            <img style="opacity:0;" class="previewPanelImage" src="' +
((__t = (photo.urlFull)) == null ? '' : __t) +
'"/>\n                            <div class="socialStack" style="opacity:0;">\n                            </div>\n                        </td>\n                        <td class="nextPanel">\n                            <i class="fa fa-angle-right"></i>\n                        </td>\n                    </tr>   \n                </table>\n            </div>\n            <div class="modal-footer photoPreviewFooter">\n                <div class="photoPreviewActions">\n                    <i class="fa fa-cloud-download" data-role="download"></i>\n                    <i class="fa fa-pencil-square-o" data-role="edit"></i>\n                    <i class="fa fa-star" data-role="promote"></i>\n                    <i class="fa fa-trash-o" data-role="delete"></i>\n                    <i class="fa fa-share-alt" data-role="share"></i>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}