'use strict';

import * as Backbone from 'backbone';

class RecordModel extends Backbone.Model {
    outputAllValues() : any{
        var output = [];
        for(var key in this.attributes.columns){
            output.push({key : key, value : this.attributes.columns[key].value});
        }
        return output;
    }

    outputKeys() {
        var output = [];
        for(var key in this.attributes.columns){
            if(this.attributes.columns[key].key) {
                output.push({key : key, value : this.attributes.columns[key].value});
            }
        }
        return output;
    }
}

export default RecordModel;
