'use strict';

import { Task } from './Task';
import { DomainContext } from '../DomainContext';
import { Page } from './Page';
import { DataPage } from './DataPage';
import { Table } from './Table';

class PageInstance extends Page {
    private linkedDataPage: DataPage;

    constructor(config: {id : string, groups: any, datafields: any}, parentTask: Task, parentTable: Table, context: DomainContext) {
        super(config, parentTask, context);
        this.linkedDataPage = parentTable != null ? parentTable.getDataPage(config.id) : null;
    }

    public getOccurrence() {
        return this.getNumberValueOrDefault('pageOcc');
    }

    public getDossierId() {
        return this.getValueOrDefault('dossierId');
    }

    public getTitle() {
        return this.getValueOrDefault('title');
    }

    public getDataPage(): DataPage {
        return this.linkedDataPage;
    }

    public setTitle(newTitle: string) {
        this.config['title'] = newTitle;
    }
}

export { PageInstance };