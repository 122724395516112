module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="loading-mask loading-mask-hidden"><div class="fade-mask"></div><div class="mask-content"><img src="images/loading-splash.gif" /><div class="loading-text">' +
((__t = (text)) == null ? '' : __t) +
'</div></div></div>';

}
return __p
}