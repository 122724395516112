module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <input type="text" class="form-control emailInput changeableField" id="' +
__e( name ) +
'" value="' +
__e( value ) +
'" ' +
((__t = (enabled)) == null ? '' : __t) +
'>\n</div>';

}
return __p
}