module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(!isRupture) { ;
__p += '\n  <div class="icon">\n  <img class="mainIcon coverIcon"/>\n  <div class="thumbContainer">\n      ';
 for(var key in overlay) { ;
__p += '\n      <div class="thumbImage ' +
((__t = (key)) == null ? '' : __t) +
'" style="background-image : url(\'' +
((__t = (server.getTokenedUrl('configuration/' + domainContext.getId() + '/image/highres,big,record/128,100,64,36,32/' + overlay[key]))) == null ? '' : __t) +
'\')"></div>\n      ';
 } ;
__p += '\n  </div>\n  <span class="fa fa-ban dossierLock"></span>\n  </div>\n';
 } ;
__p += '\n<div class="text">   \n  <div class="banner">\n    ' +
((__t = ( displayData(true) )) == null ? '' : __t) +
'\n  </div>\n  <div class="title">\n    ' +
((__t = ( displayData(false) )) == null ? '' : __t) +
'\n  </div>\n</div>\n<div class="actions">\n';
 for( var key in actions){ ;
__p += '\n    <div class="action" data-id="' +
((__t = (key)) == null ? '' : __t) +
'" style="background-image: url(\'' +
((__t = (server.getTokenedUrl('configuration/' + domainContext.getId() + '/image/highres,big,record/128,100,64,36,32/' + actions[key].getIcon()))) == null ? '' : __t) +
'\')"></div>\n';
 } ;
__p += ' \n</div>\n<div class="graph"></div>\n<div class="expansion"><div class="inner"><span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span><span class="glyphicon glyphicon-menu-up" aria-hidden="true"></span></div></div>';

}
return __p
}