'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';

/**
 * This class allows to browse the content of a filter entry. A filter entry
 * is a map of attributes key/value pairs that maps to the original xml
 * parametrage. Helper functions have been added to quickly retreive common
 * attributes.
 */
class FilterElement {
    private attributes: { [key:string]:any };

    constructor(_attributes: {[key:string]:any}) {
        this.attributes = _attributes;
    }

    /**
     * @returns the attribute 'id' of the filter entry
     */
    public getId(): string {
        return this.attributes['id'];
    }

    /**
     * @returns the attribute 'datafieldId' of the filter entry. This value
     * defines that this entry is filtering the given datafield
     */
    public getDatafieldId(): string {
        return this.attributes['datafieldId'];
    }

    /**
     * @returns the attribute 'defaultValue' of the filter entry. This value
     * is the base value that will be applied in the filter. The user will be
     * allowed to modify it later.
     */
    public getDefaultValue(): string {
        return this.attributes['defaultValue'];
    }

    /**
     * @returns the attribute 'permanentValue' of the filter entry. This value
     * is the base value that will be applied in the filter. The user won't be
     * allowed to modify it later
     */
    public getPermanentValue(): string {
        return this.attributes['permanentValue'];
    }

    /**
     * This function retrieves any attribute value that might
     * have been defined in the filter entry. It'll return undefined if not set
     * @param attributeName The string identifier of the attribute to fetch
     * @returns The value of the attribute
     */
    public getAttribute(attributeName: string): string {
        return this.attributes[attributeName];
    }

    public mustBeShownOnNavigator(): boolean {
        return this.attributes['showOnNavigator'] === true;
    }
}

/**
 * This class represents a filter from the parametrage. A filter is supposed to be
 * uniquely idenfied by its name and language. It contains a list of entries. 
 */
class Filter extends ConfigurationElement {
    private type: string;
    private sort: Array<{ datafield: string, order: string }>; 
    private elements : Array<FilterElement>;

    constructor(config: { id: string, label: string, type: string, sort: Array<{ datafield: string, order: string }>, elements : Array<{[key: string]: any}> }, context: DomainContext) {
        super(config, context);
        this.type = config.type;
        this.sort = config.sort;
        this.elements = [];
        for(let key in config.elements) {
            this.elements.push(new FilterElement(config.elements[key]));
        }
    }

    /**
     * @returns The full list of all filterElement that are stored within the filter
     */
    public getElements() : Array<FilterElement>{
        return this.elements;
    }

    /**
     * @param datafieldId The datafieldId of a filterElement within the filter
     * @returns The filterElement identified by the given datafieldId. Null otherwise 
     */
    public getElement(datafieldId: string) : FilterElement {
        for(var key in this.elements){
            let currentEl = this.elements[key];
            if(currentEl.getDatafieldId() === datafieldId) {
                return currentEl;
            }
        }

        return null;
    }

    public getType(): string {
        return this.type;
    }

    public getSort(): Array<{ datafield: string, order: string }> {
        return this.sort;
    }
}

export { Filter, FilterElement };