'use strict';

import { SAIView } from '../../Additions';
import GetGridDataRequest from '../../server/protocol/request/task/GetGridData';
import DomainManager from 'DomainManager';
import User from 'User';
import Server from 'server/Server';
import App from 'App';
import NotificationManager from './NotificationManager';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import StringUtils from '../StringUtils';
import Config from 'Config';

let $ = require('jquery');
let moment = require('moment');

interface AlertItem {
    timestampStart: number,
    timestampEnd: number,
    alertId: string,
    notificationId: string,
    notificationTask: string,
    notificationParameters: string,
    done: boolean
}

class AlertManager {
    private static _instance: AlertManager;
    private pending: { [key: string]: AlertItem }
    private checkTimer: any;
    private fetchTimer: any;
    private lastCall: number;
    private alertTimerWindow: number;

    private constructor() {
        this.pending = {};
        //Every minute, check the alerts
        this.alertTimerWindow = 1000 * 60;
        this.lastCall = null;
    }

    public reset() {
        clearInterval(this.fetchTimer);
        this.fetchNewTimers();
    }

    public static get Instance(): AlertManager {
        return this._instance || (this._instance = new this());
    }

    private fetchNewTimers() {
        if(this.checkTimer) { clearInterval(this.checkTimer); }
        let request = new GetGridDataRequest(DomainManager.getContext(User.getCurrentDomain()), 'SYS27', 'SYS27_ALERTS');
        request.setRange(0,-1);
        Server.performRequest(request)
            .then((response) => {
                for (var index in response.items) {
                    var item = response.items[index];
                    let alertEntry = this.buildAlertEntry(item);
                    if(!this.pending[alertEntry.alertId] || !this.pending[alertEntry.alertId].done) {
                        this.pending[alertEntry.alertId] = alertEntry;
                    }
                }
                this.onTimerCheck();
                this.checkTimer = setInterval(this.onTimerCheck.bind(this), this.alertTimerWindow); // Every minute we check the timer
                return true;
            })
            .catch((error) => {
                //Silent error as alerts are not critical for the user work
                //The code will check for entries again at the next timeout tick
                console.error('Error while retrieving alerts', error);
            })
            .then(() => {
                let curTime = moment();
                //Generation of properties if the config doesn't provide them
                let alertConfig = Config.alerts || {
                    intensiveRangeHours: [6, 19],
                    refreshRateMin: 60,
                    intensiveRefreshRateMin: 10
                };
                alertConfig.intensiveRangeHours = alertConfig.intensiveRangeHours || [6, 19];
                alertConfig.refreshRateMin = alertConfig.refreshRateMin || 60;
                alertConfig.intensiveRefreshRateMin = alertConfig.intensiveRefreshRateMin || 10;

                //Setting the next fetch call based on current hour
                let isRushHour = curTime.hour() >= alertConfig.intensiveRangeHours[0] && curTime.hour() < alertConfig.intensiveRangeHours[1];
                let refreshMin = (isRushHour ? alertConfig.intensiveRefreshRateMin : alertConfig.refreshRateMin);
                this.fetchTimer = setTimeout(this.fetchNewTimers.bind(this), 1000 * 60 * refreshMin);
            });
    }

    private buildAlertEntry(item: { columns: { [key: string]: any} }): AlertItem {
        if(!item.columns) { return null; }
        return {
            timestampStart: moment(item.columns['STARTDATE'].value, 'YYYY-MM-DD HH:mm:ss'),
            timestampEnd: moment(item.columns['ENDDATE'].value, 'YYYY-MM-DD HH:mm:ss'),
            alertId: item.columns['ID'].value,
            notificationId: item.columns['NOTIFICATIONID'].value,
            notificationTask: item.columns['NOTIFICATIONTASK'].value,
            notificationParameters: item.columns['NOTIFICATIONPARAMS'].value,
            done: false
        }
    }

    private onTimerCheck() : void{
        let currentTime = moment();
        let audioPlaying = false;
        for(let key in this.pending) {
            let pendingTimer = this.pending[key];
            if(!pendingTimer.done) {
                if(currentTime.isSameOrAfter(pendingTimer.timestampStart) && currentTime.isBefore(pendingTimer.timestampEnd)) {
                    let notificationManager = new NotificationManager({
                        currentTaskContext: undefined,
                        contextFiller: this.fillAlertContext.bind(this, pendingTimer),
                        domainContext: DomainManager.getContext(User.getCurrentDomain())
                    })
                    //Build of remote notification arguments
                    let remoteArgs = notificationManager.parseRemoteArguments(pendingTimer.notificationParameters);
                    for (let key in remoteArgs) {
                        remoteArgs[key] = StringUtils.resolveTextProperties(remoteArgs[key], notificationManager.context);
                    }
                    //Fetch of the notification and call
                    CClientConfiguration.getRemoteNotification(pendingTimer.notificationId, pendingTimer.notificationTask, remoteArgs)
                        .then((notif) => {
                            pendingTimer.done = true;
                            if(!audioPlaying) {
                                audioPlaying = true;
                                $('#notification-sound')[0].play();
                            }
                            notificationManager.handle(notif);
                        })
                        .catch((reason) => {
                            App.displayErrorMessage(reason);
                            //The alert crashed, we delete it which will prevent
                            //it from beeing processed again until it's fetched
                            //again if it still exists in the future
                            delete this.pending[key];
                        });
                } else if(currentTime.isAfter(pendingTimer.timestampEnd)) {
                    //Cleanup of old entries
                    delete this.pending[key];
                }
            }
        }
    }

    private fillAlertContext(alert: AlertItem, context: {[key:string]: string}) {
        context['SYSTAL.ID_PK'] = alert.alertId;
    }
}

const singleInstance = AlertManager.Instance;

export default singleInstance;
