module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var rgbVersion = colorUtils.hexToRgb(operationColor);
var hslVersion = colorUtils.rgbToHsl(rgbVersion.r, rgbVersion.g, rgbVersion.b);
var increase = hslVersion.h < 150 ? -1 : 1;
hslVersion.h = Math.min(255, hslVersion.h + increase * hslVersion.h * 0.05);
var newRgb = colorUtils.hslToRgb(hslVersion);
var secondBase = colorUtils.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
var darkerSecond = colorUtils.shadeColor(secondBase, - 0.10);
var darkerFirst = colorUtils.shadeColor(operationColor, - 0.05);
var patternId = Math.random();
;
__p += '\n<div class="modal fade show">\n    <div class="mask" style="width: 100%; height: 100%; position: absolute; top: 0px; background-color: #000; opacity: 0.2;"></div>\n    <div class="domain-selector modal-dialog">\n        <div class="modal-content">\n            <div class="modal-title-header">\n                <svg width="100%" height="100%">\n                    <pattern id="pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
'" x="0" y="0" width="180" height="15" patternUnits="userSpaceOnUse">\n                        <rect style="fill:' +
((__t = (secondBase)) == null ? '' : __t) +
';" width="90" height="15" x="0" y="0"></rect>\n                        <rect style="fill:' +
((__t = (operationColor)) == null ? '' : __t) +
';" width="90" height="15" x="90" y="0"></rect>\n                        <path style="fill:' +
((__t = (darkerSecond)) == null ? '' : __t) +
';" d="M 0,0 0,15 H 15 Z"></path>\n                        <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 90,0 90,15 H 105 Z"></path>\n                        <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 135,0 135,15 H 150 Z"></path>\n                    </pattern>\n                    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
')" />\n                </svg>\n            </div>\n            <div class="modal-title recordFormTitle">' +
((__t = (title)) == null ? '' : __t) +
'</div>\n            <div class="modal-body" style="padding: 0 5px;">\n                <div class="form-group">\n                    <label for="CURRENT_DOMAIN">' +
((__t = (currentDomainLabel)) == null ? '' : __t) +
'</label>\n                    <div class="field text" data-id="CURRENT_DOMAIN">' +
((__t = (currentDomain)) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="form-group">\n                    <label for="DOMAIN_SELECT">' +
((__t = (selectDomainLabel)) == null ? '' : __t) +
'</label>\n                    <select class="form-control changeableField selectpicker" data-id="DOMAIN_SELECT" data-style="btn-inverse">\n                        ';
 for(var i = 0; i < selectValues.length; i++) { ;
__p += '\n                            ';
 if( selectValues[i].id === currentDomain) { ;
__p += '\n                                <option value="' +
__e( selectValues[i].id ) +
'" selected>' +
__e( selectValues[i].id + ' - ' + selectValues[i].label ) +
'</option>\n                            ';
 } else { ;
__p += '\n                                <option value="' +
__e( selectValues[i].id ) +
'">' +
__e( selectValues[i].id + ' - ' + selectValues[i].label ) +
'</option>\n                            ';
 } ;
__p += '    \n                        ';
 } ;
__p += ' \n                    </select>\n                </div>\n                <div class="form-group">\n                        <label for="CURRENT_EXERCICE">' +
((__t = (currentExerciceLabel)) == null ? '' : __t) +
'</label>\n                        <div class="field text" data-id="CURRENT_EXERCICE">' +
((__t = (currentExercice)) == null ? '' : __t) +
'</div>\n                    </div>\n                    <div class="form-group">\n                        <label for="EXERCICE_SELECT">' +
((__t = (selectExerciceLabel)) == null ? '' : __t) +
'</label>\n                        <select class="form-control changeableField selectpicker" data-id="EXERCICE_SELECT" data-style="btn-inverse">\n                            ';
 for(let i in Object.keys(exercicesValues)) { ;
__p += '\n                                ';
 let key = Object.keys(exercicesValues)[i]; ;
__p += '\n                                ';
 if( exercicesValues[key].id === currentExercice) { ;
__p += '\n                                    <option value="' +
__e( exercicesValues[key].id ) +
'" selected>' +
__e( exercicesValues[key].label ) +
'</option>\n                                ';
 } else { ;
__p += '\n                                    <option value="' +
__e( exercicesValues[key].id ) +
'">' +
__e( exercicesValues[key].label ) +
'</option>\n                                ';
 } ;
__p += '    \n                            ';
 }; ;
__p += ' \n                        </select>\n                    </div>\n            </div>\n            <div class="modal-footer">\n                <div class="confirmation-button btn" data-id="CANCEL">\n                    <i class="fa fa-ban"></i>\n                    <div clas="btn-text">' +
((__t = (buttonsCancelLabel)) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="confirmation-button btn" data-id="VALIDATE">\n                    <i class="fa fa-check-circle"></i>\n                    <div clas="btn-text">' +
((__t = (buttonsValidateLabel)) == null ? '' : __t) +
'</div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}