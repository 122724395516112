'use strict';

import App from 'App';

import { SAIView } from '../../../../Additions';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { DataField } from '../../../../parametrage/structures/DataField';

let $ = require('jquery');

class FilterPopupView extends SAIView {
    //Inner values entries
    private filterCache: { [dfId: string]: { linkedDataField: DataField, value: string, label: string}}
    //Interface entries
    private buttonsHolder: JQuery<HTMLElement>;
    private filterViewEl: JQuery<HTMLElement>;
    private fChoice: JQuery<HTMLElement>;
    private opChoice: JQuery<HTMLElement>;
    private valuePicker: JQuery<HTMLElement>;
    private currentFormat: string;

    constructor(options){
        super(options);
        this.uniqId = (Math.random()*10000) + '';
        this.filterCache = {};
    }

    render(): any {
        this.checkInitialized();

        this.$el = $(this.template({
            operationTitle: 'Ajouter un critère',
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            operationColor: App.getConfig().appTheme,
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: false,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: '',
            allowDismiss: true
        }));

        this.buttonsHolder = this.$el.find('.modal-footer');

        var toAdd = $(this.btnTemplate({
            bootstrapClass: 'btn-success',
            btnClass: 'recordFormAcceptBtn',
            enableOnLoad: '',
            disabled: '',
            btnText: 'Valider',
            role: 'ok',
            dismiss: 'data-dismiss="modal"'
        }));

        toAdd.appendTo(this.buttonsHolder);
        toAdd.on('click', this.parameters.callback);

        var toAdd2 = $(this.btnTemplate({
            bootstrapClass: 'btn-success',
            btnClass: 'recordFormCancelBtn',
            enableOnLoad: '',
            disabled: '',
            btnText: 'Annuler',
            role: 'ok',
            dismiss: 'data-dismiss="modal"'
        }));

        toAdd2.appendTo(this.buttonsHolder);
    }

    display () {
        var me = this;

        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            me.$el.find('.loadingSpinner').hide();
            me.filterViewEl = me.$el.find('.innerView');
            me.$el.addClass('navigatorFilterPopup');
            me.displayFilter();
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#'+me.uniqId).remove();
        });
        this.$el.modal('show');
    }

    displayFilter(){
        this.setupChoice();
        var fChoice = this.$el.find('.filterSelect');
        fChoice.val(this.parameters.filters[0].fieldId);
        fChoice.change();
    }

    setupChoice(){
        this.fChoice = this.$el.find('.filterSelect');
        this.fChoice.empty();
        for(var key in this.parameters.filters){
            var current = this.parameters.filters[key];
            this.filterCache[current.fieldId] = current;
            this.fChoice.append('<option value="'+current.fieldId+'">'+this.getDfText(current.fieldId)+'</option>');
        }
        this.fChoice.change(this.onFChoiceChange.bind(this));
    }

    onFChoiceChange(){
        var df: string = this.fChoice.val() + '';
        var currentFilter = this.filterCache[df].linkedDataField;
        if(currentFilter.getFormat() === 'String'){
            this.setupTextFilter();
        }
    }

    setupTextFilter(){
        this.currentFormat = 'text';
        this.opChoice = this.$el.find('.operation');
        this.opChoice.empty();

        this.opChoice.append('<option value="CONTAINS">'+this.getChoiceText('CONTAINS')+'</option>');
        this.opChoice.append('<option value="NOTCONTAINS">'+this.getChoiceText('NOTCONTAINS')+'</option>');
        this.opChoice.append('<option value="STARTS">'+this.getChoiceText('STARTS')+'</option>');
        this.opChoice.append('<option value="ENDS">'+this.getChoiceText('ENDS')+'</option>');
        this.opChoice.append('<option value="DOESNOTSTART">'+this.getChoiceText('DOESNOTSTART')+'</option>');
        this.opChoice.append('<option value="DOESNOTEND">'+this.getChoiceText('DOESNOTEND')+'</option>');
        this.opChoice.append('<option value="ISONEOF">'+this.getChoiceText('ISONEOF')+'</option>');

        this.valuePicker = this.$el.find('.picker');
        this.valuePicker.empty();
        this.valuePicker.append('<input class="value"/>');
    }

    getChoiceText(choice){
        if(choice === 'CONTAINS'){
            return 'contient';
        }else if(choice === 'NOTCONTAINS'){
            return 'ne contient pas';
        }else if(choice === 'STARTS'){
            return 'commence par';
        }else if(choice === 'ENDS'){
            return 'finit par';
        }else if(choice === 'DOESNOTSTART'){
            return 'ne commence pas par';
        }else if(choice === 'DOESNOTEND'){
            return 'ne finit pas par';
        }else if(choice === 'ISONEOF'){
            return 'est un parmi';
        }else{
            return 'ERROR';
        }
    }

    getDfText(dfId){
        return this.filterCache[dfId].label;
    }

    getSelection(){
        return {
            dfId : this.fChoice.val(),
            operation : this.opChoice.val(),
            value : this.getValue(),
            type : this.currentFormat
        };
    }

    getValue(){
        if(this.currentFormat === 'text'){
            return this.valuePicker.children('input').val();
        }else{
            return 'non défini';
        }
    }
}

export { FilterPopupView };
