/// <reference path="./I18NMessage.d.ts" />

'use strict';

import StringUtils from '../StringUtils';

let jsonFiles = {
    'fr' : require('./messages_fr.json'),
    'de' : require('./messages_de.json')
};

class I18NUtils {
    private static _instance: I18NUtils;
    private messages : {[lang: string] : {[messageId:string] : {title: string, body: string, expert: string} } };
    //Singleton definition
    private constructor() {

        //initialisation of messages in all set languages
        for(let lang in jsonFiles) {
            if(this.messages  === undefined){
                this.messages = {};
            }

            if(this.messages[lang] === undefined) {
                this.messages[lang] = {};
            }

            let currentLangMessages = jsonFiles[lang];
            for(let messageKey in currentLangMessages) {
                this.messages[lang][messageKey] = currentLangMessages[messageKey];
            }
        }
    }

    public static get Instance(): I18NUtils {
        return this._instance || (this._instance = new this());
    }

    /**
     * Retrieves the user messages from the application json properties files.
     * The french language is the default one. If the message does not exist,
     * then it'll be looked up in french. If it still doesn't exist, then
     * a default error message will be fetched. If no properties are defined,
     * then an hardcoded message will be set.
     *
     * @param messageId The identifier of the message to fetch
     * @param lang The language in which we want the message
     */
    public getMessage(messageId: string, lang: string, context: {[varId: string]: string}) : I18NMessage{

        let localContext: {[varId: string]: string} = Object.assign({}, context);
        if(messageId !== 'MISSING_MESSAGE') {
            localContext['messageId'] = messageId;
        }

        //If there are no messages at all
        if(this.messages === undefined) {
            return {
                title: 'Fichiers properties manquants',
                body: 'Les fichiers internationalisés de messages ne sont pas présents. Contactez votre administrateur.',
                expert: 'Les fichiers json ne sont pas chargés'
            };
        }

        //If the requested language is not set
        if(lang === undefined || lang.trim() === '') {
            lang = 'fr';
        }

        //If the requested language messages are not defined we fallback to the
        //default language
        if(this.messages[lang] === undefined) {
            lang = 'fr';
        }

        let foundMessage = this.messages[lang][messageId];
        if(foundMessage === undefined && this.messages['fr'] !== undefined && lang !== 'fr') {
            //Message doesn't exist we try it in french if the requested language
            //isn't french already
            foundMessage = this.getMessage(messageId, lang, localContext);
        }

        if(foundMessage === undefined && messageId != 'MISSING_MESSAGE') {
            //The message still cannot be found. Falling back to the standard
            //missing message
            foundMessage = this.getMessage('MISSING_MESSAGE', lang, localContext);
        }

        if(foundMessage === undefined) {
            //We still can't find the requested message at all. We fallback to
            //an hardcoded one
            return {
                title: 'Le message ' + messageId + ' est introuvable',
                body: 'Des messages internationalisés sont introuvables. contactez votre administrateur.',
                expert: 'Le message est introuvable ainsi que le fallback MISSING_MESSAGE'
            }
        }

        //We might have to inject variables into the message
        let injectedMessage = Object.assign({}, foundMessage);
        injectedMessage.title = StringUtils.resolveTextProperties(injectedMessage.title, localContext);
        injectedMessage.body = StringUtils.resolveTextProperties(injectedMessage.body, localContext);
        injectedMessage.expert = StringUtils.resolveTextProperties(injectedMessage.expert, localContext);
        return injectedMessage;
    }

    public getText(textId: string, lang: string, context: {[varId: string]: string}) : string{

        let localContext: {[varId: string]: string} = Object.assign({}, context);
        if(textId !== 'MISSING_TEXT') {
            localContext['textId'] = textId;
        }

        //If there are no text at all
        if(this.messages === undefined) {
            return 'Fichiers properties manquants';
        }

        //If the requested language is not set
        if(lang === undefined || lang.trim() === '') {
            lang = 'fr';
        }

        //If the requested language text are not defined we fallback to the
        //default language
        if(this.messages[lang] === undefined) {
            lang = 'fr';
        }

        let foundText = this.messages[lang][textId];
        if(foundText === undefined && this.messages['fr'] !== undefined && lang !== 'fr') {
            //Text doesn't exist we try it in french if the requested language
            //isn't french already
            foundText = this.getMessage(textId, lang, localContext);
        }

        if(foundText === undefined && textId != 'MISSING_TEXT') {
            //The text still cannot be found. Falling back to the standard
            //missing text
            foundText = this.getMessage('MISSING_TEXT', lang, localContext);
        }

        if(foundText === undefined) {
            //We still can't find the requested text at all. We fallback to
            //an hardcoded one
            'Le text ' + textId + ' est introuvable';
        }

        //We might have to inject variables into the text
        return StringUtils.resolveTextProperties(foundText, localContext);
    }
}

const singleInstance = I18NUtils.Instance;

export default singleInstance;

