'use strict';

import FieldView from '../Field';
import App from 'App';
import Server from 'server/Server';
import Config from 'Config';

class GPSCoordinatesField extends FieldView {

    render() {
        super.render();
        let hasValue = this.fieldState.getValue() !== undefined && this.fieldState.getValue() !== '';

        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-gps'),this.computeIconSize())
            .then((element: JQuery<HTMLElement>) => {
                this.$el.find('.form-control').append(element);
                let dominantColor = this.options.dominantColor ? this.options.dominantColor : Config.appTheme
                this.$el.find('.form-control').children('svg').children('path').css('fill',hasValue ? dominantColor : '#999999');
            });

        if(hasValue) {
            this.$el.on('click',this.openInMaps.bind(this));
        } else {
            this.$el.find('svg').addClass('disabled');
        }
    }

    openInMaps():void {
        window.open('http://www.google.com/maps/place/'+this.fieldState.getValue(),'_blank');
    }

    computeIconSize(): {h:number, w:number} {
        let height = this.getConfig().getPosition(this.options.displayMode)['height'];
        return {h: 30*height, w:30*height};
    }
}

export default GPSCoordinatesField;
