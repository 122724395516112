'use strict';

let $ = require('jquery');
import App from 'App';
import { SAIView } from '../../Additions';
import CClientConfiguration from 'parametrage/CClientConfiguration';

class HeaderView extends SAIView {
    operations : any;
    homeOp : any;

    initialize () {
        this.template = require('ejs-loader!templates/Header.ejs');
        this.listenTo(this.model, 'change', this.render);
    }

    render () : any {
        var headerData = this.model.toJSON();
        headerData.appLogo = CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_WHITE);
        this.$el.html(this.template(headerData));
        this.$el.find('.action').click(this.triggerAction.bind(this));
        this.operations = this.$el.find('.taskOperation');
        this.homeOp = this.operations.find('[data-action="home"]');

        this.$el.find('.fa-angle-down').click(this.triggerSimplify.bind(this, 'simplify'));
        this.$el.find('.fa-angle-up').click(this.triggerSimplify.bind(this, 'complexify'));
    }

    triggerSimplify(action, evt) {
        if(action === 'simplify') {
            this.$el.find('.fa-angle-down').hide();
            this.$el.find('.fa-angle-up').show();
        } else {
            this.$el.find('.fa-angle-down').show();
            this.$el.find('.fa-angle-up').hide();
        }
        this.trigger('simplifyRequest', action);
    }

    triggerAction (evt) {
        var action = $(evt.delegateTarget).data('action');
        if(action === 'home'){
            App.router.navigate('#desktop', {trigger: true});
        }else{
            this.trigger('action', action);
        }
    }

    setupContextAction (actions) {
        this.operations.find('[data-role="context"]').off('click').remove();
        for (var key in actions) {
            var newAction = $('<span class="saiCustom saiCustom-' + actions[key].icon + ' action" data-role="context" data-action="' + actions[key].name + '"></span>');
            this.homeOp.after(newAction);
            newAction.on('click', this.triggerAction.bind(this));
        }

    }
}

export default HeaderView;
