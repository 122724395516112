module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


//themes: Array<Theme>
//pages : { [themeId : string] : { state, theme, pages, pagesCount }}
//sourceTaskConfig : Task
//displayActions: boolean
//domainContext: DomainContext

//This variable will hold the fact that we already rendered a page group
var renderedPageGroups = {};
;
__p += '\n<div class="innerPageNav">\n    ';

        for(var i = 0; i < themes.length; i++){
          var currentThemeId = themes[i].getId();
          if(pagesByTheme === undefined || pagesByTheme[currentThemeId] === undefined) {
            continue;
          }
          var currentTheme = pagesByTheme[currentThemeId].theme;
          var currentThemeState = pagesByTheme[currentThemeId].state;
          var currentThemePageCount = pagesByTheme[currentThemeId].pagesCount;
          var currentThemePages = pagesByTheme[currentThemeId].pages;
    ;
__p += '\n    <div class="pageNavTheme">\n        <div class="theme" data-theme="' +
__e(currentThemeId) +
'">\n        <div class="pageNavThemeIcon">\n        </div>\n        <div class="pageNavThemeLibell">\n            ' +
__e(currentTheme.getLabel()) +
'\n            ';
 if(currentThemeState === 'opened'){ ;
__p += '\n                <i class="fa fa-angle-down"></i>\n            ';
 } else { ;
__p += '\n                <i class="fa fa-angle-right"></i>\n            ';
 } ;
__p += '\n        </div>\n        </div>    \n        <div class="pages">\n            ';
 for(var j = 0; j < currentThemePages.length; j++){ 
                var currentPage = currentThemePages[j];
            ;
__p += '\n            ';
 if(!renderedPageGroups[currentPage.getId()] && currentThemePageCount[currentPage.getId()] > 1 ) { ;
__p += '\n                <div class="pageGroup opened" data-group="' +
((__t = ( currentPage.getId() )) == null ? '' : __t) +
'">\n                    <div class="pageNavPageLibell">\n                        ' +
__e(sourceTaskConfig.getPage(currentPage.getId()).getLabel()) +
'\n                    </div>\n                    <div class="pageNavPageIcon">\n                        <i class="fa fa-angle-down groupIcon"></i>\n                    </div>    \n                </div>    \n                ';
 renderedPageGroups[currentPage.getId()] = true; ;
__p += '\n            ';
 } ;
__p += '\n            <div class="page ' +
__e(currentThemePageCount[currentPage.getId()]>1?'page-multiple':'') +
'" data-theme="' +
__e( currentThemeId) +
'" data-pageid="' +
__e( currentPage.getId() ) +
'" data-pageocc="' +
__e( currentPage.getOccurrence() ) +
'">\n                <div class="pageNavPageLibell">\n                    ';
 if(currentThemePageCount[currentPage.getId()] > 1) { ;
__p += '\n                    ' +
__e(  currentPage.getTitle() !== undefined? currentPage.getTitle() : sourceTaskConfig.getPage(currentPage.getId()).getLabel()) +
'\n                    ';
 } else { ;
__p += '\n                    ' +
__e(  sourceTaskConfig.getPage(currentPage.getId()).getLabel() + ( currentPage.getTitle() ? ' (' + currentPage.getTitle() + ')' : '') ) +
'\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div> \n';

}
return __p
}