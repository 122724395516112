module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="gallery">\n    ';
 if (albums) {
        albums.each(function(album, index) {
            var idxDifference = currentIndex - index;

            textAlign = textAlignFunction(idxDifference);
            var translation = translateFunction(baseMove,idxDifference);
            var rotation = rotateFunction(baseDegree,idxDifference);
            var scale = scaleFunction(baseScale,idxDifference);
            var zIndex = zIndexFunction(baseZIndex,idxDifference);
            var titleColor = titleColorFunction(idxDifference);
            var opacity = opacityFunction(idxDifference);
            ;
__p += '\n            <div class="albumThumb" data-id="' +
__e( index ) +
'" data-role="' +
__e( album.get('role') ) +
'" style="left:' +
__e( translation ) +
'; opacity: ' +
__e( opacity ) +
'; -moz-transform: rotateY(' +
__e( rotation ) +
') scale(' +
__e( scale ) +
',' +
__e( scale ) +
'); -o-transform: rotateY(' +
__e( rotation ) +
') scale(' +
__e( scale ) +
',' +
__e( scale ) +
'); transform: rotateY(' +
__e( rotation ) +
') scale(' +
__e( scale ) +
',' +
__e( scale ) +
'); -webkit-transform: rotateY(' +
__e( rotation ) +
') scale(' +
__e( scale ) +
',' +
__e( scale ) +
'); z-index: ' +
__e( zIndex ) +
';">\n                <div class="albumThumbTitle" style="text-align: ' +
__e( textAlign ) +
'; color: ' +
__e( titleColor ) +
';">\n                    ' +
__e( album.get('name') ) +
'\n                </div>\n                <div class="albumThumbImage">\n                    <div class="albumImage" style="background: url(\'' +
__e( album.get('coverUrl') ) +
'\'); background-size:cover;"></div>\n                </div>\n            </div>\n            ';
 
        });
        if(albums.length > 0 && renderScroll){ ;
__p += '\n            <div class="gallerySlider">\n                <input id="' +
__e( uniqBaseId+'-slider' ) +
'" type="range" value="' +
__e( currentIndex ) +
'" min="0" max="' +
__e( albums.length-1 ) +
'" step="1"/>\n            </div>\n        ';
}
        
        if(defaultRendering){ ;
__p += '\n            <div class="noPhotoYet"> Aucune photo trouvée </div>\n        ';
}
    } ;
__p += '\n</div>';

}
return __p
}