'use strict';

import { SAIView } from '../../Additions';
import StringUtils from 'utils/StringUtils';

let $ = require('jquery');

class ModalConfirmationDialog extends SAIView {

    private title: string;
    private message: string;
    private buttons: Array<{id: string, icon: string, text: string}>;
    public static SYSTEM_CANCEL_MESSAGE: Symbol = Symbol();

    constructor(options? : any) {
        super(options);
        this.title = options.title || '';
        this.message = options.message;
        this.template = require('ejs-loader!templates/modalPopup/ModalConfirmationDialog.ejs');
        if(options.type) {
            this.buttons = this.getButtonsFromType(options.type, options.buttons);
        } else {
            if(!options.buttons) {
                {
                    this.buttons = [{
                        id: 'confirm',
                        icon: 'check-circle',
                        text: 'Oui'
                    },{
                        id: 'cancel',
                        icon: 'ban',
                        text: 'Annuler'
                    }];
                }
            } else {
                this.buttons = options.buttons;
            }
        }
    }

    private getButtonsFromType(type: string, buttonsContent: any): Array<{id: string, icon: string, text: string}> {
        let buttons = [];
        switch(type) {
            case 'YES-NO-CANCEL':
                buttons.push({
                    id: 'cancel',
                    icon: 'ban',
                    text: 'Annuler'
                });
            case 'YES-NO':
                buttons = [{
                    id: 'yes',
                    icon: 'check-circle',
                    text: 'Oui'
                },{
                    id: 'no',
                    icon: 'ban',
                    text: 'Non'
                }].concat(buttons);
                break;
            case 'OK-CANCEL':
                buttons.push({
                    id: 'ok',
                    icon: 'check-circle',
                    text: 'Ok'
                },{
                    id: 'cancel',
                    icon: 'ban',
                    text: 'Annuler'
                });
                break;
            case 'OK':
                buttons.push({
                    id: 'ok',
                    icon: 'check-circle',
                    text: 'Ok'
                });
                break;
            case 'CUSTOM':
                buttons = buttonsContent;
                for(let i in buttons) {
                    if(buttons[i].icon === undefined) {
                        buttons[i].icon = 'check-circle';
                    }
                }
                break;
            case 'CUSTOM-CANCEL':
                buttons = buttonsContent.concat({
                    id: 'cancel',
                    icon: 'ban',
                    text: 'Annuler'
                })
                break;
            default:
                console.error('invalid message type ' +type)
                break;
        }
        return buttons;
    }
    initialize(options : any) {
        this.options = options;
        this.uniqId = StringUtils.guid();
    }

    render () : any {
        var me = this;
        this.$el = $(this.template({
            title:this.title,
            message: this.getDisplayMessage(),
            buttons: this.buttons,
            uniqId: this.uniqId
        }));

        this.$el.on('shown.bs.modal', function () {

        });

        this.$el.on('hidden.bs.modal', function () {
            $('#' + me.uniqId).remove();
        });

        this.$el.modal('show');
    }

    display() : Promise<string> {
        var me = this;
        //rendering popup
        this.render();
        var promise = new Promise<string>((accept,reject) => {
            me.$el.find('.confirmation-button').on('click',me.hide.bind(me, accept));
        });

        return promise
    }

    hide(resolve,event) {
        this.$el.modal('hide');
        resolve($(event.currentTarget).data('id'));
    }

    getDisplayMessage() {
        // Remplace the [N] into <BR>
        return this.message.replace(/\[N\]/g,'<BR/>');
    }
}

export default ModalConfirmationDialog;