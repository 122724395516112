'use strict';

let $ = require('jquery');
import * as _ from 'lodash';
import { SAIView } from '../../Additions';
import { Panel } from '../../parametrage/structures/Panel';
import RecordView from './Record';
import Config from 'Config';
import RecordHeaderView from './RecordHeader';
import { DataField } from 'parametrage/structures/DataField';
import { Header } from 'models/listing/Header';
import { Task } from '../../parametrage/structures/Task';
import Context from '../../models/context/Context';
import FieldView from './Field';
import { Field } from '../../parametrage/structures/Field';

class TabContainerView extends SAIView {
    private panels : Array<Panel>;
    private recordViews : {[recordViewId: string]: RecordView}
    private tabContainerView : JQuery<HTMLElement>;
    private recordsContainerView : JQuery<HTMLElement>;
    private recordHeader : JQuery<HTMLElement>;
    private currentlySelectedTab: JQuery<HTMLElement>;
    private recordHeaderView : RecordHeaderView;
    private selectedRecord : any;
    private headerIcon: string;
    private headerMode: string;
    private taskConfig: Task;
    private displayHeader: boolean;
    private dispayMode: string;

    constructor(options?: any) {
        super(options);
        this.panels = options.panels || {};
        this.template = require('ejs-loader!templates/TabContainer.ejs');
        this.recordViews = {};
        this.selectedRecord = undefined;
        this.headerIcon = options.headerIcon;
        this.headerMode = options.headerMode;
        this.taskConfig = options.taskConfig;

        this.initializeContext();
        this.displayHeader = options.displayHeader;
    }

    onUpdateRequested(result) {
        for(let key in result.screen) {
            let curPan = new Panel(result.screen[key], this.taskConfig, this.options.domainContext);
            this.recordViews[curPan.getId()].setValuesAndPositions(curPan.getGroups());
            this.recordViews[curPan.getId()].render();
        }
    }

    setPanels(panels : Array<Panel>) : void {
        this.panels = panels;
    }

    getPanels() : Array<Panel> {
        return this.panels;
    }

    getRecordViews() : {[recordViewId: string]: RecordView} {
        return this.recordViews;
    }

    getRecordView(recordViewId: string) : RecordView {
        return this.recordViews ? this.recordViews[recordViewId] : undefined;
    }

    setSelectedRecord(record: any) : void {
        this.selectedRecord = record;
    }

    getSelectedRecord() : any {
        return this.selectedRecord;
    }

    render() :any {
        this.$el.html(this.template());
        this.tabContainerView = this.$el.find('.tab-container-tabs');
        this.recordsContainerView = this.$el.find('.tab-container-records');
        this.recordHeader = this.$el.find('.record-header');
        for(let panelIndex in this.panels) {
            let panel = this.panels[panelIndex];
            let panelId = panel.getId();
            // Create the tab-item element in the DOM
            let currentTab = $('<div data-id="' + panelId + '" class="tab-item">'+panel.getLabel()+'</div>');
            this.tabContainerView.append(currentTab);
            currentTab.on('click', this.onTabClick.bind(this, panelId));
            // Each panel is rendered as a record view in a specific tab
            let recordContainer = $('<div data-id="'+panel.getId()+'" class="tab-content"></div>').appendTo(this.recordsContainerView);
            this.recordViews[panelId].setElement(recordContainer);
            this.recordViews[panelId].render();
            this.recordViews[panelId].$el.hide();
        }
        //If there is only one panel, we get more space by hiding the tab
        if(this.panels.length === 1) {
            this.tabContainerView.hide();
        }
        //We select the first tab by default, all others will be hidden
        this.selectTab(this.panels[0].getId());

        this.recordHeader.hide();
        if(this.recordHeaderView === undefined) {
            this.recordHeaderView = new RecordHeaderView({
                el: this.recordHeader,
                icon: this.headerIcon,
                headerMode: this.headerMode,
                domainContext: this.options.domainContext
            });
        }
        this.recordHeaderView.render();
        if(this.displayHeader) {
            this.recordHeader.show();
        }
    }

    /**
     * Base on the requesting panel and the configuration definition, sends
     * the definition of all panels or only the calling one
     * @param context The context to fill with the panels contents
     * @param panelId The calling panel id
     */
    onPrepareContext(context: Context, panelId: string) {
        for(let key in this.recordViews) {
            var values = this.recordViews[key].getFieldsValues();
            context.addPanelFields(values, key);
        }
    }

    onPrepareScript(context: {[propId:string]: string}, panelId: string, withDollar: boolean) {
        for(let key in this.recordViews) {
            _.merge(context, this.recordViews[key].getScriptContext(withDollar));
        }
    }

    public setDominantColor(color) {
        this.options.dominantColor = color;
        for(var key in this.recordViews){
            this.recordViews[key].setDominantColor(color);
        }
    }

    public setDisplayMode(displayMode: string) {
        this.dispayMode = displayMode;
    }

    public onTabClick(id: string) {
        this.unSelectCurrentTab();
        this.selectTab(id);
    }

    private unSelectCurrentTab() {
        if(this.currentlySelectedTab) {
            let dataId = this.currentlySelectedTab.data('id');
            this.currentlySelectedTab.removeClass('active');
            this.currentlySelectedTab.css('border-bottom', 'none');
            this.$el.find('.tab-content[data-id="'+dataId+'"]').hide();
        }
    }

    private selectTab(id: string) {
        this.currentlySelectedTab = this.$el.find('.tab-item[data-id="'+id+'"]');
        this.$el.find('.tab-content[data-id="'+id+'"]').show();
        this.currentlySelectedTab.addClass('active');
        this.currentlySelectedTab.css('border-bottom', '4px solid ' + Config.appTheme);
    }

    public setEnabled(enabled : boolean) {
        for(let recordViewIndex in this.recordViews) {
            this.recordViews[recordViewIndex].setEnabled(enabled);
        }
    }

    public updateHeader(item : any, header: Header, titleElements : DataField[]) : void {
        let title = '';
        let subTitle = undefined;
        let dataFieldHeaderMapping = header.getDatafieldsToColumnIds();

        for(let i in titleElements) {
            let datafield = titleElements[i];
            let columnId = dataFieldHeaderMapping[datafield.getId()];
            if(datafield.isTitle()) {
                if(title.length > 0) {
                    title += ', ';
                }
                title += item.columns[columnId].value;
            } else {
                if(title.length > 0) {
                    subTitle += ', ';
                }
                subTitle += item.columns[columnId].value;
            }
        }
        if(this.recordHeaderView === undefined) {
            this.recordHeaderView = new RecordHeaderView({
                el: this.recordHeader,
                icon: this.headerIcon,
                headerMode: this.headerMode,
                domainContext: this.options.domainContext,
                taskConfig: this.taskConfig,
                values : {
                    title: title,
                    subtitle: subTitle
                }
            });
        } else {
            this.recordHeaderView.setValues({
                title: title,
                subtitle: subTitle
            });
        }
        this.recordHeaderView.render();
    }

    public initializeContext() {
        for(let panelIndex in this.panels) {
            let panel = this.panels[panelIndex];
            let panelId = panel.getId();
            let recordPanelConfig:{[propId:string]: any} = Object.assign({}, this.options);
            recordPanelConfig.groups = panel.getGroups();
            recordPanelConfig.panelId = panel.getId();
            this.recordViews[panelId] = new RecordView(recordPanelConfig);
            this.listenTo(this.recordViews[panelId], 'record.onPrepareContext', this.onPrepareContext.bind(this));
            this.listenTo(this.recordViews[panelId], 'record.onPrepareScript', this.onPrepareScript.bind(this));
            this.listenTo(this.recordViews[panelId], 'record.updateRecordsRequest', this.onUpdateRequested.bind(this));
        }
    }
} export default TabContainerView;
