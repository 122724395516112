'use strict';

import Logger from 'utils/Logger';
import * as Backbone from 'backbone';

/**
 *Context class hold a descriptor that can be sent to the server. Most of the time
 * the server requires a grid descriptor or a record descriptor to process data.
 * A context is defined by a unique id defined in the client-server protocol.
 */
interface ContextFieldDefinition {
    name?: string,
    datafieldId?: string,
    value: string
}

class Context extends Backbone.Model {
    constructor(attributes?: any, options?: any) {
        super(attributes, options);
    }
    /**
     * Creates an empty context or a context based on given screen and parameters objects
     * This allows to create a context directly from the server response of build a new one
     * to send
     */
    initialize(attributes?: any, options?: any){
        attributes = attributes || {};
        this.set('parameters', attributes.parameters || {});
        this.set('screen', attributes.screen || []);
        this.set('contextId', attributes.contextId || '');
    }


    /**
     * Allows to define the context id. Might be grid, print, record. This id is server defined
     * in the protocol.
     *
     * @param newId The id of the context
     */
    setContextId(newId){
        this.set('contextId', newId);
    }

    /**
     * Returns the context identifier. Will yield '' if the context has never been set
     */
    getContextId(){
        return this.get('contextId');
    }

    /**
     * Adds a parameter to the context parameter map. If this parameter already exists,
     * the parameter is overriden
     */
    addParameter(parKey, parValue){
        this.get('parameters')[parKey] = parValue;
    }

    /**
     * Retreives the parameter from the parameters map. If the parameters doens't exist
     * the value will be undefined
     */
    getParameter(parKey){
        return this.get('parameters')[parKey];
    }

    /**
     * Allows to know if the given parameter key is in the map. If the parameter has been
     * inserted with undefined value, the parameter will still be considered as available.
     */
    hasParameter(parKey){
        return this.get('parameters').hasOwnProperty(parKey);
    }

    /**
     * In case of panels, we need to specify which panel is filled by the given
     * list of fields values
     * @param fieldsDefinition The array of field values and properties
     * @param panelId The id of the panel to fill
     */
    addPanelFields(fieldsDefinition: Array<ContextFieldDefinition>, panelId: string) {
        this.get('screen').push({
            panelId: panelId,
            fields: fieldsDefinition
        });
    }

    public getPanelFields(panelId: string) {
        let entries = this.get('screen') || [];
        for(let key in entries) {
            if(entries[key].panelId === panelId) {
                return entries[key].fields;
            }
        }
    }
    /**
     * Allows to store all the values of the context in order to communicate
     * them to the server
     *
     * @param fieldsDefinition All the definitions of the fields. These definitions
     * might be an object or an array. In case of an object, definitions will be merged
     * into the screen context. In case of array, the screen will concatenate the incoming array
     *
     * @param nameKey As most of the time the objects are looking like key -> value, and we have
     * an array, it means that we need to integrate the key into the value part. Thus we need to
     * know what is the name of the entry that will hold the key ex : name which will give { name : key, ...}
     */
    addFields(fieldsDefinition){
        if(!fieldsDefinition){
            return;
        }

        if(typeof fieldsDefinition === 'object'){
            //Might be an array or an object
            if(fieldsDefinition.toString().indexOf('Array') !== -1){
                //It's an array
                this.get('screen').concat(fieldsDefinition);
            }else{

                for(var defKey in fieldsDefinition){
                    var fDef = fieldsDefinition[defKey];
                    if(!fDef.key){
                        fDef.key = defKey;
                    }
                    this.get('screen').push(fDef);
                }
            }
        }else{
            Logger.warn('Context', 'addFields', 'Wrong format for fields to add');
        }
    }

    /**
     * Updates a field value within the context
     *
     * @param fieldId The id of the field to update
     * @param If the identifier isn't 'name' (default) in the field, it must be specified so that
     * the system can properly identify the field to update
     * @param newValue The new value to set in the field
     */
    updateField(fieldId, nameKey, newValue){
        if(nameKey === undefined){
            nameKey = 'name';
        }

        var panels = this.get('screen');
        let found = false;
        for(var panelId in panels){
            let panelFields = panels[panelId].fields;
            for(let id in panelFields) {
                let field = panelFields[id];
                if(field['key'] === fieldId){
                    field['value'] = newValue;
                    found = true;
                    break;
                }
            }
            if(found) {
                break;
            }
        }
    }

    updateFieldStatus(fieldId, property, newValue) {
        var panels = this.get('screen');
        let found = false;
        for (var panelId in panels) {
            let panelFields = panels[panelId].fields;
            for (let id in panelFields) {
                let field = panelFields[id];
                if (field['key'] === fieldId) {
                    if(newValue === undefined) {
                        delete field[property];
                    } else {
                        field[property] = newValue;
                    }
                    break;
                }
            }
            if (found) {
                break;
            }
        }
    }

    getFieldIds(): Array<string> {
        var panels = this.get('screen');
        let fieldsIds = [];
        for (var panelId in panels) {
            let panelFields = panels[panelId].fields;
            for (let id in panelFields) {
                let field = panelFields[id];
                fieldsIds.push(field['key']);
            }
        }
        return fieldsIds;
    }

    /**
     * Outputs the context as JSON. This is the format that is expected by
     * the server.
     */
    toJSON(){
        let context = {
            screen : this.get('screen')
        };

        let params = this.get('parameters');
        for(let key in params){
            context[key] = params[key];
        }

        return context;
    }

    /**
     * Allows to clear part of a context. This is usefull when most of the context stays the
     * same but for example the fields must be reset
     *
     * @param resetConfig. Object that can contain the boolean key 'parameters' and 'screen' to
     * reset either parameters, screen, or both entries of the context
     */
    reset(resetConfig){
        if(resetConfig.parameters){
            this.set('parameters', {});
        }

        if(resetConfig.screen){
            this.set('screen', []);
        }
    }
}

export default Context;
