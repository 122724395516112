'use strict';

import HeaderListPopup from 'views/listing/HeaderListPopup';
import EventUtils from 'utils/EventUtils';
import Server from 'server/Server';

import { SAIView } from '../../Additions';
import { FilterPopupView } from '../tasks/navigators/simple/FilterPopupView';
import { DomainContext } from '../../parametrage/DomainContext';
import { Filter } from '../../parametrage/structures/Filter';
import { Table } from '../../parametrage/structures/Table';
import { GridView } from '../../parametrage/structures/GridView';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { DataField } from '../../parametrage/structures/DataField';
import { Task } from '../../parametrage/structures/Task';
import ScriptUtils from 'utils/ScriptUtils';
import StringUtils from 'utils/StringUtils';
import GetGridDataRequest from '../../server/protocol/request/task/GetGridData';
import Context from '../../models/context/Context';
let $ = require('jquery');
let _ = require('lodash');
const moment = require('moment');

class SearchBar extends SAIView {
    private filterPopup: FilterPopupView;
    private filterValues: Array<any>;
    private domainContext: DomainContext;
    private searchBar: JQuery<HTMLElement>;
    private filterHolder: JQuery<HTMLElement>;
    private criteriasEnabled: boolean;
    private previousVal: string;
    private initialFilterValues: any;
    protected scriptContext: any;
    private hideCriterias: boolean;

    constructor(options) {
        super(options);
        this.template = require('ejs-loader!templates/filter/SearchBar.ejs');
        this.options = options;

        this.domainContext = options.domainContext;
        this.criteriasEnabled = options.enabled;
        this.filterValues = [];
        this.initialFilterValues = {};
        this.hideCriterias = options.hideCriterias ? options.hideCriterias : false;

        for(let filter in this.options.filters) {
            let filterElement = this.options.filters[filter];
            this.initialFilterValues[filterElement.datafieldId] = filterElement.value;
        }

        this.scriptContext = {
            '$CURRENT_CHAIN': options.taskId.substring(0,3),
            '$LOCAL_DATE': moment().format('YYYY-MM-DD 00:00:00'),
            '$LOCAL_DATETIME': moment().format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    public render(): any {
        this.$el.html(this.template({
            enabled : this.options.enabled,
            hideCriterias: this.hideCriterias
        }));
        this.searchBar = this.$el.find('.searchBar');
        this.searchBar.on('change', this.onSearchChange.bind(this));

        if(this.criteriasEnabled){
            this.$el.find('.addFilter').on('click', this.onFilterBtnClicked.bind(this));
        }

        this.filterHolder = this.$el.find('.criterias-filter');
        for(var key in this.filterValues){
            if(this.filterValues[key].html){
                this.appendFilterEntry(this.filterValues[key]);
            }
        }
    }

    public initializeFilter(gridConfig: GridView, tableConfig: Table, filterConfig: Filter, taskConfig : Task): Promise<void> {
        let me = this;
        return new Promise((accept, reject) => {
            if(!me.filterPopup) {
                me.filterPopup = new FilterPopupView({
                    gridConfig: gridConfig,
                    tableConfig: tableConfig,
                    filterConfig: filterConfig
                });

                let filterElements = filterConfig.getElements();
                let datafieldsMap = {};
                let allFetch = [];
                for(let key in filterElements) {
                    let curEl = filterElements[key];
                    allFetch.push(CClientConfiguration.getDataField(curEl.getDatafieldId(), me.domainContext, taskConfig));
                }
                Promise.all(allFetch)
                    .then((datafields: Array<DataField>) => {
                        for(let key in datafields) {
                            let df = datafields[key];
                            datafieldsMap[df.getId()] = df;
                        }
                        for(let key in filterElements) {
                            let curEl = filterElements[key];
                            let permanentValue = curEl.getPermanentValue();
                            let defaultValue = curEl.getDefaultValue();
                            let datafield = datafieldsMap[curEl.getDatafieldId()];
                            if(datafield !== undefined) {
                                let current = {
                                    text: datafield.getLabel(),
                                    renderingId: Math.floor(Math.random() * 10000000),
                                    value: permanentValue ? permanentValue : defaultValue,
                                    datafieldId: datafield.getId()
                                }
                                this.addNewFilterEntry(current, curEl.mustBeShownOnNavigator(), curEl.mustBeShownOnNavigator() ? 'boolean' : undefined);
                            } else {
                                console.warn('Datafield ' + curEl.getDatafieldId() + ' hasn\'t been found in the table, thus it\'s discarded');
                            }
                        }
                        me.mergeFilters();
                        accept();
                    })
                    .catch(reject);
            } else {
                accept();
            }
        });
    }

    mergeFilters() : void {
        var initialUsed = [];

        for(let index in this.filterValues) {
            let item = this.filterValues[index];
            let premergeValue = item.value;
            if(this.initialFilterValues[item.datafieldId]) {
                initialUsed.push(item.datafieldId);
                item.value = this.initialFilterValues[item.datafieldId];
            }

            item.value = ScriptUtils.handleDateTransformation(item.value, 'YYYY-MM-DD 00:00:00');
            if(item.value !== premergeValue) {
                this.updateFilterLabel(item);
            }
        }
        if(this.initialFilterValues) {
            for(let index in this.initialFilterValues) {
                var initialItemValue = this.initialFilterValues[index];
                if (initialItemValue.indexOf(index) < 0) {
                    //The filter didn't contain the entry so it hasn't been applied yet
                    var newItem = {
                        datafieldId: index,
                        value: initialItemValue
                    };
                    this.filterValues.push(newItem);
                }
            }
        }
    }

    appendFilterEntry(entry){
        if(entry.value !== undefined && entry.value !== '') {
            this.filterHolder.append(entry.html);
            // CRECHE : un seul filtre enfant possible
            if(entry.datafieldId === 'CRM40_GTPEVT_MULTI_CHILDREN') {
                this.$el.find('.addFilter').addClass('disabled-filter').off('click');
            }
        }
    }

    onSearchChange(){
        var val = this.searchBar.val() + '';

        if(this.previousVal !== val && (this.previousVal || val.length !== 1)){
            this.previousVal = val;
            var customized = this.isFilterCustomized();
            if(!customized){
                if(val){
                    customized = true;
                }
            }
            this.trigger('searchChanged', val, customized);
        }
    }

    isFilterCustomized(){
        return false;
        //TODO : compare with initial array
    }

    public getFilter(): {values: Array<{ datafieldId: string, value : any}>, text: string }{
        return {
            values : this.filterValues.map((entry) => { return { 
                datafieldId: entry.datafieldId,
                value: StringUtils.resolveTextProperties(entry.value, this.scriptContext)
            };
            }),
            text : this.previousVal
        };
    }

    onFilterBtnClicked(evt) {
        var me = this;
        // -------- HARDCODED FOR PMI TO REMOVE ----------
        let datafieldId = 'CRM40_GTPEVT_MULTI_CHILDREN'
        this.getFilterGridConfig(datafieldId)
            .then(([task, datafield, gridConfig, tableConfig]) => {
                let iconConfig = undefined;
                if(gridConfig || tableConfig) {
                    iconConfig = Object.assign({}, gridConfig, tableConfig);
                }
                let newHeaderPop = new HeaderListPopup({
                    taskId: this.options.taskId,
                    gridId: datafield.getGridView(),
                    filterId: datafield.getFilterName(),
                    title: datafield.getLabel(),
                    domainContext: this.domainContext,
                    iconConfig: iconConfig
                });
                this.listenToOnce(newHeaderPop, 'selection', function(selection){
                    let newId = parseInt(Math.random()*100000000 + '');
                    let row = newHeaderPop.getHeaderList().getListing().getRow('ID', selection);
                    let newEntry = {
                        value : selection,
                        text : datafield.getLabel() + ' : '+row.getColumnsValues()['DLABEL'].value,
                        renderingId : newId,
                        datafieldId: datafieldId
                    };
                    for(let index in me.filterValues) {
                        let item = this.filterValues[index];
                        if(item.datafieldId === newEntry.datafieldId) {
                            me.filterValues.splice(me.filterValues.indexOf(item),1);
                        }
                    }
                    me.addNewFilterEntry(newEntry, true);
                    me.refreshData();
                });
                newHeaderPop.display();
            });
    }

    getFilterGridConfig(datafieldId) : Promise<any>{
        return new Promise((accept) => {
            CClientConfiguration.getTask(this.options.taskId, this.domainContext)
                .then((task) => {
                    CClientConfiguration.getDataField(datafieldId, this.domainContext, task as Task)
                        .then((datafield) => {
                            let iconConfigPromise = [];
                            iconConfigPromise.push(CClientConfiguration.getGridIconConfig(datafield.getGridView(), this.options.taskId, this.domainContext));
                            iconConfigPromise.push(CClientConfiguration.getTableIconConfig(datafield.getTableName(),this.options.taskId, this.domainContext));
                            Promise.all(iconConfigPromise)
                                .then(([gridConfig, tableConfig]) => {
                                    accept([task,datafield,gridConfig, tableConfig]);
                            });
                    });
            });
        });
    }

    onFilterPopupButtonClicked(evt) {
        var action = EventUtils.getAttributeValue(evt, 'role');
        if (action === 'ok') {
            //record validation. We get the values and send the modify on server
            var recordValues = this.filterPopup.getSelection();

            if(!this.filterValues){
                this.filterValues = [];
            }

            var currentEntry;
            let newEntryId = parseInt(Math.random()*100000000 + '');
            currentEntry = {
                value : this.buildFilterValue(recordValues),
                text : this.filterPopup.getDfText(recordValues.dfId) + ' ' + this.filterPopup.getChoiceText(recordValues.operation) + ' ' + recordValues.value,
                type : recordValues.type,
                renderingId : newEntryId
            };
            this.addNewFilterEntry(currentEntry, true);

            this.refreshData();
        }
    }

    refreshData(){
        this.trigger('searchChanged');
    }

    addNewFilterEntry(entry, render:boolean, type?){
        if(!type) { type = 'text'; }
        this.filterValues.push(entry);
        var visual = $('<div class="filterCriteria" data-index="'+entry.renderingId+'"></div>');
        if(type === 'text') {
            visual.append('<div class="text">'+entry.text+'</div>');
            var cross = $('<span class="glyphicon glyphicon-remove deleteBtn" aria-hidden="true"></span>');
            visual.append(cross);
            cross.click(this.onDeleteFilterClick.bind(this, entry.renderingId));
        } else {
            let visualEntry = $('<label>' + entry.text + '</label>')
            let checkbox = $('<input class="changeableField" type="checkbox" ' + (entry.value === true || entry.value === '1' ? 'checked' : '') + '>');
            visualEntry.prepend(checkbox);
            visual.append(visualEntry)
            checkbox.change(this.onCheckBoxFilterClick.bind(this, entry.renderingId, checkbox));
        }
        entry.html = visual;
        if(render){
            this.appendFilterEntry(entry);
        }
    }

    onDeleteFilterClick(existing){
        var index = _.findIndex(this.filterValues, function(item){ return item.renderingId === existing;});
        var current = this.filterValues[index];
        current.html.remove();
        this.filterValues.splice(index, 1);
        this.refreshData();
        // CRECHE : un seul filtre enfant possible
        if(current.datafieldId === 'CRM40_GTPEVT_MULTI_CHILDREN') {
            this.$el.find('.addFilter').removeClass('disabled-filter').on('click', this.onFilterBtnClicked.bind(this));
        }
    }

    onCheckBoxFilterClick(filterId, checkbox){
        var index = _.findIndex(this.filterValues, function(item){ return item.renderingId === filterId;});
        var current = this.filterValues[index];
        current.value = checkbox.is(':checked');
        this.refreshData();
    }

    buildFilterValue(filterValues){
        var value;
        if(filterValues.type === 'text'){
            if(filterValues.operation === 'CONTAINS'){
                value = '*'+filterValues.value+'*';
            }else if(filterValues.operation === 'NOTCONTAINS'){
                value = 'NOT *'+filterValues.value+'*';
            }else if(filterValues.operation === 'STARTS'){
                value = filterValues.value+'*';
            }else if(filterValues.operation === 'ENDS'){
                value = '*'+filterValues.value;
            }else if(filterValues.operation === 'DOESNOTSTART'){
                value = 'NOT '+filterValues.value+'*';
            }else if(filterValues.operation === 'DOESNOTEND'){
                value = 'NOT *'+filterValues.value;
            }else if(filterValues.operation === 'ISONEOF'){
                //value = filterValues.value.replace('/\s*[ ,]\s*/g', ' OR '); // Supports 1000,2000 / 1000, 2000 / 1000 2000 / 1000 , 2000
            }
        }
        return value;
    }

    updateFilterLabel(entry) {
        let me = this;
        CClientConfiguration.getTask(this.options.taskId, this.domainContext)
            .then((task) => {
                CClientConfiguration.getDataField(entry.datafieldId, this.domainContext, task as Task)
                    .then((datafield) => {
                        let text = datafield.getLabel();
                        if(datafield.getFormat() === 'List') {
                            me.getItemLabel(entry.value, datafield)
                                .then((result) => {
                                    let label = result.items[0].columns['DLABEL'].value;
                                    text =  datafield.getLabel() + ' : ' + label;
                                    entry.html.find('.text').html(text);
                                    // If the element is not in the DOM, add it
                                    if(!$.contains(document.documentElement,entry.html.get(0))) {
                                        me.appendFilterEntry(entry);
                                    }
                                });
                        } else {
                            text = datafield.getLabel() + ' : ' + entry.value;
                            entry.html.find('.text').html(text);
                            if(!$.contains(document.documentElement,entry.html.get(0))) {
                                me.appendFilterEntry(entry);
                            }
                        }
                    });
            });
    }

    // Perform a getGridData on the grid associated to the datafield with a filter
    getItemLabel(value : string, datafield: DataField) : Promise<any> {
        let dataRequest = new GetGridDataRequest(this.domainContext, this.options.taskId, datafield.getGridView());
        dataRequest.setFormatterId('');
        dataRequest.setFilterId('DEFAULT_FILTER');
        dataRequest.setRange(0, -1);

        // CRECHE : use this to bypass the filter on active children
        dataRequest.setSessionVars({
            'CUSTOMIZED_FILTER' : 'true'
        });

        return new Promise((accept, reject) => {
            // Get the grid configuration
            CClientConfiguration.getGridView(datafield.getGridView(), this.domainContext)
                .then((grid) => {
                    // The key is either defined in the foreignKeyField attribute of the datafield ...
                    let key = undefined;
                    if(datafield.getForeignKeyField()) {
                        key = grid.getColumn(datafield.getForeignKeyField()).getDatafieldId();
                    } else {
                        // ... or need to be guessed from the columns
                        key = this.getKeyFromGrid(grid);
                    }

                    if(key === undefined ) {
                        reject('Unable to find grid key');
                    }

                    let filterContext = new Context({contextId: 'filter'});
                    filterContext.addFields([{
                        datafieldId : key,
                        key : key,
                        value : value
                    }]);
                    
                    dataRequest.setFilterContext(filterContext);

                    Server.performRequest(dataRequest)
                    .then((result) => {
                        accept(result);
                    });
                });
        });
    }

    // Return the first column ending with _PK
    getKeyFromGrid(grid : GridView) : string {
        let columns = grid.getColumns();
        for(let i in columns) {
            let column = columns[i];
            let datafieldName = column.getDataField().getId();
            let regex = /_PK$/;
            if(regex.test(datafieldName)) {
                return datafieldName;
            }
        }
        return undefined;
    }
}

export default SearchBar;
