'use strict';

import GetRecord from 'server/protocol/request/task/GetRecord';
import { DomainContext } from '../../../../parametrage/DomainContext';

class GetDossierRequest extends GetRecord {
    constructor(domain, task){
        super(domain, task);
        this.service = 'getDossier';
    }
}

export default GetDossierRequest;
