'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';
import { BasePanel } from './BasePanel';

abstract class AbstractTask extends ConfigurationElement {
    protected config: { id: string,
        label?:string,
        instanceId?: string,
        access?: string,
        pagesAccess?: { [pageId: string] : string },
        initialPropsOverride?:any,
        headerEnabledActions?:string
    };
    constructor(config: { id: string }, context: DomainContext) {
        //Context is copied into the task to make sure that if we change the
        //global context, then the current one doesn't change 
        super(config, context.clone());
    }

    public abstract initializeI18N(): Promise<Array<string>>;

    public getUnitID(): string {
        return this.getValueOrDefault('unitId');
    }

    public getType(): string {
        return this.getValueOrDefault('type');
    }

    public getReadableType(): string {
        let type: string = this.getValueOrDefault('type');
        switch(type) {
            case 'R' : return 'Dossier';
            case 'B' : 
                if(this.getConfig().inputInterface === 'History/Planning') {
                    return 'Calendar';   
                } else {
                    return 'Simple';
                }
            case 'F' : return 'Double';
            case 'V' :
            case 'W' : return 'View';
            case 'L' :
            case 'M' : return 'List';
            default: throw new Error('No Handled type "' + type + '"')
        }
    }

    public getTableID(): string {
        return this.getValueOrDefault('tableId');
    }

    public getIcon(): string {
        return this.getValueOrDefault('icon');
    }

    public getExistenceConditions(): string {
        return this.getValueOrDefault('existence');
    }

    public isVisible(): boolean {
        return this.getBooleanValueOrDefault('visible', true);
    }

    public setInstanceId(id:string) {
        this.config.instanceId = id;
    }

    public getInstanceId() {
        return this.getValueOrDefault('instanceId');
    }

    public setAccesses(access: string) {
        this.config.access = access;
    }

    public setPageAccess(pageAccess: { [pageId: string] : string }) {
        this.config.pagesAccess = pageAccess;
    }

    public getAccess(): string {
        return this.config.access;
    }

    public getHeaderEnabledActions(): string {
        return this.config.headerEnabledActions;
    }

    public getPagesAccess(): { [pageId: string]: string } {
        return this.config.pagesAccess;
    }

    public setInitialPropsOverride(initialPropsOverride: any) {
        this.config.initialPropsOverride = initialPropsOverride;
    }

    public getInitialPropsOverride(): object {
        return this.config.initialPropsOverride;
    }

    public abstract clone(): AbstractTask;

    public static setReadOnly(descriptor: any, pageAccess: { [pageId: string] : string }) {
        for(let i in descriptor.pages) {
            let page = descriptor.pages[i];
            if(!pageAccess[page.id] || pageAccess[page.id].indexOf('M') < 0) {
                BasePanel.setReadOnly(page);
            }
        }
    }

    public getHelpId(): string {
        return this.getId();
    }
}

export { AbstractTask };