module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if ( mode !== 'display' ) { ;
__p += '\n<div class="loadingPubFiche animatedInfinite pulse">\n    <div class="pubFicheLoadingText">' +
((__t = (loadingTxt)) == null ? '' : __t) +
'</div>\n    <div class="bubblingG loadingSpinner"><span class="bubblingG_1"></span><span class="bubblingG_2"></span><span class="bubblingG_3"></span></div>\n</div>\n';
 } else { ;
__p += '\n<div class="pubFicheInner">\n    <div class="pubFicheMainTitle">' +
((__t = (ficheData.title)) == null ? '' : __t) +
'</div>\n    <div class="pubFicheElemsHolder"></div>\n</div>\n<div class="actionSidePane" style="display:none;">\n    <div class="actionSidePaneInner">\n        <div class="bubblingG sidepaneSpinner"><span class="bubblingG_1"></span><span class="bubblingG_2"></span><span class="bubblingG_3"></span></div>\n    </div>\n    <div class="actionSidePaneAction" style="display:none;">\n        <button class="btn btn-default pubFicheRecordValidate">Valider</button>\n        <button class="btn btn-default pubFicheRecordAddDocs">Ajouter des documents</button>\n    </div>\n</div>\n';
 };


}
return __p
}