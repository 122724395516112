'use strict';

import FieldView from 'views/record/Field';
import FieldUnit from 'factory/FieldUnit';

class TextAreaField extends FieldView {

    onDOMUpdated () {
        super.onDOMUpdated();
        //Resizing textarea so that it fills its content
        var currentTop = this.locationProperties.top;
        // TODO: check if this is still relevant for mobile
        
        if(this.displayMode !== 'desktop'){
            var fieldUnits = FieldUnit[this.displayMode];
            var textAreaFinalHeight = this.$el.height() - (fieldUnits.height - fieldUnits.fieldHeight);
            textAreaFinalHeight = textAreaFinalHeight - 2; //add a margin botton
            this.getFieldElement().css('height', textAreaFinalHeight);
        }
        
    }

    getFocusableElement(){
        return this.$el.find('textarea');
    }

    getFieldElement(){
        return this.$el.find('textarea');
    }
}

export default TextAreaField;
