module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" class="upload-button-label">' +
((__t = ( uploadLabel )) == null ? '' : __t) +
'</label>\n<input id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" type="file" class="form-control changeableField file-upload" \ndata-id="' +
__e( fieldState.getId() ) +
' " value="' +
__e( fieldState.getValue() ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' \n' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' accept="' +
((__t = ( allowedFileType )) == null ? '' : __t) +
'" >\n<div class="uploaded-file" style="display: none;"></div>\n<span class="field-loading" style="display: none;"><div class="spinner-holder"><i class="fas fa-sync-alt"></i></div></span>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>\n<span class="upload-error" style="display: none;"></span>';

}
return __p
}