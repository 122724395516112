'use strict';

class NumberUtils {
    private static _instance: NumberUtils;
    
    private globalCounter: number;

    public static get Instance(): NumberUtils {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        this.globalCounter = 0;
    }

    parseIntoFloat(input, defaultValue){
        if(typeof input === 'number'){
            return input;
        }else if(typeof input === 'string'){
            if(input.trim() === ''){
                return defaultValue;
            }else{
                var cleanedValue = input.replace(/'/g, '');
                return parseFloat(cleanedValue);
            }
        }else{
            console.warn('parsing '+(typeof input)+' into float not possible');
            return defaultValue;
        }
    }

    formatFloat(floatValue, decimals){
        if(typeof floatValue !== 'number'){
            console.warn('formatting requires float instead of '+(typeof floatValue));
            return '0.00';
        }else{
            return floatValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
        }
    }

    getUniqueNumber(){
        this.globalCounter++;
        return this.globalCounter;
    }
}
export default NumberUtils.Instance;