'use strict';

import { TaskRequest } from 'server/protocol/request/task/TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class GetRecordRequest extends TaskRequest{

    key : any;
    gridId : string;

    constructor(domain:DomainContext, task){
        super('getRecord','GET', domain, task);
    }

    setKeyContext(context){
        this.key = context;
    }

    setGridId(newId){
        this.gridId = newId;
    }

    getSerializable(){
        return {
            request1: {
                gridId: this.gridId,
                key: this.key? this.key.toJSON(): undefined
            }
        };
    }
}

export default GetRecordRequest;