'use strict';

import { BasePanel } from './BasePanel';
import { Task } from './Task';
import { DomainContext } from '../DomainContext';

class Panel extends BasePanel {
    constructor(config: {id : string, groups: any}, parentTask: Task, context: DomainContext) {
        super(config, parentTask, context);
    }

    public canShowPagesManager(): boolean {
        return this.getBooleanValueOrDefault('showPageManager', true);
    }

    public canShowSubpanel(): boolean {
        return this.getBooleanValueOrDefault('showSubNavigator', true);
    }

    public getFieldI18N(fieldId: string) {
        return this.getParentTask().getId() + '.Panel' + this.getId() + '.' + fieldId;
    }

    public getI18NID(): string {
        return this.parentTask.getId() + '.Panel' + this.getId()
    }
}

export { Panel };