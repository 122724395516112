'use strict';

let $ = require('jquery');
import { SAIView } from  'Additions';
import App from 'App';
import Server from 'server/Server';
import { DomainContext } from '../parametrage/DomainContext';
import GetGridDataRequest from '../server/protocol/request/task/GetGridData';
import User from 'User';
import LoadingMask from 'views/loading/LoadingMask';
import DomainManager from '../DomainManager';
import ColorUtils from 'utils/ColorUtils';
import { xml } from 'd3';

class DomainSelectorView extends SAIView {
    domainContext : DomainContext;
    selectValues: Array<any> = [];
    availableExercices: any;

    constructor(options?) {
        super(options);
        this.domainContext = options.domainContext;
        this.template = require('ejs-loader!templates/DomainSelector.ejs');
    }

    render() : any {
        //Building the server request
        let dataRequest = new GetGridDataRequest(this.domainContext,'SYS01', 'SYS01_N1');
        let me = this;
        let loadingReqId;
        LoadingMask.requestLoading('Chargement du navigateur')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(dataRequest);
            })
            .then((response) => {
                this.selectValues = [];
                for(let id in response.items) {
                    let item = response.items[id];
                    let key;
                    let label;
                    for(let colId in item.columns) {
                        let column = item.columns[colId];
                        if(column.name === '1') {
                            key = column.value;
                        } else if(column.name === '2') {
                            label = column.value;
                        }
                    }
                    this.selectValues.push({
                        id: key,
                        label: label
                    });
                }
            })
            .catch(App.displayErrorMessage)
            .then(() => {
                LoadingMask.hide(loadingReqId);
                App.getAvailableExercices(DomainManager.getCurrentContext(User.getCurrentDomain()))
                    .then((exercices) => {
                        this.availableExercices = exercices;
                        this.$el = $(this.template({
                            currentDomainLabel: 'Société courante',
                            currentDomain: User.getCurrentDomain(),
                            selectDomainLabel: 'Sociétés disponibles',
                            buttonsCancelLabel: 'Annuler',
                            buttonsValidateLabel: 'Valider',
                            title: 'Changer de société',
                            selectValues: this.selectValues,
                            currentExerciceLabel : 'Exercice courant',
                            selectExerciceLabel : 'Exercices disponibles',
                            currentExercice : DomainManager.getContext(User.getCurrentDomain()).getExercice().id,
                            exercicesValues : exercices,
                            operationColor: '#F6A115',
                            colorUtils: ColorUtils
                        }));
                        let selectEl:any = this.$el.find('select[data-id="DOMAIN_SELECT"]');
                        selectEl.select2();
                        let selectExerciceEl:any = this.$el.find('select[data-id="EXERCICE_SELECT"]');
                        selectExerciceEl.select2();
                        selectEl.on('change', this.updateExerciceList.bind(this));
                        this.$el.find('.btn[data-id="CANCEL"]').on('click',App.hideDomainSelector.bind(App));
                        this.$el.find('.btn[data-id="VALIDATE"]').on('click',App.changeDomain.bind(App,selectEl.val()));
                
                        this.$el.appendTo(this.options.el);
                    })
            });
    }

    hide() :void {
        this.$el.removeClass('show');
        this.$el.hide();
    }

    getSelectedDomain():string {
        var selectEl:any = this.$el.find('select[data-id="DOMAIN_SELECT"]');
        return selectEl.val();
    }

    getSelectedExercice():any {
        var selectEl:any = this.$el.find('select[data-id="EXERCICE_SELECT"]');
        return this.availableExercices[selectEl.val()];
    }

    updateExerciceList() {
        let selectedDomain = this.getSelectedDomain();
        if(DomainManager.getCurrentContext(selectedDomain) === undefined) {
            DomainManager.setContext(selectedDomain, new DomainContext(selectedDomain, null));
        }
        App.getAvailableExercices(DomainManager.getCurrentContext(selectedDomain))
            .then((exercices:{any}) => {
                this.availableExercices = exercices;
                let selectExerciceEl:any = this.$el.find('select[data-id="EXERCICE_SELECT"]');
                selectExerciceEl.val(null).trigger('change');
                selectExerciceEl.empty();
                for(let id in exercices) {
                    let ex = exercices[id];
                    selectExerciceEl.append(new Option(ex.label, ex.id,ex.active,ex.active));
                }
                selectExerciceEl.trigger('change');
            });
    }
}

export default DomainSelectorView