'use strict';

import List from 'views/listing/List';
import HeaderRenderer from 'views/listing/renderer/header/HeaderRenderer';

import * as _ from 'lodash';
let $ = require('jquery');
import { SAIView } from '../../../../Additions';
import StringUtils from 'utils/StringUtils';
import { DomainContext } from '../../../../parametrage/DomainContext';
import DeviceUtils from '../../../../utils/DeviceUtils';
import { Header } from '../../../../models/listing/Header';
import { Field } from '../../../../parametrage/structures/Field';
import Context from '../../../../models/context/Context';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { Task } from '../../../../parametrage/structures/Task';

class ListCoupleSelection extends SAIView {

    public isShown: boolean;
    public referenceInput: JQuery<HTMLElement>;

    private fieldConfig: Field;
    private keyColumn: string;
    private domainContext: DomainContext;
    private headerList: List;
    private requestHeader: Header;
    private selection: Array<any>;
    private uniqueId: string;
    private selectedInSelection: JQuery<HTMLElement>;
    private selectionListElement: JQuery<HTMLElement>;
    private navigatorElement: JQuery<HTMLElement>;
    private rootInnerElement: JQuery<HTMLElement>;
    private selectionElement: JQuery<HTMLElement>;
    private searchInput: JQuery<HTMLElement>;

    initialize (options) {
        this.template = require('ejs-loader!templates/fields/ListCoupleSelection.ejs');

        this.checkMandatoryParameter(options, 'navigatorConfig', 'Entries that will be used to configure the entries search');
        this.checkMandatoryParameter(this.parameters.navigatorConfig, 'taskId', 'Task to call for data');
        this.checkMandatoryParameter(this.parameters.navigatorConfig, 'gridId', 'Grid to retreive from server');

        this.checkMandatoryParameter(options, 'fieldName', 'label of the field');

        this.selection = options.selection;
        this.domainContext = options.domainContext;
        this.fieldConfig = options.fieldConfig;

        this.initializeConfig()
            .then((iconConfig) => {
                this.headerList = new List({
                    taskId: this.parameters.navigatorConfig.taskId,
                    gridId: this.parameters.navigatorConfig.gridId,
                    filterId: options.navigatorConfig.filterId,
                    multiSelect: {
                        selectedItemsClass: 'selectedItem',
                        selectedKeys: this.selection.map(function (item) {
                            return item.value;
                        }),
                        keyColumn: this.parameters.navigatorConfig.selectionKey
                    },
                    renderer: new HeaderRenderer({
                        domainContext: this.domainContext,
                        iconConfig: iconConfig
                    }),
                    domainContext: this.domainContext
                });
                this.listenTo(this.headerList, 'list.onPrepareContext', this.onContextRequest.bind(this));
                this.listenTo(this.headerList, 'itemClicked', this.onNavItemSelected.bind(this));
            });

    }

    onContextRequest(context: Context) {
        this.trigger('listCoupleSelection.onPrepareContext', context);
    }

    toggle () {
        if (this.isShown) {
            this.hide();
        } else {
            this.show();
        }
    }

    setSelection (selection) {
        this.selection = selection;
        if(this.headerList) {
            this.headerList.setSelectedKeys(selection.map(function (item) {
                return item.value;
            }));
        }
    }

    show () {
        if (!this.isShown) {
            this.isShown = true;
            this.render();
        }
    }

    hide () {
        if (this.isShown) {
            this.isShown = false;
            this.$el.removeProp('style');
            this.$el.removeClass('rightPosition');
            this.$el.removeClass('leftPosition');
            this.$el.removeClass('topLocated');
            this.$el.removeClass('bottomLocated');
            this.$el.addClass('closed');
            this.$el.hide();
            $(document).off('mouseup.' + this.uniqueId, this.clickedSomewhere.bind(this));
            $(document).off('tap.' + this.uniqueId, this.clickedSomewhere.bind(this));
            this.trigger('onHide');
        }
    }

    render () {
        var me = this;

        this.$el.html(this.template({
            fieldLabel: this.parameters.fieldName
        }));
        this.$el.removeClass('closed');
        this.$el.show();

        var maxHeightBottom = Math.min($(window).height() - this.referenceInput.offset().top - 150, 500);
        var maxHeightTop = Math.min(this.referenceInput.offset().top - 150, 400);
        var onLeft = $(window).width() / 2 - this.referenceInput.offset().left > 0;

        this.rootInnerElement = this.$el.children('.coupleSelection');
        this.navigatorElement = this.rootInnerElement.children('.navigatorContainer');
        this.selectionElement = this.rootInnerElement.children('.selectedElements');
        this.selectionListElement = this.selectionElement.children('.selection');
        this.searchInput = this.$el.find('input.search-bar');

        if (onLeft) {
            this.$el.addClass('leftPosition');
            this.$el.removeClass('rightPosition');
        } else {
            this.$el.addClass('rightPosition');
            this.$el.removeClass('leftPosition');
        }

        if (maxHeightBottom >= maxHeightTop) {
            //We'll locate the popup on the bottom of the screen
            this.$el.css({
                height: maxHeightBottom,
                'margin-top': 20
            });
            this.$el.addClass('bottomLocated');
            this.$el.removeClass('topLocated');
            this.navigatorElement.css({
                height: maxHeightBottom - this.searchInput.parent().outerHeight()
            });
            this.selectionElement.css({
                height: maxHeightBottom - this.searchInput.parent().outerHeight()
            });
        } else {
            //We'll locate the popup on top of the screen
            this.$el.css({
                height: maxHeightTop,
                top: -maxHeightTop
            });
            this.$el.removeClass('bottomLocated');
            this.$el.addClass('topLocated');
            this.navigatorElement.css({
                height: maxHeightTop - this.searchInput.parent().outerHeight()
            });
            this.selectionElement.css({
                height: maxHeightTop - this.searchInput.parent().outerHeight()
            });
        }

        this.headerList.setElement(this.navigatorElement);
        this.headerList.render();
        this.headerList.getListing().resetData();
        this.headerList.getListing().fetchNextPage();

        /*if (!DeviceUtils.isMobile()) {
            this.headerList['searchBarInput'].focus();
        }*/

        this.uniqueId = StringUtils.guid();

        $(document).on('mouseup.' + this.uniqueId, me.clickedSomewhere.bind(me));
        $(document).on('tap.' + this.uniqueId, me.clickedSomewhere.bind(me));

        this.searchInput.on('change',this.onSearch.bind(this));

        this.$el.find('button').click(this.hide.bind(this));
        this.renderSelection();
        return me;
    }

    onSearch(){
        let searchVal = this.searchInput.val();
        this.headerList.getListing().setFilterData([], searchVal);
        if(searchVal){
            this.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', true);
        }else{
            this.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', undefined);
        }
        this.headerList.getListing().resetData();
        this.headerList.getListing().fetchNextPage();
    }

    clickedSomewhere (e) {
        var container = this.$el;
        if ((this.referenceInput.is(e.target) || this.referenceInput.has(e.target).length > 0) || (!container.is(e.target) && container.has(e.target).length === 0)) {
            var wasInput = (this.referenceInput.is(e.target) || this.referenceInput.has(e.target).length > 0);
            $(document).off('mouseup.' + this.uniqueId);
            $(document).off('tap.' + this.uniqueId);
            this.trigger('onHide', wasInput);
            this.hide();
            this.trigger('onHidden', wasInput);
        }
    }

    renderSelection () {
        this.selectionListElement.find('.entry').off('click');
        this.selectionListElement.empty();
        for (var entryId in this.selection) {
            var entry = this.selection[entryId];
            var newEntry = $('<div class="entry" data-id="' + entry.value + '">' + entry.label + '</div>').appendTo(this.selectionListElement);
            newEntry.on('click', this.onSelectedEntryClicked.bind(this));
        }
    }

    onSelectedEntryClicked (evt) {
        let selectedId = $(evt.target).data('id').toString();
        this.selection = _.remove(this.selection, function (item) {
            return item.value !== selectedId;
        });
        this.renderSelection();
        this.trigger('change', this.selection);
        this.setSelection(this.selection);
        this.initializeRequestHeader();
        this.headerList.unselectItem(selectedId, this.keyColumn);
    }

    onNavItemSelected (row, id, view) {
        this.initializeRequestHeader();
        let labelColumn = this.fieldConfig.getDisplayColumnId();
        if(!labelColumn) {
            labelColumn = this.keyColumn;
        }
        var key = row.getColumnsValues()[this.keyColumn].value;
        var label = row.getColumnsValues()[labelColumn].value;

        var exists = _.find(this.selection, function (item) {
            return item.value === key;
        });

        if (exists !== undefined) {
            //selected item again = removing it
            this.selection = _.remove(this.selection, function (item) {
                return item.value !== key;
            });
        } else {
            this.selection.push({
                value: key,
                label: label
            });
        }

        this.renderSelection();

        this.trigger('change', this.selection);
    }

    initializeRequestHeader() {
        if(!this.requestHeader) {
            this.requestHeader = this.headerList.getListing().getHeaders();
            let columns = this.requestHeader.getColumnsMap();
            for(let key in columns) {
                if(columns[key].getDatafieldId().endsWith('_PK')) {
                    this.keyColumn = columns[key].getId();
                }
            }
        }
        if(!this.keyColumn) {
            this.keyColumn = this.requestHeader.getColumns[0].getId();
        }
    }

    initializeConfig() : Promise<any> {
        let me = this;
        return new Promise((accept, reject) => {
            let iconConfigPromise = [];
            let navGridView = this.parameters.navigatorConfig.gridId;
            let taskId = this.parameters.navigatorConfig.taskId;

            
            CClientConfiguration.getTask(taskId, me.domainContext)
                .then((taskConfig) => {
                    CClientConfiguration.getDataField(me.fieldConfig.getConfig().datafieldId, me.domainContext, taskConfig as Task)
                        .then((datafield) => {
                            iconConfigPromise.push(CClientConfiguration.getGridIconConfig(datafield.getGridView(),taskId, me.domainContext));
                            iconConfigPromise.push(CClientConfiguration.getTableIconConfig(datafield.getTableName(),taskId, me.domainContext));
                            Promise.all(iconConfigPromise)
                                .then(([gridIconConfig, tableIconConfig]) => {
                                    let iconConfig = undefined;
                                    if(gridIconConfig || tableIconConfig) {
                                        iconConfig = Object.assign({}, gridIconConfig, tableIconConfig);
                                    }
                                    if(iconConfig) {
                                        accept(iconConfig);
                                    } else {
                                        accept(undefined);
                                    }
                                })
                                .catch(reject);
                        }).catch(reject);
                });  
        });
    }
}

export default ListCoupleSelection;
