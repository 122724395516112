'use strict';

let $ = require('jquery');
import { SAIView } from  'Additions';
import App from 'App';
import User from 'User';
import Server from 'server/Server';
import { DomainContext } from '../parametrage/DomainContext';
import { UserInfosRequest } from '../server/protocol/request/auth/UserInfosRequest';

class MenuView extends SAIView {
    domainContext: DomainContext;
    
    constructor(options?) {
        super(options);
        this.domainContext = options.domainContext;
        this.template = require('ejs-loader!templates/Menu.ejs');
    }

    render() : any {
        this.$el = $(this.template({
            changeCompanyLabel: 'Changer de société',
            logoutLabel: 'Déconnexion'
        }));
        this.$el.appendTo(this.options.el);
        this.$el.find('.menu-mask').on('click', this.hide.bind(this));

        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-logout'),{h:30,w:30})
            .then((element: JQuery<HTMLElement>) => {
                element.addClass('icon');
                this.$el.find('.logout').append(element);
            });

        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-refresh'),{h:30,w:30})
            .then((element: JQuery<HTMLElement>) => {
                element.addClass('icon');
                this.$el.find('.changeCompany').append(element);
            });

        this.$el.find('.logout').on('click',App.logout.bind(App));
        if(User.getAvailableDomains().length < 2) {
            this.$el.find('.changeCompany').hide();
        } else {
            this.$el.find('.changeCompany').on('click',App.showDomainSelector.bind(App));
        }
    }

    public show():void {
        this.render();
        this.$el.show();
    }

    public hide():void {
        this.$el.hide();
    }
}

export default MenuView;