module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var rgbVersion = colorUtils.hexToRgb(operationColor);
var hslVersion = colorUtils.rgbToHsl(rgbVersion.r, rgbVersion.g, rgbVersion.b);
var increase = hslVersion.h < 150 ? -1 : 1;
hslVersion.h = Math.min(255, hslVersion.h + increase * hslVersion.h * 0.05);
var newRgb = colorUtils.hslToRgb(hslVersion);
var secondBase = colorUtils.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
var darkerSecond = colorUtils.shadeColor(secondBase, - 0.10);
var darkerFirst = colorUtils.shadeColor(operationColor, - 0.05);
var patternId = Math.random();
;
__p += '\n<div class="modal fade show">\n    <div class="mask" style="width: 100%; height: 100%; position: absolute; top: 0px; background-color: #000; opacity: 0.2;"></div>\n    <div class="password-update modal-dialog">\n        <div class="modal-content">\n            <div class="modal-title-header">\n                <svg width="100%" height="100%">\n                    <pattern id="pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
'" x="0" y="0" width="180" height="15" patternUnits="userSpaceOnUse">\n                        <rect style="fill:' +
((__t = (secondBase)) == null ? '' : __t) +
';" width="90" height="15" x="0" y="0"></rect>\n                        <rect style="fill:' +
((__t = (operationColor)) == null ? '' : __t) +
';" width="90" height="15" x="90" y="0"></rect>\n                        <path style="fill:' +
((__t = (darkerSecond)) == null ? '' : __t) +
';" d="M 0,0 0,15 H 15 Z"></path>\n                        <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 90,0 90,15 H 105 Z"></path>\n                        <path style="fill:' +
((__t = (darkerFirst)) == null ? '' : __t) +
';" d="M 135,0 135,15 H 150 Z"></path>\n                    </pattern>\n                    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-square-' +
((__t = (patternId)) == null ? '' : __t) +
')" />\n                </svg>\n            </div>\n            <div class="modal-title recordFormTitle">' +
((__t = (title)) == null ? '' : __t) +
'</div>\n            <div class="modal-body">\n                <div class="form-group">\n                    <label for="CURRENT_PASSWORD">' +
((__t = (currentPasswordLabel)) == null ? '' : __t) +
'</label>\n                    <input type="password" data-id="CURRENT_PASSWORD"/>\n                </div>\n                <div class="form-group">\n                    <label for="NEW_PASSWORD">' +
((__t = (newPasswordLabel)) == null ? '' : __t) +
'</label>\n                    <input type="password" data-id="NEW_PASSWORD"/>\n                </div>\n                <div class="form-group">\n                    <label for="CONFIRM_PASSWORD">' +
((__t = (confirmPasswordLabel)) == null ? '' : __t) +
'</label>\n                    <input type="password" data-id="CONFIRM_PASSWORD"/>\n                </div>\n                <div class="error-message"></div>\n            </div>\n            <div class="modal-footer">\n                <div class="confirmation-button btn" data-id="CANCEL">\n                    <i class="fa fa-ban"></i>\n                    <div clas="btn-text">' +
((__t = (buttonsCancelLabel)) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="confirmation-button btn" data-id="VALIDATE">\n                    <i class="fa fa-check-circle"></i>\n                    <div clas="btn-text">' +
((__t = (buttonsValidateLabel)) == null ? '' : __t) +
'</div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
}