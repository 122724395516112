'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';
import { DataPage } from './DataPage';
import { Table } from './Table';
import { Task } from './Task';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';

class DataField extends ConfigurationElement {
    private parentDataPage: DataPage;
    private parentTable: Table;
    private parentTask: Task;
    protected config: {id: string, label?: string, [prop:string]: any};
    private static ASYNC_PROP_LABEL:Symbol = Symbol();
    private static ASYNC_PROP_PLACEHOLDER:Symbol = Symbol();

    constructor(config: { id: string, [key: string] : string | number | boolean},
        parentDataPage: DataPage, parentTable: Table, parentTask: Task, context: DomainContext) {
        super(config, context);

        //Linking with parent structure
        this.parentDataPage = parentDataPage; //Page datafield
        this.parentTable = parentTable; //Table datafield
        this.parentTask = parentTask; //Task only datafield

        //remaining props to init
        this.remainingAsyncProps = [DataField.ASYNC_PROP_LABEL, DataField.ASYNC_PROP_PLACEHOLDER];
    }

    //  ____        _         __ _      _     _                  _   _
    // |  _ \  __ _| |_ __ _ / _(_) ___| | __| |___    __ _  ___| |_| |_ ___ _ __ ___
    // | | | |/ _` | __/ _` | |_| |/ _ \ |/ _` / __|  / _` |/ _ \ __| __/ _ \ '__/ __|
    // | |_| | (_| | || (_| |  _| |  __/ | (_| \__ \ | (_| |  __/ |_| ||  __/ |  \__ \
    // |____/ \__,_|\__\__,_|_| |_|\___|_|\__,_|___/  \__, |\___|\__|\__\___|_|  |___/
    //                                                |___/

    public isDataPageDataField(): boolean {
        return this.parentDataPage !== undefined;
    }

    public getDataPage(): DataPage {
        return this.parentDataPage;
    }

    public isTableDataField(): boolean {
        return this.parentTable !== undefined;
    }

    public getTable(): Table {
        if(this.parentTable === undefined && this.parentDataPage !== undefined) {
            return this.parentDataPage.getTable();
        } else {
            return this.parentTable;
        }
    }

    public isTaskDataField(): boolean {
        return this.parentTask !== undefined;
    }

    public getTask(): Task {
        return this.parentTask;
    }

    public getSource(): string {
        return this.getValueOrDefault('source', 'TaskOnly');
    }

    public setSourcePageId(pageId): void {
        this.config.sourcePageId = pageId;
    }

    public getSourcePageId(): string {
        return this.config.sourcePageId;
    }

    public getFormat(): string {
        return this.getValueOrDefault('format', 'String');
    }

    public getPresentation(): string {
        return this.getValueOrDefault('presentation');
    }

    public getEnumName(): string {
        return this.getValueOrDefault('enumName');
    }

    public getTableName(): string {
        return this.getValueOrDefault('tableName');
    }

    public getDecimalsNumber(): number {
        return this.getNumberValueOrDefault('decimalsNumber', 2);
    }

    public isTitle(): boolean {
        return this.getBooleanValueOrDefault('isTitle', false);
    }

    public isHeader(): boolean {
        return this.getBooleanValueOrDefault('isHeader', false);
    }

    public isSingleOnAllRecords(): boolean {
        return this.getBooleanValueOrDefault('singleOnAllRecords', false);
    }

    public allowsMultipleLanguages(): boolean {
        return this.getBooleanValueOrDefault('allowMultipleLanguages', false);
    }

    public getGridView(): string {
        return this.getValueOrDefault('gridView');
    }

    public getForeignKeyField(): string {
        return this.getValueOrDefault('foreignKeyField');
    }

    public getFilterName(): string {
        return this.getValueOrDefault('filterName', 'DEFAULT_FILTER');
    }

    public isSupremum(): boolean {
        return this.getBooleanValueOrDefault('supremum', false);
    }

    public getInputLimits(): string {
        return this.getValueOrDefault('inputLimits');
    }

    public getProgressLimits(): string {
        return this.getValueOrDefault('progressLimits');
    }

    public getMaxLength(): number {
        return this.getNumberValueOrDefault('maxLength', 0);
    }

    public getSpecialMask(): string {
        return this.getValueOrDefault('specialMask');
    }

    public getFileType(): string {
        return this.getValueOrDefault('fileType');
    }

    public isProtected(): boolean {
        return this.getBooleanValueOrDefault('protected', false);
    }

    public getExistenceConditions(): string {
        return this.getValueOrDefault('existence');
    }

    public getImportance(): string {
        return this.getValueOrDefault('importance');
    }

    public getSortIndex(): string {
        return this.getValueOrDefault('sortIndex');
    }

    public isGeneric(): boolean {
        return this.getBooleanValueOrDefault('generic', false);
    }

    public hasToShowSeconds(): boolean {
        return this.getBooleanValueOrDefault('showSeconds', false);
    }

    public hasToShowDays(): boolean {
        return this.getBooleanValueOrDefault('showDays', true);
    }

    public getSilo(): string {
        return this.getValueOrDefault('silo');
    }

    public getDefaultValue(): string {
        return this.getValueOrDefault('defaultValue');
    }

    public getPermanentValue(): string {
        return this.getValueOrDefault('permanentValue');
    }

    public canEnableRichText(): boolean {
        return false;
    }

    public getPlaceHolder(): string {
        return this.getValueOrDefault('placeHolder');
    }

    public getDisplayCode(): string {
        return this.getValueOrDefault('displayCode', 'TextAndCode');
    }

    public getKeyPattern(): string {
        return this.getValueOrDefault('keyPattern');
    }

    public getScriptLanguage(): string {
        return this.getValueOrDefault('scriptLanguage');
    }

    public getImageSource(): string {
        return this.getValueOrDefault('imageSource');
    }

    public getImageInteraction(): string {
        return this.getValueOrDefault('imageInteraction');
    }

    public getScriptOnClick(): string {
        return this.getValueOrDefault('scriptOnClick');
    }

    public getNotificationOnClick(): any {
        return this.getValueOrDefault('notificationOnClick');
    }

    public getNotificationType(): string {
        return this.getValueOrDefault('notificationType');
    }

    public getRemoteTask(): string {
        return this.getValueOrDefault('remoteTask');
    }

    public getRemoteArguments(): string {
        return this.getValueOrDefault('remoteArguments');
    }

    public isAutoAddedSystemDF(): boolean {
        return this.getBooleanValueOrDefault('autoAddedSystemDF', false);
    }

    public getDatabaseTable(): string {
        return this.getValueOrDefault('databaseTable');
    }

    public getDatabaseColumn(): string {
        return this.getValueOrDefault('databaseColumn');
    }

    public getDatapageID(): string {
        return this.getValueOrDefault('datapageId');
    }

    public isShownAsFilterOnNavigator(): boolean {
        return this.getBooleanValueOrDefault('showAsFilterOnNavigator', false);
    }

    public getPresentInFilterCondition(): string {
        return this.getValueOrDefault('PresentIfTrue');
    }

    public canBeUsedForFilter(): boolean {
        return this.getBooleanValueOrDefault('canBeUsedForFilter', true);
    }

    public canBeUsedForSelection(): boolean {
        return this.getBooleanValueOrDefault('canBeUsedForSelection', true);
    }

    public canBeUsedForOperations(): boolean {
        return this.getBooleanValueOrDefault('canBeUsedForOperations', true);
    }

    public canAutoSaveDocument(): boolean {
        return this.getBooleanValueOrDefault('autoSaveDocument', true);
    }

    public canShowDocumentSaveBtn(): boolean {
        return this.getBooleanValueOrDefault('canShowDocumentSaveBtn', true);
    }

    public canKeepDocumentVersions(): boolean {
        return this.getBooleanValueOrDefault('canKeepDocumentVersions', false);
    }

    public getDocumentTemplate(): string {
        return this.getValueOrDefault('documentTemplate');
    }

    public getDocumentApplication(): string {
        return this.getValueOrDefault('documentApplication');
    }

    public getDocumentPrintLanguage(): string {
        return this.getValueOrDefault('documentPrintLanguage');
    }

    public isKey(): boolean {
        return this.getId().endsWith('_PK');
    }

    public getDatafieldId() : string {
        return this.getValueOrDefault('datafieldId');
    } 

    //  _____                 _   _
    // |  ___|   _ _ __   ___| |_(_) ___  _ __  ___
    // | |_ | | | | '_ \ / __| __| |/ _ \| '_ \/ __|
    // |  _|| |_| | | | | (__| |_| | (_) | | | \__ \
    // |_|   \__,_|_| |_|\___|\__|_|\___/|_| |_|___/

    public initializeI18N(): Promise<Array<string>> {
        if(!this.hasAsyncRequirements()) {
            return null;
        }
        let currentId = this.getId();
        let me = this;

        let i18NID = this.parentTask ? this.parentTask.getId() + '.' : '';
        if(this.getSourcePageId()) {
            i18NID += this.getSourcePageId() + '.';
        }
        i18NID += currentId;
        if(i18NID.indexOf('DOSSIER.') === 6) {
            i18NID = i18NID.replace('DOSSIER.', '.');
        }
        let isGlobal = this.getTable() ? this.getTable().isGlobal() : false;
        let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), this.parentTask ? 'task' : 'table', i18NID, User.getLocale(), isGlobal);
        i18NTextPromise.then((i18NText) => {
            if(i18NText !== undefined) {
                me.setLabel(i18NText);
            }
            this.removeFromAsyncRequirement(DataField.ASYNC_PROP_LABEL);
        });

        let placeHolderI18NPromise = CClientConfiguration.getI18nText(me.getContext(), this.parentTask ? 'task' : 'table', i18NID + '.PlaceHolder', User.getLocale(), isGlobal);
        placeHolderI18NPromise.then((placeHolderI18N) => {
            if(placeHolderI18N !== undefined) {
                me.setPlaceHolder(placeHolderI18N);
            }
            this.removeFromAsyncRequirement(DataField.ASYNC_PROP_PLACEHOLDER);
        });

        return Promise.all([i18NTextPromise, i18NTextPromise]);
    }

    //  ____       _   _
    // / ___|  ___| |_| |_ ___ _ __ ___
    // \___ \ / _ \ __| __/ _ \ '__/ __|
    //  ___) |  __/ |_| ||  __/ |  \__ \
    // |____/ \___|\__|\__\___|_|  |___/

    public setPlaceHolder(newValue: string): void {
        this.config['placeHolder'] = newValue;
    }

    public setAsAutoAddedSystemDF(isAuto: boolean): void {
        this.config['autoAddedSystemDF'] = isAuto;
    }

    public setFormat(format: string) {
        this.config.format = format;
    }

    public setEnumName(enumName: string) {
        this.config.enumName = enumName;
    }
}

export { DataField };