
import Backbone from 'backbone';

class Row extends Backbone.Model {
    //Developper friendly getters abstracting model keys
    initialize(options) {
        this.set('level',Row.parseRowLevel(this.getStyles()));
    }

    getColumnsValues(){
        return this.get('rawJSON').columns;
    }

    getIcon(){
        return this.get('rawJSON').icon;
    }

    getImagePreview(){
        return this.get('rawJSON').iconNote;
    }

    getImage(){
        return this.get('rawJSON').iconNoteFull;
    }

    getOverlays(){
        return this.get('rawJSON').overlay;
    }
    /**
     * A row has a non null positive value level if it contains
     * by default a style corresponding to a rupture
     *
     * To manually set the level, call setLevel
     */
    static parseRowLevel(styles){
        if(styles !== undefined && typeof styles === 'object'){
            for (var key in styles) {
                var matchInfo = styles[key].match(/rup(tit)(\d)(g|d)/);
                if (matchInfo === null) {
                    return 0;
                }else{
                    var level = matchInfo[2];
                    return level;
                }
            }
        }
        return 0;
    }

    /**
     * Returns the row styles (ruptit, ruptot, etc...)
     */
    getStyles(){
        return this.get('rawJSON').styles;
    }

    /**
     * Returns the level of the row. A null level represents a data row
     *
     */
    getLevel(){
        return this.get('level');
    }

    /**
     * Manually set the row lvl
     */
    setLevel(newLevel){
        this.set('level', newLevel);
    }

    setHeaders(headers){
        this.set('headers', headers);
    }

    getHeaders(){
        return this.get('headers');
    }

    setActions(actions){
        this.set('actions', actions);
    }

    getActions(){
        return this.get('actions');
    }

    outputAllValues(){
        var output = [];
        var cols = this.getColumnsValues();
        for(var key in cols){
            output.push({key : key, value : cols[key].value});
        }
        return output;
    }

    outputKeys() {
        var output = [];
        var cols = this.getColumnsValues();
        var header = this.getHeaders();
        for(var key in cols){
            let currentCol = cols[key];
            if(currentCol.key === true) {
                output.push({
                    datafieldId : header.getColumnsMap()[currentCol.name].getDatafieldId(),
                    value : currentCol.value,
                    id: header.getColumnsMap()[currentCol.name].getId(),
                    key: header.getColumnsMap()[currentCol.name].getId()
                });
            }
        }
        return output;
    }

    outputAllDataFieldsValues() {
        var output = [];
        var cols = this.getColumnsValues();
        var header = this.getHeaders();
        for(var key in cols){
            let currentCol = cols[key];
            output.push({
                datafieldId : header.getColumnsMap()[currentCol.name].getDatafieldId(),
                value : currentCol.value,
                id: header.getColumnsMap()[currentCol.name].getId(),
                key: header.getColumnsMap()[currentCol.name].getId()
            });
        }
        return output;
    }
}

export default Row;
