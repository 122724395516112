module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="pubFicheActionInner">\n    <button class="btn btn-default pubFicheBtn" data-task="' +
__e(config.task) +
'" data-taskmode="' +
__e(config.taskmode) +
'">' +
((__t = (config.text)) == null ? '' : __t) +
'</button>\n</div>';

}
return __p
}