import Row from 'models/listing/Row';
import DataRow from 'models/listing/DataRow';

class RuptureRow extends Row {
    initialize(options) {
        super.initialize(options);
    }

    /**
     * Returns the array of rows contained within this rupture.
     * These rows can also be rutptures based on the context
     */
    getRows(){
        let innerRows = this.get('rawJSON').rows;
        let builtRows = [];

        for(let key in innerRows){
            let rawRow = innerRows[key];
            let rowToReturn;

            if(Row.parseRowLevel(rawRow.styles)){
                rowToReturn = new RuptureRow({
                    rawJSON : rawRow
                });
            }else{
                rowToReturn = new DataRow({
                    rawJSON : rawRow
                });
            }

            rowToReturn.setHeaders(this.getHeaders());
            rowToReturn.setActions(this.getActions());

            builtRows.push(rowToReturn);
        }
        return builtRows;
    }

    addRow(newRow){
        this.getRows().push(newRow);
    }
}

export default RuptureRow;