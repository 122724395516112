'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';
import MobileRequest from '../MobileRequest';
import { I18NRequest } from './I18NRequest';

class DashboardRequest extends ConfigurationRequest {
    private lang: string;
    constructor(domain: DomainContext, _id? : string){
        super('dashboard', domain, _id.substring(0, _id.lastIndexOf('/')));
        this.lang = _id.substring(_id.lastIndexOf('/')+1, _id.length);
    }

    public preProcess(results: Array<any>) {
        let dash = results[0];
        let i18nValues = results[1].texts || {};
        let dashId = dash.id;
        if(i18nValues[dashId] + '.label') { dash.label = i18nValues[dashId + '.label']; }
        for(let gId in dash.groups) {
            let curGrp = dash.groups[gId];
            let groupId = dashId + '.' + curGrp.id;
            if(i18nValues[groupId + '.label']) { curGrp.config.label = i18nValues[groupId + '.label']; }
            for(let tId in curGrp.tiles) {
                let curTile = curGrp.tiles[tId];
                let tileId = groupId + '.' + curTile.id;
                if(i18nValues[tileId + '.label']){ curTile.config.label = i18nValues[tileId + '.label'];}
                if(i18nValues[tileId + '.shortlabel']){ curTile.config.label = i18nValues[tileId + '.shortlabel'];}
                if(i18nValues[tileId + '.veryshortlabel']) { curTile.config.label = i18nValues[tileId + '.veryshortlabel']; }
                if(i18nValues[tileId + '.icon']) { curTile.visual.icon = i18nValues[tileId + '.icon']; }
            }
        }
    }

    public hasSubRequests() {
        return true;
    }

    public getSubRequests(): Array<MobileRequest> {
        let reqParts:Array<string> = this.getId().split('/').map(item => item.trim());
        let dashId = reqParts[reqParts.length-1];
        return [new I18NRequest(this.getDomain(), 'dashboard/' + this.lang + '/' + dashId.substring(0,3))]
    }
}

export { DashboardRequest };