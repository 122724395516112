'use strict';

import { ConfigurationElement } from './ConfigurationElement'
import { Group } from './Group'
import { DomainContext } from '../DomainContext';
import { Task } from './Task';
import { Level } from './Level';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import { Field } from './Field';

abstract class BasePanel extends ConfigurationElement {
    protected parentTask: Task;
    protected groups: { [groupId: string] : Group };
    protected groupsArr: Array<Group>;
    protected children: Array<Group | Level>;

    public static CHILD_TYPE_LEVEL:string = 'level';
    public static CHILD_TYPE_GROUP:string = 'group';
    private static ASYNC_PROP_LABEL:Symbol = Symbol();

    constructor(config: {id : string, children?: Array<any>}, parentTask: Task, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [ BasePanel.ASYNC_PROP_LABEL ];
        this.groups = {};
        this.groupsArr = [];
        this.children = [];
        this.parentTask = parentTask;
        for(let key in config.children) {
            let childConf = config.children[key];
            let cType = BasePanel.getObjectType(childConf.objectType);
            if(cType === BasePanel.CHILD_TYPE_LEVEL) {
                let newChild = new Level(childConf, this, undefined, undefined, context);
                this.children.push(newChild);
            } else if(cType === BasePanel.CHILD_TYPE_GROUP) {
                let newChild = new Group(childConf, this, undefined, context);
                this.children.push(newChild);
            } else {
                console.warn('Invalid child type in panel/page/subpanel : ' + cType);
            }
        }
    }

    public getFields(): { [fieldI18N:string]: Field} {
        let base = {};
        for(let grpId in this.groups) {
            Object.assign(base, this.groups[grpId].getI18NFields());
        }
        return base;
    }

    public getParentTask(): Task {
        return this.parentTask;
    }

    /**
     * @returns the map of Group in the panel
     */
    public getGroup(grpId): Group {
        return this.groups[grpId];
    }

    /**
     * @returns the map of groups as an array of Group 
     */
    public getGroups(): Array<Group> {
        return this.groupsArr;
    }

    public registerGroup(newChild: Group): void {
        this.groupsArr.push(newChild);
        this.groups[newChild.getId()] = newChild;
    }

    public abstract getFieldI18N(fieldId: string);

    public initializeI18N(): Promise<Array<string>> {
        let allPromises = [];

        //We need to fetch the resources
        let i18NTextPromise = this.getPanelI18NPromise();
        if(i18NTextPromise !== null) { allPromises.push(i18NTextPromise); }
        //All groups must be initialized as well
        for(let grpId in this.groups) {
            let groupProm = this.groups[grpId].initializeI18N();
            if(groupProm !== null) { allPromises.push(groupProm);}
        }
        allPromises.push(this.initializeHelp(this.getHelpId()));
        return allPromises.length === 0 ? null : Promise.all(allPromises);
    }

    public abstract getI18NID(): string;

    protected getPanelI18NPromise() : Promise<any>{
        if(!this.hasAsyncRequirements()) {
            return null;
        }
        let me = this;
        let i18NID = this.getI18NID();
        let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'task', i18NID, User.getLocale());
        i18NTextPromise.then((i18NText) => {
            if(i18NText !== undefined) {
                me.setLabel(i18NText);
            }
            me.removeFromAsyncRequirement(BasePanel.ASYNC_PROP_LABEL);
        });
        return i18NTextPromise;
    }

    public static setReadOnly(descriptor: any) {
        let setGroupReadOnly = function(descriptor) {
            for(let child in descriptor.children) {
                let childElement = descriptor.children[child];
                let type = BasePanel.getObjectType(childElement.objectType);
                if(type === BasePanel.CHILD_TYPE_GROUP) {
                    Group.setReadOnly(childElement);
                } else if(type === BasePanel.CHILD_TYPE_LEVEL) {
                    setGroupReadOnly(childElement);
                }
            }
        };

        setGroupReadOnly(descriptor);
    }

    public getHelpId(): string {
        return this.parentTask.getId() + '.Panel' + this.getId();
    }
}

export { BasePanel };