module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="table-responsive">\n    <table class="table table-grouped-striped-column groupedTable">\n        <tr>\n        ';
 for(var i = 0; i < headers.length; i++){ ;
__p += '\n            ';
 if( ! headers[i].hidden && headers[i].fid !== ruptCol) { ;
__p += '\n            <th style="' +
__e(getHeaderStyle(headers[i], false)) +
'"><div class="' +
__e(getColumnClass(headers[i])) +
'"><p class="dynamicGridValue">' +
__e(headers[i].text) +
'</p></div></th>\n            ';
 } ;
__p += '\n        ';
 } ;
__p += ' \n        </tr>\n    </table>\n</div>';

}
return __p
}