'use strict';

import * as Backbone from 'backbone';

import DocumentModel from 'models/Document';

class DocumentsCollection extends Backbone.Collection<DocumentModel>{
    //model: DocumentModel
};

export default DocumentsCollection;
