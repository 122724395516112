'use strict';

import * as Backbone from 'backbone';
let $ = require('jquery');

import App from 'App';
import User from 'User';
import Server from 'server/Server';
import DomainManager from 'DomainManager';
import LoadingMask from 'views/loading/LoadingMask';

import { WallpaperRequest } from 'server/protocol/request/configuration/WallpaperRequest';

import CClientConfiguration from 'parametrage/CClientConfiguration';
import ThemeManager from 'parametrage/ThemeManager';

import { SAIDashboardView } from '@sai/dashboard';
import { SAIView } from '../Additions';
import { DomainContext } from '../parametrage/DomainContext';
import GetGridDataRequest from '../server/protocol/request/task/GetGridData';
import NotificationManager from '../utils/notification/NotificationManager';
import DeviceUtils from '../utils/DeviceUtils';
import { ColorTheme } from '../parametrage/lookandfeel/ColorTheme';
import GetTaskRequest from '../server/protocol/request/task/GetTask';

let userDashboardJsonData = require('../../resources/dashboard-system.json');

class Desktop extends SAIView {

    //private currentDashboard: SAIDashboardView;
    private dashboardList: Array<{dashboard: SAIDashboardView, container:JQuery<HTMLElement>}>
    private scrollStart;
    private currentDashboardIndex;
    private dashboardWidth;
    private startDashboardScroll:boolean;
    private currentMouseX:number;
    private currentLeftScroll;
    private checkInterval;
    private lastRefreshedDashboard;
    private lastRefreshedTime;
    private toucheStartPosition;

    constructor(options) {
        options.domainContext = User.getCurrentDomain();
        super(options);

        this.template = require('ejs-loader!templates/Desktop');
        this.tagName = 'div';
        this.id = '';
        this.className = '';
        let baseReferenceWidth: number = 1920;
        let baseReferenceHeight: number = 1080;
        Backbone.View.apply(this, arguments);
    }

    getViewType():string {
        return 'desktop';
    }

    initialize() {}

    onHide() {}

    onShow() {
        this.scrollToDashboard(false);
    }

    /**
     * Renders the desktop template and query for shortcuts and background images
     * @returns {undefined}
     */
    render() : any {
        var me = this;
        me.$el.html(this.template);
        me.retrieveUserDashboards();
        me.setUserBackground();
    }

    setUserBackground(){
        let loadingReqId;
        LoadingMask.requestLoading('Chargement du fond d\'écran')
        .then((reqId) => {
            loadingReqId = reqId;
            return Promise.all([CClientConfiguration.getWallPaper(), ThemeManager.getUserTheme()]);
        })
        .then(([wallId, theme]) => {
            let wallReq = new WallpaperRequest(DomainManager.getCurrentContext(User.getCurrentDomain()), theme.getId(), wallId);
            let wallUrl = wallReq.getUrl();
            $('#desktop-image').css('background-image', 'url(\''+Server.getTokenedUrl(wallUrl)+'\')');
            $('#desktop-image-mask').css('background', 'rgba(255,255,255,'+ theme.getProperty('backgroundOpacity') +')');
        })
        .catch(App.displayErrorMessage)
        .then(() => { LoadingMask.hide(loadingReqId); });
    }

    /**
     * Retrieves the dashboards from the user and renders then on desktop in case
     * of success
     * @returns {undefined}
     */
    retrieveUserDashboards() {
        let loadingReqId;
        LoadingMask.requestLoading('Chargement du dashboard')
            .then((reqId) => {
                loadingReqId = reqId;
                let dashboardPromises = [];
                for(let dashboard of User.getDashboards()) {
                    dashboardPromises.push(CClientConfiguration.getDashboard(dashboard, User.getLocale(), DomainManager.getContext(User.getCurrentDomain())));
                }
                return Promise.all(dashboardPromises);
            })
            .then((dashboards:Array<SAIDashboardView>) => {
                if(dashboards !== undefined && dashboards !== null) {
                    let availableTasks = User.getAvailableTasks();
                    let dashboardAccessPromises = [];
                    for(let db of dashboards) {
                        db.setCallbacks({
                            'resource' : this.onResourceRequest.bind(this),
                            'data' : this.onDataRequest.bind(this)
                        });
                        for(let dbg of db.getGroups()) {
                            for (let dashboardIndex in dbg) {
                                let group = dbg[dashboardIndex];
                                for (let tileIndex in group.tiles) {
                                    let tile = group.tiles[tileIndex];
                                    let configTaskId = tile.getConfig().getTaskId();
                                    let taskAvailable = true;
                                    if(configTaskId !== undefined && configTaskId !== '') {
                                        if(availableTasks[configTaskId]) {
                                            let taskAccess = availableTasks[configTaskId];
                                            taskAvailable = taskAccess.indexOf('R') >= 0 || taskAccess.indexOf('X') != 0;
                                        } else {
                                            taskAvailable = false;
                                        }
                                    }
                                    tile.getConfig().config.enabled = taskAvailable;
                                }
                            }
                        }
                    }
                    Promise.all(dashboardAccessPromises).then(() => {
                        this.setUserDashboards(dashboards);
                    }).catch(App.displayErrorMessage);
                } else {
                    throw new Error('Erreur lors de la récupération du dashboard');
                }
            })
            .catch(App.displayErrorMessage)
            .then(() => { LoadingMask.hide(loadingReqId); });
    }

    onResourceRequest(config: any) : Promise<string> {
        return new Promise((resolve, reject) => {
            let context: DomainContext = DomainManager.getCurrentContext(User.getCurrentDomain());
            let sizes = config.sizes || '128,100,64,36,any';
            if (config.resource.indexOf('image/' + User.getCurrentDomain() + '/private') === 0) {
                resolve(Server.getTokenedUrl(config.resource));
            } else if(config.context == 'tileIcon') {
                //Requesting icons
                ///configuration/<package>/image/high,big,task/128,100,64,36/employee.png
                resolve(Server.getTokenedUrl('configuration/' + context.getId() + '/image/shortcut,big,record/' + sizes + '/' + config.resource));
            } else if(config.context === 'tileBackgroundImage') {
                resolve(Server.getTokenedUrl('configuration/' + context.getId() + '/image/shortcut/any/' + config.resource));
            } else {
                reject('Handling for context ' + config.context + ' not implemented yet');
            }
        });
    }

    onDataRequest(config: any): Promise<any> {
        let dataRequest = new GetGridDataRequest(DomainManager.getCurrentContext(User.getCurrentDomain()), config.taskId, config.gridId);
        dataRequest.setFormatterId(config.formatterId);
        dataRequest.setFilterId(config.filterId);
        dataRequest.setRange(0, 20);

        return Server.performRequest(dataRequest);
    }

    /**
     * Populates the dashboard list collection from the server response
     * and renders the first one on screen. User can change dashboard from its menu
     * @param {type} response The server response to the getDesktop call
     * @returns {undefined}
     */
    setUserDashboards(dashboards) {
        this.currentDashboardIndex = 1;
        this.dashboardList=[];
        let i=0;
        let shortContainer = $('.shortcutsContainers');

        let sysDashboard = new SAIDashboardView(userDashboardJsonData);
        sysDashboard.setCallbacks({
            'resource' : this.onResourceRequest.bind(this),
            'data' : this.onDataRequest.bind(this)
        });
        dashboards.splice(0,0,sysDashboard);

        if(DeviceUtils.isMobile()) {
            let width = $('body').width();
            this.dashboardWidth = width;
            shortContainer.css({
                'width': width  * dashboards.length,
                //'overflow': 'scroll',
                'height' : shortContainer.parent().height()
            });
        } else {
            let width = $('body').width()-200;
            shortContainer.css({
                'width': width,
                'overflow': 'scroll',
                'height' : shortContainer.parent().height(),
                'white-space' : 'nowrap'
            });
            
            this.dashboardWidth = width;
        }


        let promises = [];
        let index = 0;
        for(let dashboard of dashboards) {
            let currentDashboardContainer = $('<div></div>');
            if(this.isIOS12()) {
                let leftPos = 5 +((this.dashboardWidth+5) * index);
                currentDashboardContainer.css({
                    position: 'absolute',
                    top: 0,
                    left: leftPos,
                    width: this.dashboardWidth
                });
            } else if(DeviceUtils.isMobile()) {
                currentDashboardContainer.css({
                    position: 'relative',
                    top: 0,
                    width: this.dashboardWidth,
                    float: 'left'

                });
            } else {
                currentDashboardContainer.css({
                    'display': 'inline-block',
                    'vertical-align' : 'top',
                    'width': this.dashboardWidth
                });
            }
            shortContainer.append(currentDashboardContainer);

            if(dashboard === undefined){
                break;
            }
            this.dashboardList.push({
                dashboard: dashboard,
                container: currentDashboardContainer
            });
            dashboard.setElement(currentDashboardContainer);

            promises.push(this.renderDashboard(i));

            var me = this;

            $(window).on('resize', function(){
                if(currentDashboardContainer.is(':visible')) {
                    me.renderDashboard(i);
                }
            });
            i++;
            index++;
        }

        if(DeviceUtils.isMobile()) {
            $('.shortcutsContainers').on('touchstart', this.onTouchStart.bind(this));
            $('.shortcutsContainers').on('touchend', this.onTouchEnd.bind(this));
            $('#desktop-image-mask').on('touchstart', this.onTouchStart.bind(this));
            $('#desktop-image-mask').on('touchend', this.onTouchEnd.bind(this));
        } else {
            let moveLeft = $('<i class="fa fa-angle-left dashboard-arrow left"></i>');
            moveLeft.css({
                'top' : '50%',
                'left' : 70
            });
            moveLeft.appendTo($('.shortcutsContainers'));

            let moveRight = $('<i class="fa fa-angle-right dashboard-arrow right"></i>');
            moveRight.css({
                'top' : '50%',
                'right' : 20
            });
            moveRight.appendTo($('.shortcutsContainers'));

            moveLeft.on('click', this.switchDashboard.bind(this, true));
            moveRight.on('click', this.switchDashboard.bind(this, false));
        }

        Promise.all(promises)
            .then(() => {
                this.refreshDashboard();
                this.scrollToDashboard();
            });
    }

    /**
     * Renders the shortcut to the screen base on the internal collection of
     * shortcuts
     * @returns {undefined}
     */
    renderDashboard(index:number) {
        let me = this;
        let dashboard = this.dashboardList[index];
        if(dashboard === undefined){
            console.warn('Trying to build non existing dashboard');
            return;
        }

        let container = dashboard.container;
        container.empty();
        let loadingReqId;
        return new Promise((accept, reject) => {
            LoadingMask.requestLoading('Chargement de la tâche')
            .then((reqId) => {
                loadingReqId = reqId;
                return Promise.all([ThemeManager.getUserTheme(), me.getDashboardProperties(dashboard.dashboard.model.id)]);
            })
            .then(([theme, globalProps]) => {
                let dbTheme = theme as ColorTheme;
                dashboard.dashboard.render(dbTheme.getColors(), globalProps);
                dashboard.dashboard.addTileListener(function(event, tileModel){
                    $('#notification-sound')[0].muted = false;
                    let launcher = tileModel.getLauncherParameters();
                    if(launcher.type === 'Task') {
                        let taskId = launcher.taskId;
                        let overrideParameters = {};
                        for(let prop in launcher) {
                            if(prop !== 'type') {
                                overrideParameters[prop] = launcher[prop];
                            }
                        }
                        /*if(tileModel.getConfig().getGridId()) {
                            overrideParameters['gridId'] = tileModel.getConfig().getGridId();
                            overrideParameters['launchAtStart'] = true;
                        }*/
                        App.router.navigate('#task/'+taskId, {trigger: false, replace: false});
                        App.router.displayNewTask(taskId, undefined, overrideParameters);
                    } else if(launcher.type === 'Notification') {
                        let taskId = launcher.taskId;
                        let overrideParameters = {};
                        for(let prop in launcher) {
                            if(prop !== 'type') {
                                overrideParameters[prop] = launcher[prop];
                            }
                        }
                        let notificationManager = new NotificationManager({
                            currentTaskContext: undefined,
                            contextFiller : undefined,
                            domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
                        })
                        if (launcher.notification.type === 'local') {
                            notificationManager.handle(launcher.notification);
                        } else {
                            CClientConfiguration.getRemoteNotification(launcher.notification.notificationId, launcher.notification.remoteTask, launcher.notification.remoteArguments)
                                .then((notif) => {
                                    notificationManager.handle(notif);
                                })
                                .catch(App.displayErrorMessage);
                        }
                    } else if(launcher.type === 'None') {
                        let localAction = launcher.taskId;
                        if(localAction === 'LOGOUT') {
                            App.logout();
                        } else if(localAction === 'CHANGE_DOMAIN') {
                            App.showDomainSelector();
                        } else if(localAction === 'UPDATE_PASSWORD') {
                            App.showPasswordUpdate();
                        }
                    }
                });
            })
            .catch(App.displayErrorMessage)
            .then(() => {
                LoadingMask.hide(loadingReqId);
                accept(undefined);
            });
        });
    }

    getDashboardProperties(dashboardId: string) : Promise<{}> {
        let dataRequest = new GetGridDataRequest(DomainManager.getCurrentContext(User.getCurrentDomain()), 'UNI16', 'UNI16_N1');
        dataRequest.setSessionVars({
            'DASHBOARD_ID': dashboardId
        });
        return new Promise((resolve, reject) => {
            let loadingReqId: string;
            LoadingMask.requestLoading('Chargement des données du dashboard')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return Server.performRequest(dataRequest);
                })
                .then((response) => {
                    let globalProps = {};
                    for (let id in response.items) {
                        let item = response.items[id];
                        globalProps[item.columns['2'].value] = item.columns['3'].value;
                    }
                    resolve(globalProps);
                })
                .catch(reject)
                .then(() => {
                    LoadingMask.hide(loadingReqId);
                });
        });
    }

    refreshDashboard(force?: boolean) {
        // Update the tiles with the refresh property
        if(this.currentDashboardIndex !== undefined) {
            let now = Date.now();
            if(force || this.currentDashboardIndex !== this.lastRefreshedDashboard || this.lastRefreshedTime ==  undefined || now - this.lastRefreshedTime > 1000) {
                let me = this;
                let dashboard = this.dashboardList[this.currentDashboardIndex].dashboard;
                this.lastRefreshedDashboard = this.currentDashboardIndex;
                this.lastRefreshedTime = now;
                this.getDashboardProperties(dashboard.model.id)
                    .then((globalProps) => {
                        dashboard.setGlobalProperties(globalProps);
                        let dashGroups = dashboard.getGroups();
                        for (let dashboardIndex in dashGroups) {
                            let group = dashGroups[dashboardIndex];
                            for (let tileIndex in group.tileViews) {
                                let tile = group.tileViews[tileIndex];
                                if (tile.getType() === 'Grid' && tile.model.getConfig().isEnabled()) {
                                    Promise.all([me.onDataRequest(tile.model.getConfig().config), tile])
                                        .then(([data, tile]) => {
                                            tile.refreshTile(data);
                                        });
                                } else {
                                    tile.refreshTile();
                                }
                            }
                        }
                    })
                    .catch(App.displayErrorMessage);
            }
        }
    }

    public delete() {
        this.$el.empty();
    }

    private onTouchStart(evt) {
        this.toucheStartPosition = evt.originalEvent.touches[0];
        this.scrollStart = this.getScrollableArea().scrollLeft();
    }

    private checkScrollingLeft() {
        let scrollLeft = this.getScrollableArea().scrollLeft();
        if(scrollLeft === this.currentLeftScroll || this.checkInterval === undefined) {
            clearInterval(this.checkInterval);
            this.computeNewCurrentDashboad();
            this.checkInterval = undefined;
        }
        this.currentLeftScroll = scrollLeft;
    }

    private onTouchEnd(evt) {
        let finalPosition = evt.changedTouches[evt.changedTouches.length-1];
        let deltaX = finalPosition !== undefined ? Math.abs(finalPosition.clientX - this.toucheStartPosition.clientX) : 0;
        let deltaY = finalPosition !== undefined ? Math.abs(finalPosition.clientY - this.toucheStartPosition.clientY) : 0;
        if(deltaX > 10 || deltaY > 10) {
            this.currentLeftScroll = this.getScrollableArea().scrollLeft();
            // Check if the screen is still moving before checking the dashboard position
            if(this.checkInterval === undefined) {
                this.checkInterval = setInterval(this.checkScrollingLeft.bind(this), 100);
                setTimeout(()=>{
                    if(this.checkInterval !== undefined) {
                        console.warn('clearing interval due to timeout');
                        clearInterval(this.checkInterval);
                        this.checkInterval = undefined;
                        this.computeNewCurrentDashboad();
                    }
                }, 2000);
            }
        }
    }

    private computeNewCurrentDashboad() {
        let scrollEnd = this.getScrollableArea().scrollLeft();
        let delta = scrollEnd - this.scrollStart;
        let frac = Math.floor($('.shortcutsContainers').width() * 0.15);
        if (Math.abs(delta) / $('.shortcutsContainers').width() > 0.10) {
            let previousDashBoardIndex =  this.currentDashboardIndex;
            this.currentDashboardIndex = Math.floor(scrollEnd / this.dashboardWidth);
            // Get the currentDashboard from the current left scroll position
            if(delta < 0) {
                this.currentDashboardIndex = Math.floor((scrollEnd+frac) / this.dashboardWidth);
            } else {
                this.currentDashboardIndex = Math.floor((scrollEnd-frac) / this.dashboardWidth)+1;
            }
            if(previousDashBoardIndex !== this.currentDashboardIndex) {
                $('.shortcutsContainers').scrollTop(0);
            }
        }
        this.scrollToDashboard(true);
    }

    private scrollToDashboard(animate?:boolean) {
        if(DeviceUtils.isMobile()) {
            if(animate) {
                this.getScrollableArea().animate({scrollLeft : (this.currentDashboardIndex * this.dashboardWidth)+9}, 200);
            } else {
                this.getScrollableArea().scrollLeft((this.currentDashboardIndex * this.dashboardWidth)+9);
            }
        } else {
            this.getScrollableArea().scrollTop(0);
            if(animate) {
                this.getScrollableArea().animate({ scrollLeft : (this.currentDashboardIndex * this.dashboardWidth)+9});
            } else {
                this.getScrollableArea().scrollLeft((this.currentDashboardIndex * this.dashboardWidth)+9);
            }
            if(this.currentDashboardIndex === 0) {
                $('.dashboard-arrow.left').css({'display':'none'});
            } else {
                $('.dashboard-arrow.left').css({'display':'block'});
            }
            if(this.currentDashboardIndex === (this.dashboardList.length-1)) {
                $('.dashboard-arrow.right').css({'display':'none'});
            } else {
                $('.dashboard-arrow.right').css({'display':'block'});
            }
        }
        this.refreshDashboard();
    }

    private switchDashboard(left,evt) {
        if(left) {
            this.currentDashboardIndex = this.currentDashboardIndex-1;
        } else {
            this.currentDashboardIndex = this.currentDashboardIndex+1;
        }
        
        this.scrollToDashboard(true);
    }

    private getScrollableArea() : JQuery<HTMLElement> {
        if(DeviceUtils.isMobile()) {
            return $('body');
        } else {
            return $('.shortcutsContainers');
        }
    }

    private isIOS12() {
        const userAgent = navigator.userAgent.toLowerCase();
        const regex = /12(\.\d)+ mobile/;
        if(userAgent.indexOf('ipad') >= 0 && userAgent.match(regex)) {
            return true;
        } else {
            return false;
        }
    }
}

export default Desktop;
