import Renderer from 'views/listing/renderer/Renderer';
import HeaderListItem from 'views/listing/renderer/header/HeaderListItem';

class HeaderRenderer extends Renderer{

    initialize(options){
        super.initialize(options);
        this.template = require('ejs-loader!templates/listing/HeaderList.ejs');
        this.domainContext = options.domainContext;
        this.iconConfig = options.iconConfig;
        this.itemClass = options.itemClass;
        this.gridConfig = options.gridConfig;
    }

    renderCore(){
        this.element.html(this.template());
        this.element.addClass('header-list');

        let itemsContainer = this.element.find('.items-container');
        // Look for the first element that is scrollable.
        let scrollableElement = this.element;
        while(scrollableElement.css('overflow') === 'visible' && scrollableElement.parent().length === 1) {
            // If the current element is not scrollable and has a parent, use the parent
            scrollableElement = scrollableElement.parent();
        }
        
        scrollableElement.on('scroll', function(){
            if(itemsContainer.find('.loadMore').length > 0) {
                var element = itemsContainer.find('.loadMore')[0];
                var pageTop = $(window).scrollTop();
                var pageBottom = pageTop + $(window).height();
                var elementTop = $(element).offset().top;
                var elementBottom = elementTop + $(element).height();
                if((elementTop <= pageBottom) && (elementBottom >= pageTop)) {
                    itemsContainer.find('.loadMore').click();
                }
            }
        });
    }

    renderItems(rows, loadMoreCallback){
        var itemsViews = [];

        this.removeLoadMore();

        let itemsContainer = this.element.find('.items-container');

        var nbItems = itemsContainer.children('.header-item').length;
        var counter = 0;
        for(var rowId in rows){
            var newDiv = $('<div></div>').appendTo(itemsContainer);
            var newItem = new HeaderListItem({
                el : newDiv,
                row: rows[rowId],
                itemId: nbItems + counter,
                lineStyle: this.lineStyle,
                allowExpand: this.lineExpanse,
                domainContext: this.domainContext,
                popupMenu: this.popupMenu,
                iconConfig: this.iconConfig,
                itemClass: this.itemClass,
                gridConfig: this.gridConfig
            });
            newItem.render();
            newItem.isNew = true;
            itemsViews.push(newItem);
            counter++;
        }

        if(loadMoreCallback){
            this.addLoadMore(loadMoreCallback);
        }
        return itemsViews;
    }

    addLoadMore(loadMoreCallback){
        let itemsContainer = this.element.find('.items-container');

        var moar = $('<div class="loadMore">Plus de résultats</div>').appendTo(itemsContainer);
        var me = this;
        moar.on('click', function(evt){
            me.removeLoadMore();
            loadMoreCallback();
        });
    }

    removeLoadMore(){
        let itemsContainer = this.element.find('.items-container');

        itemsContainer.find('.loadMore').remove();
    }

    resetData(){
        let itemsContainer = this.element.find('.items-container');
        itemsContainer.empty();
    }
}

export default HeaderRenderer;