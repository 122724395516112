'use strict';

let $ = require('jquery');
import Server from 'server/Server';
import App from 'App';
import { SAIView } from '../../../Additions';
import StringUtils from 'utils/StringUtils';
import ColorUtils from 'utils/ColorUtils';
import PDFViewer from './viewers/PDFViewer';
import IMGViewer from './viewers/IMGViewer';

class NoteDownload extends SAIView {
    files : any;
    constructor(files : any, options? : any) {
        super(options);
        this.files = files;
    }

    initialize (options) {
        this.options = options;
        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.uniqId = StringUtils.guid();
    }

    render () : any {
        this.checkInitialized();
        this.$el = $(this.template({
            operationTitle : 'Téléchargement des notes',
            operationColor : App.getConfig().appTheme,
            uniqId : this.uniqId,
            allowDocs : false,
            hideSpinner : false,
            hideCloseButton : false,
            allowDismiss : true,
            hideTitle : false,
            extraClass: '',
            colorUtils: ColorUtils
        }));
        this.$el.find('.innerView').hide();
    }

    display() : any {
        //rendering popup
        this.render();

        var content = this.$el.find('.modal-body');
        content.addClass('grey');
        var noteId;
        var fileId;
        var me = this;
        for (noteId = 0; noteId < this.files.length; noteId++) {
            if(this.files[noteId].noteText !== undefined && this.files[noteId].noteText !== '') {
                var textElement = $('<div class="text-element"></div>');
                textElement.append(this.files[noteId].noteText.replace(new RegExp('\\[N\\]','g'),'<br>'));
                content.append(textElement);
            }
            for(fileId = 0; fileId < this.files[noteId].files.length; fileId++) {
                var document = this.files[noteId].files[fileId];
                var fileName = document.name + '.' + document.type;
                var downloadElement = $('<div class="download-element"></div>');
                downloadElement.append('<div class="fa icon '+document.type+'"></div><div class="filename">'+fileName+'</div><i class="fa fa-cloud-download action"></i>')
                content.append(downloadElement);
                var url = Server.getTokenedUrl(document.full);
                downloadElement.bind('click',{url : url, type: document.type},function(event) {
                    if (event.data.type === 'pdf') {
                        let viewer = new PDFViewer({
                            documentLink: event.data.url
                        });
                        viewer.display();
                    } else if (['jpg', 'bmp', 'png', 'jpeg', 'gif'].indexOf(event.data.type) >= 0) {
                        let viewer = new IMGViewer({
                            documentLink: event.data.url
                        });
                        viewer.display();
                    } else {
                        App.downloadDocument(event.data.url);
                    }
                    me.startAnimation($(event.currentTarget),{X:event.pageX, Y:event.pageY});
                });
                if (this.files[noteId].files.length === 1 && url.indexOf('.pdf')) {
                    //There is only one entry
                    setTimeout(function () {
                        downloadElement.click();
                    }, 300);
                }
            }
        }

        var footer = this.$el.find('.modal-footer');
        footer.addClass('grey');

        var header = this.$el.find('.modal-header');
        header.append('<i class="fa fa-times-circle modal-close"></i>');
        this.$el.find('.modal-close').on('click', this.hideModal.bind(this));

        this.$el.on('hidden.bs.modal', function () {
            //calling given callback
            $('#' + me.uniqId).remove();
        });
        this.$el.modal('show');
    }

    hideModal() {
        this.$el.modal('hide');
    }

    startAnimation(parent : JQuery<HTMLElement>, position) {
        //create .ink element if it doesn't exist
        if (parent.find('.ink').length === 0) {
            parent.prepend('<span class="ink"></span>');
        }

        var ink = parent.find('.ink');
        //incase of quick double clicks stop the previous animation
        ink.removeClass('animate');

        //set size of .ink
        if (!ink.height() && !ink.width()) {
            //use parent's width or height whichever is larger for the diameter to make a circle which can cover the entire element.
            var d = Math.max(parent.outerWidth(), parent.outerHeight());
            ink.css({ height: d, width: d });
        }

        //get click coordinates
        //logic = click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center;
        var x = position.X - parent.offset().left - ink.width() / 2;
        var y = position.Y - parent.offset().top - ink.height() / 2;

        //set the position and add class .animate
        ink.css({ top: y + 'px', left: x + 'px' }).addClass('animate');
    }
}

export default NoteDownload;