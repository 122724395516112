module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="pubFicheTitleInner">' +
((__t = (config.label)) == null ? '' : __t) +
'</div>';

}
return __p
}