module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="menu-item ' +
((__t = (enabled ? '' : 'disabled')) == null ? '' : __t) +
'" title="' +
((__t = (label)) == null ? '' : __t) +
'" data-action="' +
((__t = (action)) == null ? '' : __t) +
'" data-menucounter="' +
((__t = (menuCounter)) == null ? '' : __t) +
'">\n    <span class="menu-item-label"><p>' +
((__t = (label)) == null ? '' : __t) +
'</p></span><span class="menu-item-icon"></span>\n</div>';

}
return __p
}