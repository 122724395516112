module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class="item">\n    <tbody>\n        <tr>\n            <td class="iconPart" style="padding-right:10px; width:70px;">\n                <div class="iconsContainer">\n                    <img class="recordImage" style="background-color: ' +
((__t = (values.backgroundColor)) == null ? '' : __t) +
';" src="' +
((__t = (values.icon)) == null ? '' : __t) +
'">\n                </div>\n            </td>\n            <td class="textPart">\n                <div class="navBanner">' +
((__t = (values.newText)) == null ? '' : __t) +
'</div>\n                <div class="navValue">\n                </div>\n            </td>\n        </tr>\n    </tbody>\n</table>';

}
return __p
}