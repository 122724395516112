module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="menu-holder">\n    <div class="menu-mask"></div>\n    <div class="menu">\n        <div class="menu-item changeCompany">' +
((__t = (changeCompanyLabel)) == null ? '' : __t) +
'</div>\n        <div class="menu-item logout">' +
((__t = (logoutLabel)) == null ? '' : __t) +
'</div>\n    </div>\n</div>';

}
return __p
}