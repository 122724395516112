module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group">\n    <label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'</label>\n    <input type="text" class="form-control changeableField listCoupleInput" data-id="' +
__e( fieldState.getId() ) +
' " readonly ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' >\n    <i class="glyphicon glyphicon-list select-list"></i>\n    <div class="selectionRendering">\n        <div class="inner"></div>\n    </div>\n    <div class="selectionPopup" style="display: none;"></div>\n</div>\n';

}
return __p
}