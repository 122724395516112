'use strict';

import * as Backbone from 'backbone';

class DocumentModel extends Backbone.Model{
    url = function():string { return ''}

    constructor(options?) {
        super(options);
    }

    initialize(): void {
    };  

    validate(attrs, options) : void {
    }

    parse(response, options) : any {
        return response;
    }
};

export default DocumentModel;
