'use strict';

import * as Backbone from 'backbone';
let $ = require('jquery');
import App from 'App';
import Server from 'server/Server';
import { SAIView } from '../../Additions';
import { DomainContext } from '../../parametrage/DomainContext';
import { Task } from '../../parametrage/structures/Task';
import { TaskView } from './Task';

class TaskMenuView extends SAIView {
    entryMenu : any;
    innerIdCounter : Number;
    tasks : any;
    menuEntries : any;
    selected : any;
    domainContext: DomainContext;

    constructor(options){
        super(options);
        this.tagName = 'div';
        this.domainContext = options.domainContext;
        Backbone.View.apply(this, arguments);
    }

    initialize (options) {
        this.options = options;

        this.template = require('ejs-loader!templates/task/TaskMenu.ejs');
        this.entryMenu = require('ejs-loader!templates/task/TaskMenuEntry.ejs');
        this.innerIdCounter = 0;

        this.tasks = {};
        this.menuEntries = {};
    }

    render () : any {
        this.$el.html(this.template({
            //appLogo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK)
        }));
        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-home'),{h:60,w:60})
            .then((element: JQuery<HTMLElement>) => {
                element.addClass('task-menu-icon')
                element.on('click',App.backToDesktop.bind(App));
                this.$el.find('.homeLogo').append(element);
            });
        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-menu'),{h:60,w:60})
            .then((element: JQuery<HTMLElement>) => {
                element.addClass('task-menu-icon')
                element.on('click',App.displayMenu.bind(App));
                this.$el.find('.menuLogo').append(element);
            });
    }

    addTask (task) {
        let tasksHolder = this.$el.find('.open_tasks');
        var newId = task.getTaskInstanceId();

        let icon = this.mapTaskIconToFont(task);

        var newEntry:JQuery = $(this.entryMenu({
            innerId: newId,
            taskId: task.taskConfig.getId(),
            icon: icon,
            transform: ' '
        })).appendTo(tasksHolder);

        this.tasks[newId] = task;
        this.menuEntries[newId] = newEntry;

        newEntry.click(this.onEntryClicked.bind(this));

        this.getEntryIcon(newEntry.find('.mainImage'), icon);
    }

    mapTaskIconToFont(task){
        let taskConfig : Task = task.taskConfig;
        var type = taskConfig.getReadableType().toLowerCase();
        if(type === 'dossier' || type === 'simple'){
            return 'task_input';
        }else if(type === 'view'){
            return 'task_grid';
        }else if(type === 'calendar') {
            return 'task_agenda';
        }else if(type === 'list'){
            return 'task_list';
        }else{
            return 'cross_circle';
        }
    }

    getEntryIcon(newEntry : any, icon:string) {
        let me = this;
        let imgSrc = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-' + icon);
        let prom = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', imgSrc);
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.send();
        })
            .then((response) => me.handleGetImg([response,imgSrc])
            ).then((element) => {
                newEntry.append(element);
            }).catch((error) => {
                console.error(error);
            });
    }

    handleGetImg([response, imgSrc]) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (response.indexOf('<svg') >= 0) {
                var randNum = Math.floor(Math.random() * 100000);
                response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                var xmlSvg = $($.parseXML(response));
                var size = '60px';
                xmlSvg.find('svg').attr('class', 'task-menu-icon').attr('width', size).attr('height',size);
                resolve(xmlSvg.find('svg'));
            } else {
                let imgElement = $('<img class="task-menu-icon" src="'+imgSrc+'"/>');
                resolve(imgElement);
            }
        });
    }

    removeTask (task: TaskView): void {
        this.menuEntries[task.getTaskInstanceId()].remove();
        delete this.menuEntries[task.getTaskInstanceId()];
    }

    onEntryClicked (evt) {
        var id = $(evt.delegateTarget).data('id');
        App.showView(this.tasks[id]);
        this.setSelectedEntry(id);
    }

    setSelectedEntry (entryId) {
        if (this.selected) {
            this.selected.removeClass('selectedEntry');
            this.selected.find('#outerCircle').css('fill', this.selected.find('#circle2').attr('fill'));
        }

        this.selected = this.menuEntries[entryId];
        if (this.selected) {
            this.selected.addClass('selectedEntry');
            this.selected.find('#outerCircle').css('fill','#FFFFFF');
        }
    }

    requestTaskSpecificIconUpdate (entryId, url) {
        if (this.tasks[entryId]) {
            var img = this.menuEntries[entryId].find('image');
            var svg = this.menuEntries[entryId].find('.specificIcon');
            if (url === null || url === '') {
                svg.hide();
            } else {
                img.attr('xlink:href', url);
                svg.show();
            }
        }
    }
}

export default TaskMenuView;
