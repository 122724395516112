'use strict';

import { SAIView } from '../../Additions';
import Server from 'server/Server';
import User from 'User';
import App from 'App';
import I18NUtils from 'utils/i18n/I18NUtils';
import { DomainContext } from '../../parametrage/DomainContext';
import { Task } from '../../parametrage/structures/Task';
let $ = require('jquery');


class RecordHeaderView extends SAIView {
    private icon : string;
    private overlays: any;
    private headers: Headers;
    private values: {[field:string]: string}
    private headerMode: string;
    private domainContext : DomainContext;

    constructor(options?: any) {
        super(options);
        this.icon = options.icon;
        this.overlays = options.overlays;
        this.headers = options.headers;
        this.values = options.values ? options.values : {};
        this.headerMode = options.headerMode;
        this.domainContext = options.domainContext;
        this.template = require('ejs-loader!templates/RecordHeader.ejs');
    }

    setHeaders(headers: Headers) :void {
        this.headers = headers;
    }

    setValues(values:{[field:string]: string}) :void {
        this.values = values;
    }

    render():any {
        if(this.headerMode === Task.HEADER_DISPLAY_MODE_NONE) {
            this.$el.html(this.template({
            }));
        } else {
            this.$el.html(this.template({
                overlay: this.overlays,
                domainContext: this.domainContext,
                server: Server
            }));

            let iconSize;
            if(this.headerMode === Task.HEADER_DISPLAY_MODE_SMALL) {
                iconSize = 40;
            } else {
                iconSize = 60;
            }
            this.$el.find('.record-header-icon').addClass(this.headerMode.toLocaleLowerCase());
            this.$el.find('.record-header-texts').addClass(this.headerMode.toLocaleLowerCase());

            if($.isEmptyObject(this.values)) {
                if(this.headerMode === Task.HEADER_DISPLAY_MODE_SMALL) {
                    this.$el.find('.record-header-text').text(I18NUtils.getText('NO_RECORD_SELECTED',User.getLocale(),{}));
                } else {
                    this.$el.find('.record-header-title').text(I18NUtils.getText('NO_RECORD_SELECTED',User.getLocale(),{}));
                    this.$el.find('.record-header-text').text(I18NUtils.getText('SELECT_RECORD_FROM_MENU',User.getLocale(),{}));
                }
            } else {
                if(this.headerMode === Task.HEADER_DISPLAY_MODE_SMALL) {
                    this.$el.find('.record-header-text').text(this.values.title);
                } else {
                    this.$el.find('.record-header-title').text(this.values.title);
                    this.$el.find('.record-header-text').text(this.values.subtitle);
                }
            }
        }       
    }

} export default RecordHeaderView