'use strict';

import FieldView from '../views/record/Field';

import GroupView from '../views/record/Group';

import F_BF from '../views/record/subfields/Button';
import F_CBF from '../views/record/subfields/CheckBox';
import F_LF from '../views/record/subfields/Link';
import F_CF from '../views/record/subfields/Couple';

import F_DF from '../views/record/subfields/Date';
import F_DTF from '../views/record/subfields/DateTime';
import F_DTTF from '../views/record/subfields/Time';

import F_GIF from '../views/record/subfields/GedImage';
import F_HF from '../views/record/subfields/Hidden';
import F_LSF from '../views/record/subfields/ListEnum';
import F_GDO from '../views/record/subfields/GedDocument';

import F_NF from '../views/record/subfields/Number';
import F_SF from '../views/record/subfields/Enum';
import F_TF from '../views/record/subfields/Text';
import F_FF from '../views/record/subfields/Filter';
import F_TAF from '../views/record/subfields/TextArea';
import F_GCF from '../views/record/subfields/GPSCoordinates';
import F_FLF from '../views/record/subfields/Float';
import F_EIF from '../views/record/subfields/EnumIcon';

import F_PAS from '../views/record/subfields/Password';
import F_INT from '../views/record/subfields/Integer';
import F_YEA from '../views/record/subfields/Year';
import F_PHO from '../views/record/subfields/Phone';
import F_EMA from '../views/record/subfields/Email';
import F_LCF from '../views/record/subfields/ListCouple';
import F_FIF from '../views/record/subfields/File';

import F_SYF from '../views/record/subfields/System';
import F_PS from '../views/record/subfields/PrintSettings';

import FieldUnit from 'factory/FieldUnit';
import { DomainContext } from '../parametrage/DomainContext';
import { Field } from '../parametrage/structures/Field';

export class FieldFactory {

    private static fieldNameToObject(type : string) {
        switch(type) {
            case 'Button'          : return F_BF;
            case 'Link'            : return F_LF;
            case 'Checkbox'        : return F_CBF;
            case 'Couple'          : return F_CF;
            case 'Date'            : return F_DF;
            case 'DateTime'        : return F_DTF;
            case 'Time'            : return F_DTTF;
            case 'GEDImage'        : return F_GIF;
            case 'GEDDocument'     : return F_GDO;
            case 'Hidden'          : return F_HF;
            case 'ListEnum'        : return F_LSF;
            case 'Financial'       : return F_NF;
            case 'Enum'            : return F_SF;
            case 'Text'            : return F_TF;
            case 'MultiLineString' : return F_TAF;
            case 'Password'        : return F_PAS;
            case 'Integer'         : return F_INT;
            case 'Phone'           : return F_PHO;
            case 'ListCouple'      : return F_LCF;
            case 'SystemField'     : return F_SYF;
            case 'Filter'          : return F_FF;
            case 'GPSCoordinates'  : return F_GCF;
            case 'PrintSettings'   : return F_PS;
            case 'Float'           : return F_FLF;
            case 'Email'           : return F_EMA;
            case 'EnumIcon'        : return F_EIF;
            case 'File'            : return F_FIF;
            case 'Year'            : return F_YEA;
        };
        return function() {console.error('invalid type '+type);};
    }

    public static buildField<T extends FieldView>(fieldConfig : Field, displayMode, domainContext: DomainContext) : T {
        let type = fieldConfig.getObjectTypeFromFormatPresentation();
        var FieldDescriptor: any = this.fieldNameToObject(type);
        return new FieldDescriptor({
            config: fieldConfig,
            displayMode: displayMode,
            domainContext: domainContext
        });
    }

    public static locateFieldInGroup(groupView : GroupView, fields) : void {
        var rowMap = {};
        var autoMap = {};

        //Handling multiple fields on rows
        //Setting up the top and height position$
        let displayMode = groupView.getDisplayMode();
        for(var fIndex in fields){
            var currentField:FieldView = fields[fIndex];
            var currentPosition = currentField.getState().getPosition(displayMode);
            if(!currentPosition.visible || currentPosition.row == -99) {
                continue;
            }
            var fieldUnits = FieldUnit[displayMode];
            if(currentPosition.row > 0){
                if(!rowMap[currentPosition.row]){
                    rowMap[currentPosition.row] = [];
                    //Number of auto items
                    autoMap[currentPosition.row] = {
                        count: 0, // Number of auto in the row
                        fixedSpace: 0 // Total space already used in %
                    };
                }

                var fieldWidth = currentPosition.width;
                var fieldHeight = currentPosition.height;
                var value;
                if(/^\d+$/.test(fieldWidth)){
                    //Direct Unit
                    //Computing percentage of the screen taken by the fixedSpace
                    var groupWidth = groupView.$el.width();
                    value = fieldWidth * fieldUnits.col;
                    autoMap[currentPosition.row].fixedSpace = autoMap[currentPosition.row].fixedSpace + value;
                    currentField.getLocationProperties().fieldWidth = value+'px';
                }else if(fieldWidth.toLowerCase() === 'auto'){
                    //we need an extra iteration to resolve
                    autoMap[currentPosition.row].count = autoMap[currentPosition.row].count + 1;
                }else{
                    //direct percentage
                    currentField.getLocationProperties().fieldWidth = fieldWidth;
                    value = parseInt(fieldWidth.substring(0,fieldWidth.length - 1));
                    autoMap[currentPosition.row].fixedSpace = autoMap[currentPosition.row].fixedSpace + value;
                }

                let borders = fieldUnits.height - fieldUnits.fieldHeight;
                currentField.getLocationProperties().labelVisible = currentPosition.labelVisible;
                currentField.getLocationProperties().height = borders + fieldHeight * fieldUnits.fieldHeight;
                currentField.getLocationProperties().fieldHeight = fieldHeight * fieldUnits.fieldHeight;
                currentField.getLocationProperties().top = ((currentPosition.row -1) * fieldUnits.height) + fieldUnits.height/8; // As group will have an extra fieldHeight/4
                rowMap[currentPosition.row].push(currentField);
            }
        }
        //Setting up the left and width position
        for(var rowIdx in rowMap){
            var currentRow = rowMap[rowIdx];
            var currentMargin = 0;
            for(var fieldIdx in currentRow){
                var currentField: FieldView = currentRow[fieldIdx];
                var fieldUnt = FieldUnit[displayMode];
                var currentFieldPosition = currentField.getState().getPosition(displayMode);
                var fieldCol = currentFieldPosition.col;
                var labelCol = currentFieldPosition.labelColumn;

                if(/^\d+$/.test(fieldCol)){
                    // -1 is due to the fact that the parametrage always specifies 1 as the first column
                    currentField.getLocationProperties().fieldLeft = (fieldCol -1) * fieldUnt.col + 'px';
                }else if(fieldCol.toLowerCase() === 'auto'){
                    currentField.getLocationProperties().fieldLeft = currentMargin + '%';
                }else {
                    currentField.getLocationProperties().fieldLeft = fieldCol;
                }
                if (currentField.getLocationProperties().fieldLeft == '-99') {
                    currentField.getLocationProperties().fieldLeft = '-999';
                }

                if(/^\d+$/.test(labelCol)){
                    // -1 is due to the fact that the parametrage always specifies 1 as the first column
                    currentField.getLocationProperties().labelLeft = (labelCol -1) * fieldUnt.col + 'px';
                }else {
                    currentField.getLocationProperties().labelLeft = labelCol;
                }

                if(currentField.getLocationProperties().fieldWidth === undefined){
                    if(autoMap[rowIdx].width === undefined){
                        autoMap[rowIdx].width = (100 - autoMap[rowIdx].fixedSpace) / autoMap[rowIdx].count + '%';
                    }
                    //Auto field
                    currentField.getLocationProperties().fieldWidth = autoMap[rowIdx].width;
                }
                currentMargin = currentMargin + parseInt(currentField.getLocationProperties().fieldWidth.substring(0,currentField.getLocationProperties().fieldWidth.length - 1));
                
                if(currentField.getConfig().isCouple() && currentField.fieldConfig.getLinkedCouple().isFusion()) {
                    this.handleFusionCouple(currentField,fields);
                }
            }
        }

        //Moving the screen into proper position based on previously computed
        for(var rowId in fields){
            var currentRowField: FieldView = fields[rowId];
            var currentRowFieldPosition = currentRowField.getState().getPosition(displayMode);
            if(currentRowFieldPosition.row > 0){
                fields[rowId].renderPosition();
            }
        }
    };

    public static locateGroupinPanel(panel, groupView: GroupView, mode) : {left?: any, top?: any, width?: any, height?: any} {
        let position = groupView.getConfig().getPosition(mode);

        if(mode === 'phone'){
            return {
                left: (position.column*FieldUnit[mode].col),
                width: '100%'
            };
        }else{
            let positionHeigh = parseInt(position.height);
            let heightToSet = positionHeigh * FieldUnit[mode].height + FieldUnit[mode].height*3/4;
            if(mode === 'tablet'){
                heightToSet = (positionHeigh+1) * FieldUnit[mode].height - 3;
            }
            return {
                left: position.column.indexOf('%') > -1 ? position.column : ((position.column - 1)*FieldUnit[mode].col),
                top: ((position.row-1)*FieldUnit[mode].height),
                width: position.width.indexOf('%') > -1? position.width : position.width *FieldUnit[mode].col,
                height: heightToSet
            };
        }
    };

    private static handleFusionCouple(currentField: FieldView, fields: any) {
        let couple = currentField.fieldConfig.getLinkedCouple();
        let childrenIds = [];
        for(let child in couple.getFields()) {
            childrenIds.push(couple.getFields()[child].getId());
        }
        
        let minLeft;
        let currentWidth = 0;
        let unitType;
        for(let fieldIdx in childrenIds) {
            let coupleFieldView = fields[childrenIds[fieldIdx]];
            let pos = coupleFieldView.getLocationProperties();
            unitType = 'px';
            if(pos && pos.fieldLeft) {
                unitType = pos.fieldWidth.indexOf('%') > 0 ? '%' : 'px';
                if(minLeft) {
                    let currentLeft = this.getNumberValue(pos.fieldLeft);
                    if(currentLeft < minLeft) {
                        currentWidth = minLeft - currentLeft + currentWidth;
                        minLeft = currentLeft;
                    } else {
                        currentWidth = currentLeft - minLeft + this.getNumberValue(pos.fieldWidth);
                    }
                } else {
                    minLeft = this.getNumberValue(pos.fieldLeft);
                    currentWidth = this.getNumberValue(pos.fieldWidth);
                }
            } else {
                return;
            }
        }

        for(let fieldIdx in childrenIds) {
            let coupleFieldView = fields[childrenIds[fieldIdx]];
            let pos = coupleFieldView.getLocationProperties();
            pos.fieldWidth = currentWidth + unitType;
            pos.fieldLeft = minLeft + unitType;
        }
    }

    private static getNumberValue(strNum: string) : number {
        let regex = /(-?\d+)[p%]?/;
        let match = regex.exec(strNum);
        return parseInt(match[1]);
    }
}

//export default FieldFactory;