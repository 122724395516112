'use strict';

import Backbone from 'backbone';

import GridStatsEngine from 'factory/GridStatsEngine';
import LanguageUtils from 'utils/LanguageUtils';

import _ from 'lodash';
import * as d3 from 'd3';
import { SAIView } from '../../../Additions';

class GridStatsView extends SAIView {

    initialize(options) {
        this.options = options;
        this.checkMandatoryParameter(options, 'headers', 'columns headers in simple view');
        this.checkMandatoryParameter(options, 'rawData', 'columns headers in simple view');
        if (!options.graphConfigs) {
            this.options.graphConfigs = [];
        }
        this.currentCharts = [];
        this.interfaceVisible = true;

        this.template = require('ejs-loader!templates/viewtypes/GridStats.ejs');
    }

    render() {
        this.checkInitialized();
        this.$el.html(this.template({
            headers: this.options.headers,
            pluralize: LanguageUtils.pluralize,
            graphConfigs: this.options.graphConfigs
        }));

        this.mainPanel = this.$el.children('.statsPropertiesPanel');
        this.propertiesPanel = this.mainPanel.children('.propertiesPanel');
        this.canvasHolder = this.mainPanel.children('.graphDraw');

        this.graphParams = this.propertiesPanel.children('.graphParams');
        this.graphPreset = this.propertiesPanel.children('.graphPreset');
        this.graphVisual = this.propertiesPanel.children('.graphVisual');
        this.openSettings = this.propertiesPanel.children('.graphSettings');
        this.okBtn = this.propertiesPanel.children('.btn');

        this.okBtn.click(this.onOkBtnClick.bind(this));
        this.openSettings.click(this.onOpenSettings.bind(this));
        this.graphVisual.find('input:radio[name="r1"]').change(this.onPresentationChange.bind(this));

        this.graphTypePanel = this.graphParams.children('.graphTypePanel');

        this.inputRow = this.graphTypePanel.children('.inputRow');
        this.inputTypeElement = this.inputRow.find('.inputType');

        this.criteria1Row = this.graphTypePanel.children('.criteria1Row');
        this.criteria1Element = this.criteria1Row.find('.critere1select');
        this.criteria1Element.change(this.on1stCriteriaChange.bind(this));
        this.criteria1GroupBy = this.criteria1Row.find('select[id="critere1GroupType"]');
        this.criteria1GroupBy.change(this.on1stGroupByChange.bind(this));
        this.criteria1Slider = this.criteria1Row.find('.slider');
        this.criteria1Slider.slider();

        this.criteria2Row = this.graphTypePanel.children('.criteria2Row');
        this.criteria2Element = this.criteria2Row.find('.critere2select');
        this.criteria2Element.change(this.on2ndCriteriaChange.bind(this));
        this.criteria2GroupBy = this.criteria2Row.find('select[id="critere2GroupType"]');
        this.criteria2GroupBy.change(this.on2ndGroupByChange.bind(this));
        this.criteria2Slider = this.criteria2Row.find('.slider');
        this.criteria2Slider.slider();

        this.graphPreset.find('.presetConfig').change(this.onPresetChange.bind(this));

        this.showStackedCharts(false);
    }

    onOkBtnClick(evt) {
        this.initEngineAndDisplayGraph();
    }

    onZoomGraph(evt) {

    }

    onPresetChange(evt) {
        var index = parseInt($(evt.delegateTarget).val());
        if (this.interfaceVisible) {
            this.presetGraphConfig(index, false);
        } else {
            this.presetGraphConfig(index, true);
        }
    }

    presetGraphConfig(index, launch) {
        //We've to preselect the first one
        var currentPreset = this.options.graphConfigs[index];
        var currentInputCounter = 1;
        for (var key in currentPreset.columns) {
            var col = currentPreset.columns[key];
            if (col.type === 'input') {
                if (col.inputType === 'occurrence') {
                    this.inputTypeElement.val('#occ');
                } else {
                    this.inputTypeElement.val(col.id + '_' + col.inputType.toUpperCase());
                }
            } else if (col.type === 'criteria') {
                var currentcritInput = this['criteria' + currentInputCounter + 'Element'];
                var currentcritGroupBy = this['criteria' + currentInputCounter + 'GroupBy'];
                var currentcritRange = this['criteria' + currentInputCounter + 'Slider'];
                currentcritInput.val(col.id);
                currentcritInput.change();
                if (col.groupBy) {
                    currentcritGroupBy.val(col.groupBy.toUpperCase());
                    currentcritGroupBy.change();
                    currentcritRange.slider('setAttribute', 'value', parseInt(col.groupRange));
                    currentcritRange.slider('refresh');
                }
                currentInputCounter++;
            }
        }

        var selector = 'input:radio[value="' + currentPreset.series + '_' + currentPreset.type.toLowerCase() + '"]';

        this.graphVisual.find('input:radio[checked]').removeProp('checked');
        this.graphVisual.find(selector).prop('checked', true);

        if (launch) {
            this.initEngineAndDisplayGraph(currentPreset.name);
        }
    }

    onOpenSettings(evt) {
        this.setInterfaceVisible(true);
        this.$el.css({
            'overflow-y': 'hidden'
        });
    }

    on1stGroupByChange(evt) {
        var selected = this.criteria1GroupBy.find(':selected');
        var min = parseFloat(selected.data('min'));
        var max = parseFloat(selected.data('max'));
        this.populateCriteriaRange(1, min, max, 1);
    }

    on2ndGroupByChange(evt) {
        var selected = this.criteria2GroupBy.find(':selected');
        var min = parseFloat(selected.data('min'));
        var max = parseFloat(selected.data('max'));
        this.populateCriteriaRange(2, min, max, 1);
    }

    on1stCriteriaChange(evt) {
        this.criteria1GroupBy.html(''); //cleaning all entries from the list
        this.criteria1GroupBy.prop('disabled', true);
        this.resetCriteriaRange('1');
        var value = this.criteria1Element.val();

        this.populateCriteriaGrouping('1', value);
    }

    on2ndCriteriaChange(evt) {

        if (this.criteria2Element.val() !== '') {
            this.showStackedCharts(true);
        } else {
            this.showStackedCharts(false);
        }

        this.criteria2GroupBy.html(''); //cleaning all entries from the list
        this.criteria2GroupBy.prop('disabled', true);
        this.resetCriteriaRange('2');
        var value = this.criteria2Element.val();

        this.populateCriteriaGrouping('2', value);
    }

    populateCriteriaGrouping(critIndex, critVal) {
        if (critVal !== '') {
            var allHeaders = {};

            for (var key in this.options.headers) {
                var current = this.options.headers[key];
                allHeaders[current.dataIndex] = current;
            }

            var format = allHeaders[critVal].format;
            var groupBy;
            if (format === 'Number' || format === 'Integer') {
                groupBy = [
                    { value: 'NUM1', text: 'tranche de 1' },
                    { value: 'NUM10', text: 'tranche de 10', rangeMin: 1, rangeMax: 9 },
                    { value: 'NUM100', text: 'tranche de 100', rangeMin: 1, rangeMax: 9 },
                    { value: 'NUM1000', text: 'tranche de 1\'000', rangeMin: 1, rangeMax: 9 },
                    { value: 'NUM10000', text: 'tranche de 10\'000', rangeMin: 1, rangeMax: 9 },
                    { value: 'NUM100000', text: 'tranche de 100\'000', rangeMin: 1, rangeMax: 9 }];
            } else if (format === 'Date' || format === 'DateTime') {
                groupBy = [
                    { value: 'DATEDAY', text: 'jour', rangeMin: 1, rangeMax: 30 },
                    { value: 'DATESAMEMONTHDAY', text: 'jour du mois', rangeMin: 1, rangeMax: 7 },
                    { value: 'DATESAMEWEEKDAY', text: 'jour de la semaine', rangeMin: 1, rangeMax: 6 },
                    { value: 'DATEMONTH', text: 'mois', rangeMin: 1, rangeMax: 12 },
                    { value: 'DATESAMEMONTH', text: 'mois de l\'année', rangeMin: 1, rangeMax: 11 },
                    { value: 'DATEYEAR', text: 'année', rangeMin: 1, rangeMax: 5 },
                    { value: 'DATEHOURSOFDAY', text: 'heures du jour', rangeMin: 1, rangeMax: 23 }];
            }

            if (groupBy !== undefined) {
                this['criteria' + critIndex + 'GroupBy'].prop('disabled', false);
                for (var i = 0; i < groupBy.length; i++) {
                    var htmlToAp = [];
                    htmlToAp.push('<option value="');
                    htmlToAp.push(groupBy[i].value);
                    htmlToAp.push('"');
                    htmlToAp.push(' data-min="');
                    htmlToAp.push(groupBy[i].rangeMin);
                    htmlToAp.push('"');
                    htmlToAp.push(' data-max="');
                    htmlToAp.push(groupBy[i].rangeMax);
                    htmlToAp.push('"');
                    if (i === 0) {
                        htmlToAp.push(' selected');
                    }
                    htmlToAp.push('>');
                    htmlToAp.push(groupBy[i].text);
                    htmlToAp.push('</option>');
                    this['criteria' + critIndex + 'GroupBy'].append(htmlToAp.join(''));
                }

                this.populateCriteriaRange(critIndex, groupBy[0].rangeMin, groupBy[0].rangeMax, 1);
            }
        }
    }

    resetCriteriaRange(index) {
        var currentSlider = this['criteria' + index + 'Slider'];
        currentSlider.slider('setAttribute', 'min', 0);
        currentSlider.slider('setAttribute', 'max', 20);
        currentSlider.slider('setAttribute', 'value', 10);
        currentSlider.slider('setAttribute', 'enabled', false);
        currentSlider.slider('setAttribute', 'tooltip', 'hide');
        currentSlider.slider('refresh');
    }

    populateCriteriaRange(index, min, max, value) {
        var currentSlider = this['criteria' + index + 'Slider'];
        currentSlider.slider('setAttribute', 'min', min);
        currentSlider.slider('setAttribute', 'max', max);
        currentSlider.slider('setAttribute', 'value', value);
        currentSlider.slider('setAttribute', 'enabled', true);
        currentSlider.slider('setAttribute', 'tooltip', 'always');
        currentSlider.slider('setAttribute', 'tooltip_position', 'bottom');
        currentSlider.slider('refresh');
    }

    showStackedCharts(show) {
        if (show) {
            this.graphVisual.find('input:radio[value^="stacked"]').parent().show();
        } else {
            this.graphVisual.find('input:radio[value^="stacked"]').parent().hide();
            this.graphVisual.find('input:radio[value^="single"]').prop('checked', true);
        }
    }

    onPresentationChange(evt) {
        if (this.canvasHolder.is(':visible')) {
            //We're displaying graphs at the moment, we just have to present
            //everything again using the new presentation mode
            this.displayGraphs(this.latestSeries);
        }
    }

    initializeEngine() {
        if (!this.currentGraphEngine) {
            try {
                this.currentGraphEngine = new GridStatsEngine({
                    headers: this.options.headers,
                    inputType: this.inputTypeElement.val(),
                    criteria1: this.criteria1Element.val(),
                    criteria2: this.criteria2Element.val(),
                    grouping1: this.criteria1GroupBy.val(),
                    grouping2: this.criteria2GroupBy.val(),
                    range1: this.criteria1Slider.slider('getValue'),
                    range2: this.criteria2Slider.slider('getValue')
                });
                this.currentGraphEngine.setRawData(this.options.rawData);
            } catch (ex) {
                console.error(ex);
            }
        } else {
            this.currentGraphEngine.setInputType(this.inputTypeElement.val());
            this.currentGraphEngine.setCriteria1(this.criteria1Element.val());
            this.currentGraphEngine.setCriteria2(this.criteria2Element.val());
            this.currentGraphEngine.setGrouping1(this.criteria1GroupBy.val());
            this.currentGraphEngine.setGrouping2(this.criteria2GroupBy.val());
            this.currentGraphEngine.setRange1(this.criteria1Slider.slider('getValue'));
            this.currentGraphEngine.setRange2(this.criteria2Slider.slider('getValue'));
        }
    }

    setInterfaceVisible(value) {
        if (value) {
            this.graphPreset.show();
            this.graphParams.show();
            this.okBtn.show();
            this.canvasHolder.hide();
            this.openSettings.hide();
            this.interfaceVisible = true;
        } else {
            if (this.options.graphConfigs.length < 2) {
                this.graphPreset.hide();
            }
            this.graphParams.hide();
            this.okBtn.hide();
            this.canvasHolder.show();
            this.openSettings.show();
            this.interfaceVisible = false;
        }
    }

    initEngineAndDisplayGraph(title) {
        this.initializeEngine();
        var series = this.computeSeries();
        this.setInterfaceVisible(false);
        this.displayGraphs(series, title);
    }

    computeSeries() {
        var series = this.latestSeries = this.currentGraphEngine.generateSeries();
        return series;
    }

    displayGraphs(series, title) {
        var checked = this.$el.find('input:radio[name="r1"]:checked');
        var value = checked.val();
        var presentation = value.substring(value.indexOf('_') + 1, value.length);
        var stacked = value.substring(0, value.indexOf('_')) === 'stacked';
        //clearing previous charts
        for (var key in this.currentCharts) {
            this.currentCharts[key].destroy();
        }

        this.canvasHolder.empty();

        var firstEl; //There always will be some items in the array as we couldn't generate graph otherwise
        for (var key2 in series) {
            firstEl = series[key2];
            break;
        }

        var counter = 1;
        if (firstEl.children) {
            //double serie
            if (stacked) {
                var stackedSeriesText = title;
                if (!title) {
                    stackedSeriesText = this.inputTypeElement.find('option:selected').text() + ' par ' + this.criteria1Element.find('option:selected').text().toLowerCase();
                    stackedSeriesText += ' par ' + this.criteria2Element.find('option:selected').text().toLowerCase();
                }
                this.insertGraph(series, presentation, stackedSeriesText, stacked, true, counter);

            } else {
                var single = Object.keys(series).length === 1;
                for (var key3 in series) {
                    var specific = series[key3].label ? series[key3].label : key3;
                    var seriesText = title;
                    if (!title) {
                        seriesText = this.inputTypeElement.find('option:selected').text() + ' par ' + this.criteria1Element.find('option:selected').text().toLowerCase();
                        seriesText += ' par ' + this.criteria2Element.find('option:selected').text().toLowerCase() + ' ' + specific;
                    } else {
                        seriesText += ' ' + specific;
                    }

                    this.insertGraph(series[key3].children, presentation, seriesText, false, single, counter);
                    counter++;
                }
            }

        } else {
            var finalText = title;
            if (!title) {
                finalText = this.inputTypeElement.find('option:selected').text() + ' par ' + this.criteria1Element.find('option:selected').text().toLowerCase();
            }

            this.insertGraph(series, presentation, finalText, false, true, counter);
        }
        if (counter > 1) {
            this.$el.css({
                'overflow-y': 'scroll'
            });
        } else {
            this.$el.css({
                'overflow-y': 'hidden'
            });
        }

        this.canvasHolder.children('.graphZoom').click(this.onZoomGraph.bind(this));
    }

    insertGraph(series, presentation, title, stacked, single, id) {
        var holder = $('<div style="position: relative;"></div>').appendTo(this.canvasHolder);
        $('<h3 class="graphTitle">' + title + '</h3>').appendTo(holder);
        $('<span data-id="' + id + '" class="glyphicon glyphicon-fullscreen graphZoom" aria-hidden="true"></span>').appendTo(holder);

        var chartData = [
        ];

        if (stacked !== true) {
            for (var sKey in series) {
                chartData.push({
                    val: series[sKey].inputsValue,
                    name: series[sKey].label ? series[sKey].label : sKey,
                    order: series[sKey].order
                });
            }

            chartData = _.sortBy(chartData, function (item) {
                return item.order;
            });
        } else {
            chartData = series;
        }

        var chartName = presentation.capitalizeFirstLetter();
        var maxHeight = 400;
        var minRadius = 150;

        if (stacked === true) {
            chartName = 'Stacked' + chartName;
        }

        if (single === true) {
            var availableHeight = $(window).height() - this.canvasHolder.offset().top - 50;
            maxHeight = availableHeight;
            minRadius = availableHeight / 2 - 100;
        }

        var radius = Math.min(minRadius, (this.canvasHolder.width() - 100) / 2);

        var chartOptions = {
            outerRadius: minRadius,
            labelRadius: 1.2 * radius,
            scaleAngleFilter: Math.PI / 64,
            colorset: d3.scaleOrdinal(d3.schemeCategory20)
        };

        if (presentation === 'doughnut') {
            chartOptions.innerRadius = 0.7 * radius;
        }

        var new3dChart = new SAICharts({
            rootElement: $('<div>').appendTo(this.canvasHolder).toArray()[0],
            width: Math.floor(this.canvasHolder.width()) - 1,
            height: maxHeight - 25
        })[chartName](chartData, chartOptions);

        this.currentCharts.push(new3dChart);
    }

    onDOMUpdated() {
        if (this.options.graphConfigs.length > 0) {
            this.presetGraphConfig(0, true);
        }
    }
}

export default GridStatsView;
