import Formatter from 'models/listing/formatters/Formatter';
import { Header } from 'models/listing/Header';

import _ from 'lodash';

class DynamicGridFormatter extends Formatter{

    /**
     * The formatter contains a new definition of columns that
     * can be based on the grid ones or on new definitions
     */
    buildHeaders(gridHeaders){
        let headers = [];
        let formatterHeadersMapping = {};

        let headersMapping = {};
        for(let key in gridHeaders){
            headersMapping[gridHeaders[key].dataIndex] = gridHeaders[key];
        }

        let initHeaders = this.get('options').headers;
        let nbHeads = initHeaders? initHeaders.length : 0;

        let groupCounter = 0;

        //If there is no column definition in the formatter then there is absolutely nothing to display
        for (let headIdx = 0; headIdx < nbHeads; headIdx++) {
            let first = true;
            let currentEl = initHeaders[headIdx];

            if (!currentEl.type) {
                console.error('[formatter] Formatter element without type ' + JSON.stringify(currentEl));
                continue;
            }

            if (currentEl.type === 'column') {
                let newCol = this.parseFormatterColumn(currentEl, headersMapping);
                headers.push(newCol);
                formatterHeadersMapping[newCol.dataIndex] = newCol;
            } else if (currentEl.type === 'group') {
                groupCounter++;
                for (var i = 0; i < currentEl.cols.length; i++) {
                    currentEl.cols[i].groupId = groupCounter;
                    var newGrpCol = this.parseFormatterColumn(currentEl.cols[i], headersMapping);
                    if (newGrpCol !== null && first) {
                        newGrpCol.grouped = true;
                        first = false;
                    }
                    headers.push(newGrpCol);
                    formatterHeadersMapping[newGrpCol.dataIndex] = newGrpCol;
                }
            } else {
                console.error('Unknown element type ' + currentEl.type);
                continue;
            }
        }

        this.set('headersObject', new Header(headers));
        this.set('headersMapping', formatterHeadersMapping);

        return headers;
    }

    /**
     * formats a given set of source rows into formatted rows
     * default implementation returns the same set
     */
    formatRows(rows){
        let formattedRows = [];

        let topLevelRows = {};

        let { prebuiltTplsCond, prebuiltTplsExpr } = this.prebuildHeadersTemplates();
        let breaksSorts = this.get('sort');

        //generating breaks
        let currentArrayToBreak = rows;
        let currentBreakArray = [];
        let lvl = 0;
        let headerMapping = this.get('headersMapping');
        let allColumns = this.get('headersObject').getColumns();

        for(let sortKey = breaksSorts.length -1 ; sortKey >= 0; sortKey--){
            let { by, display, breaks } = breaksSorts[sortKey];
            let breaksMap = {};
            for(let entry in breaks){
                breaksMap[breaks[entry].key] = breaks[entry].value;
            }

            let currentValue;
            let currentBreak;
            for(let key in currentArrayToBreak){
                let toFormat = currentArrayToBreak[key];
                let newRow;
                if(lvl === 0){
                    newRow = {
                        chartValues: toFormat.chartValues,
                        icon: toFormat.icon,
                        overlay: toFormat.overlay,
                        styles: toFormat.styles,
                        columns: {}
                    };

                    this.computeRowValues(newRow.columns, toFormat.columns, prebuiltTplsCond, prebuiltTplsExpr, true, true);
                }else{
                    newRow = toFormat;
                }

                let currentBreakValue = newRow.columns[by].value;

                if(currentValue === undefined || currentBreakValue !== currentValue){

                    if(currentBreak !== undefined){
                        this.computeHumanValues(currentBreak, allColumns, display, by);
                    }

                    //new break
                    currentBreak = {
                        chartValues: toFormat.chartValues,
                        icon: null,
                        overlay: null,
                        styles: [],
                        columns: {},
                        rows: []
                    };

                    currentValue = currentBreakValue;
                    currentBreak.styles.push('ruptit'+(sortKey+1)+'g');
                    currentBreak.columns[display] = {
                        value : breaksMap[currentBreakValue]
                    };
                    currentBreakArray.push(currentBreak);
                }

                this.sumValuesInBreak(headerMapping, display, by, currentBreak, newRow);
                currentBreak.rows.push(newRow);
            }

            currentArrayToBreak = currentBreakArray;
        }

        return currentBreakArray;
    }

    computeHumanValues(currentBreak, allColumns, display, by){
        for(let headerKey in allColumns){
            let column = allColumns[headerKey];
            let dataIndex = column.getId();
            if(dataIndex !== display && dataIndex !== by && currentBreak.columns[dataIndex] && currentBreak.columns[dataIndex].humanValue === undefined){
                currentBreak.columns[dataIndex].humanValue = this.getHumanValue(currentBreak.columns[dataIndex].value, column);
            }
        }
    }

    sumValuesInBreak(headerMapping, display, by, currentBreak, newRow){
        for(let headerKey in headerMapping){
            if(headerKey !== display && headerKey !== by && headerMapping[headerKey].summable !== 'false' &&
                (headerMapping[headerKey].format === 'Number' || headerMapping[headerKey].format === 'Integer')){
                //we've to sum the values
                if(currentBreak.columns[headerKey] === undefined){
                    currentBreak.columns[headerKey] = {
                        value : 0
                    };
                }
                currentBreak.columns[headerKey].value += parseFloat(newRow.columns[headerKey].value);
            }
        }
    }
    prebuildHeadersTemplates(){
        let prebuiltTplsCond = {};
        let prebuiltTplsExpr = {};

        let headers = this.get('headersObject').getColumns();

        for (var headId = 0; headId < headers.length; headId++) {
            var current = headers[headId];
            this.buildTpls(current,prebuiltTplsCond,prebuiltTplsExpr);

        }

        return {
            prebuiltTplsCond: prebuiltTplsCond,
            prebuiltTplsExpr: prebuiltTplsExpr
        };
    }

    buildTpls(current, prebuiltTplsCond, prebuiltTplsExpr){
        if (!current.isHidden()) {
            if (current.getCondition()) {
                var condTpl = '<% if(' + current.getCondition() + '){%><%-\'true\'%><%}else{%><%-\'false\'%><%}%>';
                prebuiltTplsCond[current.getId()] = _.template(condTpl);
            }

            if (current.getExpr()) {
                var exprTpl = '<%-' + current.getExpr() + '%>';
                prebuiltTplsExpr[current.getId()] = _.template(exprTpl);
            }
        }
    }

    /**
     * The dynamic formatter computes everything client side
     */
    isLocal(){
        return true;
    }

    computeRowValues(row, currentRowData, conditionsTpls, expressionTpls, applySource, applyExpr){
        let headers = this.get('headersObject').getColumns();
        for (var headKey in headers) {
            var currentHeader = headers[headKey];
            if (applySource && currentHeader.getSource()) {
                //Direct retreival of value from raw item
                let sources = currentHeader.getSource().split(',');
                if (currentHeader.getCondition()) {
                    var tpl = conditionsTpls[currentHeader.getId()];
                    if (tpl(this.flattenValues(currentRowData)) === 'true') {
                        //condition success
                        this.computeSourceValue(currentHeader, sources, currentRowData, row);
                    } else {
                        row[currentHeader.getId()] = this.getNullValueFromFormat(currentHeader.getFormat());
                    }
                } else {
                    //No condition, just input value
                    this.computeSourceValue(currentHeader, sources, currentRowData, row);
                }
            } else if (applyExpr && currentHeader.getExpr()) {
                if (currentHeader.getCondition()) {
                    var tpl2 = conditionsTpls[currentHeader.getId()];
                    if (tpl2(this.flattenValues(currentRowData)) === 'true') {
                        //condition success
                        this.computeExprValue(currentHeader, expressionTpls, currentRowData, row);
                    } else {
                        row[currentHeader.getId()] = this.getNullValueFromFormat(currentHeader.getFormat());
                    }
                } else {
                    this.computeExprValue(currentHeader, expressionTpls, currentRowData, row);
                }
            }
        }
    }

    computeSourceValue(currentHeader, sources, currentRowData, row){
        for(let key = 0; key < sources.length; key ++){
            let humanValue;
            if(key === 0){
                row[currentHeader.getId()] = currentRowData[sources[key]];
                if(currentHeader.getFormat() === 'Number' || currentHeader.getFormat() === 'Integer'){
                    row[currentHeader.getId()].value = parseFloat(row[currentHeader.getId()].value);
                }
                humanValue = this.getHumanValue(currentRowData[sources[key]].value, currentHeader);
            }else{
                humanValue = row[currentHeader.getId()].humanValue + ' ' + this.getHumanValue(currentRowData[sources[key]].value, currentHeader);
            }
            row[currentHeader.getId()].humanValue = humanValue;
        }
    }

    computeExprValue(currentHeader, expressionTpls, currentRowData, row){
        row[currentHeader.getId()] = {
            value: expressionTpls[currentHeader.getId()](this.flattenValues(currentRowData))
        };
        row[currentHeader.getId()].humanValue = this.getHumanValue(row[currentHeader.getId()].value, currentHeader);

    }

    flattenValues (row) {
        var newRow = {};
        for (var key in row) {
            if (row[key].value !== undefined) {
                newRow[key.substring(4)] = row[key].value;
            } else {
                newRow[key.substring(4)] = row[key];
            }
        }
        return newRow;
    }

    getRuptureColumns(){
        return [this.get('options').sort[0].by];
    }
}

export default DynamicGridFormatter;
