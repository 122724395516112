'use strict';

import { DomainContext } from './parametrage/DomainContext';

class DomainManager {
    private static _instance: DomainManager;
    private domainContext: { [domainId:string] : DomainContext}

    private constructor() {
        this.domainContext = {};
    }

    public static get Instance(): DomainManager {
        return this._instance || (this._instance = new this());
    }

    public setContext(domainId: string, context: DomainContext): void {
        this.domainContext[domainId] = context;
    }

    public getContext(domainId: string): DomainContext {
        return this.domainContext[domainId];
    }

    public getCurrentContext(userDomain: string): DomainContext {
        return this.domainContext[userDomain];
    }
}

const singleInstance = DomainManager.Instance;

export default singleInstance;