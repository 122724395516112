import App from 'App';
import ModalView from 'views/modal/ModalView';

/**
 * Generic modal viewer that displays a link. See child classes for specific
 * rendering of various urls. It provides download and fullscreen capabilities.
 */
class DocumentViewer extends ModalView {
    protected body: JQuery<HTMLElement>;
    private exitFullBtn: JQuery<HTMLElement>;
    private fullBtn: JQuery<HTMLElement>;

    initialize(options) {
        super.initialize(options);
        this.checkMandatoryParameter(options, 'documentLink', 'The task to use');
        this.parameters.title = options.title;
    }

    public renderInnerPanel(element: JQuery<HTMLElement>) {
        if (this.parameters.title !== undefined) {
            var header = $('<div class="modal-header"></div>').appendTo(element);
            $('<div class="titleText">' + this.parameters.title + '</div>').appendTo(header);
        }
        element.css('max-height', $(window).height() - 50 - element.offset().top);
        this.body = $('<div class="modal-body"></div>').appendTo(element);
        var footer = $('<div class="modal-footer"></div>').appendTo(element);
        //Close Button.
        var closeBtn = $('<button type="button" role="cancel" class="btn btn-modal-cancel">Fermer</button>').appendTo(footer);
        closeBtn.one('click', this.onCloseClick.bind(this));
        //Download Button
        var downloadBtn = $('<button type="button" role="download" class="btn btn-modal-download">Télécharger</button>').appendTo(footer);
        downloadBtn.on('click', this.onDownloadClick.bind(this));
        //Fullscreen Button
        this.fullBtn = $('<button type="button" role="download" class="btn btn-modal-download">Plein Ecran</button>').appendTo(footer);
        this.fullBtn.on('click', this.onFullClick.bind(this, element));
        //Exit Button
        this.exitFullBtn = $('<button type="button" role="download" class="btn btn-modal-download" style="display:none;">Fenêtré</button>').appendTo(footer);
        this.exitFullBtn.on('click', this.onExitClick.bind(this, element));
        //Rendering pdf from given link
        this.renderLink(element);
    }

    /**
     * Generic rendering method to display the given link.
     * @param element The modal main inner content element.
     */
    protected renderLink(element: JQuery<HTMLElement>): void {}

    /**
     * Closes the preview and modal
     */
    private onCloseClick() {
        //This will allow the caller to know that the user pressed cancel on purpose
        this.$el.modal('hide');
        this.trigger('userCancelled');
    }

    /**
     * Download the pdf in another tab
     */
    private onDownloadClick() {
        App.downloadDocument(this.parameters.documentLink);
    }

    /**
     * Displays the document in fullscreen mode.
     * @param element The main inner panel of the modal that contains the
     * rendered document.
     */
    private onFullClick(element: JQuery<HTMLElement>) {
        this.fullBtn.hide();
        this.exitFullBtn.show();
        let htmlEl = element[0] as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };
        let fullProm = null;
        if (htmlEl.requestFullscreen) {
            fullProm = htmlEl.requestFullscreen();
        } else if (htmlEl.mozRequestFullScreen) {
            fullProm = htmlEl.mozRequestFullScreen();
        } else if (htmlEl.webkitRequestFullscreen) {
            fullProm = htmlEl.webkitRequestFullscreen();
        } else if (htmlEl.msRequestFullscreen) {
            fullProm = htmlEl.msRequestFullscreen();
        }
        if (fullProm !== null) {
            fullProm.then(() => {
                this.renderLink(element);
            })
        }
    }

    /**
     * Displays the document in window mode.
     * @param element The element that was previously in fullscreen mode and
     * that gets out of it.
     */
    private onExitClick(element: JQuery<HTMLElement>): void {
        this.exitFullBtn.hide();
        this.fullBtn.show();
        let fullDoc = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        }
        let fullProm = null;
        if (fullDoc.exitFullscreen) {
            fullProm = fullDoc.exitFullscreen();
        } else if (fullDoc.mozCancelFullScreen) { /* Firefox */
            fullProm = fullDoc.mozCancelFullScreen();
        } else if (fullDoc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            fullProm = fullDoc.webkitExitFullscreen();
        } else if (fullDoc.msExitFullscreen) { /* IE/Edge */
            fullProm = fullDoc.msExitFullscreen();
        }
        if (fullProm !== null) {
            fullProm.then(() => {
                this.renderLink(element);
            })
        }
    }
}

export default DocumentViewer;