'use strict';

import TextView from 'views/record/subfields/Text';

class EmailField extends TextView{
    initialize (options) {
        super.initialize(options);
    }

    setModelValue(field, value) {
        super.setModelValue(field, value);

        this.emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        this.valid = this.emailRegEx.test(value);

        if(!this.valid) {
            field.addClass('invalid');
        } else {
            field.removeClass('invalid');
        }
    }
}


export default EmailField;