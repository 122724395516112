'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';
import { DataField } from './DataField';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { AbstractTask } from './AbstractTask';
import { Task } from './Task';

/**
 * This class represents some styled data
 */
class DataCell {
    private value: string;
    private styles: Array<string>;

    constructor(_styledvalue: string) {
        this.styles = [];
        let styleReg = /<SC=(.+)>/g;

        //Extracting <SC=...> tags from the styled value
        let match = styleReg.exec(_styledvalue);
        while (match != null) {
            this.styles.push(match[1]);
            match = styleReg.exec(_styledvalue);
        }

        //Extracting the value from the styled value
        let dataReg = /(?:<SC=.+>)*(.*)/g;
        match = dataReg.exec(_styledvalue);

        //There might be no data at all
        this.value = match !== null ? match[1] : '';
    }

    public getValue(): string {
        return this.value;
    }

    public getStyles(): Array<string> {
        return this.styles;
    }
}

/**
 * This class represents a styled row of data. This is used as internal representation
 * of data returned by the server in a GridView
 */
class DataRow {
    private cells : Array<DataCell>;
    private columns : Array<Column>;
    private styles: Array<string>;

    constructor(_cells : string, _column: Array<Column>) {
        this.styles = [];
        this.cells = [];

        //Extracting <SL=...> tags from the styled value
        let styleReg = /<SL=(.+)>/g;        
        let match = styleReg.exec(_cells);
        while (match != null) {
            this.styles.push(match[1]);
            match = styleReg.exec(_cells);
        }

        //Extracting cell values from the styled row
        let dataReg = /(?:<SL=.+>)*(.*)/g;
        match = dataReg.exec(_cells);

        //There might be no data at all
        let cellsContent = match !== null ? match[1].split('[C]') : [];
        for(let cellEntry in cellsContent) {
            this.cells.push(new DataCell(cellsContent[cellEntry]));
        }
        this.columns = _column;
    }

    public getCells() : Array<DataCell> {
        return this.cells;
    }

    public getIndexedCell(index: number) : DataCell{
        if(index < 0 || index > this.cells.length) {
            return null;
        } else {
            return this.cells[index];
        }
    }

    public getIdentifiedCell(id: string) : DataCell {
        let index = 0;
        for(let key in this.columns) {
            if(this.columns[key].getId() === id) {
                return this.getIndexedCell(index);
            }
            index++;
        }

        //No column with the given id in the grid
        console.error('The requested column ' + id + ' does not exist in the grid.');
        return null;
    }
}

/**
 * This class allows to browse the content of a grid Column. It contains all the
 * helpers to retrieves the base attributes that are commonly required on a 
 * column.
 */
class Column {
    private attributes: {id:string, [key:string]:string };
    private datafield: DataField;
    private context: DomainContext;
    private subColumns: Array<Column>;

    constructor(_attributes: {id: string, [key:string]:string}, context: DomainContext) {
        this.context = context;
        this.attributes = _attributes;
        this.datafield = new DataField(this.attributes, null, null, null, this.context);
        this.subColumns = [];
        for(let subElId in this.getAttribute('elements') as any) {
            let subEl = this.getAttribute('elements')[subElId];
            this.subColumns.push(new Column(subEl, context));
        }
    }

    /**
     * @returns the attribute 'id' of the column
     */
    public getId(): string {
        return this.attributes['id'];
    }

    /**
     * @returns the attribute 'label' of the column
     */
    public getLabel(): string {
        return this.attributes['label'];
    }

    /**
     * @returns the attribute 'format' of the column
     */
    public getFormat(): string {
        return this.attributes['format'];
    }

    /**
     * @returns the size of the column
     */
    public getWidth(): number {
        if(this.attributes['width'] === undefined) {
            return 0;
        } else {
            return parseInt(this.attributes['width']);
        }
    }

    public getDatafieldId(): string {
        return this.attributes['datafieldId'];
    }

    /**
     * This function retrieves any attribute value that might
     * have been defined in the Column entry. It'll return undefined if not set
     * @param attributeName The string identifier of the attribute to fetch
     * @returns The value of the attribute
     */
    public getAttribute(attributeName: string): string {
        return this.attributes[attributeName];
    }

    public getDataField(): DataField {
        return this.datafield;
    }

    public setDataField(df:DataField): void {
        this.datafield = df;
    }

    public getSubColumns(): Array<Column> {
        return this.subColumns;
    }
}

/**
 * This class represents a Grid of data from the parametrage. A gridview is supposed to be
 * uniquely idenfied by its name. It contains a list of Columns and might contain some data. 
 */
class GridView extends ConfigurationElement {
    private columns : Array<Column>;
    private rows: Array<DataRow>;
    private contextTask : string;

    constructor(config: { id: string, label: string, elements : Array<{id:string, [key: string]: string}>, data: string, contextTask ?:string }, context: DomainContext ) {
        super(config, context);
        this.contextTask = config.contextTask;
        this.columns = [];
        for(let key in config.elements) {
            this.columns.push(new Column(config.elements[key], context));
        }

        let rowsData = config.data? config.data.split('[L]') : [];
        this.rows = [];
        for(let key in rowsData) {
            this.rows.push(new DataRow(rowsData[key], this.columns));
        }
    }

    initialize() : Promise<any> {
        let me = this;
        return new Promise((accept) => {
            let datafieldsPromises = [];
            if(this.contextTask !== undefined) {
                CClientConfiguration.getTask(this.contextTask, this.getContext())
                    .then((taskConfig) => {
                        datafieldsPromises = this.generateDatafieldConfigRequest(taskConfig as Task);
                        Promise.all(datafieldsPromises)
                            .then((datafields) => {
                                for(let dfIndex in datafields) {
                                    let datafield = datafields[dfIndex];
                                    let column = this.getColumnByIndex(dfIndex);
                                    let df = this.mergeDatafieldWithGridConfig(datafield, dfIndex);
                                    column.setDataField(df);
                                }
                                accept(undefined);
                            });
                    });
            } else {
                datafieldsPromises = this.generateDatafieldConfigRequest(undefined);
                Promise.all(datafieldsPromises)
                    .then((datafields) => {
                        for(let dfIndex in datafields) {
                            let datafield = datafields[dfIndex];
                            let column = this.getColumnByIndex(dfIndex);
                            let df = this.mergeDatafieldWithGridConfig(datafield, dfIndex);
                            column.setDataField(df);
                        }
                        accept(undefined);
                    });
            }
        });
    }

    private generateDatafieldConfigRequest(taskConfig: Task) {
        let promises = [];
        for(let colId in this.getColumns()) {
            let column = this.getColumns()[colId];
            if(column.getAttribute('objectType') === 'GridColumn') {
                promises.push(
                    CClientConfiguration.getDataField(column.getDatafieldId(),this.getContext(), taskConfig)
                );
            } else if(column.getAttribute('objectType') === 'GridLevel') {
                let elements:any = column.getSubColumns();
                for(let id in elements) {
                    let subfield = elements[id];
                    if(subfield.objectType === 'GridColumn') {
                        promises.push(CClientConfiguration.getDataField(subfield.datafieldId,this.getContext(), taskConfig));
                    }
                }
            }
        }
        return promises;
    }

    /**
     * @returns The full list of all Columns that are stored within the gridview
     */
    public getColumns() : Array<Column>{
        return this.columns;
    }

    /**
     * @param id The unique identifier of a Column within the GridView
     * @returns The Column uniquely identified by the given id. Null otherwise 
     */
    public getColumn(id: string) : Column {
        for(var key in this.columns){
            let currentCol = this.columns[key];
            if(currentCol.getId() === id) {
                return currentCol;
            }
        }

        return null;
    }

    public getColumnByDataFieldId(id: string) : Column {
        for(var key in this.columns){
            let currentCol = this.columns[key];
            if(currentCol.getDatafieldId() === id) {
                return currentCol;
            } else if(currentCol.getAttribute('objectType') === 'GridLevel') {
                let elements:any = currentCol.getSubColumns();
                for(let subId in elements) {
                    let subfield = elements[subId];
                    if(subfield.datafieldId === id) {
                        return subfield;
                    }
                }
            }
        }
        return null;
    }

    public getColumnByIndex(index: string) : Column {
        return this.columns[index];
    }

    public getRows() : Array<DataRow> {
        return this.rows;
    }

    public getDatafieldKeyColumns() {
        let result = [];
        for(let i in this.columns) {
            let column = this.columns[i];
            if(column.getDataField() && column.getDataField().isKey()) {
                result.push(column.getDataField());
            }
        }
        return result;
    }

    private mergeDatafieldWithGridConfig(datafield: DataField, index: string) {
        let columnDatafield = undefined;
        if(datafield !== undefined) {
            columnDatafield = new DataField(datafield.getClonedConfig(), datafield.getDataPage(), datafield.getTable(), datafield.getTask(), datafield.getContext());
        } else {
            columnDatafield = new DataField({ id: 'DUMMY_DF', format: 'String' }, undefined, undefined, undefined, this.getContext());
        }
        let col = this.getColumnByIndex(index);
        if(col.getLabel() !== undefined) {
            columnDatafield.setLabel(col.getLabel());
        }
        if(col.getFormat() !== undefined) {
            columnDatafield.setFormat(col.getFormat());
        }

        if(col.getAttribute('enumName') !== undefined) {
            columnDatafield.setEnumName(col.getAttribute('enumName'));
        }
        return columnDatafield;
    }
}

export { GridView, Column, DataRow, DataCell };