'use strict';

import { DataField } from './DataField';
import { ConfigurationElement } from './ConfigurationElement';
import { DomainContext } from '../DomainContext';
import { Table } from './Table';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';

class DataPage extends ConfigurationElement {

    private datafields: { [dfId: string] : DataField };
    private datafieldsArr: Array<DataField>;
    private parentTable: Table;
    private static ASYNC_PROP_LABEL:Symbol = Symbol();

    constructor(config: { id: string, datafields: Array<{ id: string, label: string, [dfProp:string]: string | number | boolean}>}, parentTable: Table, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [ DataPage.ASYNC_PROP_LABEL];
        this.parentTable = parentTable;
        this.datafields = {};
        this.datafieldsArr = [];
        for(let key in config.datafields) {
            let newDf = new DataField(config.datafields[key], this, null, null, context);
            this.datafields[newDf.getId()] = newDf;
            this.datafieldsArr.push(newDf);
        }

        let parentId = parentTable.getId();
        let currentId = this.getId();
        let dPagePrefix = parentId.substring(0,6) + '.' + currentId;
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_CREATION_DATE', 'DateTime');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_CREATION_BY', 'String');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_MODIFICATION_DATE', 'DateTime');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_MODIFICATION_BY', 'String');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_VERSION', 'Integer');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_NUMBER', 'Integer');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_START_DATE', 'DateTime');
        this.createBaseDataField(dPagePrefix, 'FM_PAGE_END_DATE', 'DateTime');
    }

    public getTable(): Table {
        return this.parentTable;
    }

    public getThemeID(): string {
        return this.getValueOrDefault('themeId');
    }

    public isSingle(): boolean {
        return this.getBooleanValueOrDefault('isSingle', true);
    }

    public isShared(): boolean {
        return this.getBooleanValueOrDefault('isShared', false);
    }

    public getExistenceConditions(): string {
        return this.getValueOrDefault('existence');
    }

    public initializeI18N(): Promise<Array<string>> {
        let allPromises = [];
        let me = this;
        if(this.hasAsyncRequirements()) {
            //We need to fetch the resources
            let i18NID = this.parentTable.getId().substring(0,6) + '.' + this.getId();
            let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'table', i18NID, User.getLocale());
            i18NTextPromise.then((i18NText) => {
                if(i18NText !== undefined) {
                    me.setLabel(i18NText);
                }
                me.removeFromAsyncRequirement(DataPage.ASYNC_PROP_LABEL);
            });
            allPromises.push(i18NTextPromise);
        }
        //All datafield must be initialized as well
        for(let dfId in this.datafields) {
            let someProm = this.datafields[dfId].initializeI18N();
            if(someProm != null) {
                allPromises.push(someProm);
            }
        }

        return allPromises.length > 0 ? Promise.all(allPromises) : null;
    }

    /**
     * @returns If the page has to call the server upon creation
     */
    public canCallOnPrepareNewPage(): boolean {
        return this.getBooleanValueOrDefault('callOnPrepareNewPage', false);
    }

    public getDataField(dfId: string): DataField {
        return this.datafields[dfId];
    }

    public getDataFields(): Array<DataField> {
        return this.datafieldsArr;
    }

    private createBaseDataField(prefix: string, datafieldId: string, format: string){
        let dfId = prefix + '.' + datafieldId;
        let existingDf: DataField = this.getDataField(dfId);
        if(existingDf === undefined){
            //The datafield hasn't be defined yet, we need to create it
            existingDf = new DataField({
                id: dfId,
                label: dfId,
                source: 'DataBaseDossier',
                autoAddedSystemDF: true,
                format: format,
                showSeconds: format === 'DateTime' || format === 'Time'
            }, this, null, null, this.getContext());
            this.datafields[existingDf.getId()] = existingDf;
            this.datafieldsArr.push(existingDf);
        }
    }

    getIcon(): string{
        return this.getValueOrDefault('icon', 'page');
    }
}

export { DataPage }
