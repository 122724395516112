'use strict';

import { ConfigurationElement } from './ConfigurationElement';
import { Table } from './Table';
import { DomainContext } from '../DomainContext';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import StringUtils from 'utils/StringUtils';

class Theme  extends ConfigurationElement{
    private parentTable: Table;
    private static ASYNC_PROP_LABEL: Symbol = Symbol();

    constructor(config: { id: string, [key: string] : string | number | boolean}, parentTable: Table, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [Theme.ASYNC_PROP_LABEL];

        //Linking with parent structure
        this.parentTable = parentTable; //Table datafield
    }

    public getTable(): Table {
        return this.parentTable;
    }

    public initializeI18N(): Promise<string> {
        let me = this;

        if(this.hasAsyncRequirements()) {
            //We need to fetch the resources
            let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'table', this.parentTable.getId().substring(0,6) + '.' + this.getId(), User.getLocale());
            i18NTextPromise.then((i18NText) => {
                if(i18NText !== undefined) {
                    me.setLabel(i18NText);
                    me.removeFromAsyncRequirement(Theme.ASYNC_PROP_LABEL);
                }
            });
            return i18NTextPromise;
        } else {
            return null;
        }
    }

    public getOrder(): number {
        return this.getNumberValueOrDefault('order', 9999);
    }

    public getIcon(): string {
        return StringUtils.basename(this.getValueOrDefault('icon'));
    }

    public getParentTable(): Table {
        return this.parentTable;
    }
}

export { Theme }