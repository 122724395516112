module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var visibleCol = 0; ;
__p += '\n';
 for(var grp = 0; grp < headers.length; grp++){ ;
__p += '\n    ';
 var currentGroup = headers[grp] ;
__p += '\n    ';
 var first = true; ;
__p += '\n    ';
 for(var i = 0; i < currentGroup.getColumns().length; i++) { ;
__p += '\n        ';
 var currentCol = currentGroup.getColumns()[i]; ;
__p += '\n\n        ';
 if( ! currentCol.isHidden() && ! _.includes(ruptCols, currentCol.getId())) { ;
__p += '\n            ';
 visibleCol++ ;
__p += '\n            ';
 if(grp != 0 && first){ ;
__p += '\n                ';
 first = false; ;
__p += '\n                <td class="col-splitter"/>\n            ';
 } ;
__p += '\n            ';
 var current = row.getColumnsValues()[currentCol.getId()]; ;
__p += '\n            \n            <td class="dynamicGridValue" style="' +
__e(getHeaderStyle(currentCol, true)) +
'' +
__e( getRowStyle(row, visibleCol)) +
'"> \n                ';
 if(current) {;
__p +=
((__t = (current.humanValue !== undefined? current.humanValue : current.value)) == null ? '' : __t);
 } ;
__p += ' \n            </td>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n';
 } ;


}
return __p
}