import { ConfigurationElement } from './ConfigurationElement';
import { Task } from './Task';
import { Group } from './Group';
import { Level } from './Level';
import { Field } from './Field';
import { DomainContext } from '../DomainContext';

'use strict';

class Couple extends ConfigurationElement{
    private parentTask: Task;
    private parentGroup: Group;
    private parentLevel: Level;
    private children: Array<Field>;

    protected config: { id: string, label?: string, [propId:string]: any };

    constructor(config: {id : string, [propId: string]: any}, parentTask: Task, parentGroup: Group, parentLevel: Level, context: DomainContext) {
        super(config, context);
        this.parentGroup = parentGroup;
        this.parentTask = parentTask;
        this.parentLevel = parentLevel;
        this.children = [];
        for(let key in config.children) {
            let childConf = config.children[key];
            let cType = Couple.getObjectType(childConf.objectType);
            if(cType === Group.CHILD_TYPE_FIELD) {
                let newChild = new Field(childConf, parentTask, parentGroup, parentLevel, context);
                this.children.push(newChild);
                newChild.setLinkedCouple(this);
            } else {
                console.warn('Invalid child type in couple : ' + cType);
            }
        }
    }

    public getFields(): Array<Field> {
        return this.children;
    }

    public getFilter(): string {
        return this.getValueOrDefault('filter', 'DEFAULT_FILTER');
    }

    public getGrid(): string {
        return this.getValueOrDefault('gridView');
    }

    public getForeignKey(): string {
        return this.getValueOrDefault('key');
    }

    public getLocalKey(): string {
        return this.getValueOrDefault('keyVar');
    }

    public getOpenWithTask(): string {
        return this.getValueOrDefault('openWith');
    }

    public isFusion(): boolean {
        return this.getBooleanValueOrDefault('fusion');
    }

    public isSwitched(): boolean {
        return this.getBooleanValueOrDefault('switch');
    }

    public getKeyField(): Field {
        for(let key in this.children) {
            let curField = this.children[key];
            if(curField.getDatafieldId() === this.getForeignKey()) {
                return curField;
            }
        }
        throw new Error('Unable to locate key field in couple');
    }

    public getLabelField(): Field {
        for(let key in this.children) {
            let curField = this.children[key];
            if(curField.getDatafieldId() !== this.getForeignKey()) {
                return curField;
            }
        }
        throw new Error('Unable to locate label field in couple');
    }
}

export { Couple };