module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="task-panel">\n    <div class="taskHeader"></div>\n    <div class="taskBody">\n        <div class="recordBanner" style="display:none;"></div>\n        <div class="navigatorContainer"></div>\n        <div class="dossierHeader"></div>   \n        <div class="dossierRow ' +
__e( displayMode === 'desktop' ? 'row' : '') +
'">\n            <div class="pagesNavigatorContainer" style="display:none;"></div>\n            <div class="dossierRecordContainer" style="display:none;"></div>\n        </div>\n        <div class="navTabBar"></div>\n        <div class="pageNavTabBar" style="display: none;"></div>\n        <div class="pageTabBar" style="display: none;"></div>\n        <div class="ficheContainer" style="display: none;"></div>\n        <div class="albumContainer" style="display: none;"></div>\n    </div>\n</div>';

}
return __p
}