module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group gedImage-form-group">\n    <label for="' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'</label>\n    <input class="gedImageFile" type="file" accept="image/jpeg" capture="camera">\n    <div class="gedImageNew">\n        ';
 if(this.getGedImageState() === 'display') { ;
__p += '\n            <div class="gedImageInner" style="background-image: url(\'' +
((__t = (server.getTokenedUrl(this.getFileImage(this.noteIndex,this.imgIndex)))) == null ? '' : __t) +
'\')">\n            </div>\n        ';
 } else if(this.getGedImageState() === 'new') { ;
__p += '\n            <div class="gedImageInner" style=""></div>  \n            <p style="" class="gedImageInsertText">Insérer une image</p>\n        ';
 } else if(this.getGedImageState() === 'progress') { ;
__p += '\n            <div class="gedImageInner" style=""></div>\n            <progress min="0" max="100" value="' +
((__t = (this.getProgress())) == null ? '' : __t) +
'"><span>' +
((__t = (this.getProgress())) == null ? '' : __t) +
'</span>%</progress>\n        ';
 } else { ;
__p += '\n            <div class="gedImageInner" style="background-image: url(\'' +
((__t = (this.getBackgroundImage())) == null ? '' : __t) +
'\')"></div>\n            <i class="fa fa-check-square-o"></i>\n        ';
 } ;
__p += ' \n        <div class="actions" style="display: none;">\n            <i class="fa fa-plus-square edit readonly-hide"></i>\n            <i class="fa fa-search-plus zoom readonly-show"></i>\n            <i class="fa fa-trash delete readonly-hide"></i>\n        </div>\n        <div class="fa fa-chevron-circle-left previous" style="display: none;"></div>\n        <div class="fa fa-chevron-circle-right next" style="display: none;"></div>\n    </div>\n</div>';

}
return __p
}