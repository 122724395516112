'use strict';

import App from 'App';
import Server from 'server/Server';
import FieldView from '../Field';
import PageIconPreview from 'views/tasks/navigators/pages/PageIconPreview';
import {Note, NoteFile} from 'models/Note';
import PendingDocument from '../../../models/PendingDocument';
import User from 'User';
const moment = require('moment');

class GedImageField extends FieldView {
    private noteIndex: number;
    private imgIndex: number;
    private upFilesIds: Note[];
    private actionsVisible: boolean;
    private fileUp: JQuery<HTMLElement>;
    private uploadFileName: string;
    private uploadFileType: string;
    private state: string;
    private backgroundImage: string;
    private progress: number;

    constructor(options) {
        super(options);
        this.noteIndex = 0;
        this.imgIndex = 0;
        this.state = 'new';
        this.backgroundImage = '';
        this.upFilesIds = [];
        this.on('imageResized', this.onImageResized.bind(this));
    }

    render(){
        let notes = this.fieldState.getNotes();
        if(this.state === 'new' && this.hasFiles()) {
            this.state = 'display';
        }

        super.render();
        if(notes.length === 0 && this.fieldConfig.isReadOnly()) {
            // Readonly without picture
            this.$el.hide();
        }
        this.actionsVisible = false;

        this.fileUp = this.$el.find('.gedImageFile');
        this.fileUp.change(this.onNewFileChange.bind(this));

        this.$el.find('.gedImageNew').on('click', this.onClickRequest.bind(this));
        
        var actions = this.$el.find('.actions');
        actions.find('.edit').on('click', this.editPhoto.bind(this));
        actions.find('.zoom').on('click', this.zoomPhoto.bind(this));
        actions.find('.delete').on('click',this.deletePhoto.bind(this));
        
        var previous = this.$el.find('.previous');
        previous.on('click',this.previous.bind(this));

        var next = this.$el.find('.next');
        next.on('click',this.next.bind(this));

        this.displayNavigationArrows();
    }

    editPhoto(){
        this.fileUp.click();
    }

    zoomPhoto(){
        var newPreview = new PageIconPreview({
            pageIcon : this.getFullFileImage(this.noteIndex,this.imgIndex)
        });
        newPreview.render();
    }

    deletePhoto(){
        let note = this.getNote(this.noteIndex);
        this.addFileOperation(this.noteIndex, note.getFiles()[this.imgIndex].getName(),null,'delete');
        note.deleteFile(this.imgIndex);
        this.imgIndex--;
        if(this.imgIndex < 0) { this.imgIndex = 0;}
        if(!this.hasFiles()) {
            this.state = 'new';
        } else {
            this.backgroundImage = Server.getTokenedUrl(this.getFileImage(this.noteIndex,this.imgIndex));
        }
        this.render();
        this.$el.trigger('SAIFieldUpdate');
    }

    previous() {
        event.stopPropagation();
        this.imgIndex--;
        this.backgroundImage = Server.getTokenedUrl(this.getFileImage(this.noteIndex,this.imgIndex));
        this.render();
    }

    next() {
        event.stopPropagation();
        this.imgIndex++;
        this.backgroundImage = Server.getTokenedUrl(this.getFileImage(this.noteIndex,this.imgIndex));
        this.render();
    }

    onClickRequest(){
        var currentState = this.state;
        if(currentState === 'new'){
            if( !this.fieldConfig.isReadOnly()) {
                this.editPhoto();
            }
        }else if(currentState !== 'progress'){
            if(this.actionsVisible){
                this.hideActions();
                this.actionsVisible=false;
            }else{
                this.showActions();
                this.actionsVisible=true;
            }
        }
    }

    showActions(){
        this.$el.find('.actions').show();
        if(this.fieldConfig.isReadOnly()) {
            this.$el.find('.readonly-hide').hide();
        }
    }

    hideActions(){
        this.$el.find('.actions').hide();
    }

    displayNavigationArrows() {
        let notes = this.fieldState.getNotes();
        if (notes.length > 0) {
            if (this.imgIndex > 0) {
                this.$el.find('.previous').show();
            } else {
                this.$el.find('.previous').hide();
            }

            if (this.imgIndex < notes[this.noteIndex].getFiles().length - 1) {
                this.$el.find('.next').show();
            } else {
                this.$el.find('.next').hide();
            }
        }
    }

    onNewFileChange (evt) {
        var me = this;
        // Read in file
        var file = evt.target.files[0];
        this.uploadFileName = file.name;
        this.uploadFileType = file.type;
        if(this.uploadFileType.match(/image\//)) {
            var reader = new FileReader();
            reader.onloadend = function (readerEvent: any) {
                var image = new Image();
                image.onload = function (imageEvent) {
                    // Resize the image
                    var canvas = document.createElement('canvas'),
                        maxSize = 1024,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    // for now, we only use jpeg but the goal is to use me.uploadFileType when the server will accept other formats
                    var dataUrl = canvas.toDataURL('image/jpeg','1.0');
                    var resizedImage = me.dataURLToBlob(dataUrl);
                    me.trigger('imageResized', resizedImage, me.uploadFileName);
                }
                image.src = readerEvent.target.result;
            }
            reader.readAsDataURL(file);
        } else {
            App.displayErrorMessage({title: 'Chargement impossible', body: 'Veuillez sélectionner une image valide'});
        }
    }

    dataURLToBlob(dataUrl) {
        var BASE64_MARKER = ';base64,';
        var parts;
        var contentType;
        var raw;
        if (dataUrl.indexOf(BASE64_MARKER) == -1) {
            parts = dataUrl.split(',');
            contentType = parts[0].split(':')[1];
            raw = parts[1];
            return new Blob([raw], {type: contentType});
        }

        parts = dataUrl.split(BASE64_MARKER);
        contentType = parts[0].split(':')[1];
        raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    }

    onImageResized (blob, filename) {
        var newFile = new File([blob],filename);
        this.state = 'progress';
        this.progress = 0;
        this.render();
        App.putFile(newFile, this.onFileAdded.bind(this), this.onFileProgress.bind(this), this.domainContext);
    }

    getProgress(): Number {
        return this.progress;
    }

    onFileAdded (resp) {
        this.state = 'uploaded';
        if (resp.thumbnail) {
            this.backgroundImage = Server.getTokenedUrl(resp.thumbnail);
            this.addFileImage(this.noteIndex,resp.thumbnail);
            this.addFileOperation(this.noteIndex,null,resp.id, 'new');
            this.imgIndex = this.getNote(this.noteIndex).getLastFileIndex();
        }
        this.render();
        this.displayNavigationArrows();
        this.$el.trigger('SAIFieldUpdate');
    }

    onFileProgress (evt) {
        if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total * 100;
            this.progress = percentComplete;
            this.render();
        }
    }

    /**
     * @returns The array of document ids that are associated with the field
     */
    getPendingDocuments () {
        return this.upFilesIds;
    }

    addFileOperation(noteIndex, docId, pendingId, operation) {
        if(!this.upFilesIds[noteIndex]) {
            this.upFilesIds[noteIndex] = new Note({noteId: this.getNote(noteIndex).getNoteId()});
        }
        this.upFilesIds[noteIndex].addPendingDocument(new PendingDocument({'documentId' : docId, 'operation': operation,'pendingId' : pendingId}));
    }

    renderPosition () {
        /*
         * Depending of the mode, we set the css on various entries
         */

        if (this.displayMode === 'tablet') {
            this.$el.css({
                width: this.locationProperties.fieldWidth,
                height: this.locationProperties.fieldHeight,
                top: this.locationProperties.top,
                'margin-left': this.locationProperties.fieldLeft
            });
        } else if (this.displayMode === 'desktop') {
            let labelLeftPos = this.locationProperties.labelLeft;
            let marginLeft = labelLeftPos;
            if (!labelLeftPos) {
                marginLeft = this.locationProperties.fieldLeft;
            }

            this.$el.css({
                width: this.locationProperties.fieldWidth,
                height: this.locationProperties.fieldHeight,
                top: this.locationProperties.top,
                'margin-left': marginLeft
            });

            if (this.fieldState.isLabelVisible(this.displayMode)) {
                this.getLabelElement().css({
                    position: 'absolute',
                    top: -25
                });
            } else {
                this.getLabelElement().css({
                    display: 'none'
                });
            }
        } else {
            console.error('invalid display mode for fields');
            App.displayError('invalid display mode for fields');
        }
    }

    getFieldElement () {
        return this.$el.find('.gedImageNew');
    }

    getMainElement () {
        return this.$el.find('.form-group');
    }

    getFileImage(noteIndex, fileIndex) {
        let note = this.getNote(noteIndex);
        if(note.getLastFileIndex() < fileIndex) {
            return null;
        } else {
            return note.getFile(fileIndex).getThumbnail().replace('#','%23');
        }
    }

    getFullFileImage(noteIndex, fileIndex) {
        let file = this.getNote(noteIndex).getFile(fileIndex);
        if(file !== null && file.getFull() !== undefined) {
            return file.getFull().replace('#','%23');
        } else {
            return this.getFileImage(noteIndex, fileIndex);
        }
    }

    addFileImage(noteIndex, thumbnail) {
        let note = this.getNote(noteIndex);
        note.addFile(new NoteFile({'thumbnail': thumbnail}));
    }

    getNote(index): Note {
        let notes = this.fieldState.getNotes();
        if(notes.length === 0) {
            notes.push(new Note({
                author: User.getId(),
                creationDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                files: [],
                modificationDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                noteId: '',
                noteText: ''
            }));
            this.fieldState.setNotes(notes);
            this.fieldState.setValue('1');
        }
        return notes[index];
    }

    getGedImageState(): string {
        return this.state;
    }

    getBackgroundImage(): string {
        return this.backgroundImage;
    }

    hasFiles():boolean {
        let notes = this.fieldState.getNotes();
        if(notes.length === 0) {
            return false
        } else {
            for(let i in notes) {
                let note = notes[i];
                if(note.getLastFileIndex() >= 0) {
                    return true;
                }
            }
        }
        return false;
    }
}

export default GedImageField;
