'use strict';

import FieldView from 'views/record/Field';
import App from 'App';
import Server from 'server/Server';

class GedDocumentField extends FieldView {
    constructor(options) {
        super(options);

        this.noteIndex = 0;
        this.fileIndex = 0;
    }

    onSizeUpdated (newSize){
        super.onSizeUpdated(newSize);
    }

    render() {
        super.render();
        var button = this.$el.find('.fieldButton');
        let files = this.fieldState.getNotes();
        if(files.length == 0) {
            button.prop('disabled',true);
        }
        button.on('click',this.downloadDocument.bind(this));
    }

    downloadDocument() {
        let files = this.fieldState.getNotes();
        var document = files[this.noteIndex].noteId;
        var url = Server.getTokenedUrl(files[this.noteIndex].getFile(0).full);
        App.downloadDocument(url);
    }
}

export default GedDocumentField;
