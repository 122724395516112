'use strict';

import User from 'User';

import DateField from 'views/record/subfields/Date';
import 'eonasdan-bootstrap-datetimepicker';
import DeviceUtils from '../../../utils/DeviceUtils';
import ScriptUtils from 'utils/ScriptUtils';

class DateTimeField extends DateField{
    public keepTime: boolean;

    constructor (options){
        super(options);
        this.visualDateFormat = 'DD/MM/YYYY HH:mm';
        this.keepTime = true;
    }

    getPickerOptions () {
        var collapse = false;
        if(this.displayMode === 'phone'){
            collapse = true;
        }
        var params = {
            locale: User.getLocale().toLowerCase(),
            format: this.visualDateFormat,
            collapse: collapse
        };

        params['ignoreReadonly'] = true;

        return params;
    }

    parseValue(value: string) : string {
        value = value || '';
        if(value.includes('$')) {
            //Script, we need to evaluate
            value = ScriptUtils.evalInContext(value, this.scriptContext);
        }
        let humanDate = /(\d\d)\/(\d\d)\/(\d\d\d\d)(.*)/g;
        if(value.match(humanDate)){
            //transformation of 21/07/2018 10:20 in 2018-07-21 10:20
            let match = humanDate.exec(value);
            value = match[3] + '-' + match[2] + '-' + match[1] + match[4];
        }
        return value;
    }

    protected getDefaultFormat():string {
        return 'YYYY-MM-DD HH:mm:ss';
    }
}

export default DateTimeField;
