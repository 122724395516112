'use strict';

class LanguageUtils
{
    static pluralize(text: string) {
        var words = text.split(' ');
        if (!words[0].endsWith('x') && !words[0].endsWith('s')) {
            words[0] = words[0] + 's';
        }
        return words.join(' ');
    }
        
}

export default LanguageUtils;