'use strict';

/**
 * A DomainContext represents a "package entry" that will be used to retrieve
 * specific entries from the parametrage. Each domain from the application
 * might be linked to the same or different DomainContext which mean for 
 * example that a same id combo can have a different representation in different
 * domains
 */
class DomainContext {
    private id: string;
    private domain: string;
    private exercice : any;

    /**
     * @param _domain The working domain.
     * @param _id optionnal domain. This is used when we want to copy a context.
     * If not given, the context will automatically get a new random unique id 
     */
    constructor(_domain: string, _id?: string) {
        this.domain = _domain;
        if(_id) {
            this.id = _id;
        } else {
            this.id = this.generateRandomId();
        }
    }

    /**
     * Returns the domain identifier
     */
    public getDomain() : string {
        return this.domain;
    }

    /**
     * Returns the unique id of this domain
     */
    public getId() : string {
        return this.id;
    }

    public getExercice() : any {
        return this.exercice;
    }

    public setExercice(exercice: any):void {
        this.exercice = exercice;
    }

    /**
     * Returns a copy of the given domain. This is useful as a domain can be
     * deprecated and changed. However an instance of a task might want to 
     * keep the original domain until it's closed. Local copies of domain 
     * context are thus performed where we need to perform tasks that will keep
     * the same domain context no matter what happens.
     */
    public clone(): DomainContext {
        let newDomainContext = new DomainContext(this.domain, this.id);
        newDomainContext.setExercice(this.exercice);
        return newDomainContext;
    }

    /**
     * This function generates an uuid for the domain.
     */
    private generateRandomId() : string{
        function s4() : string {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
    }
}

export { DomainContext };