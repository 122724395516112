'use strict';

import { ConfigurationRequest } from './ConfigurationRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';
import { Table } from '../../../../parametrage/structures/Table';

class TableRequest extends ConfigurationRequest {
    constructor(domain: DomainContext, _id? : string){
        super('table', domain, _id);
    }

    public postProcess(entity: Table): Promise<Table> {
        return new Promise<Table>((accept, reject) => {entity.initializeI18N()
            .then(() => {
                accept(entity);
            })
            .catch(reject);
        });
    }
}

export { TableRequest };