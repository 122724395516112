module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="searchElement">\n	<input type="text" class="form-control searchBar" placeHolder="Recherche"/>\n	<span class="glyphicon glyphicon-search searchBarFilterBtn"></span>\n</div>\n';
 if(!hideCriterias) { ;
__p += '\n<div class="criterias-filter">\n	<button type="button" class="' +
((__t = (enabled? '' : 'disabled-filter ')) == null ? '' : __t) +
'btn btn-default addFilter">+ Ajouter un critère</button>\n</div>\n';
 } ;


}
return __p
}