'use strict';

import * as _ from 'lodash';
let $ = require('jquery');

class ToastFactory
{
    private static _instance: ToastFactory;
    private location: number;
    private toasts : Array<{id: number, elem: JQuery<HTMLElement>}>;

    public static get Instance(): ToastFactory {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        this.location = 0;
        this.toasts = [];
    }

    displayText(text, durationMs) {
        this.generateToast({
            text: text
        }, '<div class="toast-text">${text}</div>', durationMs);
    }

    displayTextAndIcon(text, fontIcon, durationMs) {
        this.generateToast({
            text: text,
            icon: fontIcon
        }, '<div class="toast-textIcon"><div>${text}</div><i class="fa fa-${icon}"></i></div>', durationMs);
    }

    generateToast(params, template, durationMs) {
        var finalHtml = _.template(template)(params);

        var newEntry = {
            id: Math.floor(Math.random()*10000000),
            elem: $(finalHtml).appendTo($('body'))
        };

        for (var key in this.toasts) {
            this.toasts[key].elem.css({
                bottom: parseInt(this.toasts[key].elem.css('bottom')) + 32
            });
        }

        this.toasts.push(newEntry);

        function removeEntry(entry) {
            _.remove(this.toasts, function (n:any) {
                return n.id === entry.id;
            });

            newEntry.elem.remove();
        }

        newEntry.elem.fadeIn(400).delay(durationMs).fadeOut(400, removeEntry.bind(this, newEntry));
    }
}

export default ToastFactory.Instance;
