'use strict';

require('../../sai-custom.font');

import App from 'App';
import MessagePopup from 'views/message/MessagePopup';
import Logger from 'utils/Logger';

import Server from 'server/Server';

import ColorUtils from './utils/ColorUtils';

import * as Backbone from 'backbone';
let $ = require('jquery');

import ColorThief from 'color-thief-browser';

$(document).ready(function(){
    /**
     * Init of the webapp screen. The first panel will always be the login
     * @param {type} Backbone Backbone is requires to handle mvc
     * @param {type} Login the login view is required as it'll be displayed to the user
     * @returns {undefined}
     */
    Server.setGlobalFailureCallback(App.handleInternalError);
    Server.setGlobalAuthenticationMissingCallback(App.requestModalLogin.bind(App));

    if (App.getConfig().webTitle) {
        $(document).prop('title', App.getConfig().webTitle);
    }

    if (App.getConfig().appTheme) {
        $(document).find('meta[name="theme-color"]').attr('content',App.getConfig().appTheme);
    }

    App.messageView = MessagePopup;
    App.views.taskContainer = $('.taskContainer');
    App.views.taskContainer.hide();

    //App['router'] = new Router();

    Backbone.history.start();

    $(window).on('resize', App.onWindowResize);

    App.currentWidth = $(window).width();
    App['initialWidth'] = App.currentWidth;
    App['colorThief'] = {
        renderer : new ColorThief(),
        cache: {},
        getColor: function(source, key){
            if(this.cache[key] === undefined){
                try {
                    var fe = this.renderer.getColor(source);
                    this.cache[key] = ColorUtils.rgbToHex(fe[0], fe[1], fe[2]);
                }catch(err) {
                    Logger.warn('SimpleTask', 'handleIconLoaded', 'Cannot fetch color from img. If you\'re in localhost environment , it\'s ok');
                    $('body').children('canvas').remove();
                    this.cache[key] = App.getConfig().appTheme;
                }

            }
            return this.cache[key];
        }
    };

    $('head').append('<style type="text/css">.app-background-color{background-color: '+ App.getConfig().appTheme +' !important;}</style>');

});