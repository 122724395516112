'use strict';

import Backbone from 'backbone';

import App from 'App';
import { SAIView } from '../../../Additions';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import ColorUtils from 'utils/ColorUtils';

class TemplatesListPopupView extends SAIView {
    initialize (options) {
        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.checkMandatoryParameter(options, 'templates', 'The task to use');
        this.checkMandatoryParameter(options, 'taskId', 'The task to use');

        this.uniqId = parseInt(Math.random()*10000);
    }

    render () {
        this.checkInitialized();

        this.$el = $(this.template({
            operationTitle: 'Choix du template',
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            operationColor: App.getConfig().appTheme,
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: false,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: '',
            allowDismiss: true,
            colorUtils: ColorUtils
        }));

        this.documentsList = this.$el.find('.innerView');
        this.loadingSpin = this.$el.find('.loadingSpinner');
    }

    display () {
        var me = this;
        //rendering popup
        this.render();

        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            me.displayTemplateList();
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#'+me.uniqId).remove();
        });

        this.$el.modal('show');
    }

    hide(){
        this.$el.modal('hide');
    }

    displayTemplateList(){
        this.loadingSpin.hide();
        for(var key in this.parameters.templates){
            var currentTpl = this.parameters.templates[key];
            var newEl = $('<div class="templateEntry" data-id="'+currentTpl.id+'"><p>'+currentTpl.name+'</p><i class="fa fa-file-pdf-o"></i></div>');
            this.documentsList.append(newEl);
        }

        this.documentsList.find('.templateEntry').click(this.onTplClick.bind(this));
    }

    onTplClick(evt){
        var id = $(evt.currentTarget).data('id');
        this.trigger('templateSelected', id);
    }
}

export default TemplatesListPopupView;
