module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="filterBox"><div class="removeFilterBtn" data-role="' +
((__t = (key)) == null ? '' : __t) +
'">&times;</div>' +
((__t = (name)) == null ? '' : __t) +
' : ' +
((__t = (value)) == null ? '' : __t) +
'</div>';

}
return __p
}