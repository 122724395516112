'use strict';

import { TaskRequest } from './TaskRequest';
import { DomainContext } from '../../../../parametrage/DomainContext';

class PrintFileRequest extends TaskRequest{
    constructor(domain:DomainContext, task){
        super('printFile','POST', domain, task);
    }
}

export default PrintFileRequest;