'use strict';

import { DomainContext } from '../DomainContext';
import { AbstractTask } from './AbstractTask';
import { DataField } from './DataField';
import { Panel } from './Panel';
import { SubPanel } from './SubPanel';
import { Page } from './Page';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import User from 'User';
import { Field } from './Field';
import { Table } from './Table';
import DeviceUtils from '../../utils/DeviceUtils';

class Task extends AbstractTask {
    protected config: { id: string, label?:string, datafields: any, pages: any, screen: any, [prop:string]: any};
    private datafields: { [dfId: string] : DataField };
    private datafieldsArr: Array<DataField>;
    private panels: { [panId: string]: Panel};
    private panelsArr: Array<Panel>;
    private subPanel: SubPanel;
    private pages: { [pgId: string]: Page};
    private pagesArr: Array<Page>;
    private fields: {[fieldI18NId: string]: Field};
    private linkedTable: Table;

    private static PANEL_TYPE_SUBPANEL:string = 'subpanel';
    private static PANEL_TYPE_PANEL:string = 'panel';

    public static BUTTONS_VISIBILITY_ALWAYS:string = 'Always';
    public static BUTTONS_VISIBILITY_ONCHANGE:string = 'OnChange';

    public static HEADER_DISPLAY_MODE_NONE:string = 'None';
    public static HEADER_DISPLAY_MODE_SMALL:string = 'Small';
    public static HEADER_DISPLAY_MODE_BIG:string = 'Big';
    public static HEADER_DISPLAY_MODE_NOTE:string = 'Note';

    public static DEFAULT_ACCESS_RIGHTS:string = 'NMDRX';
    public static DEFAULT_FILTER_VALUE:string = 'DEFAULT_FILTER';
    public static DEFAULT_TERMINATE_POLICY:string = 'inputTerminatePolicy';

    private static ASYNC_PROP_LABEL: Symbol = Symbol();
    private static ASYNC_PROP_TABLE: Symbol = Symbol();

    constructor(config: { id: string , datafields: any, screen: any, pages: any}, context: DomainContext) {
        super(config, context);
        this.remainingAsyncProps = [ Task.ASYNC_PROP_LABEL, Task.ASYNC_PROP_TABLE ];
        this.fields = {};

        this.datafields = {};
        this.datafieldsArr = [];
        if(config.datafields) {
            for(let key in config.datafields) {
                let newDf = new DataField(config.datafields[key], null, null, this, context);
                this.datafields[newDf.getId()] = newDf;
                this.datafieldsArr.push(newDf);
            }
        }

        this.panels = {};
        this.panelsArr = [];
        for(let key in config.screen) {
            let panConf = config.screen[key];
            let oType = Task.getObjectType(panConf.objectType);
            if(oType === Task.PANEL_TYPE_SUBPANEL) {
                let newPan = new SubPanel(config.screen[key], this, context);
                this.subPanel = newPan;
                Object.assign(this.fields, newPan.getFields());
            } else if (oType === Task.PANEL_TYPE_PANEL){
                let newPan = new Panel(config.screen[key], this, context);
                this.panels[newPan.getId()] = newPan;
                this.panelsArr.push(newPan);
                Object.assign(this.fields, newPan.getFields());
            } else {
                console.warn('Invalid child type ' + oType);
            }
        }

        this.pages = {};
        this.pagesArr = [];
        for(let key in config.pages) {
            let newPage = new Page(config.pages[key], this, context);
            this.pages[newPage.getId()] = newPage;
            this.pagesArr.push(newPage);
            Object.assign(this.fields, newPage.getFields());
        }
    }

    public getLinkedTable() : Table {
        return this.linkedTable;
    }

    public setLinkedTable(table: Table) : void {
        this.linkedTable = table;
    }

    public getFields() {
        return this.fields;
    }

    public initializeI18N(): Promise<Array<string>> {
        let allPromises = [];
        let me = this;

        if(this.hasAsyncRequirements()) {
            //We need to fetch the resources
            let i18NTextPromise = CClientConfiguration.getI18nText(me.getContext(), 'task', this.getId(), User.getLocale());
            i18NTextPromise.then((i18NText) => {
                if(i18NText !== undefined) {
                    me.setLabel(i18NText);
                }
                me.removeFromAsyncRequirement(Task.ASYNC_PROP_LABEL);
            });
            allPromises.push(i18NTextPromise);

            //We need to fetch the table
            let tablePromise = CClientConfiguration.getTable(this.getTableID(), me.getContext());
            tablePromise.then((table: Table) => {
                me.setLinkedTable(table);
                
                me.removeFromAsyncRequirement(Task.ASYNC_PROP_TABLE);
            });
            allPromises.push(tablePromise);
        }

        //All datafield must be initialized as well
        for(let dfId in this.datafields) {
            let dfProm = this.datafields[dfId].initializeI18N();
            if(dfProm !== null){ allPromises.push(dfProm); }
        }
        //As well as all pages
        for(let dpId in this.pages) {
            let dpProm = this.pages[dpId].initializeI18N();
            if(dpProm !== null) { allPromises.push(dpProm); }
        }
        //As well as all panels
        for(let pnId in this.panels) {
            let pnProm = this.panels[pnId].initializeI18N();
            if(pnProm !== null) { allPromises.push(pnProm); }
        }

        if(this.subPanel) {
            let subProm = this.subPanel.initializeI18N();
            if(subProm !== null) { allPromises.push(subProm); }
        }

        return Promise.all(allPromises);
    }

    public getInputInterface(): string { return this.getValueOrDefault('intputInterface'); }
    public getAutoUpdateOnTaskChange(): string { return this.getValueOrDefault('autoUpdateOnTaskChange'); }

    public getEditTask(): string { return this.getValueOrDefault('editTask'); }
    public getViewTask(): string { return this.getValueOrDefault('viewTask'); }
    public getListTask(): string { return this.getValueOrDefault('listTask'); }

    public isVisible(): boolean { return this.getBooleanValueOrDefault('visible', true); }

    public canCallOnOpenTask(): boolean { return this.getBooleanValueOrDefault('callOnOpenTask', false); }
    public canCallOnPrepareNewEntry(): boolean { return this.getBooleanValueOrDefault('callOnPrepareNewEntry', false); }

    //Navigator properties
    public getNavigatorTableID(): string { 	return this.getValueOrDefault('navigatorTableId', this.getTableID()); }
    public getNavigatorGridView(): string {
        if(this.getNavigatorPresentation() === 'header') {
            return this.getHeaderID() ? this.getHeaderID() : this.getValueOrDefault('navigatorGridView');
        } else {
            return this.getValueOrDefault('navigatorGridView');
        }
    }
    public getHeaderID(): string { 	return this.getValueOrDefault('headerId'); }
    public getNavigatorFilter(): string {
        let propsOverride = this.getInitialPropsOverride();
        if(propsOverride && propsOverride['filterId'] !==  undefined) {
            return propsOverride['filterId'];
        } else {
            return this.getValueOrDefault('navigatorFilter', Task.DEFAULT_FILTER_VALUE);
        }
    }
    public isNavigatorImportEnabled(): boolean { return this.getBooleanValueOrDefault('navigatorImportEnabled', true); }
    public getNavigatorImportDomains(): string { return this.getValueOrDefault('navigatorImportDomains'); }
    public getNavigatorImportFilter(): string { return this.getValueOrDefault('navigatorImportFilter'); }
    public isNewASelect(): boolean { return this.getBooleanValueOrDefault('newIsSelect', false); }
    public getSelectionGridView(): string { return this.getValueOrDefault('selectionGridView'); }
    public canNavigatorAutoSelectFirst(): boolean { return this.getBooleanValueOrDefault('navigatorAutoSelectFirst', false); }
    public canNavigatorShowFastSearchPanelOnStartup(): boolean { return this.getBooleanValueOrDefault('navigatorShowFastSearchPanelOnStartup', false); }
    public canNavigatorSkipFirstLoad(): boolean { return this.getBooleanValueOrDefault('navigatorSkipFirstLoad', false); }
    public isNavigatorNewOnTop(): boolean { return this.getBooleanValueOrDefault('navigatorNewOnTop', false); }
    public getNavigatorFormater(): string { return this.getValueOrDefault('navigatorFormatter');}

    //Sub-navigator properties
    public getSubNavigatorTableID(): string { return this.getValueOrDefault('subNavigatorTableId'); }
    public getSubNavigatorGridView(): string { return this.getValueOrDefault('subNavigatorGridView'); }
    public getSubNavigatorFilter(): string { return this.getValueOrDefault('subNavigatorFilter'); }
    public canSubNavigatorAutoSelectFirst(): boolean { return this.getBooleanValueOrDefault('subNavigatorAutoSelectFirst', false); }

    //Propriétés de validation/fermeture/navigation
    public getButtonsVisibles(): string {
        let buttonsVisibility = this.getValueOrDefault('buttonsVisibles', Task.BUTTONS_VISIBILITY_ALWAYS);
        if(buttonsVisibility === 'true') {
            return Task.BUTTONS_VISIBILITY_ALWAYS;
        } else if(buttonsVisibility === 'false') {
            return Task.BUTTONS_VISIBILITY_ONCHANGE;
        } else {
            return buttonsVisibility;
        }
    }
    public canHeaderValidate(): boolean { return this.getBooleanValueOrDefault('headerValidation', true); }
    public canDisableHeaderOnValidate(): boolean { return this.getBooleanValueOrDefault('disableHeaderOnValidate', false); }
    public canReloadPagesOnValidate(): boolean { return this.getBooleanValueOrDefault('reloadPagesOnValidate', false); }
    public getHeaderPage(): string { return this.getValueOrDefault('headerInPage'); }
    public canSkipHeaderCreation(): boolean { return this.getBooleanValueOrDefault('skipHeaderCreation', false); }
    public getAutoSelectedPage(): string { return this.getValueOrDefault('autoSelectPage', this.getHeaderPage()); }
    public getInputTerminatePolicy(): string { return this.getValueOrDefault('inputTerminatePolicy', Task.DEFAULT_TERMINATE_POLICY); }
    public getSubButtonsVisibility(): string { return this.getValueOrDefault('subButtonsVisibles'); }
    public canClose(): boolean { return this.getBooleanValueOrDefault('canClose', true); }
    public canCloseWithoutTerminate(): boolean { return this.getBooleanValueOrDefault('canCloseWithoutTerminate', true); }
    public canLazyAddAndModify(): boolean { return this.getBooleanValueOrDefault('lazyAddAndModify', false); }
    public canInterruptLongTask(): boolean { return this.getBooleanValueOrDefault('canInterruptLongTask', false); }
    public canCollapseBreaks(): boolean { return this.getBooleanValueOrDefault('collapsibleBreaks', false); }
    public getOpenThrough(): string { return this.getValueOrDefault('openThrough'); }

    //Size of headers and content
    public getHeaderDisplayMode(): string { return this.getValueOrDefault('headerDisplayMode', Task.HEADER_DISPLAY_MODE_SMALL); }
    public getShowPageHeader(): string { return this.getValueOrDefault('showPageHeader'); }

    //Display of hep and menus
    public getGroupHelpDisplay(): string { return this.getValueOrDefault('getGroupShowHelp'); }
    public getShortcutsRightPanelDisplay(): string { return this.getValueOrDefault('showShortcutsRightPanel'); }
    public getDefaultWindowState(): string { return this.getValueOrDefault('defaultWindowState'); }

    //Restriction des droits définis dans le paramétrage
    public getHeaderEnabledActions(): string { return this.getValueOrDefault('headerEnabledActions', Task.DEFAULT_ACCESS_RIGHTS); }
    public getSubLevelEnabledActions(): string { return this.getValueOrDefault('subLevelEnabledActions',  Task.DEFAULT_ACCESS_RIGHTS); }

    //Duplications
    public canDuplicate(): boolean { return this.getBooleanValueOrDefault('enableDuplicate', false); }
    public canSubNavigatorDuplicate(): boolean { return this.getBooleanValueOrDefault('enableSubNavigatorDuplicate', false); }
    public canMultipleDelete(): boolean { return this.getBooleanValueOrDefault('enableMultipleDelete', false); }
    public canSubNavigatorMultipleDelete(): boolean { return this.getBooleanValueOrDefault('enableSubNavigatorMultipleDelete', false); }

    //Flèches pour inverser l'ordre dans le sous navigateur
    public canShowSubNavigatorMoveArrows(): boolean { return this.getBooleanValueOrDefault('enableSubNavigatorMoveArrows', false); }
    public getSubNavigatorMoveArrowsDataFieldID(): string { return this.getValueOrDefault('subNavigatorMoveArrowsDataFieldID'); }

    //Mailbox and files handling
    public canDropMails(): boolean { return this.getBooleanValueOrDefault('isMailbox', false); }
    public canSubDropMails(): boolean { return this.getBooleanValueOrDefault('subIsMailbox', false); }
    public canDropFiles(): boolean { return this.getBooleanValueOrDefault('allowFileDrop', false); }

    /** Exercice might be retrieved from another chain */
    public getInheritsDomainVarsFromChain(): string { return this.getValueOrDefault('inheritsDomainVarsFromChain'); }

    /**
     * Defines if we've to look up for workflow instances on the current record
     * If set to true, the retrieval will be allowed by the global property :
     * WORKFLOW_RETRIEVAL_ON_RECORD ON/OFF
     */
    public canLookupWorkflowsOnOpen(): boolean { return this.getBooleanValueOrDefault('lookupWorkflowsOnOpen', true); }

    /**
     * Looks for the given id taskonly datafield in the task
     * @param dfId The id of the datafield to retrieve
     */
    public getDataField(dfId: string) : DataField {
        return this.datafields[dfId];
    }

    /**
     * Returns an array of all taskonly datafields of the task
     */
    public getDataFields(): Array<DataField> {
        return this.datafieldsArr;
    }

    public hasDataField(dfId: string): boolean {
        return this.datafieldsArr[dfId] !== undefined;
    }

    public addDataField(datafield: DataField): void {
        this.datafields[datafield.getId()] = datafield;
        this.datafieldsArr.push(datafield);
    }

    /**
     * Returns the specific page identified by the given datapageId
     * @param datapageId The datapageId of the page to fetch
     */
    public getPage(dpId: string): Page {
        return this.pages[dpId];
    }

    /**
     * Returns the configuration ordered array of pages within this task
     */
    public getPages() : Array<Page> {
        return this.pagesArr;
    }

    /**
     * Returns the specific panel identified by the given panel id
     * @param panelId The panel id to fetch
     */
    public getPanel(panId: string): Panel {
        return this.panels[panId];
    }

    /**
     * Returns the configuration ordered array of panels within this task
     */
    public getPanels() : Array<Panel> {
        return this.panelsArr;
    }

    public getSubPanel(): SubPanel {
        return this.subPanel;
    }

    public clone(): Task {
        let clonedConfig = JSON.parse(JSON.stringify(this.config));
        let task = new Task(clonedConfig, this.getContext());
        task.setLinkedTable(this.getLinkedTable());
        let currentFields = this.getFields();
        let clonedFields = task.getFields();
        for(let fId in currentFields) {
            clonedFields[fId].copyLinksFrom(currentFields[fId]);
        }
        return task;
    }

    getIconConfig() {
        return this.config.iconConfig;
    }

    setIconConfig(iconConfig) {
        this.config.iconConfig = iconConfig;
    }

    public setInputInterface(inputInterface: string): void { 
        this.config.inputInterface = inputInterface;
    }

    public getNavigatorPresentation():string {
        if(DeviceUtils.isMobile() || DeviceUtils.isMediumDevice() || DeviceUtils.isSmallDevice() || this.getType() === 'R') {
            return 'header';
        } else {
            return this.getValueOrDefault('navigatorPresentation','grid');
        }
    }

    public getFieldFromDataField(dataFieldId : String) : Field {
        for(let key in this.fields) {
            let field = this.fields[key];
            //couples datafield might contain a $
            if(field.getDatafieldId() === dataFieldId || field.getValue() === '$' + dataFieldId) {
                return field;
            }
        }
        return undefined;
    }

    public getKeys() {
        return this.linkedTable.getKeyDatafields();
    }

    public getKeysForNewEntry(): Array<any> {
        const formattedKey = [];
        const keys = this.getKeys();
        for(let i in keys) {
            const df = keys[i];
            formattedKey.push({
                datafieldId: df.getConfig().id,
                value: ''
            });
        }
        return formattedKey;
    }
}

export { Task };