module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group gps-form-group">\n    <label for="' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'</label>\n    <div class="form-control"></div>\n</div>';

}
return __p
}