'use strict';

import { SAIView } from '../../Additions';
import ScriptUtils from 'utils/ScriptUtils';
import App from 'App';
import User from 'User';
import Server from 'server/Server';
import DomainManager from 'DomainManager';
import MenuItemView from './MenuItem';
import { DomainContext } from '../../parametrage/DomainContext';
let $ = require('jquery');

class DesktopActionsMenuView extends SAIView {
    private actionsList:Array<MenuItemView>;
    private domainContext: DomainContext;
    private toggleState: string;

    constructor(options) {
        super(options);
        this.actionsList = options.actionsList;
        this.domainContext = DomainManager.getCurrentContext(User.getCurrentDomain());
        this.toggleState = options.toggleState;
    }

    render():any {
        this.$el = $('<div class="desktop-page-menu"><div class="toggle-menu" title="Réduire le menu"><span class="toggle-menu-label">Réduire</span><span class="toggle-menu-icon"></span></div></div>');
        this.$el.addClass(this.toggleState);
        App.getSvgIcon(Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-open-menu'),{h:20, w:20})
            .then((element: JQuery<HTMLElement>) => {
                element.addClass('menu-open-button');
                this.$el.find('.toggle-menu-icon').append(element);
            });
        this.$el.find('.toggle-menu').on('click', this.toggleMenu.bind(this));
        
        // Remove all the listener because they will be added in the loop
        this.stopListening();
        for (var i in this.actionsList) {
            this.addMenuItem(this.actionsList[i], true);
        }

        return this.$el;
    }

    toggleMenu() {
        if (this.$el !== undefined) {
            if (this.$el.hasClass('closed')) {
                this.$el.removeClass('closed');
                this.toggleState = '';
            } else {
                this.$el.addClass('closed');
                this.toggleState = 'closed';
            }
        }
    }

    addMenuItem(menuItem: MenuItemView, alreadyInserted?: boolean) {
        menuItem.render();
        this.$el.append(menuItem.$el);
        menuItem.onDomUpdated();
        this.listenTo(menuItem, 'action', this.bubbleMenuAction.bind(this));
        if(!alreadyInserted) {
            this.actionsList.push(menuItem);
        }
    }

    setToggleState(toggleState: string) {
        this.toggleState = toggleState;
        this.toggleMenu();
    }

    bubbleMenuAction(menuItem, actionType, actionArguments) {
        this.trigger('action', actionType, actionArguments);
    }

    setActionsList(list:Array<MenuItemView>) {
        this.actionsList = list;
    }

    removeActionsWithScope(actionScope: string):void {
        let i = this.actionsList.length;
        while(i > 0) {
            i--;
            let item = this.actionsList[i];
            if(item.getActionScope() === actionScope) {
                item.$el.remove();
                this.actionsList.splice(i,1);
            }
        }
    }

    displayActionsWithScope(scope: string, context: {[prop:string]: any}): void {
        this.shouldDisplayActionWithScope(scope, context, true);
    }

    hideActionsWithScope(scope: string): void {
        this.shouldDisplayActionWithScope(scope, {}, false);
    }

    shouldDisplayActionWithScope(scope: string, context: {[prop:string]: any}, display: boolean): void {
        for(let i in this.actionsList) {
            let item:MenuItemView = this.actionsList[i];
            if(item.getScope() === scope) {
                if(!display) {
                    item.$el.hide();
                } else {
                    //Check of the visibility condition
                    let visible = true;
                    let visibilityCondition = item.getVisible();
                    if(visibilityCondition !== undefined) {
                        visible = ScriptUtils.evalInContext(visibilityCondition, context);
                    }
                    if(visible === true) {
                        item.$el.show();
                    } else {
                        item.$el.hide();
                    }
                }
            }
        }
    }

    toggleActionsVisibilityFromMultiContext(scope: string, contextArray: Array<{[prop:string]: any}>, display: boolean) {
        for(let i in this.actionsList) {
            let item:MenuItemView = this.actionsList[i];
            if(item.getScope() === scope) {
                if(!display) {
                    item.$el.hide();
                } else {
                    //Check of the visibility condition
                    let visible = true;
                    let visibilityCondition = item.getVisible();
                    for(var index in contextArray) {
                        var context = contextArray[index];
                        visible = visible && ScriptUtils.evalInContext(visibilityCondition, context);
                    }
                    if(visible === true) {
                        item.$el.show();
                    } else {
                        item.$el.hide();
                    }
                }
            }
        }
    }

    isVisibleFromContext(visibilityCondition:string, context:{[prop:string]: any}):boolean {
        if(visibilityCondition !== undefined) {
            return ScriptUtils.evalInContext(visibilityCondition, context);
        } else {
            return true;
        }
    }

    getDisplayedItems(): Array<MenuItemView> {
        let finalActions = [];
        for(let i in this.actionsList) {
            let item:MenuItemView = this.actionsList[i];
            if(item.$el.is(':visible')) {
                finalActions.push(item);
            }
        }
        return finalActions;
    }

    setItemEnable(itemId:string, enabled: boolean): void {
        for(let i in this.actionsList) {
            let item:MenuItemView = this.actionsList[i];
            if(item.id === itemId) {
                item.setEnable(enabled);
            }
        }
    }

    deleteActionsWithScope(scope:string):void {
        for(let i in this.actionsList) {
            let item:MenuItemView = this.actionsList[i];
            if(item.getActionScope() === scope) {
                item.remove();
            }
        }
    }

} export default DesktopActionsMenuView;