'use strict';

import * as Backbone from 'backbone';
import User from 'User';

import * as _ from 'lodash';
import * as moment from 'moment';

/**
 * GridStatsEngine object that allows to compute graph series from a
 * set of data and criterias
 *
 * @param attributes The attributes given to the function
 * @param options The options
 *
 * @type GridStatsEngine
 */
class GridStatsEngine extends Backbone.Model
{
    inputType : string;
    criteria1: string;
    grouping1: string;
    range1: string
    criteria2: string;
    grouping2: string;
    range2: string;
    headers: any;
    rawData: Array<any>;
    data: Array<any>;
    scaleCreated: {[key:string]:any};

    constructor(attributes:any, options?:any) {
        super(attributes, options);

        attributes = attributes || {};

        if (attributes.headers) {
            this.parseHeaders(attributes.headers);
        }

        if (attributes.inputType) {
            this.inputType = attributes.inputType;
        }

        if (attributes.criteria1) {
            this.criteria1 = attributes.criteria1;
        }

        if (attributes.grouping1) {
            this.grouping1 = attributes.grouping1;
        }

        if (attributes.range1) {
            this.range1 = attributes.range1;
        }

        if (attributes.criteria2) {
            this.criteria2 = attributes.criteria2;
        }

        if (attributes.grouping2) {
            this.grouping2 = attributes.grouping2;
        }

        if (attributes.range2) {
            this.range2 = attributes.range2;
        }

        this.initialize.apply(this, arguments);
    }
    
    initialize() {
            if (!this.headers || typeof this.headers !== 'object') {
                throw 'Array of headers definition are mandatory in engine';
            }

            if (!this.inputType || typeof this.inputType !== 'string') {
                throw 'String input column must be given in the constructor. Example : NAV1_TOT';
            }

            if (!this.criteria1 || typeof this.inputType !== 'string') {
                throw 'String criteria 1 is mandatory for the engine to be able to compute';
            }
        }

        setRawData(rawDataCollection) {
            this.rawData = rawDataCollection;
            this.filterData(this.rawData);
        }
        
        setCriteria1(newCrite) {
            if (newCrite) {
                this.criteria1 = newCrite;
            }
        }

        setCriteria2(newCrite) {
            if (newCrite) {
                this.criteria2 = newCrite;
            }
        }

        setGrouping1(newCrite) {
            if (newCrite) {
                this.grouping1 = newCrite;
            }
        }

        setGrouping2(newCrite) {
            if (newCrite) {
                this.grouping2 = newCrite;
            }
        }

        setRange1(newCrite) {
            if (newCrite) {
                this.range1 = newCrite;
            }
        }

        setRange2(newCrite) {
            if (newCrite) {
                this.range2 = newCrite;
            }
        }

        setInputType(newInput) {
            if (newInput) {
                this.inputType = newInput;
            }
        }

        filterData(rawData) {
            var me = this;
            me.data = [];

            rawData.each(function (item) {
                if (me.keepOnlyDataRows(item)) {
                    me.data.push(item.toJSON().columns);
                }
            });
        }

        keepOnlyDataRows(row) {
            return row.get('type') === 'data';
        }

        parseHeaders(rawHeaders) {
            this.headers = {};

            for (var key in rawHeaders) {
                var current = rawHeaders[key];
                this.headers[current.dataIndex] = current;
            }
        }

        generateSeries() {
            var finalData = this.data;
            var recursive = false;
            if (this.criteria2) {
                finalData = this.splitDataFromCriteria(this.criteria2, finalData, recursive, this.grouping2, this.range2);
                recursive = true;
            }

            finalData = this.splitDataFromCriteria(this.criteria1, finalData, recursive, this.grouping1, this.range1);

            return finalData;
        }

        splitDataFromCriteria(criteriaId, data, recursive, grouping, range) {
            /*
             * First, we check if the current dataset is an array or a map. If
             * it's a map, then it means that we already processed the data with
             * another criteria and thus we've to call this function recursively
             * on the sub data children
             */
            if (recursive) {

                for (var key in data) {
                    data[key].children = this.splitDataFromCriteria(criteriaId, data[key].rows, false, grouping, range);
                }
                return data;

            } else {
                var headerFormat = this.headers[criteriaId].format;

                if (headerFormat === 'List') {
                    //We've to duplicate the rows that contain multiple values
                    //in the criteria column
                    var toRemove = [];
                    var toAdd = [];
                    for (var dataIdx in data) {
                        var currentRow = data[dataIdx];
                        var currentValues = currentRow[criteriaId].value.split(',').map(item => item.trim());
                        if (currentValues.length > 1) {
                            toRemove.push(dataIdx);
                            for (var valId in currentValues) {
                                var newRow = _.cloneDeep(data[dataIdx]);
                                newRow[criteriaId].value = currentValues[valId];
                                toAdd.push(newRow);
                            }
                        }
                    }

                    for (var i = toRemove.length - 1; i >= 0; i--) {
                        data.splice(toRemove[i], 1);
                    }

                    for (var toAddIdx in toAdd) {
                        data.push(toAdd[toAddIdx]);
                    }
                }

                var operation;
                var inputCol;
                if (this.inputType.endsWith('#occ')) {
                    operation = 'occ';
                } else if (this.inputType.endsWith('_TOT')) {
                    operation = 'tot';
                    inputCol = this.inputType.substring(0, this.inputType.length - 4);
                } else {
                    operation = 'moy';
                    inputCol = this.inputType.substring(0, this.inputType.length - 4);
                }

                var categories = {};

                this.scaleCreated = undefined;
                for (var rowIdx in data) {
                    if(!this.scaleCreated){
                        this.scaleCreated = {

                        };
                    }

                    if(!this.scaleCreated[criteriaId]){
                        this.generateScale(categories, headerFormat, grouping, range);
                        this.scaleCreated[criteriaId] = true;
                    }

                    var curRow = data[rowIdx];
                    var bucketProps = this.getBucketName(headerFormat, curRow[criteriaId].value, grouping, range);
                    var bucket = bucketProps.name;
                    if (!categories[bucket]) {
                        categories[bucket] = {
                            inputsValue: 0,
                            order: bucketProps.order,
                            rows: []
                        };
                    }
                    categories[bucket].rows.push(curRow);
                    if (operation === 'occ') {
                        categories[bucket].inputsValue++;
                    } else {
                        categories[bucket].inputsValue += parseFloat(curRow[inputCol].value);
                    }
                }

                for (var catKey in categories) {
                    var currentCate = categories[catKey];
                    if (operation === 'moy') {
                        if(currentCate.rows.length > 0){
                            currentCate.inputsValue = currentCate.inputsValue / currentCate.rows.length;
                        }
                    }

                    if (headerFormat === 'Enum') {
                        currentCate.label = this.headers[criteriaId].enumValues[catKey];
                    } else if (headerFormat === 'Boolean') {
                        currentCate.label = catKey === '1' ? '✔' : '✗';
                    }
                }

                return categories;
            }
        }

        generateScale(categories, headerFormat, grouping, range){
            if (headerFormat === 'Date' || headerFormat === 'DateTime') {
                var date = moment('2015-01-01', 'YYYY-MM-dd');
                switch (grouping) {
                    case 'DATESAMEWEEKDAY':
                        for(var weekDay = 1; weekDay < 8; weekDay ++){
                            var bucketProps = this.getBucketName(headerFormat, date.isoWeekday(weekDay).format('YYYY-MM-DD HH:mm:ss'), grouping, range);
                            categories[bucketProps.name] = {
                                inputsValue: 0,
                                order: bucketProps.order,
                                rows: []
                            };
                        }
                        break;
                    case 'DATESAMEMONTHDAY':
                        for(var monthDay = 1; monthDay < 32; monthDay ++){
                            var bucketPs = this.getBucketName(headerFormat, date.date(monthDay).format('YYYY-MM-DD HH:mm:ss'), grouping, range);
                            categories[bucketPs.name] = {
                                inputsValue: 0,
                                order: bucketPs.order,
                                rows: []
                            };
                        }
                        break;
                    case 'DATESAMEMONTH':
                        for(var month = 0; month < 12; month ++){
                            var bucketPrps = this.getBucketName(headerFormat, date.month(month).format('YYYY-MM-DD HH:mm:ss'), grouping, range);
                            categories[bucketPrps.name] = {
                                inputsValue: 0,
                                order: bucketPrps.order,
                                rows: []
                            };
                        }
                        break;
                    case 'DATEYEAR':
                        break;
                    case 'DATEMONTH':
                        break;
                    case 'DATEDAY':
                        break;
                    case 'DATEHOURSOFDAY':
                        for(var hour = 0; hour < 24; hour ++){
                            var bucketHPrps = this.getBucketName(headerFormat, date.hour(hour).format('YYYY-MM-DD HH:mm:ss'), grouping, range);
                            categories[bucketHPrps.name] = {
                                inputsValue: 0,
                                order: bucketHPrps.order,
                                rows: []
                            };
                        }
                        break;

                }
            }
        }

        getBucketName(headerFormat, value, grouping, range) {
            if (headerFormat === 'String' || headerFormat === 'Enum' ||
                    headerFormat === 'Boolean' || headerFormat === 'List' ||
                    headerFormat === 'Text') {
                return {
                    name: value,
                    order: value
                };
            } else if (headerFormat === 'Number' || headerFormat === 'Integer') {
                var tmpDiv = grouping.substring(3);
                var divider = parseFloat(tmpDiv);
                var result = value / (divider * parseFloat(range));
                var botBucket = Math.floor(result);
                return {
                    name: botBucket + '-' + (botBucket + divider),
                    order: botBucket + ''
                };
            } else if (headerFormat === 'Date' || headerFormat === 'DateTime') {
                var date = moment(value, 'YYYY-MM-DD HH:mm:ss');
                switch (grouping) {
                    case 'DATEDAY':
                        return this.groupByDay(date, range);
                    case 'DATESAMEWEEKDAY':
                        return this.groupBySameWeekDay(date, range);
                    case 'DATESAMEMONTHDAY':
                        return this.groupBySameMonthDay(date, range);
                    case 'DATESAMEMONTH':
                        return this.groupBySameMonth(date, range);
                    case 'DATEYEAR':
                        return this.groupByYear(date, range);
                    case 'DATEMONTH':
                        return this.groupByMonth(date, range);
                    case 'DATEHOURSOFDAY':
                        return this.groupByHoursOfDay(date, range);
                }
                return {name: 'not implemented yet'};
            }
            return {name: 'unknown grouping'};
        }

        groupByHoursOfDay(date, range){
            if(range === 1){
                return {
                    name: date.format('HH'),
                    order: this.pad(date.hour(),2)
                };
            }else{
                var splitBuckets = this.getSplittedBucket(date.hour()+1, range, 24);

                //Scaling back to 0-11
                splitBuckets.start = splitBuckets.start - 1;
                splitBuckets.end = splitBuckets.end - 1;

                var name = '';
                if (splitBuckets.end === splitBuckets.start) {
                    name = date.hour(splitBuckets.start).format('HH');
                } else {
                    name = date.hour(splitBuckets.start).format('HH') + '-' + date.hour(splitBuckets.end).format('HH');
                }
                return{
                    name: name,
                    order: this.pad(splitBuckets.start, 2)
                };
            }
        }

        groupByDay(date, range){
            if(range === 1){
                return {
                    name: date.format('DD MMM YY'),
                    order: date.format('YYYY-MM-DD')
                };
            }else{
                var date1900 = moment('1900-01-01', 'YYYY-MM-DD');
                date = date.startOf('day');
                var splitBuckets = this.getSplittedBucket(date.diff(date1900, 'days'), range, Number.MAX_VALUE);

                var name = '';

                var endDate = date1900.clone();
                name = date1900.add(splitBuckets.start, 'days').format('DD MMM YYYY') + '-';
                name += endDate.add(splitBuckets.end, 'days').format('DD MMM YYYY');

                return{
                    name: name,
                    order: date.millisecond(splitBuckets.start).format('YYYYMMDD')
                };
            }
        }

        groupBySameWeekDay(date, range) {
            date = date.locale(User.getLocale().toLowerCase());
            if (range === 1) {
                return {
                    name: date.format('ddd'),
                    order: date.isoWeekday() + ''
                };
            }

            var splitBuckets = this.getSplittedBucket(date.isoWeekday(), range, 7);
            var name = '';
            if (splitBuckets.end === splitBuckets.start) {
                name = date.isoWeekday(splitBuckets.start).locale(User.getLocale().toLowerCase()).format('ddd');
            } else {
                name = date.isoWeekday(splitBuckets.start).format('ddd') + '-' + date.isoWeekday(splitBuckets.end).format('ddd');
            }
            return{
                name: name,
                order: this.pad(splitBuckets.start, 2)
            };
        }

        groupBySameMonthDay(date, range) {
            if (range === 1) {
                return {
                    name: date.date(),
                    order: this.pad(date.date(), 2)
                };
            } else {
                var splitBuckets = this.getSplittedBucket(date.date(), range, 31);

                var name = '';
                if (splitBuckets.end === splitBuckets.start) {
                    name = splitBuckets.start.toString();
                } else {
                    name = splitBuckets.start + '-' + splitBuckets.end;
                }
                return{
                    name: name,
                    order: this.pad(splitBuckets.start, 2)
                };
            }
        }

        groupByMonth(date, range){
            if(range === 1){
                return {
                    name: date.format('MMM YY'),
                    order: date.year() + this.pad(date.month(),2)
                };
            }else{
                var splitBuckets = this.getSplittedBucket(date.year()*12 + date.month()- (1900*12), range, Number.MAX_VALUE);

                //Scaling back to proper date
                splitBuckets.startYear = Math.floor(splitBuckets.start/12)+1900;
                splitBuckets.startMonth = splitBuckets.start - (splitBuckets.startYear - 1900) * 12;
                splitBuckets.endYear = Math.floor(splitBuckets.end/12)+1900;
                splitBuckets.endMonth = splitBuckets.end - (splitBuckets.endYear - 1900) * 12;

                var name = date.year(splitBuckets.startYear).month(splitBuckets.startMonth).format('MMM YYYY') + '-';
                name += date.year(splitBuckets.endYear).month(splitBuckets.endMonth).format('MMM YYYY');

                return{
                    name: name,
                    order: date.year(splitBuckets.endYear).month(splitBuckets.endMonth).format('YYYYMM')
                };
            }
        }

        groupByYear(date, range){
            if(range === 1){
                return {
                    name: date.format('YYYY'),
                    order: date.year() + ''
                };
            }else{
                var splitBuckets = this.getSplittedBucket(date.year()-1970, range, Number.MAX_VALUE);

                //Scaling back to proper date
                splitBuckets.start = splitBuckets.start + 1970;
                splitBuckets.end = splitBuckets.end + 1970;

                var name = date.year(splitBuckets.start).format('YYYY');
                name = date.year(splitBuckets.start).format('YYYY') + '-' + date.year(splitBuckets.end).format('YYYY');

                return{
                    name: name,
                    order: splitBuckets.start
                };
            }
        }

        groupBySameMonth(date, range){
            date = date.locale(User.getLocale().toLowerCase());
            if (range === 1){
                return{
                    name: date.format('MMM'),
                    order: this.pad(date.month(), 2)
                };
            }else{
                var splitBuckets = this.getSplittedBucket(date.month()+1, range, 12);

                //Scaling back to 0-11
                splitBuckets.start = splitBuckets.start - 1;
                splitBuckets.end = splitBuckets.end - 1;

                var name = '';
                if (splitBuckets.end === splitBuckets.start) {
                    name = date.month(splitBuckets.start).format('MMM');
                } else {
                    name = date.month(splitBuckets.start).format('MMM') + '-' + date.month(splitBuckets.end).format('MMM');
                }
                return{
                    name: name,
                    order: this.pad(splitBuckets.start, 2)
                };
            }
        }

        pad(num, size) {
            var s = num + '';
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }

        getSplittedBucket(num, range, max) {
            var nthBucket = Math.floor((num - 1) / range);
            var startBucket = nthBucket * range + 1;
            var endBucket = Math.min(startBucket + range - 1, max);
            return {
                start: startBucket,
                end: endBucket,
                startYear: undefined,
                startMonth: undefined,
                endYear: undefined,
                endMonth: undefined
            };
        }
}

export default GridStatsEngine;
